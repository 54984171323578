import gymRemunerationProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';
import { GymRemunerationCreate } from './GymRemunerationCreate';
import { GymRemunerationEdit } from './GymRemunerationEdit';
import { GymRemunerationList } from './GymRemunerationList';

import GymRemunerationIcon from '@material-ui/icons/AttachMoney';

export default {
  name: 'GymRemuneration',
  resources: {
    create: GymRemunerationCreate,
    edit: GymRemunerationEdit,
    list: GymRemunerationList,
  },
  icon: GymRemunerationIcon,
  dataProvider: {
    getOneName: 'gymRemuneration',
    customDataProvider: gymRemunerationProvider,
    useCustomDP: true,
  },
} as IResourceExport;
