import { IResourceExport } from '../../@types/dataProvider';
import { ErpUserAccountsList } from './ErpUserAccountsList';

import erpUserAccountsProvider from './dataProvider';
import { ErpUserAccountsEdit } from './ErpUserAccountsEdit';

export default {
  name: 'ErpUserAccounts',
  resources: {
    list: ErpUserAccountsList,
    edit: ErpUserAccountsEdit,
  },
  dataProvider: {
    getOneName: 'erpUserAccounts',
    useCustomDP: true,
    customDataProvider: erpUserAccountsProvider,
  },
} as IResourceExport;
