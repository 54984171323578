import passwordResetProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'PasswordReset',
  dataProvider: {
    getOneName: 'passwordReset',
    useCustomDP: true,
    customDataProvider: passwordResetProvider,
  },
} as IResourceExport;
