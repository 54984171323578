export function isHideCompanyChannelToggleDisabled(formData: any) {
  if (formData.isSponsorshipPlatform) {
    formData.hideCompanyChannel = true;
    return true;
  }

  return false;
}

export function isValidEmail(email: string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export type EmployeeIdentifiers = {
  refKey: string;
  email?: string | null;
};
