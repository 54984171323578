import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allGymInfrastructure(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: GymInfrastructureFilter
  ) {
    items: allGymInfrastructure(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      slug
      name
    }
    total: _allGymInfrastructureMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetManyReference = (params: any) => {
  const preparedVariables = {
    filter: { id: params.id },
  };

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: (resp: any) => {
      return {
        data: resp?.data?.items || [],
        total: resp.data.total.count,
      };
    },
  };
};
