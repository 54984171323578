import gql from 'graphql-tag';

import { difference } from '../../../utils';
import { gymRemFields } from './GetOne';

const CREATE_MUTATION = gql`
  mutation createGymRemuneration(
    $iban: String
    $sourceType: GymRemunerationSourceType!
    $sourceID: ID!
    $effectiveAt: DateTime!
    $discoveryPass: Int
    $monthlyFirmCapping: Int
    $type: GymRemunerationType!
    # Standard & Monthly capped & peak hour (=== normal price)
    $price: Float
    # Monthly capped
    $capping: Int
    # Degressivity
    $tiers: [GymRemunerationTier!]
    # Peak hours
    $peakHourPrice: Float
    $intervals: [GymRemunerationOffPeakHourIntervals!]
    # Gym Offers
    $gymOffersInput: [GymOfferInput!]
  ) {
    createGymRemuneration(
      input: {
        iban: $iban
        sourceType: $sourceType
        sourceID: $sourceID
        effectiveAt: $effectiveAt
        discoveryPass: $discoveryPass
        monthlyFirmCapping: $monthlyFirmCapping
        type: $type
        price: $price
        capping: $capping
        tiers: $tiers
        peakHourPrice: $peakHourPrice
        intervals: $intervals
        gymOffersInput: $gymOffersInput
      }
    ) {
      data: gymRemuneration {
        ...GymRemFields
      }
    }
  }
  ${gymRemFields}
`;

const UPDATE_MUTATION = gql`
  mutation updateGymRemuneration($id: ID!, $iban: String!) {
    updateGymRemuneration(input: { id: $id, iban: $iban }) {
      data: gymRemuneration {
        ...GymRemFields
      }
    }
  }
  ${gymRemFields}
`;

type MultiOffer = {
  name: {
    en_EN: string;
    fr_FR: string;
    nl_NL: string;
  };
  price: number;
  costInCredits: number;
  maxPublicPrice: number;
};

const formatCreateInput = ({
  type,
  iban,
  sourceType,
  sourceID,
  effectiveAt,
  discoveryPass,
  monthlyFirmCapping,
  price,
  capping,
  degressivityType,
  tiers,
  peakHourPrice,
  intervals,
  gymOffers,
}: {
  type: string;
  iban?: string;
  sourceType: string;
  sourceID: string;
  effectiveAt: Date;
  discoveryPass?: number;
  monthlyFirmCapping?: number;
  price?: number;
  capping?: number;
  degressivityType?: 'VOLUME' | 'GRADUATED' | 'USER_LIFETIME_USAGE';
  tiers?: { price: number; limit?: number }[];
  peakHourPrice?: number;
  intervals?: string[];
  gymOffers?: MultiOffer[];
}) => {
  let result = {};
  switch (type) {
    case 'STANDARD':
      result = {
        type: 'STANDARD',
        price,
        gymOffersInput:
          gymOffers?.map((offer) => ({
            name: {
              fr_FR: offer.name.fr_FR,
              en_EN: offer.name.en_EN,
              nl_NL: offer.name.nl_NL,
            },
            price: offer.price,
            costInCredits: offer.costInCredits,
            maxPublicPrice: offer.maxPublicPrice,
          })) ?? [],
      };
      break;
    case 'MONTHLY_CAPPED':
      result = {
        type: 'MONTHLY_CAPPED',
        price,
        capping,
      };
      break;
    case 'DEGRESSIVITY':
      // We need to put -1 in the last tier before sending it to the API ;)
      tiers![tiers!.length - 1] = { ...tiers![tiers!.length - 1], limit: -1 };
      result = {
        type: `DEGRESSIVITY_${degressivityType}`,
        tiers: tiers,
      };
      break;
    case 'PEAK_HOURS':
      throw new Error('Cannot create legacy Peak Hour remuneration model');
    case 'OFF_PEAK_HOURS':
      result = {
        type: 'OFF_PEAK_HOURS',
        price,
        peakHourPrice,
        intervals,
      };
      break;
    default:
      break;
  }

  return {
    iban,
    sourceID,
    sourceType,
    effectiveAt,
    discoveryPass,
    monthlyFirmCapping,
    ...result,
  };
};

// intervals can be GymRemunerationPeakHourIntervals or GymRemunerationOffPeakHourIntervals
// but GymRemunerationPeakHourIntervals are obsolete so me must only retain
// GymRemunerationOffPeakHourIntervals values when creating a new gymRemuneration,
// GymRemunerationOffPeakHourIntervals = [ 'MORNING' , 'AFTERNOON' ]
const sanitizeIntervals = (intervals?: string[]) => {
  if (!intervals) return undefined;
  const sanitizedIntervals: string[] = [];
  if (intervals?.includes('MORNING')) sanitizedIntervals.push('MORNING');
  if (intervals?.includes('AFTERNOON')) sanitizedIntervals.push('AFTERNOON');
  return sanitizedIntervals;
};

export const Create = (params: any) => {
  let query = CREATE_MUTATION;
  let newParams = formatCreateInput(params.data);

  if (params.previousData) {
    const diff = difference(
      { ...params.data, isLatest: undefined },
      { ...params.previousData, isLatest: undefined }
    );

    if (Object.keys(diff).length === 1 && Object.keys(diff)[0] === 'iban') {
      console.log('We are just updating the IBAN');
      query = UPDATE_MUTATION;
      // @ts-ignore
      newParams = { id: params.id, iban: params.data.iban };
    }
  }

  if ((newParams as any)?.intervals) {
    (newParams as any).intervals = sanitizeIntervals((newParams as any).intervals);
  }

  return {
    variables: newParams,
    query,
    parseResponse: (resp: any) => {
      const resultKey =
        query === CREATE_MUTATION ? 'createGymRemuneration' : 'updateGymRemuneration';
      if (!resp.data && !resp.data[resultKey]) return { data: {} };
      return resp.data[resultKey];
    },
  };
};
