import passAuditProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'PassAudit',
  dataProvider: {
    getOneName: 'passAudit',
    useCustomDP: true,
    customDataProvider: passAuditProvider,
  },
} as IResourceExport;
