import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { Create, SimpleForm, useTranslate } from 'react-admin';
import BsportForm from './BsportForm';
import DeciplusForm from './DeciplusForm';
import MindbodyForm from './MindbodyForm';
import ResamaniaV2Form from './ResamaniaV2Form';
import ZingfitForm from './ZingfitForm';
import NeonessForm from './NeonessForm';
import KeepcoolForm from './KeepcoolForm';
import EpisodForm from './EpisodForm';
import HeitzForm from './HeitzForm';
import MondayForm from './MondayForm';

const useStyles = makeStyles({
  buttons: {
    marginBottom: '15px',
  },
});

enum ErpForms {
  Episod = 'EPISOD',
  Heitz = 'HEITZ',
  Keepcool = 'KEEPCOOL',
  Mindbody = 'MINDBODY',
  Zingfit = 'ZINGFIT',
  Neoness = 'NEONESS',
  Deciplus = 'DECIPLUS',
  Resamaniav2 = 'RESAMANIAV2',
  Bsport = 'BSPORT',
  Monday = 'MONDAY',
}

export const ErpCreate = (props: any) => {
  const [currentVisibleForm, setCurrentVisibleForm] = useState<ErpForms | null>(null);
  const translate = useTranslate();
  const classes = useStyles();

  const toggleVisibleForm = useCallback(
    (erpForm: ErpForms) => {
      setCurrentVisibleForm(erpForm === currentVisibleForm ? null : erpForm);
    },
    [currentVisibleForm]
  );

  return (
    <Create {...props} undoable={false}>
      <SimpleForm {...props}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Mindbody)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Mindbody' })}
        </Button>
        {currentVisibleForm === ErpForms.Mindbody && <MindbodyForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Monday)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Monday' })}
        </Button>
        {currentVisibleForm === ErpForms.Monday && <MondayForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Zingfit)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Zingfit' })}
        </Button>
        {currentVisibleForm === ErpForms.Zingfit && <ZingfitForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Deciplus)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Deciplus' })}
        </Button>
        {currentVisibleForm === ErpForms.Deciplus && <DeciplusForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Resamaniav2)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'ResamaniaV2' })}
        </Button>
        {currentVisibleForm === ErpForms.Resamaniav2 && <ResamaniaV2Form {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Bsport)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Bsport' })}
        </Button>
        {currentVisibleForm === ErpForms.Bsport && <BsportForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Neoness)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Neoness' })}
        </Button>
        {currentVisibleForm === ErpForms.Neoness && <NeonessForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Keepcool)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Keepcool' })}
        </Button>
        {currentVisibleForm === ErpForms.Keepcool && <KeepcoolForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Episod)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Episod' })}
        </Button>
        {currentVisibleForm === ErpForms.Episod && <EpisodForm {...props} />}

        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleVisibleForm(ErpForms.Heitz)}
          className={classes.buttons}
        >
          {translate('resources.Erp.actions.addErp', { erpName: 'Heitz' })}
        </Button>
        {currentVisibleForm === ErpForms.Heitz && <HeitzForm {...props} />}
      </SimpleForm>
    </Create>
  );
};
