import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetOne } from './GetOne';
import { GetList, GetMany, GetManyReference } from './Many';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne,
    GET_LIST: GetList,
    GET_MANY: GetMany,
    GET_MANY_REFERENCE: GetManyReference,
  };
};
