import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import debounce from 'debounce-promise';
import { translate, useRefresh } from 'ra-core';
import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  Error,
  FormDataConsumer,
  FormTab,
  Labeled,
  Loading,
  Pagination,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useQuery,
} from 'react-admin';
import AsyncSelect from 'react-select/async';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
  },
};

const useClasses = makeStyles({
  cancellationContainer: {
    display: 'flex',
  },
  classesFilteringContainer: {
    display: 'flex',
    width: '100%',
  },
  classesFilteringBox: {
    margin: '5px',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '10px',
  },
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
});

const ErpTitle = ({ record, translate }: any) => (
  <span>
    {translate('resources.Erp.editTitle', {
      name: (record && `${record.name} - ${record.siteID}`) || '',
    })}
  </span>
);

const PassEditActions = (props: any) => (
  <TopToolbar>
    <AddGymAction {...props} />
  </TopToolbar>
);

const AddGymAction = ({ data: erpData, classes, translate }: any) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<LightGym[]>([]);

  async function getGyms(slug: string) {
    const res: { data?: LightGym[] } = await dataProvider.getList('Gym', {
      pagination: { page: 1, perPage: 20 },
      filter: { slug },
      sort: { field: 'slug', order: 'DESC' },
    });

    return !res || !res.data
      ? []
      : res.data.map((gym) => ({
          id: gym.id,
          slug: gym.slug,
          availableSession: gym.availableSession,
          firm: gym.firm,
        }));
  }

  function addErpToGyms() {
    if (!selected.length) return;
    Promise.all(
      selected.map((gym) => {
        return dataProvider.update('Gym', {
          id: gym.id,
          data: {
            id: gym.id,
            erpID: erpData.id,
            firm: gym.firm,
          },
          previousData: { id: gym.id },
        });
      })
    ).then(() => {
      setOpen(false);
      refresh();
    });
  }

  const loadOptions = (inputValue: any) => getGyms(inputValue);
  const debouncedLoadOptions = debounce(loadOptions, 500, { leading: false });

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.Erp.gym.addGyms')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle>{translate('resources.Erp.gym.addGyms')}</DialogTitle>
        <DialogContent>
          <AsyncSelect
            isMulti
            menuIsOpen
            name="colors"
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={({ slug }: LightGym) => slug}
            getOptionValue={({ id }: LightGym) => id}
            loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
            label="resources.Erp.gym.gymSlug"
            onChange={(gyms) => {
              // Arg typing don't mind of param "isMulti" -> cast it to array manually.
              const typedGyms = gyms as LightGym[];
              if (typedGyms) setSelected(typedGyms);
              else setSelected([]);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
            {translate('ra.action.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={addErpToGyms}
          >
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MindbodyEditForm = (props: any) => {
  const classes = useClasses();

  const { data: mindbodyMetadata, loading, error } = useQuery({
    type: 'getOne',
    resource: 'MindbodyMetadata',
    payload: { id: props.record.siteID },
  });
  const dataProvider = useDataProvider();
  const [activationLink, setActivationLink] = useState('');
  const [mboError, setMboError] = useState('');

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!mindbodyMetadata) return null;

  function getActivationLink(siteID: string) {
    dataProvider
      .GET_ACTIVATION_LINK('MindbodyMetadata', { siteID })
      .then(({ data }: { data: { code: string } }) => {
        setActivationLink(data.code);
      })
      .catch((e: any) => {
        setMboError(e.message);
      });
  }

  return (
    <>
      <TextInput source="siteID" disabled />
      <SelectInput
        source="locationID"
        label="resources.Erp.fields.location"
        choices={mindbodyMetadata.locations}
        allowEmpty
      />
      <TextInput
        source="paymentMethodID"
        label="resources.Erp.fields.paymentMethod"
        validate={[required()]}
        disabled
      />
      <FormDataConsumer>
        {({ formData }: any) => (
          <Labeled label="resources.Erp.fields.mandatoryFields">
            <div>
              {formData.mandatoryFields.map((item: string, i: number) => (
                <Chip label={item} key={i} />
              ))}
            </div>
          </Labeled>
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }: any) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => getActivationLink(formData.siteID)}
          >
            get activation link
          </Button>
        )}
      </FormDataConsumer>

      {activationLink && (
        <a href={activationLink} target="_blank" rel="noopener noreferrer">
          {activationLink}
        </a>
      )}

      {mboError && <p className={classes.error}>{mboError}</p>}

      <ArrayInput
        source="erpAvailableSessions"
        label="resources.Erp.fields.erpPassTypes.label"
      >
        <SimpleFormIterator>
          <SelectInput
            source="erpPassTypeID"
            label="resources.Erp.fields.erpPassTypes.name"
            choices={mindbodyMetadata.services}
            validate={[required()]}
          />
          <BooleanInput
            source="isForBooking"
            label="resources.Erp.fields.erpPassTypes.isForBooking"
            initialValue={false}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <div>
        <PlanningOffsetComponent />
        <TextInput
          source="data.referredBy"
          label="Referred By"
          placeholder="Gymlib"
        ></TextInput>
      </div>
    </>
  );
};

const ZingfitEditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="login"
        label="resources.Erp.fields.login"
        validate={[required()]}
      />
      <TextInput
        source="password"
        label="resources.Erp.fields.password"
        validate={[required()]}
      />
      <TextInput
        source="siteID"
        label="resources.Erp.fields.region"
        validate={[required()]}
      />
      <TextInput
        label="resources.Erp.fields.groupID"
        source="groupID"
        validate={[required()]}
      />
      <TextInput
        label="resources.Erp.fields.groupCode"
        source="groupCode"
        validate={[required()]}
      />
      <PlanningOffsetComponent />
      <FormDataConsumer>
        {({ formData }: any) => (
          <Labeled label="resources.Erp.fields.mandatoryFields">
            <div>
              {formData.mandatoryFields.map((item: string, i: number) => (
                <Chip label={item} key={i} />
              ))}
            </div>
          </Labeled>
        )}
      </FormDataConsumer>
      <TextInput
        label="resources.Erp.fields.erpPassTypeID"
        source="erpPassTypeID"
        validate={[required()]}
      />
      <BooleanInput
        source="enableStartDateSync"
        label="resources.Erp.fields.enableStartDateSync"
      />
      <TextInput
        label="resources.Erp.fields.terms"
        source="terms"
        validate={[required()]}
      />
      <TextInput label="resources.Erp.fields.url" source="url" validate={[required()]} />
      <BooleanInput
        source="data.zingfit.withSpotBooking"
        label="resources.Erp.fields.zingfitWithSpotBooking"
      />
    </>
  );
};

const BsportEditForm = (props: any) => {
  return (
    <>
      <TextInput source="siteID" label="resources.Erp.fields.siteID.bsport" disabled />
      <TextInput
        source="data.establishmentID"
        label="resources.Erp.fields.establishmentID"
        disabled
      />
      <FormDataConsumer>
        {({ formData }: any) => (
          <Labeled label="resources.Erp.fields.mandatoryFields">
            <div>
              {formData.mandatoryFields.map((item: string, i: number) => (
                <Chip label={item} key={i} />
              ))}
            </div>
          </Labeled>
        )}
      </FormDataConsumer>
      <ArrayInput
        source="erpAvailableSessions"
        label="resources.Erp.fields.erpPassTypes.label"
      >
        <SimpleFormIterator>
          <TextInput
            source="erpPassTypeID"
            label="resources.Erp.fields.erpPassTypes.name"
            disabled
          />
          <BooleanInput
            source="isForBooking"
            label="resources.Erp.fields.erpPassTypes.isForBooking"
            disabled
          />
        </SimpleFormIterator>
      </ArrayInput>
      <PlanningOffsetComponent />
    </>
  );
};

const NeonessEditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validate={[required()]}
      />
      <BooleanInput source="isLive" label="resources.Erp.fields.neoness.isLive" />
      <PlanningOffsetComponent />
    </>
  );
};

const KeepcoolEditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validate={[required()]}
      />
    </>
  );
};

const EpisodEditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validate={[required()]}
      />
      <PlanningOffsetComponent />
    </>
  );
};

const HeitzEditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validate={[required()]}
      />
      <TextInput
        source="paymentMethodID"
        label="resources.Erp.fields.paymentMethodID"
        validate={[required()]}
      />
      <TextInput
        source="url"
        label="resources.Erp.fields.apiUrl"
        validate={[required()]}
      />
    </>
  );
};

const Resamaniav2EditForm = (props: any) => {
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validate={[required()]}
      />
      <PlanningOffsetComponent />
      <TextInput
        source="data.resamaniav2.clientID"
        label="clientID"
        validate={[required()]}
      />
      <TextInput
        source="data.resamaniav2.clientSecret"
        label="clientSecret"
        validate={[required()]}
      />
      <TextInput
        source="data.resamaniav2.clientToken"
        label="clientToken"
        validate={[required()]}
      />
      <TextInput
        source="data.resamaniav2.clubID"
        label="clubID"
        validate={[required()]}
      />
    </>
  );
};

const DefaultEditForm = () => {
  return (
    <>
      <TextInput source="siteID" disabled />
      <FormDataConsumer>
        {({ formData }: any) => (
          <Labeled label="resources.Erp.fields.mandatoryFields">
            <div>
              {formData.mandatoryFields.map((item: string, i: number) => (
                <Chip label={item} key={i} />
              ))}
            </div>
          </Labeled>
        )}
      </FormDataConsumer>
      <ArrayInput
        source="erpAvailableSessions"
        label="resources.Erp.fields.erpPassTypes.label"
      >
        <SimpleFormIterator>
          <TextInput
            source="erpPassTypeID"
            label="resources.Erp.fields.erpPassTypes.name"
            disabled
          />
          <BooleanInput
            source="isForBooking"
            label="resources.Erp.fields.erpPassTypes.isForBooking"
            disabled
          />
        </SimpleFormIterator>
      </ArrayInput>
      <PlanningOffsetComponent />
    </>
  );
};

const ErpEditWithoutStyle = (props: any) => {
  const classes = useClasses();
  return (
    <Edit
      {...props}
      undoable={false}
      title={<ErpTitle translate={props.translate} />}
      actions={<PassEditActions {...props} />}
    >
      <TabbedForm redirect="edit">
        <FormTab label="resources.Erp.tabs.informations">
          <TextInput source="name" disabled />
          <FormDataConsumer>
            {(props: any) => (
              <>
                {props.record.name === 'MINDBODY' && <MindbodyEditForm {...props} />}
                {props.record.name === 'ZINGFIT' && <ZingfitEditForm {...props} />}
                {props.record.name === 'BSPORT' && <BsportEditForm {...props} />}
                {props.record.name === 'NEONESS' && <NeonessEditForm {...props} />}
                {props.record.name === 'KEEPCOOL' && <KeepcoolEditForm {...props} />}
                {props.record.name === 'EPISOD' && <EpisodEditForm {...props} />}
                {props.record.name === 'HEITZ' && <HeitzEditForm {...props} />}
                {props.record.name === 'RESAMANIAV2' && (
                  <Resamaniav2EditForm {...props} />
                )}
                {![
                  'MINDBODY',
                  'ZINGFIT',
                  'BSPORT',
                  'NEONESS',
                  'KEEPCOOL',
                  'EPISOD',
                  'HEITZ',
                  'RESAMANIAV2',
                ].includes(props.record.name) && <DefaultEditForm {...props} />}
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.Erp.tabs.associatedGyms">
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.Erp.associatedGyms.title"
            source="id"
            target="erp"
            reference="Gym"
            sort={{ field: 'slug', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid rowClick="edit">
              <TextField source="slug" label="resources.Erp.associatedGyms.fields.slug" />
              <BooleanField
                source="active"
                label="resources.Erp.associatedGyms.fields.active"
              />
              <TextField
                source="bookingFlow"
                label="resources.Erp.associatedGyms.fields.bookingFlow"
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Classes Filtering">
          <div className={classes.classesFilteringContainer}>
            <div className={classes.classesFilteringBox}>
              <Typography color="textSecondary" variant="h5">
                Blacklisted classes
              </Typography>
              <Divider />
              <ArrayInput source="blacklistedClasses" label="">
                <SimpleFormIterator disableReordering>
                  <TextInput label="blacklisted item"></TextInput>
                </SimpleFormIterator>
              </ArrayInput>
            </div>
            <div className={classes.classesFilteringBox}>
              <Typography color="textSecondary" variant="h5">
                Whitelisted classes
              </Typography>
              <Divider />
              <ArrayInput source="whitelistedClasses" label="">
                <SimpleFormIterator disableReordering getItemLabel={() => ''}>
                  <TextInput label="whitelisted item"></TextInput>
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ErpEdit = translate(withStyles(styles)(ErpEditWithoutStyle));

interface LightGym {
  id: string;
  slug: string;
  firm: { id: string };
  availableSession: { commission: number; discoveryPass: boolean };
}
