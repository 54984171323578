import companyAuditProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'CompanyAudit',
  dataProvider: {
    getOneName: 'companyAudit',
    useCustomDP: true,
    customDataProvider: companyAuditProvider,
  },
} as IResourceExport;
