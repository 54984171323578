import React, { useState, useCallback } from 'react';
import { Typography, Box } from '@material-ui/core';

import {
  GoogleMap,
  Circle,
  Marker,
  useLoadScript,
  StandaloneSearchBox,
} from '@react-google-maps/api';

import { useTranslate } from 'ra-core';
import { TextInput, required, useNotify } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import { SelectInput } from 'react-admin';
import {
  extractDataFromPlace,
  getCountriesChoices,
  getGymLocalesForCountry,
} from './utils';

const styles = {
  mapContainerElement: {
    height: '500px',
    display: 'flex',
    margin: '15px',
    width: 'calc(100%-30px)',
    '& >div:nth-child(2)': {
      width: 'calc(50% - 30px)',
      margin: '15px',
      height: '370px',
      display: 'flex',
    },
  },
  searchElement: {
    display: 'flex',
  },
  addressFields: {
    margin: '15px',
  },
  line: {
    display: 'inline-flex',
    width: '100%',
  },
  halfLine: {
    display: 'inline-flex',
    width: '50%',
  },
};

interface MapMarkerProps {
  position: google.maps.LatLngLiteral;
  onPositionChanged: (newPosition: google.maps.LatLngLiteral) => void;
}

const MapMarker = ({ position, onPositionChanged }: MapMarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const onLoad = useCallback((marker) => {
    setMarker(marker);
  }, []);

  return (
    <Marker
      onLoad={onLoad}
      position={position}
      onDragEnd={() => {
        if (!marker) return;
        const newPosition = marker.getPosition();
        if (!newPosition) return;
        return onPositionChanged(newPosition.toJSON());
      }}
      draggable
    />
  );
};

interface MapProps {
  formData: any;
  classes: any;
}

const plugins = ['places'];

const MapWithoutStyle = ({ classes, formData, ...props }: MapProps) => {
  const form = useForm();

  const translate = useTranslate();
  const notify = useNotify();

  const [locales, setLocales] = useState(
    getGymLocalesForCountry(formData.country, translate)
  );

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    // @ts-ignore
    libraries: plugins,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

  const [center] = useState<google.maps.LatLngLiteral>({
    lat: formData && formData.latitude ? formData.latitude : 48.858282,
    lng: formData && formData.longitude ? formData.longitude : 2.3833525999999665,
  });

  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>(center);

  const changeMarkerPosition = (pos: google.maps.LatLngLiteral) => {
    if (!map) return;

    map.setCenter(pos);
    setMarkerPosition(pos);

    form.change('latitude', pos.lat);
    form.change('longitude', pos.lng);
  };

  const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
    try {
      const fields = extractDataFromPlace(place);
      if (fields) {
        if (fields.country) {
          setLocales(getGymLocalesForCountry(fields.country, translate));
        }
        for (let [key, value] of Object.entries(fields)) {
          if (value) form.change(key, value);
        }
        if (place.geometry) {
          changeMarkerPosition(place.geometry.location.toJSON());
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        return notify(e.message, 'error');
      }
    }
  };

  if (!formData) return null;

  const renderMap = () => {
    return (
      <div>
        {!formData.egymGymId ? (
          <GoogleMap
            options={{
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: false,
              draggable: true,
            }}
            onLoad={(mapInstance) => {
              setMap(mapInstance);
            }}
            mapContainerClassName={classes.mapContainerElement}
            zoom={16}
            center={center}
          >
            <StandaloneSearchBox
              onLoad={(searchBoxInstance) => {
                setSearchBox(searchBoxInstance);
              }}
              onPlacesChanged={() => {
                if (!map || !searchBox) return;

                const places = searchBox.getPlaces();
                if (places.length) {
                  const place = places[0];
                  onPlaceChanged(place);
                }
              }}
            >
              <input
                type="text"
                placeholder="Customized your placeholder"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-120px',
                }}
              />
            </StandaloneSearchBox>
            <MapMarker
              position={markerPosition}
              onPositionChanged={(newPosition) => {
                changeMarkerPosition(newPosition);
              }}
            />
            <Circle
              center={markerPosition}
              radius={formData.punchRadius || 500}
              options={{ fillColor: 'rgb(43,152,240)', strokeColor: 'rgb(43,152,240)' }}
            />
          </GoogleMap>
        ) : (
          <div className={classes.line}>
            <Typography component="div">
              <Box fontWeight="fontWeightBold" m={1}>
                {translate('resources.Gym.fields.address.label')}
              </Box>
            </Typography>
          </div>
        )}

        <div className={classes.line}>
          <TextInput
            {...props}
            source="latitude"
            validate={required()}
            label="resources.Gym.fields.latitude.label"
            placeholder={translate('resources.Gym.fields.latitude.placeholder')}
            className={classes.addressFields}
          />
          <TextInput
            {...props}
            source="longitude"
            validate={required()}
            label="resources.Gym.fields.longitude.label"
            placeholder={translate('resources.Gym.fields.longitude.placeholder')}
            className={classes.addressFields}
          />
        </div>
        <div className={classes.line}>
          <div className={classes.halfLine}>
            <TextInput
              {...props}
              source="streetNumber"
              label="resources.Gym.fields.streetNumber.label"
              placeholder={translate('resources.Gym.fields.streetNumber.placeholder')}
              className={classes.addressFields}
            />
          </div>
          <TextInput
            {...props}
            source="streetName"
            validate={required()}
            label="resources.Gym.fields.address.label"
            placeholder={translate('resources.Gym.fields.streetName.placeholder')}
            className={classes.addressFields}
          />
          <TextInput
            {...props}
            source="zipCode"
            validate={required()}
            label="resources.Gym.fields.zipCode.label"
            placeholder={translate('resources.Gym.fields.zipCode.placeholder')}
            className={classes.addressFields}
          />
        </div>
        <div className={classes.line}>
          <TextInput
            {...props}
            source="city"
            validate={required()}
            label="resources.Gym.fields.city.label"
            placeholder={translate('resources.Gym.fields.city.placeholder')}
            className={classes.addressFields}
          />
          <SelectInput
            {...props}
            className={classes.addressFields}
            source="country"
            validate={required()}
            label="resources.Gym.fields.country.label"
            choices={getCountriesChoices(translate)}
            onChange={(event: any) => {
              const locales = getGymLocalesForCountry(event.target.value, translate);
              setLocales(locales);
              form.change('locale', locales[0].id);
            }}
          />
          <SelectInput
            {...props}
            className={classes.addressFields}
            source="locale"
            validate={required()}
            label="resources.Gym.fields.locale.label"
            choices={locales}
          />
        </div>
      </div>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Map loading</div>;
};

export default withStyles(styles)(MapWithoutStyle);
