import gql from 'graphql-tag';
import { Product, TranslatedField } from '../../products/dataProvider/Many';

const CREATE_PRODUCT_PRICE_GROUP_MUTATION = gql`
  mutation BO_CREATE_PRODUCT_PRICE_GROUP_OPERATION(
    $input: BO_CreateProductPriceGroupInput!
  ) {
    BO_CreateProductPriceGroup(input: $input) {
      data: productPriceGroup {
        id
        active
        name {
          fr
          en
          nl
        }
        prices {
          id
          price
          product {
            id
            name {
              fr
              en
              nl
            }
          }
        }
      }
    }
  }
`;

type ProductPriceGroupPayload = {
  name: TranslatedField;
  active: boolean;
  productPrices: Record<string, Product>;
};

export const Create = () => {
  return (params: { data: ProductPriceGroupPayload }) => {
    const productPrices = Object.values(params.data.productPrices)
      .filter((product) => product.active)
      .map((product) => ({
        productID: product.id,
        price: product.price,
      }));

    return {
      query: CREATE_PRODUCT_PRICE_GROUP_MUTATION,
      variables: {
        input: {
          name: params.data.name,
          active: params.data.active,
          productPrices,
        },
      },
      parseResponse: (response: any) => response.data.BO_CreateProductPriceGroup,
    };
  };
};
