import gql from 'graphql-tag';

import { gymRemFields } from './GetOne';
import { parseResponseMany } from './utils';

const GET_LIST_QUERY = gql`
  query allGymRemunerations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: GymRemunerationFilter!
  ) {
    items: allGymRemunerations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...GymRemFields
      metrics {
        currentMonthPaidPasses
        currentMonthBalance
      }
    }
    total: _allGymRemunerationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
  ${gymRemFields}
`;

export const GetList = (params: any) => {
  params = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    perPage: params.pagination ? params.pagination.perPage : 25,
    sortField: params.sort ? params.sort.field : null,
    sortOrder: params.sort ? params.sort.order : null,
    filter: params.filter,
  };
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse: parseResponseMany,
  };
};

export const GetMany = (params: any) => {
  params = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    perPage: params.pagination ? params.pagination.perPage : 25,
    sortField: params.sort ? params.sort.field : null,
    sortOrder: params.sort ? params.sort.order : null,
    filter: params.filter,
  };
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse: parseResponseMany,
  };
};

export const GetManyReference = (params: any) => {
  const preparedVariables = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    perPage: params.pagination ? params.pagination.perPage : 25,
    sortField: params.sort ? params.sort.field : null,
    sortOrder: params.sort ? params.sort.order : null,
    filter: params.filter,
  };
  if (params?.filter?.id) {
    preparedVariables.filter = {
      active: params.filter.active,
      sourceID: params.filter.id || params.id,
    };
  }

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: parseResponseMany,
  };
};
