import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query ExternalIntegrationGetOne($id: ID!) {
    data: ExternalIntegrationGetOne(id: $id) {
      id
      companyID
      integrationType
      createdAt
      ignoreNextSync
      more {
        s3Path
        receiveByEmail
        blacklistedOrganizationUnits
        keepOrganizationUnitsRegex
        ignoreEmailRegex
      }
      fields {
        email
        refKeyName
        firstName
        lastName
      }
      status
    }
  }
`;

export const GetOne = (params: any) => {
  const preparedVariables = {
    id: params.id,
  };
  return {
    variables: preparedVariables,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      if (
        resp.data.data.more.blacklistedOrganizationUnits &&
        resp.data.data.more.blacklistedOrganizationUnits.length > 0
      ) {
        resp.data.data.more.blacklistedOrganizationUnits = resp.data.data.more.blacklistedOrganizationUnits.map(
          (e: string) => ({
            value: e,
          })
        );
      }
      return {
        data: {
          ...resp.data.data,
          blacklistedOrganizationUnits: resp.data.data.more.blacklistedOrganizationUnits,
        },
      };
    },
  };
};
