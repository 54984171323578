import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query hrManager($id: String!) {
    data: hrManager(id: $id) {
      id
      email
      companies {
        id
      }
      status
    }
  }
`;

const IS_HR_EMAIL_FREE_QUERY = gql`
  query isHrEmailFree($email: String!) {
    data: isHrEmailFree(email: $email)
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          companyIDs: resp.data.data.companies.map((company: any) => company.id),
        },
      };
    },
  };
};

export const IsHrEmailFree = (params: any) => {
  return {
    variables: params,
    query: IS_HR_EMAIL_FREE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data,
        },
      };
    },
  };
};
