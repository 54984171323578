import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudDeleteMany, translate } from 'react-admin';

const DeletePendingActionButton = ({
  basePath,
  crudDeleteMany,
  resource,
  selectedIds,
  translate,
}: any) => {
  const [isOpen, setOpen] = useState(false);

  let buttonLabel =
    selectedIds && selectedIds.length > 1
      ? 'resources.Sponsorship.actions.deletePending.button.plural'
      : 'resources.Sponsorship.actions.deletePending.button.singular';

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    crudDeleteMany(resource, selectedIds, basePath);
    setOpen(true);
  };

  return (
    <Fragment>
      <Button
        label={buttonLabel}
        onClick={handleClick}
        variant="contained"
        color="primary"
      />
      <Confirm
        isOpen={isOpen}
        title={translate('resources.Sponsorship.actions.deletePending.title')}
        content={translate('resources.Sponsorship.actions.deletePending.content')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export const DeletePendingAction = translate(
  connect(
    undefined,
    { crudDeleteMany }
  )(DeletePendingActionButton)
);
