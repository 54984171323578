import { gql } from '@apollo/client';
import * as _ from 'lodash';

const UPDATE_MUTATION = gql`
  mutation updatePartnerEvent(
    $id: ID!
    $startDate: DateTime
    $endDate: DateTime
    $showDates: Boolean
    $longitude: Float
    $latitude: Float
    $address: String
    $city: String
    $zipCode: String
    $country: GymCountry
    $radius: Int
    $backgroundColor: PartnerEventColor
    $title: TranslatedTitleInput
    $discount: Int
    $cta: PartnerEventCTA
    $subtitle: TranslatedTitleInput
    $picture: FileInput
    $deeplink: String
  ) {
    updatePartnerEvent(
      input: {
        partnerEventID: $id
        data: {
          startDate: $startDate
          endDate: $endDate
          showDates: $showDates
          longitude: $longitude
          latitude: $latitude
          address: $address
          city: $city
          zipCode: $zipCode
          country: $country
          radius: $radius
          backgroundColor: $backgroundColor
          title: $title
          discount: $discount
          cta: $cta
          subtitle: $subtitle
          picture: $picture
          deeplink: $deeplink
        }
      }
    ) {
      data: partnerEvent {
        id
        startDate
        endDate
        showDates
        location {
          coordinates
        }
        address
        city
        zipCode
        country
        radius
        backgroundColor
        title {
          fr
          en
          nl
        }
        discount
        cta
        subtitle {
          fr
          en
          nl
        }
        deeplink
      }
    }
  }
`;
const allowedFields = [
  'startDate',
  'endDate',
  'showDates',
  'longitude',
  'latitude',
  'address',
  'city',
  'zipCode',
  'country',
  'radius',
  'backgroundColor',
  'title',
  'discount',
  'cta',
  'subtitle',
  'picture',
  'deeplink',
  'isThereSubtitle',
  'isTherePromotion',
];

const parseResponse = (resp: any) => {
  if (!resp.data && !resp.data.updatePartnerEvent) return { data: {} };

  return { data: resp.data.updatePartnerEvent.data };
};

export const Update = (params: any) => {
  const payload: any = {
    id: params.data.id,
  };

  allowedFields.forEach((key) => {
    if (_.isEqual(params.data[key], params.previousData[key])) {
      return;
    }

    if (key === 'latitude' || key === 'longitude') {
      const latitude = parseFloat(params.data.latitude);
      payload.latitude = latitude;
      const longitude = parseFloat(params.data.longitude);
      payload.longitude = longitude;
    }
    if (key === 'isThereSubtitle') {
      payload.subtitle = params.data.isThereSubtitle ? params.data.subtitle : null;
    }
    if (key === 'isTherePromotion') {
      payload.discount = params.data.isTherePromotion ? params.data.discount : null;
    } else {
      payload[key] = params.data[key];
    }
  });

  console.log('[UPDATE][HOMEHEADER] = ', payload);
  return {
    variables: payload,
    parseResponse,
    query: UPDATE_MUTATION,
  };
};
