import { gql } from '@apollo/client';

const DELETE_MUTATION = gql`
  mutation deletePartnerEvent($id: ID!) {
    deletePartnerEvent(input: { partnerEventID: $id }) {
      data: partnerEvent {
        id
        startDate
        endDate
        showDates
        location {
          coordinates
        }
        address
        city
        zipCode
        country
        radius
        backgroundColor
        title {
          fr
          en
          nl
        }
        discount
        cta
        subtitle {
          fr
          en
          nl
        }
        deeplink
      }
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp.data && !resp.data.deletePartnerEvent) return { data: {} };

  return { data: resp.data.deletePartnerEvent.data };
};

export const Delete = (params: any) => {
  console.log('[DELETE][HOMEHEADER]');
  return {
    variables: params,
    parseResponse,
    query: DELETE_MUTATION,
  };
};
