import { Create } from './Create';
import { Delete } from './Delete';
import { DeleteMany } from './DeleteMany';
import { GetOne } from './GetOne';
import { GetList, GetMany } from './Many';
import { Update } from './Update';

export default {
  UPDATE: Update,
  CREATE: Create,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
  DELETE: Delete,
  DELETE_MANY: DeleteMany,
};
