import React, { useState } from 'react';
import {
  DateInput,
  ImageField,
  NumberInput,
  TranslatableInputs,
  SelectInput,
  ImageInput,
  required,
  TextInput,
  SimpleForm,
  maxLength,
  BooleanInput,
  Edit,
  useEditController,
  FormDataConsumer,
} from 'react-admin';
import { PartnerEventColor } from './types';
import { styles } from './styles';
import PartnerEventMapAddress from './PartnerEventMapAddress';

const PartnerEventEdit = (props: any) => {
  const classes = styles();
  const { record } = useEditController(props);

  const [isSubtitle, setIsSubtitle] = useState(!!record?.isThereSubtitle);
  const [isTherePromotion, setIsTherePromotion] = useState(!!record?.isTherePromotion);

  const titleMaxLength = (value: any) => {
    const max = isSubtitle ? 21 : 40;
    if (value.length > max) {
      return 'trop long';
    }
    return undefined;
  };

  const titleValidation = [required(), titleMaxLength];

  const backgroundColorChoice = [
    { id: PartnerEventColor.Lemon, name: 'Lemon' },
    { id: PartnerEventColor.Peach, name: 'Peach' },
    { id: PartnerEventColor.Pearl, name: 'Pearl' },
  ];
  const getBackgroundColorPreview = (test: any) => {
    switch (test) {
      case PartnerEventColor.Lemon:
        return '#F7F7DA';
      case PartnerEventColor.Peach:
        return '#F9DBDA';
      case PartnerEventColor.Pearl:
        return '#D3E8EA';
      default:
        return 'white';
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm>
        <div className={classes.boxInLine}>
          <DateInput
            label="resources.partnerEvent.label.startDate"
            source="startDate"
            validate={[required()]}
            defaultValue={new Date()}
          />
          <DateInput
            label="resources.partnerEvent.label.endDate"
            source="endDate"
            validate={[required()]}
          />
        </div>
        <BooleanInput label="resources.partnerEvent.label.showDates" source="showDates" />

        <FormDataConsumer>
          {(props: any) => <PartnerEventMapAddress {...props} />}
        </FormDataConsumer>

        <TranslatableInputs
          groupKey="title"
          locales={['fr', 'en', 'nl']}
          validate={titleValidation}
        >
          <TextInput
            label="resources.partnerEvent.label.title"
            source="title"
            validate={titleValidation}
            className={classes.fullWidth}
          />
        </TranslatableInputs>

        <BooleanInput
          label="resources.partnerEvent.label.showSubtitle"
          source="isThereSubtitle"
          onChange={setIsSubtitle}
        />

        <FormDataConsumer>
          {({ formData }: any) => {
            return (
              formData.isThereSubtitle && (
                <TranslatableInputs
                  groupKey="resources.partnerEvent.label.subtitle"
                  locales={['fr', 'en', 'nl']}
                >
                  <TextInput
                    label="resources.partnerEvent.label.subtitle"
                    source="subtitle"
                    validate={[formData.isThereSubtitle && required(), maxLength(70)]}
                    className={classes.fullWidth}
                  />
                </TranslatableInputs>
              )
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, record }: any) => (
            <>
              {record.picture &&
                (formData.picture === record.picture || !formData.picture) && (
                  <div>
                    <img src={record.picture} alt="initialLogo" />
                  </div>
                )}
              {!record.picture && !formData.picture && (
                <div>
                  <img
                    src="//place-hold.it/240x100?text=No Logo Yet!"
                    alt="No Logo Yet!"
                  />
                </div>
              )}
            </>
          )}
        </FormDataConsumer>
        <ImageInput
          source="picture"
          label="resources.partnerEvent.label.image"
          accept="image/png"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <div className={classes.boxInLine}>
          <SelectInput
            label="resources.partnerEvent.label.backgroundColor"
            source="backgroundColor"
            choices={backgroundColorChoice}
            validate={[required()]}
            className={classes.quarterWidth}
          />
          <FormDataConsumer>
            {({ formData }: any) => {
              return (
                <div
                  className={classes.colorVizualiser}
                  style={{
                    backgroundColor: getBackgroundColorPreview(formData.backgroundColor),
                  }}
                />
              );
            }}
          </FormDataConsumer>
        </div>

        <BooleanInput
          label="resources.partnerEvent.label.showDiscount"
          onChange={(v: boolean) => setIsTherePromotion(v)}
          source="isTherePromotion"
        />
        <FormDataConsumer>
          {({ formData }: any) => {
            return (
              formData.isTherePromotion && (
                <NumberInput
                  step={1}
                  label="resources.partnerEvent.label.discount"
                  source="discount"
                  validate={[isTherePromotion && required()]}
                />
              )
            );
          }}
        </FormDataConsumer>

        <SelectInput
          label="resources.partnerEvent.label.cta"
          source="cta"
          choices={[
            { id: 'BOOKING', name: 'resources.partnerEvent.label.booking' },
            { id: 'PARTICIPATE', name: 'resources.partnerEvent.label.participate' },
          ]}
          validate={[required()]}
        />

        <TextInput
          label="resources.partnerEvent.label.deeplink"
          source="deeplink"
          placeholder="gymlib://gym/:gymId"
          validate={[required()]}
          style={{
            width: '50%',
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default PartnerEventEdit;
