/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';

import { useDataProvider, downloadCSV } from 'react-admin';

import {
  Button,
  Modal,
  makeStyles,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  CircularProgress,
} from '@material-ui/core';
import CSVFile from '../../utils/csv/CSVFile';
import { EMAIL_REGEX } from '../../utils';
import { PassActions } from '../../@types/common';
import { unparse as convertToCSV } from 'papaparse';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useTranslate } from 'react-admin';

const styles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.25) !important',
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    outline: 'none',
    borderRadius: 4,
    minWidth: 700,
    minHeight: 520,
    backgroundColor: 'white',
    padding: 32,
  },
  marginBottom: {
    marginBottom: 24,
  },
  list: {
    maxHeight: 250,
    margin: 16,
  },
  marginEverywhere: {
    margin: 16,
  },
});

interface ExtendedPenalty {
  email: string;
  pass?: any;
  penaltyType: 'LC' | 'NS';
  failureReason?: string;
}

const HandlePenaltyListing = ({ data }: any) => {
  const [gymIDs, setGymIDs] = useState<string[]>([]);
  const [status, setStatus] = useState('waiting');
  const [userData, setUserData] = useState<
    { email: string; penalties: ('NS' | 'LC')[] }[]
  >([]);
  const [users, setUsers] = useState<{ userID: string; email: string; passes: any[] }[]>(
    []
  );
  const [penalties, setPenalties] = useState<ExtendedPenalty[]>([]);
  const [successPenalties, setSuccessPenalties] = useState<ExtendedPenalty[]>([]);
  const [errorPenalties, setErrorPenalties] = useState<ExtendedPenalty[]>([]);
  const classes = styles();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  useEffect(() => {
    if (!data || !data.id) return;
    if (data.firm && data.firm.id) {
      dataProvider
        .getList('Gym', {
          filter: {
            firm: { id: data.firm.id },
          },
          pagination: { perPage: 50 },
        })
        .then((gyms: any) => {
          setGymIDs(gyms.data.map((gym: any) => gym.id));
        });
    } else setGymIDs([data.id]);
  }, [data, dataProvider]);

  if (!data || !data.id) return null;

  const handleData = (data: any[]) => {
    const newData: typeof userData = [];
    let _users: typeof users = [];
    let _penalties: typeof penalties = [];
    if (!data || !Array.isArray(data)) {
      setStatus('error');
      return;
    }

    for (let i = 0; i < data.length; i++) {
      if (!data[i] || !Array.isArray(data[i]) || data[i].length !== 2) {
        setStatus('error');
        return;
      }
      if (EMAIL_REGEX.test(data[i][0]) && ['LC', 'NS'].includes(data[i][1])) {
        const index = newData.findIndex((d) => d.email === data[i][0].toLowerCase());
        if (index !== -1) newData[index].penalties.push(data[i][1]);
        else newData.push({ email: data[i][0].toLowerCase(), penalties: [data[i][1]] });
        _penalties.push({ email: data[i][0].toLowerCase(), penaltyType: data[i][1] });
      }
    }
    setUserData(newData);
    setStatus('success');
    setTimeout(async () => {
      setStatus('searchUsers');
      const res = await dataProvider.getList('User', {
        filter: { emails: newData.map((d) => d.email) },
        pagination: { perPage: newData.length },
      });
      _users = res.data.map((user: any) => ({
        userID: user.id,
        email: user.email,
        passes: [],
      }));
      setUsers(_users);

      for (let i = 0; i < res.data.length; i++) {
        const res2 = await dataProvider.getList('Pass', {
          filter: {
            userId: res.data[i].id,
            gymIDs,
            startDate: new Date().setMonth(new Date().getMonth() - 2),
          },
          pagination: { perPage: 10 },
          sort: { field: 'createdAt', order: 'ASC' },
        });
        _users = _users!.map((user) => {
          if (user.userID === res.data[i].id)
            return {
              userID: user.userID,
              email: user.email,
              passes: res2.data
                .filter((pass: any) => ['PUNCHABLE', 'USED'].includes(pass.status))
                .sort((pass1: any, pass2: any) => {
                  if (pass1.status === pass2.status) return 0;
                  if (pass1.status === 'PUNCHABLE') return -1;
                  return 1;
                }),
            };
          else return user;
        });
      }
      for (let i = 0; i < _penalties.length; i++) {
        const userPassesIndex = _users.findIndex((u) => u.email === _penalties[i].email)!;
        if (userPassesIndex !== -1)
          if (_users[userPassesIndex].passes.length > 0)
            _penalties[i].pass = _users[userPassesIndex].passes.shift();
          else _penalties[i].failureReason = 'PASS_NOT_FOUND';
        else _penalties[i].failureReason = 'USER_NOT_FOUND';
      }
      setUsers(_users);
      setStatus('displayPass');
      setPenalties(_penalties);
    }, 3000);
  };

  const declarePenalties = async () => {
    setStatus('declarePenalties');
    const success: ExtendedPenalty[] = [];
    const errors: ExtendedPenalty[] = [];
    for (let i = 0; i < penalties.length; i++) {
      if (penalties[i].pass) {
        try {
          await dataProvider.update('Pass', {
            data: {
              action:
                penalties[i].penaltyType === 'NS'
                  ? PassActions.NO_SHOW
                  : PassActions.LATE_CANCELLED,
              id: penalties[i].pass.id,
            },
          });
          success.push(penalties[i]);
        } catch {
          errors.push({ ...penalties[i], failureReason: 'CANNOT_DECLARE_PENALTY' });
        }
      } else errors.push(penalties[i]);
    }
    setSuccessPenalties(success);
    setErrorPenalties(errors);
    setStatus('displayResults');
  };

  const downloadExample = () => {
    const csv = convertToCSV(
      [
        { email: 'email@example.com', penalty: 'LC' },
        { email: 'email@example.com', penalty: 'NS' },
        { email: 'autreemail@example.com', penalty: 'NS' },
        { email: 'theolebg@gymlib.com', penalty: 'LC' },
        { email: 'theolebg@gymlib.com', penalty: 'LC' },
        { email: 'theolebg@gymlib.com', penalty: 'NS' },
        { email: 'emailrigolo@gymlib.com', penalty: 'NS' },
        { email: 'emailrigolo@gymlib.com', penalty: 'LC' },
      ],
      { header: false }
    );
    downloadCSV(csv, 'listing_example');
  };

  const downloadSuccess = () => {
    const csv = convertToCSV({
      data: successPenalties.map((p) => ({
        email: p.email,
        passID: p.pass.id,
        oldPassStatus: p.pass.status,
        penaltyType: p.penaltyType,
      })),
      fields: ['email', 'passID', 'oldPassStatus', 'penaltyType'],
    });
    downloadCSV(csv, 'succeeded_penalties');
  };

  const downloadErrors = () => {
    const csv = convertToCSV({
      data: errorPenalties.map((p) => ({
        email: p.email,
        passID: p.pass ? p.pass.id : 'NULL',
        penaltyType: p.penaltyType,
        failureReason: p.failureReason,
      })),
      fields: ['email', 'passID', 'penaltyType', 'failureReason'],
    });
    downloadCSV(csv, 'error_penalties');
  };

  return (
    <div className={classes.modalContent}>
      {['success', 'waiting'].includes(status) && (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.marginBottom}
            onClick={downloadExample}
          >
            {translate('resources.Gym.actions.handlePenaltyListing.downloadExample')}
          </Button>
          <CSVFile handleCSVResult={handleData} />
          {status === 'success' && (
            <Typography className={classes.marginEverywhere}>
              {translate('resources.Gym.actions.handlePenaltyListing.validListing')}
            </Typography>
          )}
        </>
      )}
      {status === 'searchUsers' && (
        <>
          {users && users.length === 0 && (
            <>
              <Typography className={classes.marginBottom}>
                {translate('resources.Gym.actions.handlePenaltyListing.searchUsers')}
              </Typography>
              <CircularProgress size={48} />
            </>
          )}
          {users && users.length > 0 && (
            <Typography>
              {translate('resources.Gym.actions.handlePenaltyListing.searchPasses', {
                usersCount: users.length,
              })}
            </Typography>
          )}
        </>
      )}
      {status === 'displayPass' && penalties && (
        <>
          <Typography className={classes.marginEverywhere}>
            {translate('resources.Gym.actions.handlePenaltyListing.validPenalties', {
              validPenalties: penalties.filter((p) => p.pass).length,
            })}
          </Typography>
          <TableContainer component={Paper} className={classes.list}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('resources.Gym.actions.handlePenaltyListing.email')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.penaltyType')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.passID')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.createdAt')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.passStatus')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.passLink')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penalties
                  .filter((p) => p.pass)
                  .map((pass, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {pass.email}
                      </TableCell>
                      <TableCell align="right">{pass.penaltyType}</TableCell>
                      <TableCell align="right">{pass.pass.id}</TableCell>
                      <TableCell align="right">{pass.pass.createdAt}</TableCell>
                      <TableCell align="right">{pass.pass.status}</TableCell>
                      <TableCell align="right">
                        <Link
                          href={`https://backoffice.gymlib.com/#/Pass/${pass.pass.id}`}
                          target="_blank"
                        >
                          <OpenInNewIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={declarePenalties}
            className={classes.marginBottom}
            variant="contained"
            color="primary"
          >
            {translate('resources.Gym.actions.handlePenaltyListing.handlePenalties', {
              penaltiesCount: penalties.filter((p) => p.pass).length,
            })}
          </Button>
          <Typography className={classes.marginEverywhere}>
            {translate('resources.Gym.actions.handlePenaltyListing.unvalidPenalties', {
              unvalidPenalties: penalties.filter((p) => !p.pass).length,
            })}
          </Typography>
          <TableContainer component={Paper} className={classes.list}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('resources.Gym.actions.handlePenaltyListing.email')}
                  </TableCell>
                  <TableCell align="right">
                    {translate('resources.Gym.actions.handlePenaltyListing.penaltyType')}
                  </TableCell>
                  <TableCell align="right">
                    {translate(
                      'resources.Gym.actions.handlePenaltyListing.failureReason'
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penalties
                  .filter((p) => !p.pass)
                  .map((pass, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {pass.email}
                      </TableCell>
                      <TableCell align="right">{pass.penaltyType}</TableCell>
                      <TableCell align="right">{pass.failureReason}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {status === 'declarePenalties' && (
        <>
          <Typography className={classes.marginEverywhere}>
            {translate(
              'resources.Gym.actions.handlePenaltyListing.onGoingPenaltyDeclaration'
            )}
          </Typography>
          <CircularProgress size={48} />
        </>
      )}
      {status === 'displayResults' && (
        <>
          <Typography className={classes.marginEverywhere}>
            {translate('resources.Gym.actions.handlePenaltyListing.penaltiesHandled', {
              penaltiesHandled: successPenalties.length,
            })}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.marginBottom}
            onClick={downloadSuccess}
          >
            {translate('resources.Gym.actions.handlePenaltyListing.downloadExport')}
          </Button>
          <Typography className={classes.marginEverywhere}>
            {translate('resources.Gym.actions.handlePenaltyListing.penaltiesNotHandled', {
              penaltiesNotHandled: errorPenalties.length,
            })}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadErrors}
            className={classes.marginEverywhere}
          >
            {translate('resources.Gym.actions.handlePenaltyListing.downloadExport')}
          </Button>
        </>
      )}
    </div>
  );
};

const ButtonWrappedPenaltyListing = ({ data }: any) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const classes = styles();

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.Gym.actions.handlePenaltyListing.name')}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modal}
        BackdropProps={{ className: classes.backdrop }}
      >
        {(open && <HandlePenaltyListing data={data} />) || <div />}
      </Modal>
    </div>
  );
};

export default ButtonWrappedPenaltyListing;
