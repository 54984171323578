import React from 'react';
import { useForm } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { TextInput, required } from 'react-admin';

const styles = {};

const KeepcoolForm = () => {
  const form = useForm();
  form.change('name', 'KEEPCOOL');
  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validation={[required()]}
      />
    </>
  );
};

export default withStyles(styles)(KeepcoolForm);
