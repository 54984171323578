import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { Editable } from './form';

export const GymRemunerationCreate = ({ ...props }: any) => (
  <Create {...props}>
    <SimpleForm redirect="edit" initialValues={{ tiers: Array(2) }}>
      <Editable isCreate={true} />
    </SimpleForm>
  </Create>
);
