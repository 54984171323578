import { Box, Typography } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import * as React from 'react';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  NumberInput,
  required,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { AmountFormatter } from '../../utils';

const CustomCreditPackCreateToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const ProdutCreateNameRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput
        resource="CreditPacks"
        source="name.fr"
        label="Traduction française"
        validate={[required()]}
      />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput
        resource="CreditPacks"
        source="name.en"
        label="Traduction anglais"
        validate={[required()]}
      />
    </Box>
    <Box flex={2}>
      <TextInput
        resource="CreditPacks"
        source="name.nl"
        label="Traduction néerlandaise"
        validate={[required()]}
      />
    </Box>
  </Box>
);

const CreditPackCreateGeneralInfoRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <BooleanInput resource="CreditPacks" source="active" label="Activé" />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <NumberInput
        {...AmountFormatter()}
        min={0}
        resource="CreditPacks"
        source="price"
        label="Prix €"
      />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <NumberInput resource="CreditPacks" source="credits" label="Crédits" min={0} />
    </Box>
  </Box>
);

export const CreditPackCreate: React.FunctionComponent = (props: any) => {
  return (
    <Create {...props}>
      <FormWithRedirect
        resource="CreditPacks"
        validateOnBlur={true}
        initialValues={{
          name: { fr: '', en: '', nl: '' },
          credits: 0,
          active: true,
          price: 0,
          type: 'ONE_TIME_PURCHASE',
        }}
        render={(formProps: any) => (
          <>
            <form>
              <Box marginX="3rem" paddingY="2rem">
                <section>
                  <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h6" gutterBottom>
                        Informations générales
                      </Typography>
                      <CreditPackCreateGeneralInfoRow />
                    </Box>
                  </Box>
                  <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h6" gutterBottom>
                        Nom du produit
                      </Typography>
                      <ProdutCreateNameRow />
                    </Box>
                  </Box>
                </section>
              </Box>
            </form>
            <CustomCreditPackCreateToolbar {...formProps} />
          </>
        )}
      />
    </Create>
  );
};
