import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation createPasswordReset($id: ID!, $type: String!) {
    data: createPasswordReset(input: { type: $type, id: $id }) {
      success
    }
  }
`;

export const Create = (params: any) => {
  console.log('[CREATE][GYM_MANAGER]', params);

  return {
    variables: { id: params.id, type: params.data.type },
    query: CREATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: { ...resp.data.data },
      };
    },
  };
};
