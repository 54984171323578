import SponsorshipIcon from '@material-ui/icons/PersonAdd';

import { SponsorshipList } from './SponsorshipList';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'Sponsorship',
  resources: {
    list: SponsorshipList,
  },
  icon: SponsorshipIcon,
  dataProvider: {
    getOneName: 'sponsorship',
    shouldForce: true,
  },
} as IResourceExport;
