import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allUsersDevices(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: UserDeviceFilter
  ) {
    items: allUserDevices(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      deviceID
      deviceName
      createdAt
    }
    total: _allUserDevicesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.map((device: any, index: number) => {
      return {
        id: index,
        ...device,
      };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  console.log('List', params);
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };

  console.log('params =>', params);
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  console.log('MANY', params);
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };

  console.log('params =>', params);
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  console.log('MANY ref', params);
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  console.log('params =>', params);
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
