import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { useState } from 'react';
import { SaveButton } from 'react-admin';
import { Toolbar } from 'react-admin';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  translate,
  useNotify,
  useRedirect,
  useDataProvider,
} from 'react-admin';
import { PlatformType } from '../../@types/common';
import { EmailWhitelistInput } from './Whitelist/EmailWhitelistInput';
import InputLabel from '@material-ui/core/InputLabel';
import { CreateHrContact } from './manager/CreateHrContact';
import { Record } from 'ra-core';
import CorporationNameSlug from './CorporationNameSlug';
import { isHideCompanyChannelToggleDisabled } from './utils';
import { EmployeesIdentifiersWhitelistInput } from './Whitelist/EmployeesIdentifiersWhitelistInput';
import { Labeled } from 'react-admin';
import { useTranslate } from 'react-admin';
const html5EmailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const styles: Styles<any, any> = {
  logo: {
    '& img': {
      margin: 0,
      backgroundColor: 'black',
      maxHeight: 100,
      maxWidth: 240,
    },
  },
  fileUploader: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& div': {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
      },
      '& div:nth-child(2)': {
        marginLeft: '60px',
      },
      '& div:first-child': {
        marginLeft: 0,
      },
    },
  },
  emailList: {
    display: 'flex',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  emailListAdditionDeletion: {
    display: 'flex',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      '& p': {
        display: 'block',
      },
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  tableFull: {
    width: '100%',
    borderSpacing: '30px',
  },
  halfSize: {
    width: '50%',
  },
  fullWidth: {
    width: '100%',
  },
  simpleForm: {
    'overflow-x': 'auto',
  },
  initialLogoWrapper: {
    margin: 0,
  },
  initialLogo: {
    maxHeight: '10rem',
  },
};

const validateData = async (values: any) => {
  const errors: any = {};
  if (!values.name) errors.name = 'The name is required';
  if (!values.slug) errors.slug = 'The slug is required';
  if (!values.country) errors.country = 'The country is required';
  else if (!['FR', 'BE'].includes(values.country)) {
    errors.country = -'The selected country is not valid';
  }
  if (values.addDeleteEmail) {
    errors.addDeleteEmail = [];
    for (let i = 0; i < values.addDeleteEmail.length; i++) {
      if (
        typeof values.addDeleteEmail[i] === 'string' &&
        !values.addDeleteEmail[i].match(html5EmailRegex)
      ) {
        errors.addDeleteEmail[i] = 'Not an email';
      }
      if (typeof values.addDeleteEmail[i] === 'object') {
        errors.addDeleteEmail[i] = 'Email cannot be empty';
      }
    }
  }

  if (values.logo?.rawFile) {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        var image = new Image();
        image.onerror = () => {
          errors.logo = 'Could not Load Image';
          return resolve(errors);
        };
        image.onload = () => {
          if (image.width !== 240 || image.height !== 100) {
            errors.logo = 'resources.CorporateCompany.create.error.image';
          }
          return resolve(errors);
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(values.logo.rawFile);
    });
  }
  return errors;
};

const CreateCorporationToolbar = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSuccess = async (createdCompanyResponse: any) => {
    const createdCompany = createdCompanyResponse.data;
    const companyIDs = [createdCompany.id];
    if (!createdCompany.isSponsorshipPlatform) {
      const res: { data: Record } = await dataProvider.create('CorporateCompany', {
        data: {
          active: true,
          name: createdCompany.name + ' Proches',
          slug: createdCompany.slug + '-proches',
          country: createdCompany.country,
          isSponsorshipPlatform: true,
          // hardcoded id of the starter plan(ppg): see https://egym.atlassian.net/browse/WPAC-95
          productPriceGroupID: '63204b376e4e2873d2a239db',
          hasFeed: true,
        },
      });
      if (res.data.id) {
        console.log('created relative company, updating original one', res.data.id);

        await dataProvider.update('CorporateCompany', {
          id: createdCompany.id,
          data: {
            id: createdCompany.id,
            sponsorshipPlatformID: res.data.id,
          },
          previousData: {
            id: createdCompany.id,
          },
        });
      }
    }
    if (props.hrEmail) {
      const res: { data: Record } = await dataProvider.create('HrManager', {
        data: {
          email: props.hrEmail,
          companyIDs,
        },
      });
      console.log('created hr contact', res.data.email);
    }
    props.setIsSubmitting(false);
    notify(`resources.CorporateCompany.createTitle`);
    redirect(`/CorporateCompany/${createdCompany.id}`);
  };

  const onError = (error: any) => {
    props.setIsSubmitting(false);
    notify(
      typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
      'error',
      undefined,
      false
    );
  };
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.pristine || props.invalid}
        onClick={() => {
          if (!props.invalid) {
            props.setIsSubmitting(true);
          }
        }}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Toolbar>
  );
};

const CorporationTitle = ({ translate }: any) => (
  <span>{translate('resources.CorporateCompany.createTitle')}</span>
);

const CorporationCreateWithoutStyle = ({ classes, translate, ...props }: any) => {
  const [hrEmail, setHrEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const t = useTranslate();

  return (
    <Create {...props} title={<CorporationTitle translate={translate} />}>
      <SimpleForm
        validate={validateData}
        className={classes.simpleForm}
        toolbar={
          <CreateCorporationToolbar setIsSubmitting={setIsSubmitting} hrEmail={hrEmail} />
        }
      >
        <table className={classes.tableFull}>
          <tbody>
            <tr>
              <td className={classes.halfSize}>
                <BooleanInput
                  source="active"
                  label="resources.CorporateCompany.fields.active.label"
                  initialValue
                />
              </td>
            </tr>
            <CorporationNameSlug classes {...props} />
            <tr>
              <td className={classes.halfSize}>
                <SelectInput
                  source="country"
                  label="resources.CorporateCompany.fields.country.label"
                  initialValue="FR"
                  choices={[
                    {
                      id: 'FR',
                      name: 'resources.CorporateCompany.fields.country.france',
                    },
                    {
                      id: 'BE',
                      name: 'resources.CorporateCompany.fields.country.belgium',
                    },
                  ]}
                  validate={[required()]}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <FormDataConsumer>
                  {({ formData }: any) => (
                    <>
                      <ImageInput
                        source="logo"
                        accept="image/png"
                        label="resources.CorporateCompany.fields.logo.addLabel"
                        className={[classes.fileUploader, classes.logo]}
                      >
                        <ImageField source="src" />
                      </ImageInput>
                      {!formData.logo && (
                        <div className={classes.initialLogoWrapper}>
                          <img
                            src="//place-hold.it/240x100?text=No Logo Yet!"
                            className={classes.initialLogo}
                            alt="No Logo Yet!"
                          />
                        </div>
                      )}
                    </>
                  )}
                </FormDataConsumer>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classes.fullWidth}>
          <RadioButtonGroupInput
            {...props}
            label="resources.CorporateCompany.fields.platformType.label"
            initialValue={PlatformType.Credit}
            source="platformType"
            onChange={(e: any) => {
              console.log(e);
            }}
            choices={[
              {
                id: PlatformType.Credit,
                name: translate(
                  `resources.CorporateCompany.fields.platformType.${PlatformType.Credit}`
                ),
              },
              {
                id: PlatformType.CreditSponsorship,
                name: translate(
                  `resources.CorporateCompany.fields.platformType.${PlatformType.CreditSponsorship}`
                ),
              },
            ]}
          />
        </div>
        <FormDataConsumer>
          {({ formData, ...props }: any) => {
            if (
              [PlatformType.Credit, PlatformType.CreditSponsorship].includes(
                formData.platformType
              )
            ) {
              return (
                <ReferenceInput
                  {...props}
                  label="resources.CorporateCompany.fields.productPrice.label"
                  source="productPriceGroupID"
                  reference="ProductPriceGroups"
                  validate={[required()]}
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  allowEmpty={false}
                >
                  <AutocompleteInput optionText="name.fr" />
                </ReferenceInput>
              );
            }
          }}
        </FormDataConsumer>
        <table className={classes.tableFull}>
          <tbody>
            <tr>
              <td className={classes.halfSize}>
                <ArrayInput
                  label="resources.CorporateCompany.fields.validEmployeeEmailDomains.label"
                  placeholder={translate(
                    'resources.CorporateCompany.fields.validEmployeeEmailDomains.placeholder'
                  )}
                  source="validEmployeeEmailDomains"
                  className={classes.emailList}
                >
                  <SimpleFormIterator>
                    <TextInput
                      source="value"
                      placeholder={translate(
                        'resources.CorporateCompany.fields.validEmployeeEmailDomains.addPlaceholder'
                      )}
                      label="resources.CorporateCompany.fields.validEmployeeEmailDomains.addLabel"
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </td>
            </tr>
            <tr>
              <BooleanInput
                source="usersCanRegisterWithIdentifier"
                label="resources.CorporateCompany.fields.usersCanRegisterWithIdentifier.label"
                initialValue={false}
              />
              <FormDataConsumer>
                {(props: any) =>
                  props.formData?.usersCanRegisterWithIdentifier && (
                    <>
                      <Labeled
                        label={translate(
                          'resources.CorporateCompany.fields.signupInputName.label'
                        )}
                      />
                      <div className={classes.line}>
                        <TextInput
                          multiline
                          source="signupInputName.fr"
                          label="fr"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputName.en"
                          label="en"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputName.nl"
                          label="nl"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                      </div>
                      <Labeled
                        label={t(
                          'resources.CorporateCompany.fields.signupInputPlaceholder.label'
                        )}
                      />
                      <div className={classes.line}>
                        <TextInput
                          multiline
                          source="signupInputPlaceholder.fr"
                          label="fr"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputPlaceholder.en"
                          label="en"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputPlaceholder.nl"
                          label="nl"
                          inputProps={{ maxLength: 240 }}
                          validate={[required()]}
                        />
                      </div>
                      <Labeled
                        label={t(
                          'resources.CorporateCompany.fields.signupInputHelpingText.label'
                        )}
                      />
                      <div className={classes.line}>
                        <TextInput
                          multiline
                          source="signupInputHelpingText.fr"
                          label="fr"
                          inputProps={{ maxLength: 140 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputHelpingText.en"
                          label="en"
                          inputProps={{ maxLength: 140 }}
                          validate={[required()]}
                        />
                        <TextInput
                          multiline
                          source="signupInputHelpingText.nl"
                          label="nl"
                          inputProps={{ maxLength: 140 }}
                          validate={[required()]}
                        />
                      </div>

                      <TextInput
                        multiline
                        source="companyInvitationLink"
                        label="resources.CorporateCompany.fields.companyInvitationLink.label"
                        inputProps={{ maxLength: 500 }}
                      />
                    </>
                  )
                }
              </FormDataConsumer>
            </tr>
            <tr>
              <td className={classes.tableFull}>
                <FormDataConsumer>
                  {(props: any) => {
                    return props.formData?.usersCanRegisterWithIdentifier ? (
                      <EmployeesIdentifiersWhitelistInput
                        {...props}
                        fromCreate={true}
                        isSubmitting={isSubmitting}
                      />
                    ) : (
                      <EmailWhitelistInput {...props} fromCreate={true} />
                    );
                  }}
                </FormDataConsumer>
              </td>
            </tr>
            <tr>
              <td className={classes.halfSize}>
                <FormDataConsumer>
                  {({ formData, ...rest }: any) =>
                    formData &&
                    ![PlatformType.Sponsorship, PlatformType.CreditSponsorship].includes(
                      formData.platformType
                    ) && (
                      <ReferenceInput
                        {...rest}
                        label="resources.CorporateCompany.fields.sponsorshipPlatform.add"
                        source="sponsorshipPlatformID"
                        reference="CorporateCompany"
                        filter={{
                          isActive: formData.active,
                        }}
                        filterToQuery={(searchText: string) => ({ name: searchText })}
                        allowEmpty
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
              </td>
            </tr>
            <tr>
              <td className={classes.halfSize}>
                <FormDataConsumer>
                  {({ formData }: any) => (
                    <BooleanInput
                      source="hideCompanyChannel"
                      label="resources.CorporateCompany.fields.hideCompanyChannel.label"
                      initialValue={false}
                      format={(value: boolean) => !value}
                      parse={(value: boolean) => !value}
                      disabled={isHideCompanyChannelToggleDisabled(formData)}
                    ></BooleanInput>
                  )}
                </FormDataConsumer>
                <BooleanInput
                  source="hasChallenge"
                  label="resources.CorporateCompany.fields.hasChallenge.label"
                  initialValue={true}
                />
                <BooleanInput
                  source="sendWelcomeEmail"
                  label="resources.CorporateCompany.fields.sendWelcomeEmail.label"
                  initialValue={false}
                />
                <BooleanInput
                  source="canBeListedPublicly"
                  label="resources.CorporateCompany.fields.canBeListedPublicly.label"
                  initialValue={false}
                />
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <SelectInput
                  source="defaultUserLocale"
                  label="Language"
                  initialValue="fr"
                  choices={[
                    { id: 'fr', name: 'FR' },
                    { id: 'en', name: 'EN' },
                    { id: 'nl', name: 'NL' },
                  ]}
                ></SelectInput>
              </td>
            </tr>
            <td>
              <CreateHrContact setHrEmail={setHrEmail}></CreateHrContact>
            </td>
            <tr>
              <TextInput
                source="billingLink"
                label="Billing link"
                formClassName={classes.inlineBlock}
                placeholder="Billing link"
              />
              <TextInput
                source="reportingLink"
                label="Reporting Link"
                formClassName={classes.inlineBlock}
                placeholder="Reporting link"
              />
            </tr>
          </tbody>
        </table>
      </SimpleForm>
    </Create>
  );
};

export const CorporationCreate = translate(
  withStyles(styles)(CorporationCreateWithoutStyle)
);
