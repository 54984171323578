import { cloneDeep } from 'lodash';

export const formatData = (untouched: any) => {
  const gymRemuneration = cloneDeep(untouched);
  switch (gymRemuneration.__typename) {
    case 'GymRemunerationStandard':
      gymRemuneration.type = 'STANDARD';
      break;
    case 'GymRemunerationMonthlyCapped':
      gymRemuneration.type = 'MONTHLY_CAPPED';
      break;
    case 'GymRemunerationDegressivity':
      gymRemuneration.degressivityType = gymRemuneration.type;
      gymRemuneration.type = 'DEGRESSIVITY';
      break;
    case 'GymRemunerationPeakHours':
      gymRemuneration.intervals = gymRemuneration.oldIntervals;
      gymRemuneration.type = 'PEAK_HOURS';
      break;
    case 'GymRemunerationOffPeakHours':
      gymRemuneration.type = 'OFF_PEAK_HOURS';
      break;
    default:
      break;
  }

  gymRemuneration.isEffective =
    gymRemuneration.isLatest && new Date(gymRemuneration.effectiveAt) < new Date();

  return gymRemuneration;
};

export const parseResponseOne = (resp: any) => {
  if (!resp || !resp.data || !resp.data.data) return { data: {} };
  const formatted = formatData(resp.data.data);
  return { data: formatted };
};

export const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.map((res: any) => formatData(res)),
    total: resp.data.total.count,
  };
};
