import challengeProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';
import ChallengeList from './ChallengeList';

export default {
  name: 'Challenge',
  resources: {
    list: ChallengeList,
  },
  dataProvider: {
    getOneName: 'challenge',
    providerWithIntrospection: challengeProvider,
  },
} as IResourceExport;
