import { Button, makeStyles, Modal } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';
import CSVFile from '../../utils/csv/CSVFile';

const styles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    '& button': { marginTop: '20px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    outline: 'none',
    borderRadius: 4,
    minWidth: 400,
    minHeight: 300,
    backgroundColor: 'white',
  },
});

const GymShowcaseAction = () => {
  const [open, setOpen] = useState(false);
  const [gymShowcases, setGymShowcases] = useState<
    {
      gymSlug: string;
      startDate: string;
      endDate: string;
    }[]
  >([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [updateGymShowcase] = useMutation(
    {
      type: 'update',
      resource: 'Gym',
      payload: {
        action: 'GymShowcase',
        data: { gymShowcases: gymShowcases },
      },
    },
    {
      onSuccess: () => {
        setOpen(false);
        refresh();
        notify(translate('resources.Gym.actions.gymShowcase.success'), 'info');
      },
      onFailure: () => {
        setGymShowcases([]);
        return notify(
          translate('resources.Gym.actions.gymShowcase.errors.malformedCsv'),
          'warning'
        );
      },
    }
  );

  const classes = styles();

  const handleData = (data: string[][]) => {
    // Remove header line
    data.shift();

    if (data.some(data => data.length !== 3)) {
      setGymShowcases([]);
      return notify(
        translate('resources.Gym.actions.gymShowcase.errors.malformedCsv'),
        'warning'
      );
    }

    setGymShowcases(
      data.map(data => ({
        gymSlug: data[0],
        startDate: moment(data[1], 'DD/MM/YYYY').toISOString(),
        endDate: moment(data[2], 'DD/MM/YYYY').toISOString(),
      }))
    );
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.Gym.actions.gymShowcase.button')}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
        <div className={classes.modalContent}>
          <CSVFile handleCSVResult={handleData} />
          <Button
            variant="contained"
            color="primary"
            disabled={!gymShowcases.length}
            onClick={() => updateGymShowcase()}
          >
            {translate('resources.Gym.actions.gymShowcase.validate')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default GymShowcaseAction;
