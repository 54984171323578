import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  BooleanInput,
  FormDataConsumer,
  Link as AdminLink,
  NumberInput,
  required,
  TextInput,
  useTranslate,
} from 'react-admin';
import { DEFAULT_BOOKING_CANCELLATION_TIME_IN_MINUTES } from '../../config';
import { BookingFlow } from './BookingFlow';
import { BookingFlow as BookingFlowTypes } from './dataProvider/types';
import { isOpenAccess, showCancellationTime } from './utils';
import { Typography, Box } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { showGeneralReservationProcess } from './utils';
import { RichInput } from './RichInput';
import { isAPIV2 } from './utils';

export const BookingFlowSection = ({ classes }: any) => {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.line}>
        <FormDataConsumer>
          {(props: any) => <BookingFlow classes={classes} {...props} />}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: any) =>
            formData?.bookingFlow === BookingFlowTypes.BOOKING_WITHOUT_ERP_SPECIAL && (
              <BooleanInput
                className={classes.inlineBlock}
                source="payWithPassCode"
                label="API V2"
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: any) =>
            showCancellationTime(
              formData?.bookingFlow,
              formData?.isUnlimitedOfferGym
            ) && (
              <div className={classes.inlineBlock}>
                <NumberInput
                  source="cancellationTimeInMinutes"
                  label="resources.Gym.fields.cancellationTimeInMinutes.label"
                  step={1}
                  min={0}
                  initialValue={DEFAULT_BOOKING_CANCELLATION_TIME_IN_MINUTES}
                  validate={required()}
                />
              </div>
            )
          }
        </FormDataConsumer>
        <div className={classes.inlineBlock}>
          <TextInput source="erp.id" label="resources.Gym.fields.erpID.label" disabled />
          <FormDataConsumer>
            {({ formData }: any) =>
              formData &&
              formData.erp &&
              formData.erp.id && (
                <AdminLink to={`/Erp/${formData.erp.id}`}>
                  <LaunchIcon />
                </AdminLink>
              )
            }
          </FormDataConsumer>
        </div>
      </div>
      <div className={classes.inlineBlock}>
        <FormDataConsumer>
          {(formData: any) =>
            isAPIV2(formData) && (
              <TextInput
                source="externalID"
                label="resources.Gym.fields.externalID.label"
                className={classes.inlineBlock}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: any) =>
            isOpenAccess(formData) && (
              <BooleanInput
                source="hasLimitedSession"
                label={translate('resources.Gym.description.hasLimitedSession')}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: any) =>
            formData &&
            showGeneralReservationProcess(formData.bookingFlow) && (
              <div className={classes.inlineBlock}>
                <Typography component="div">
                  <Box fontWeight="fontWeightBold">
                    {translate('resources.Gym.description.reservationProcess.general')}
                  </Box>
                  <Box m={1}>
                    <BookmarkIcon /> {translate('resources.Gym.description.tooltip')}
                  </Box>
                </Typography>
                <RichInput
                  source="reservationProcess.general"
                  settings={{
                    plugins: ['autoresize', 'lists link '],
                    menubar: false,
                    statusbar: false,
                    toolbar:
                      'undo redo | bold italic underline strikethrough | forecolor | link | removeformat',
                  }}
                />
              </div>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: any) =>
            formData &&
            formData.bookingFlow === 'BOOKING_WITHOUT_ERP_REGULAR' && (
              <div className={classes.inlineBlock}>
                <Typography component="div">
                  <Box fontWeight="fontWeightBold" m={1}>
                    {translate(`resources.Gym.description.reservationProcess.steps.0`)}
                  </Box>
                  <TextInput
                    source="reservationProcess.phoneNumber"
                    label={translate(
                      'resources.Gym.description.reservationProcess.phoneNumber'
                    )}
                  />
                  <TextInput
                    source="reservationProcess.email"
                    label={translate(
                      'resources.Gym.description.reservationProcess.email'
                    )}
                  />
                  <TextInput
                    source="reservationProcess.dynamicSchedule"
                    label={translate(
                      'resources.Gym.description.reservationProcess.dynamicSchedule'
                    )}
                  />
                </Typography>
              </div>
            )
          }
        </FormDataConsumer>
      </div>
    </>
  );
};
