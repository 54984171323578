import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteGymRemuneration($id: ID!) {
    deleteGymRemuneration(input: { id: $id }) {
      data: success
    }
  }
`;

const parseResponse = (resp: any) => {
  return { ...resp };
};

export const Delete = (params: any) => {
  return {
    variables: params,
    parseResponse,
    query: DELETE_MUTATION,
  };
};
