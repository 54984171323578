import React from 'react';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { SimpleForm } from 'react-admin';
import { Create } from 'react-admin';

export const ExternalIntegrationCreate = (props: any) => {
  return (
    <Create {...props} undoable={false}>
      <SimpleForm {...props}>
        <ReferenceInput {...props} source="companyName" reference="CorporateCompany">
          <AutocompleteInput
            {...props}
            shouldRenderSuggestions={(val: string) => {
              return val.trim().length > 2;
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
