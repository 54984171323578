import { GetOne } from './GetOne';
import { GetList, GetManyReference } from './Many';
import { Update } from './Update';

export default {
  UPDATE: Update,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY_REFERENCE: GetManyReference,
};
