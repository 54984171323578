import { cloneDeep } from 'lodash';
import { EmployeeIdentifiers } from '../utils';

export const FormatCorporation = (untouched: any) => {
  const corporation = cloneDeep(untouched);
  if (
    corporation.validEmployeeEmailDomains &&
    corporation.validEmployeeEmailDomains.length > 0 &&
    // We need to check if we didn't already cleaned up the record (somehow it would nest `value` for the same company...)
    typeof corporation.validEmployeeEmailDomains[0] !== 'object'
  ) {
    corporation.validEmployeeEmailDomains = corporation.validEmployeeEmailDomains.map(
      (email: string) => ({
        value: email,
      })
    );
  }

  if (corporation.validEmployeesAsString) {
    corporation.validEmployees = JSON.parse(corporation.validEmployeesAsString).map(
      (validEmployee: EmployeeIdentifiers) => {
        return {
          refKey: validEmployee.refKey,
          email: validEmployee.email,
        };
      }
    );
  }

  if (
    corporation.emailBlacklistRegex &&
    corporation.emailBlacklistRegex.length > 0 &&
    // We need to check if we didn't already cleaned up the record (somehow it would nest `value` for the same company...)
    typeof corporation.emailBlacklistRegex[0] !== 'object'
  ) {
    corporation.emailBlacklistRegex = corporation.emailBlacklistRegex.map(
      (email: string) => ({
        value: email,
      })
    );
  }
  corporation.sendWelcomeEmail = !!corporation.sendWelcomeEmail;

  corporation.canBeListedPublicly = !!corporation.canBeListedPublicly;

  if (corporation.discounts?.length) {
    corporation.discounts = corporation.discounts.map((discount: any) => ({
      ...discount,
      amountType: typeof discount.percentOff === 'number' ? 'percent' : 'amount',
      amount:
        typeof discount.percentOff === 'number'
          ? discount.percentOff
          : discount.amountOff,
    }));
  }

  return corporation;
};
