import gql from 'graphql-tag';

export enum ProductType {
  CREDITS_SUBSCRIPTION = 'CREDITS_SUBSCRIPTION',
  CREDITS_AND_UNLIMITED_SUBSCRIPTION = 'CREDITS_AND_UNLIMITED_SUBSCRIPTION',
  UNLIMITED_SUBSCRIPTION = 'UNLIMITED_SUBSCRIPTION',
  ONE_TIME_PURCHASE = 'ONE_TIME_PURCHASE',
}

export type TranslatedField = { fr: string; en: string; nl: string };

export type Product = {
  id: string;
  credits: number;
  name: TranslatedField;
  price: number;
  active: boolean;
  type: ProductType;
};

export type ProductList = {
  total: number;
  data: Product[];
};

const GET_LIST_QUERY = gql`
  query BO_AllProducts {
    itemsCreditsSubscription: BO_AllProducts(type: CREDITS_SUBSCRIPTION) {
      items {
        id
        credits
        name {
          fr
          en
          nl
        }
        price
        active
        type
      }
      total
    }
    itemsBundleSubscription: BO_AllProducts(type: CREDITS_AND_UNLIMITED_SUBSCRIPTION) {
      items {
        id
        credits
        name {
          fr
          en
          nl
        }
        price
        active
        type
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (
    !resp ||
    !resp.data ||
    !resp.data.itemsCreditsSubscription ||
    !resp.data.itemsBundleSubscription
  )
    return resp;

  return {
    data: [
      ...resp.data.itemsCreditsSubscription.items,
      ...resp.data.itemsBundleSubscription.items,
    ],
    total:
      resp.data.itemsCreditsSubscription.total + resp.data.itemsBundleSubscription.total,
  };
};

export const GetList = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};

export const GetMany = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};
