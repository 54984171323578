import React, { useState, useEffect } from 'react';
import { useDataProvider, Record } from 'ra-core';

import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
  },
};

interface Props {
  setCorporationID: React.Dispatch<React.SetStateAction<any>>;
}

const GetCorporationsDropdown = ({ setCorporationID }: Props) => {
  const dataProvider = useDataProvider();

  const [suggestions, setSuggestions] = useState<{ label: string; value: any }[]>([]);

  useEffect(() => {
    dataProvider
      .GET_LITE_LIST('CorporateCompany', {
        pagination: {
          page: 1,
          perPage: 100000,
        },
        filter: {},
        sort: {
          field: 'slug',
          order: 'ASC',
        },
      })
      .then((res: { data?: Record[] }) => {
        if (!res || !res.data) return;
        const correctData = res.data as any[];
        setSuggestions(
          correctData.map((corporation) => {
            const data = { label: corporation.slug, value: corporation.id };
            return data;
          })
        );
      });
  }, [dataProvider]);

  return (
    <Select
      name="colors"
      options={suggestions}
      className="basic-single"
      classNamePrefix="select"
      onChange={(value: any) => {
        setCorporationID(value.value);
      }}
    />
  );
};

export default withStyles(styles)(GetCorporationsDropdown);
