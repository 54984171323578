import { Menu as MenuType } from '../@types/common';
import activities from './activities';
import audits from './audits';
import corporations from './corporations';
import erps, { mindbodyMetadata, mondayMetadata } from './erps';
import externalCoupon from './externalCoupons';
import coupons from './coupons';
import firms from './firms';
import gymManagers from './gymManagers';
import gyms, { gymReview, gymCountry } from './gyms';
import hrManagers from './hrManagers';
import passes, { passesValidation } from './passes';
import passwordResets from './passwordResets';
import services from './services';
import sponsorships from './sponsorships';
import users, { userDevices, usersCharge, usersEvent } from './users';
import gymRemuneration from './gymRemunerations';
import products from './products';
import productPriceGroups from './productPriceGroups';
import creditPacks from './creditPacks';
import companyAudits from './companyAudits';
import activityGroup from './activityGroups';
import externalIntegration from './externalIntegration';
import passAudits from './passAudits';
import gymAudits from './gymAudits';
import challenges from './challenges';
import challengeTemplates from './challengeTemplates';
import partnerEvent from './partnerEvent';
import gymEquipment from './gymEquipment';
import gymInfrastructure from './gymInfrastructure';
import erpUserAccounts from './erpUserAccounts';

export const Menu: MenuType = [
  {
    groupName: 'customer',
    resources: [users, sponsorships],
  },
  {
    groupName: 'companies',
    resources: [corporations, hrManagers],
  },
  {
    groupName: 'credits',
    resources: [products, productPriceGroups, creditPacks],
  },
  passes,
  {
    groupName: 'coupons',
    resources: [coupons, externalCoupon],
  },
  {
    groupName: 'gym',
    resources: [gyms, firms, gymManagers, erps, gymRemuneration, partnerEvent],
  },
  {
    groupName: 'activity',
    resources: [activityGroup, activities, services],
    closed: true,
  },
  externalIntegration,
  challenges,
  erpUserAccounts,
];

export default {
  challengeTemplates,
  challenges,
  externalIntegration,
  activities,
  audits,
  corporations,
  erps,
  firms,
  gyms,
  gymManagers,
  gymRemuneration,
  gymReview,
  gymCountry,
  erpUserAccounts,
  hrManagers,
  mindbodyMetadata,
  mondayMetadata,
  passes,
  passesValidation,
  externalCoupon,
  coupons,
  passwordResets,
  services,
  sponsorships,
  users,
  usersCharge,
  usersEvent,
  userDevices,
  companyAudits,
  products,
  productPriceGroups,
  creditPacks,
  activityGroup,
  passAudits,
  gymAudits,
  partnerEvent,
  gymEquipment,
  gymInfrastructure,
};
