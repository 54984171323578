import gql from 'graphql-tag';

import { FormatGym, GymFragment } from './utils';

const GET_LIST_QUERY = gql`
  query allGyms(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: GymFilter
  ) {
    items: allGyms(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...GymFields
    }
    total: _allGymsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
  ${GymFragment}
`;



const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items.filter((element: any) => element !== null).map(FormatGym),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => ({
    // Use the default query variables
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    // Override the query, 
    query:GET_LIST_QUERY,
    parseResponse,
  });

export const GetMany = (params: any) => {
  const filter: Record<string, any> = {};
  if (params.ids) {
    filter['ids'] = params.ids;
  }
  return {
    // Use the default query variables
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: filter,
    },
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  if (params.target === 'firm') preparedVariables.filter['firm'] = { id: params.id };
  else preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
