export enum BookingFlow {
  IN_APP_BOOKING = 'IN_APP_BOOKING',
  BOOKING_WITHOUT_ERP_REGULAR = 'BOOKING_WITHOUT_ERP_REGULAR',
  BOOKING_WITHOUT_ERP_SPECIAL = 'BOOKING_WITHOUT_ERP_SPECIAL',
  OPEN_ACCESS_WITH_ERP = 'OPEN_ACCESS_WITH_ERP',
  OPEN_ACCESS_WITHOUT_ERP = 'OPEN_ACCESS_WITHOUT_ERP',
}

export const OpenAccessBookingFlows = new Set([
  BookingFlow.OPEN_ACCESS_WITHOUT_ERP,
  BookingFlow.OPEN_ACCESS_WITH_ERP,
]);
