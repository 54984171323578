import { GetOne } from './GetOne';
import { GetList, GetMany, GetManyReference } from './Many';
import { Create } from './Create';
import { Delete } from './Delete';

export default {
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
  GET_MANY_REFERENCE: GetManyReference,
  CREATE: Create,
  UPDATE: Create,
  DELETE: Delete,
};
