import React from 'react';
import { Create, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';

import { required } from 'react-admin';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const FirmCreate = (props: any) => (
  <Create {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />} initialValues={{ some: 'value' }}>
      <TextInput source="slug" validation={[required()]} />
      <TextInput source="name" validation={[required()]} />
    </SimpleForm>
  </Create>
);
