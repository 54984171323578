import gql from 'graphql-tag';

import { subscriptionStatus } from './subscriptionStatus';

const GET_LIST_QUERY = gql`
  query allUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: BO_AllUserFilter
  ) {
    items: BO_AllUsers(
      input: {
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
        filter: $filter
      }
    ) {
      items {
        id
        createdAt
        lastLoginDate
        email
        firstname
        lastname
        gender
        phoneNumber
        address
        birthday
        city
        zipcode
        picture
        hasValidSubscription
        status
        blockDetails {
          blockedBy {
            id
            email
          }
        }
        corporateCompany {
          id
          name
          slug
          isSponsorshipPlatform
        }
        subscription {
          category
          endDate
        }
        roles
        activationCodes {
          code
          active
          createdAt
          verifiedAt
        }
        deviceID
        deviceName
        lastAppVersion
        credits
      }
      total
    }
  }
`;

const COUNT_USERS = gql`
  query allUsers($filter: BO_AllUserFilter) {
    items: BO_AllUsers(input: { page: 0, perPage: 5000, filter: $filter }) {
      items {
        id
        status
        email
        hasValidSubscription
        subscription {
          endDate
        }
      }
      total
    }
  }
`;

const parseResponse = (resp: any) => {
  const users = resp.data.items.items.map((user: any) => {
    const blockDetails = user.blockDetails;

    const blockedByManagerID =
      blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.id;
    const blockedByManagerEmail =
      (blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.email) ||
      'Gymlib';

    return {
      ...user,
      subscriptionStatus: subscriptionStatus(user),
      blockedBy: {
        id: blockedByManagerID,
        email: blockedByManagerEmail,
      },
    };
  });
  return { data: users, total: resp.data.items.total };
};

export const GetList = (params: any) => {
  if (params.filter && params.filter.countUsers) {
    const newParams: any = {
      filter: { emails: params.filter.emails },
      // This is used by the Graphql simple data provider but it is overriden in the underlying query
      pagination: { page: 0, perPage: 1 },
      sort: {},
    };

    if (params.filter.corporateCompany && params.filter.corporateCompany.id) {
      newParams.filter.corporateCompany = { id: params.filter.corporateCompany.id };
    }

    console.log('Filter', newParams);

    return {
      // Use the default query variables and parseResponse
      variables: newParams,
      // Override the query
      query: COUNT_USERS,
      parseResponse,
    };
  }

  const newParams = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: params.filter,
  };

  return {
    // Use the default query variables and parseResponse
    variables: newParams,
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: {
        ids: params.ids,
      },
    },
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: { corporateCompany: { id: params.id } },
  };
  return {
    // Use the default query variables and parseResponse
    variables: preparedVariables,
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};
