import { Create } from './Create';
import { Delete } from './Delete';
import { GetOne, IsHrEmailFree } from './GetOne';
import { GetList, GetMany, GetManyReference } from './Many';
import { Update } from './Update';

export default {
  UPDATE: Update,
  CREATE: Create,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
  DELETE: Delete,
  GET_MANY_REFERENCE: GetManyReference,
  IS_HR_EMAIL_FREE: IsHrEmailFree,
};
