import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query BO_listErpUserAccounts($page: Int, $perPage: Int, $filter: GymWithErpFilter) {
    items: BO_listErpUserAccounts(page: $page, perPage: $perPage, filter: $filter) {
      id
      userEmail
      erpFirmEmail
      gym {
        name
      }
      active
    }
    total: _listErpUserAccountsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items,
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    query: GET_LIST_QUERY,
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      filter: {
        gymName: params.filter.gymName,
        email: params.filter.email,
      },
    },
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      filter: {
        gymName: params.filter.gymName,
        email: params.filter.email,
      },
    },
    query: GET_LIST_QUERY,
    parseResponse,
  };
};
