import { IResourceExport } from '../../@types/dataProvider';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import provider from './dataProvider';
import { ProductPriceGroupList } from './ProductPriceGroupList';
import { ProductPriceGroupEdit } from './ProductPriceGroupEdit';
import { ProductPriceGroupCreate } from './ProductPriceGroupCreate';

export default {
  name: 'ProductPriceGroups',
  icon: DynamicFeedIcon,
  resources: {
    list: ProductPriceGroupList,
    edit: ProductPriceGroupEdit,
    create: ProductPriceGroupCreate,
  },
  dataProvider: {
    getOneName: 'BO_ProductPriceGroup',
    providerWithIntrospection: provider,
  },
} as IResourceExport;
