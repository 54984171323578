import ServiceIcon from '@material-ui/icons/RoomService';
import { IResourceExport } from '../../@types/dataProvider';

import { ServiceList } from './ServiceList';
import { ServiceEdit } from './ServiceEdit';
import { ServiceCreate } from './ServiceCreate';

import serviceProvider from './dataProvider';

export default {
  name: 'Service',
  resources: {
    list: ServiceList,
    edit: ServiceEdit,
    create: ServiceCreate,
  },
  icon: ServiceIcon,
  dataProvider: {
    getOneName: 'service',
    useCustomDP: true,
    customDataProvider: serviceProvider,
  },
} as IResourceExport;
