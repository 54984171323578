import React from 'react';
import { BooleanInput, FormDataConsumer, NumberInput } from 'react-admin';
import { useTranslate } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';
import { CappingComponent } from './CappingComponent';
import { OffPeakComponent } from './OffPeakComponent';
import { Divider, Typography } from '@material-ui/core';
import { ArrayInput, DateInput, SimpleFormIterator } from 'ra-ui-materialui';
import { showPromotions } from './utils';
import { minValue, maxValue, required } from 'react-admin';
import { isPromotionEnded } from '../gyms/utils';

const styles = {
  inlineBlock: {
    margin: '25px',
  },

  alert: {
    backgroundColor: '#fff4e5',
    color: '#6f470e',
    borderRadius: '4px',
    padding: '6px',
  },
};

const addButtonDisabled = (data: any) => data.promotions?.length === 1;

const PulsesWithoutStyle = ({ classes }: any) => {
  const translate = useTranslate();

  return (
    <section>
      <Typography
        className={classes.dividerFullWidth}
        color="textPrimary"
        display="block"
        variant="h6"
      >
        Unlimited:
      </Typography>
      <Divider />
      <div className={classes.line}>
        <BooleanInput
          source="isUnlimitedOfferFirm"
          label="resources.Gym.fields.isUnlimitedOfferFirm"
          className={classes.inlineBlock}
        />
      </div>

      <Divider />

      <NumberInput
        source={'admissionChargeInCredits'}
        label="resources.Firm.fields.admissionChargeInCredits.label"
        placeholder={translate(
          'resources.Firm.fields.admissionChargeInCredits.placeholder'
        )}
        min={0}
        max={99}
        step={1}
      />
      <div className={classes.inlineBlock}>
        <FormDataConsumer>
          {({ formData }: any) => (
            <CappingComponent formData={formData} translate={translate} />
          )}
        </FormDataConsumer>
      </div>
      <BooleanInput
        className={classes.inlineBlock}
        source={'discoverySession'}
        label="resources.Firm.fields.discoverySession.label"
        helperText="resources.Firm.fields.discoverySession.description"
      />
      <div className={classes.inlineBlock}>
        <FormDataConsumer>
          {({ formData }: any) => (
            <OffPeakComponent formData={formData} translate={translate} />
          )}
        </FormDataConsumer>
      </div>
      <FormDataConsumer>
        {({ formData }: any) =>
          showPromotions(formData) && (
            <>
              <Typography
                className={classes.dividerFullWidth}
                color="textPrimary"
                display="block"
                variant="h6"
              >
                Promotions sur les séances
              </Typography>
              <Divider />

              {isPromotionEnded(formData) && (
                <div className={classes.alert}>la promotion est terminée</div>
              )}
              <ArrayInput source="promotions" label="">
                <SimpleFormIterator disableAdd={addButtonDisabled(formData)}>
                  <NumberInput
                    source="percent"
                    label="resources.Firm.fields.promotion.percent"
                    min="0"
                    max="100"
                    validate={[minValue(0), maxValue(100), required()]}
                  ></NumberInput>
                  <DateInput
                    source="startDate"
                    label="resources.Firm.fields.promotion.startDate"
                    validate={required()}
                  ></DateInput>
                  <DateInput
                    source="endDate"
                    label="resources.Firm.fields.promotion.endDate"
                    validate={required()}
                  ></DateInput>
                </SimpleFormIterator>
              </ArrayInput>
            </>
          )
        }
      </FormDataConsumer>
    </section>
  );
};

export default withStyles(styles)(PulsesWithoutStyle);
