import { CreateParams as RaCreateParams, UpdateParams as RaUpdateParams } from 'ra-core';
import { IResourceExport } from './dataProvider';

interface MenuGroup {
  groupName: string;
  resources: IResourceExport[];
  closed?: boolean;
}

export type MenuItems = MenuGroup | IResourceExport;

export type Menu = MenuItems[];

export function isMenuGroup(item: MenuItems): item is MenuGroup {
  return (item as MenuGroup).groupName !== undefined;
}

export type DataProviderCallback = (
  type: 'CREATE' | 'UPDATE' | 'DELETE',
  resource: 'User' | 'Pass' | 'Gym',
  payload?: any,
  options?: any
) => Promise<any>;

export enum BlockReason {
  LeftCompany = 'LEFT_COMPANY',
  EndedProspect = 'ENDED_PROSPECT',
  Fraudster = 'FRAUDSTER',
  SponsorLeft = 'SPONSOR_LEFT',
}

export enum PassActions {
  TRIGGER_REMUNERATION = 'triggerRemunerationProcess',
  REFUND = 'refund',
  PUNCH_PASS = 'punchPass',
  GPS_ANOMALY = 'declareGPSAnomaly',
  VALIDATE_BOOKING = 'validateBooking',
  LATE_CANCELLED = 'lateCancelled',
  CANCEL_LATE_CANCELLED = 'cancelLateCancelled',
  NO_SHOW = 'noShow',
  CANCEL_NO_SHOW = 'cancelNoShow',
  CANCEL_REMUNERATION = 'cancelRemuneration',
  REFUND_GYM_PASSES = 'refundGymPasses',
}

declare global {
  interface Window {
    hj?: any;
  }
}

export enum PassType {
  VISIT_1 = 'VISIT_1',
  VISITS_5 = 'VISITS_5',
  VISITS_10 = 'VISITS_10',
  MONTH_1 = 'MONTH_1',
  MONTHS_3 = 'MONTHS_3',
}

export enum ErpFirmName {
  Episod = 'EPISOD',
  Heitz = 'HEITZ',
  Keepcool = 'KEEPCOOL',
  Mindbody = 'MINDBODY',
  Resamania = 'RESAMANIA',
  Zingfit = 'ZINGFIT',
  Neoness = 'NEONESS',
  Deciplus = 'DECIPLUS',
}

export enum PlatformType {
  Employees = 'EMPLOYEES',
  Sponsorship = 'SPONSORSHIP',
  Credit = 'CREDIT',
  CreditSponsorship = 'CREDIT_SPONSORSHIP',
}

export interface UpdateParams<T = any> extends RaUpdateParams {
  data: T;
}

export interface CreateParams<T = any> extends RaCreateParams {
  data: T;
}

export enum GymRemunerationType {
  STANDARD,
  MONTHLY_CAPPED,
  DEGRESSIVITY,
  PEAK_HOURS,
}

export enum PulseChangeEvents {
  CANCELATION = 'Annulation',
  RESERVATION = 'Réservation',
  REFUND = 'Remboursement',
  UNSUBSCRIBE = 'Désabonnement',
  PURCHASE_OF_PULSES = 'Achat de pulses',
  UPGRADE = 'Upgrade',
  DOWNGRADE = 'Downgrade',
  MOVEMENT_VIA_BO = 'Mouvement depuis le BO',
  SUBSCRIBE_RENEWAL = 'Abonnement ou Renouvellement',
  REQUEST_REJECTED = 'Demande refusée',
  TRIGGER_REM = 'Déclenchement de la rémunération',
  UNLIMITED_ROLLOUT = "Migré sur l'offre Illimité",
}

export interface ISelectItem {
  id: string;
  name: string;
}
