import gql from 'graphql-tag';
import { formatGroup } from './shared';

const GET_ONE_QUERY = gql`
  query BO_ActivityGroup($id: ID!) {
    data: BO_ActivityGroup(id: $id) {
      id
      name
      slug
      translations {
        fr
        en
        nl
      }
      activities {
        id
        slug
      }
    }
  }
`;

export const GetOne = () => {
  return (params: any) => {
    return {
      query: GET_ONE_QUERY,
      variables: {
        id: params.id,
      },
      parseResponse: (ret: any) => {
        if (!ret.data) return { data: {} };
        return { data: formatGroup(ret.data.data) };
      },
    };
  };
};
