import gql from 'graphql-tag';
import { GetListParams } from 'ra-core';

const GET_LIST_QUERY = gql`
  query BO_AllErps(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ErpFilter
  ) {
    items: BO_AllErps(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      items {
        id
        siteID
        name
        locationID
        mandatoryFields
        paymentMethodID
        erpAvailableSessions {
          erpPassTypeID
          isForBooking
        }
        groupID
        businessUnitID
        planningHoursOffset
        groupCode
        erpPassTypeID
        promoCode
        blacklistedClasses
        whitelistedClasses
        terms
        discountType
        enableStartDateSync
        login
        password
        url
        isLive
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  return {
    data: resp?.data?.items?.items || [],
    total: resp?.data?.items?.total || 0,
  };
};

export const GetList = (params: GetListParams) => {
  return {
    query: GET_LIST_QUERY,
    variables: {
      page: params.pagination ? params.pagination.page - 1 : 0,
      perPage: params.pagination ? params.pagination.perPage : 25,
      sortField: params.sort ? params.sort.field : null,
      sortOrder: params.sort ? params.sort.order : null,
      filter: params.filter,
    },
    parseResponse: parseResponseMany,
  };
};

export const GetMany = (params: GetListParams) => {
  return {
    query: GET_LIST_QUERY,
    variables: {
      page: params.pagination ? params.pagination.page - 1 : 0,
      perPage: params.pagination ? params.pagination.perPage : 25,
      sortField: params.sort ? params.sort.field : null,
      sortOrder: params.sort ? params.sort.order : null,
      filter: params.filter,
    },
    parseResponse: parseResponseMany,
  };
};
