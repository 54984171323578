import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';

export const ProductPriceGroupList = (props: any) => (
  <List {...props} undoable="false" bulkActions={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="name.fr" label="Nom" />
      <BooleanField source="active" label="Activé" />
    </Datagrid>
  </List>
);
