import React from 'react';

import { useDropzone } from 'react-dropzone';
import { parse as convertFromCSV } from 'papaparse';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    color: '#999',
  },
};

const FileDropZone = ({
  onDrop,
  classes,
  label,
}: {
  onDrop: (files: File[]) => any;
  classes: any;
  label?: string;
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    multiple: false,
    onDrop,
  });

  return (
    <section>
      <div {...getRootProps()} className={classes}>
        <input {...getInputProps()} />
        {label ? <p>{label}</p> : <p>Click to select a file</p>}
      </div>
    </section>
  );
};

interface RawProps {
  handleCSVResult: (data: Array<any>) => void;
  label?: string;
}

interface Props extends RawProps {
  classes: any;
}

export const UnconnectedFileDrop = ({ handleCSVResult, classes, label }: Props) => {
  const handleUpload = (content: string) => {
    // eslint-disable-next-line
    const cleaned = content.replace(/\r\n/g, '\n').replace(/[^\x00-\x7F]/g, '');
    const parsed = convertFromCSV(cleaned);

    if (parsed.errors.length) {
      console.warn('Errors in parsing the CSV', parsed.errors);
    }

    return handleCSVResult(parsed.data);
  };

  return (
    <FileDropZone
      onDrop={(files) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          let result: string;
          if (!reader || !reader.result) return [];
          if (typeof reader.result === 'string') result = reader.result;
          else result = Buffer.from(reader.result).toString('utf8');

          handleUpload(result);
        };

        files.forEach((file) => reader.readAsArrayBuffer(file));
      }}
      label={label}
      classes={classes.dropZone}
    />
  );
};

export default compose<Props, RawProps>(withStyles(styles))(UnconnectedFileDrop);
