import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetOne } from './GetOne';
import { GetList } from './Many';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_ONE: GetOne(introspection),
    GET_LIST: GetList(),
  };
};
