import React, { useCallback, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  email,
  FormDataConsumer,
  FormTab,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  AutocompleteArrayInput,
  regex,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  translate,
  DateInput,
  maxValue,
  useQuery,
} from 'react-admin';
import DebugInfos, { Props as DebugInfosProps } from '../../utils/DebugInfos';
import { BookingFlow as BookingFlowTypes } from './dataProvider/types';
import Description from './Description';
import GymMapAddress from './GymMapAddress';
import HandlePenaltyListing from './HandlePenaltyListing';
import { OnlineTemporarilyClosedComponent } from './OnlineTemporarilyClosed';
import OpeningTimes from './OpeningTimes';
import Pictures from './Pictures';
import Pulses from './Pulses';
import AddGymManager from './AddGymManager';
import { DisplayRemProcess } from './RemProcessDisplay';
import {
  isUsePublicPriceForPaymentDisabled,
  showPromotions,
  validatePromotionDates,
} from './utils';

import TestErpIntegration from './TestErpIntegration';
import { BookingFlowSection } from './BookingFlowSection';
import moment from 'moment';
import _ from 'lodash';
import { GetManyReference } from '../gymAudits/dataProvider/Many';
import MainActivity from './Activities';
import { AutomaticCheckinComponent } from './AutomaticCheckinComponent';
import { CustomPunchControl } from './CustomPunchControl';

const styles = {
  inlineBlock: {
    margin: '15px',
    flex: '1',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 30px)',
    margin: '15px',
  },
  containerElement: {
    height: '400px',
    display: 'flex',
    margin: '15px',
    width: 'calc(100%-30px)',
    '& >div:nth-child(2)': {
      width: 'calc(50% - 30px)',
      margin: '15px',
      height: '370px',
      display: 'flex',
    },
  },
  searchElement: {
    display: 'flex',
  },
  hideIteratorLabel: {
    '& p': {
      display: 'none',
    },
  },
  emailListContainer: {
    margin: '15px',
  },
  biOpsHooksListContainer: {
    margin: '15px',
  },
  emailsList: {
    paddingLeft: '50px',
    paddingTop: '20px',
    width: 'calc(50% - 50px)',
    '& li': {
      borderBottom: 'none',
      '& span': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  inlineBlockEmailPreferences: {
    width: '50%',
  },
  inlineBlockBiOpsHooks: {
    width: '50%',
  },
  emailInput: {
    width: 'calc(100% - 30px)',
    marginRight: '30px',
  },
  descriptionEditor: {
    margin: '15px',
    // '& >div': {

    // },
    width: '100%',
    // border: '2px solid #ccc !important',
    // borderRadius: '10px',
    // '& >div:first-child': {
    //   borderBottom: '2px solid #ccc !important',
    // },
    // '& >div': {
    //   padding: '15px !important',
    //   margin: '0 !important',
    // },
  },
  gymPropertiesContainer: {
    width: 'calc(100% - 30px)',
    margin: '15px',
  },
  halfLine: {
    display: 'inline-flex',
    flex: '1',
    margin: '15px',
    '& >div': {
      flex: '1',
    },
    '& >div:first-child': {
      marginRight: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    '& >div:nth-child(2)': {
      marginLeft: '15px',
    },
  },
  tabSales: {
    '& table': {
      width: '100%',
    },
    '& div:only-child': {
      width: '100%',
      'overflow-x': 'auto',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  reviewTable: {
    width: '100%',
    // tableLayout: 'auto',
  },
  lastOpeningDate: {
    width: '25%',
    display: 'flex',
  },
};

const GymTitle = ({ translate, record }: any) => (
  <span>{translate('resources.Gym.editTitle', { name: record ? record.name : '' })}</span>
);

const GymEditActions = (props: any) => {
  return props.data ? (
    <TopToolbar>
      <HandlePenaltyListing {...props} />
      {props.data.erp ? <TestErpIntegration {...props} /> : null}
    </TopToolbar>
  ) : null;
};

const CustomToolbar = (props: any) => (
  <Toolbar {...props} pristine={false} className={props.classes.toolbar}>
    <SaveButton pristine={false} />
    <DeleteButton redirect="list" undoable={false} />
  </Toolbar>
);

const CustomColumn = ({ customRender, record }: any) => {
  return <div>{customRender ? customRender(record) : <div>{record?.fromUser}</div>}</div>;
};

const debug: DebugInfosProps['fields'] = [
  {
    label: 'Find in DB',
    content: (record) => `db.gyms.findOne({_id: ObjectId("${record.id}")})`,
    isCode: true,
  },
];

const validateData = (values: any) => {
  const errors: any = {};
  if ([BookingFlowTypes.BOOKING_WITHOUT_ERP_REGULAR].includes(values.bookingFlow)) {
    if (
      !values.reservationProcess?.email &&
      !values.reservationProcess?.phoneNumber &&
      !values.reservationProcess?.dynamicSchedule
    ) {
      const errorMessage = translate(
        'resources.Gym.description.reservationProcess.error'
      );
      errors.reservationProcess = {
        email: errorMessage,
        phoneNumber: errorMessage,
        dynamicSchedule: errorMessage,
      };
    }
  }

  if (showPromotions(values.bookingFlow)) {
    const validateDatesResult = validatePromotionDates(values.promotions);
    if (Array.isArray(validateDatesResult) && validateDatesResult.length > 0) {
      errors.promotions = validateDatesResult;
    }
  }

  return errors;
};

const validateLastOpeningDate = (value: string) => {
  const maxDate = moment().endOf('day').toISOString();
  const errorMessage = `Max ${moment().format('YYYY-MM-DD')}`;
  const validator = maxValue(maxDate);
  return validator(value) ? errorMessage : undefined;
};

const GymEditWithoutStyle = ({ classes, translate, ...props }: any) => {
  const { data: gym } = useQuery({
    type: 'getOne',
    resource: 'Gym',
    payload: { id: props.id },
  });

  const [actionType, setActionType] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (selectedOption: string) => {
    if (actionType.includes(selectedOption)) {
      setActionType(actionType.filter((item: string) => item !== selectedOption));
    } else {
      setActionType(actionType.concat(selectedOption));
    }
  };

  const actionTypes = [
    { label: 'Update gym', value: 'UPDATE_GYM' },
    { label: 'Create remuneration', value: 'CREATE_REMUNERATION' },
    { label: 'Update remuneration', value: 'UPDATE_REMUNERATION' },
  ];

  const debouncedOnChange = useCallback(
    _.debounce((value: string) => {
      setInputValue(value);
    }, 500),
    []
  );

  if (!gym) {
    return null;
  } else {
    // when we use the slug in the url, we need to update the props.id
    props = { ...props, id: gym.id };
  }

  return (
    <Edit
      {...props}
      undoable={false}
      title={<GymTitle translate={translate} />}
      actions={<GymEditActions />}
      aside={<DebugInfos fields={debug} />}
    >
      <TabbedForm
        submitOnEnter={false}
        toolbar={<CustomToolbar classes={classes} />}
        redirect="list"
        validate={validateData}
      >
        <FormTab label="resources.Gym.tabs.informations">
          <div className={classes.line}>
            <OnlineTemporarilyClosedComponent {...props} classes={classes} />

            <div className={classes.halfLine}>
              <ReferenceInput
                label="resources.Gym.fields.firm.label"
                source="firm.id"
                reference="Firm"
                className={classes.quarterLine}
                allowEmpty
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </div>
          </div>
          {!!gym.egymGymId && (
            <div className={classes.line}>
              <TextInput
                source="egymGymId"
                label="resources.Gym.fields.egymGymId.label"
                className={classes.inlineBlock}
                disabled
              />
            </div>
          )}

          <div className={classes.line}>
            <TextInput
              source="name"
              label="resources.Gym.fields.name.label"
              validate={required()}
              placeholder={translate('resources.Gym.fields.name.placeholder')}
              className={classes.inlineBlock}
              disabled={!!gym.egymGymId}
            />
          </div>
          <div className={classes.line}>
            <TextInput
              validate={[
                required(),
                regex(
                  /^[a-zA-Z0-9%]+(?:-[a-zA-Z0-9%]+)*$/,
                  'Le slug ne peut pas contenir de caractères spéciaux'
                ),
              ]}
              source="slug"
              label="resources.Gym.fields.slug.label"
              placeholder={translate('resources.Gym.fields.slug.placeholder')}
              className={classes.inlineBlock}
              disabled={!!gym.egymGymId}
            />
          </div>

          <FormDataConsumer>
            {(props: any) => (
              <>
                <GymMapAddress
                  mapContainerClassName={classes.containerElement}
                  searchBoxClassName={classes.searchElement}
                  {...props}
                  disabled={!!gym.egymGymId}
                />
                {!!gym.gymUUID && (
                  <div className={classes.line}>
                    <Typography component="div">
                      <Box>
                        {translate('resources.Gym.admin.configuration.details')}{' '}
                        <a
                          className={classes.link}
                          href={`${process.env.REACT_APP_INTERNAL_ADMIN_URL}/gym-location/gymfinder/gym/${gym.gymUUID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Internal Admin
                        </a>
                      </Box>
                    </Typography>
                  </div>
                )}

                {!gym.egymGymId && <OpeningTimes translate={translate} />}
                <div className={classes.line}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      {translate('resources.Gym.restrictions.corporate.title')}
                    </Box>
                    <Box>
                      {translate('resources.Gym.restrictions.corporate.description')}
                    </Box>
                  </Typography>
                </div>
                <div className={classes.line}>
                  <ReferenceArrayInput
                    {...props}
                    source="corporationIDs"
                    label="resources.Gym.fields.corporationIDs"
                    reference="CorporateCompany"
                    perPage={5}
                    sort={{ field: `name`, order: 'ASC' }}
                  >
                    <AutocompleteArrayInput {...props} />
                  </ReferenceArrayInput>
                </div>
                <div className={classes.line}>
                  <BooleanInput
                    source="unlimitedJokers"
                    className={classes.inlineBlock}
                    label="resources.Gym.fields.unlimitedJokers.label"
                  />
                </div>
                <div className={classes.line}>
                  <BooleanInput
                    source="indexSEO"
                    label="resources.Gym.fields.indexSEO.label"
                    className={classes.inlineBlock}
                  />
                </div>
                <div className={classes.line}>
                  <ArrayInput
                    className={[classes.hideIteratorLabel, classes.emailListContainer]}
                    source="emailPreferences"
                    label="resources.Gym.fields.emailPreferences.label"
                  >
                    <SimpleFormIterator>
                      <SelectInput
                        className={classes.inlineBlockEmailPreferences}
                        source="event"
                        label="resources.Gym.fields.emailPreferences.eventType.label"
                        choices={[
                          {
                            id: 'PASSGENERATED',
                            name: translate(
                              'resources.Gym.fields.emailPreferences.eventType.passGenerated'
                            ),
                          },
                          {
                            id: 'PASSVALIDATED',
                            name: translate(
                              'resources.Gym.fields.emailPreferences.eventType.passValidated'
                            ),
                          },
                          {
                            id: 'PASSCREDITED',
                            name: translate(
                              'resources.Gym.fields.emailPreferences.eventType.passCredited'
                            ),
                          },
                          {
                            id: 'BOOKING_WITHOUT_ERP_REGULAR',
                            name: translate(
                              'resources.Gym.fields.emailPreferences.eventType.bookingWithoutErpRegular'
                            ),
                          },
                        ]}
                      />
                      <ArrayInput
                        className={[classes.hideIteratorLabel, classes.emailsList]}
                        source="emails"
                        label="resources.Gym.fields.emailPreferences.emails.label"
                      >
                        <SimpleFormIterator>
                          <TextInput
                            className={[classes.hideIteratorLabel, classes.emailInput]}
                            label="resources.Gym.fields.emailPreferences.emails.email.label"
                            placeholder={translate(
                              'resources.Gym.fields.emailPreferences.emails.email.placeholder'
                            )}
                            validate={email()}
                            format={(value: any) =>
                              typeof value === 'object' ? null : value
                            }
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <BooleanInput
                        className={classes.inlineBlockEmailPreferences}
                        source="isEnabled"
                        label="resources.Gym.fields.emailPreferences.isEnabled.label"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </div>
                <div className={classes.line}>
                  <ArrayInput
                    className={[
                      classes.hideIteratorLabel,
                      classes.biOpsHooksListContainer,
                    ]}
                    source="biOpsHooks"
                    label="resources.Gym.fields.biOpsHooks.label"
                  >
                    <SimpleFormIterator>
                      <SelectInput
                        className={classes.inlineBlockEmailPreferences}
                        source="event"
                        label="resources.Gym.fields.biOpsHooks.eventType.label"
                        choices={[
                          {
                            id: 'PASSGENERATED',
                            name: translate(
                              'resources.Gym.fields.biOpsHooks.eventType.passGenerated'
                            ),
                          },
                          {
                            id: 'PASSVALIDATED',
                            name: translate(
                              'resources.Gym.fields.biOpsHooks.eventType.passValidated'
                            ),
                          },
                        ]}
                      />
                      <TextInput
                        source="url"
                        className={[classes.hideIteratorLabel, classes.emailInput]}
                        label="resources.Gym.fields.biOpsHooks.url.label"
                        placeholder={translate(
                          'resources.Gym.fields.biOpsHooks.url.placeholder'
                        )}
                        validate={required()}
                      />
                      <BooleanInput
                        className={classes.inlineBlockEmailPreferences}
                        source="isEnabled"
                        label="resources.Gym.fields.biOpsHooks.isEnabled.label"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </div>
                <div className={classes.line}>
                  <DateInput
                    source="lastOpeningDate"
                    label="resources.Gym.fields.lastOpeningDate.label"
                    className={classes.lastOpeningDate}
                    validate={validateLastOpeningDate}
                  />
                </div>
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.Gym.tabs.sessions">
          <Pulses translate={translate} {...props} />
        </FormTab>
        <FormTab label="resources.Gym.tabs.bookingflow">
          <FormDataConsumer>
            {(props: any) => (
              <>
                <FormDataConsumer>
                  {(props: any, formData: any) => (
                    <CustomPunchControl
                      classes={classes}
                      formData={formData}
                      {...props}
                    />
                  )}
                </FormDataConsumer>
                <BookingFlowSection classes={classes} {...props} />
                <AutomaticCheckinComponent
                  classes={classes}
                  {...props}
                ></AutomaticCheckinComponent>
                <div className={classes.line}>
                  <BooleanInput
                    className={classes.inlineBlock}
                    source="usePublicPriceForPayment"
                    label="resources.Gym.fields.usePublicPriceForPayment.label"
                    disabled={isUsePublicPriceForPaymentDisabled(props)}
                  />
                </div>
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        {!gym.egymGymId && (
          <FormTab label="resources.Gym.tabs.description">
            <div className={classes.descriptionEditor}>
              <Description {...props} />
            </div>
            <BooleanInput source="isOnline" label="resources.Gym.fields.isOnline.label" />
            <MainActivity classes {...props} />
            <ReferenceArrayInput
              className={classes.gymPropertiesContainer}
              source="servicesIds"
              reference="Service"
              perPage={1000}
              sort={{ field: `name.${props.locale || 'fr'}`, order: 'ASC' }}
              allowEmpty
              label="resources.Gym.fields.servicesIds.label"
            >
              <SelectArrayInput optionText={`name.${props.locale || 'fr'}`} />
            </ReferenceArrayInput>

            <ReferenceArrayInput
              className={classes.gymPropertiesContainer}
              source="equipmentIds"
              reference="GymEquipment"
              perPage={1000}
              allowEmpty
              label="resources.Gym.fields.equipmentIds.label"
            >
              <SelectArrayInput />
            </ReferenceArrayInput>

            <ReferenceArrayInput
              className={classes.gymPropertiesContainer}
              source="infrastructureIds"
              reference="GymInfrastructure"
              perPage={1000}
              allowEmpty
              label="resources.Gym.fields.infrastructureIds.label"
            >
              <SelectArrayInput />
            </ReferenceArrayInput>

            <NumberInput source="area" min={0} />
            <NumberInput
              source="poolLength"
              label="resources.Gym.fields.poolLength.label"
              min={0}
            />
            <div className={classes.line}>
              <TextInput
                className={classes.inlineBlock}
                source="website"
                label="resources.Gym.fields.website.label"
              />
              <TextInput
                className={classes.inlineBlock}
                source="instagramUsername"
                label="resources.Gym.fields.instagramUsername.label"
                validate={[regex(/^[a-zA-Z0-9._]{3,30}$/, 'Entrez un username valide')]}
              />
            </div>
          </FormTab>
        )}

        <FormTab label="resources.Gym.tabs.remunerationProcess">
          <FormDataConsumer>
            {({ formData, ...props }: any) => (
              <DisplayRemProcess {...props} type="GYM" data={formData} />
            )}
          </FormDataConsumer>
        </FormTab>
        {!gym.egymGymId && (
          <FormTab label="resources.Gym.tabs.pictures">
            <Pictures {...props} />
          </FormTab>
        )}
        <FormTab label="resources.Gym.tabs.passList" contentClassName={classes.tabSales}>
          <ReferenceManyField
            pagination={<Pagination />}
            label="Pass"
            source="id"
            target="gym"
            reference="Pass"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid rowClick="edit">
              <ReferenceField
                label="resources.Pass.fields.userEmail"
                source="user.id"
                reference="User"
                sortable={false}
              >
                <TextField source="email" />
              </ReferenceField>
              <FunctionField
                label="Status"
                render={(record: any) =>
                  translate(
                    'resources.Pass.fields.passStatus.' +
                      ((record && record.status) || 'unknown')
                  )
                }
              />
              <DateField source="createdAt" showTime />
              <DateField source="transferredAt" showTime />
              <DateField source="lastValidationDate" showTime />
              <DateField source="expirationDate" showTime />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.Gym.tabs.managers">
          <AddGymManager {...props} />
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.Gym.manager.registered"
            source="id"
            target="gym"
            reference="GymManager"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid rowClick="edit">
              <TextField source="email" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.Gym.tabs.reviews">
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.Gym.reviews.title"
            source="id"
            target="gym"
            reference="GymReview"
            sort={{ field: 'postedAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid
              classes={{
                table: classes.reviewTable,
              }}
            >
              <DateField source="postedAt" />
              <ReferenceField
                label="resources.Users.fields.email"
                source="author.id"
                reference="User"
                sortable={false}
              >
                <TextField source="email" />
              </ReferenceField>
              <TextField source="comment" fullWidth />
              <TextField source="rating" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.Gym.tabs.events">
          <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
            {actionTypes.map((option) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                control={
                  <Checkbox
                    color="primary"
                    checked={actionType.includes(option.value)}
                    onChange={() => {
                      handleChange(option.value);
                    }}
                  />
                }
              />
            ))}
          </div>
          <TextInput
            source="message"
            alwaysOn
            onChange={(event: any) => debouncedOnChange(event.target.value)}
          />
          <ReferenceManyField
            filter={{ action: actionType, message: inputValue }}
            pagination={<Pagination />}
            label="resources.Gym.fields.events"
            reference="GymAudit"
            target="gym.id"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
            getManyReference={(params: any) =>
              GetManyReference({
                ...params,
                filter: { action: actionType, message: inputValue },
              })
            }
          >
            <Datagrid>
              <TextField source="action" />
              <TextField source="message" />
              <DateField source="createdAt" showTime />
              <CustomColumn
                label="resources.Gym.fields.author"
                customRender={(record: any) => <div>{record?.fromUser}</div>}
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const GymEdit = translate(withStyles(styles)(GymEditWithoutStyle));
