import React, { useState } from 'react';

import { Mutation, translate as translateDecorator } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const CreatePasswordResetAction = ({ data, translate }: any) => {
  const [open, setOpen] = useState(false);

  if (!data) return null;

  const payload = {
    id: data.id,
    data: { ...data, isAnonymized: true, type: 'GymManager' },
  };

  const options = {
    undoable: false,
    callback: ({
      requestPayload,
      payload,
      error,
    }: {
      requestPayload: any;
      payload: any;
      error: any;
    }) => {
      if (error) return console.log('Failed to anonymize user', error);
    },
    onSuccess: {
      notification: {
        body: translate('resources.GymManager.actions.passwordReset.success'),
        level: 'info',
      },
      refresh: true,
    },
    onError: {
      notification: {
        body: translate('resources.GymManager.actions.passwordReset.failure'),
        level: 'warning',
      },
    },
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.GymManager.actions.passwordReset.name')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.GymManager.actions.passwordReset.name')}{' '}
          {data && data.email ? data.email : ''}
        </DialogTitle>
        <DialogContent>
          <span>
            {translate('resources.GymManager.actions.passwordReset.dialogText')}
          </span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Cancel
          </Button>

          <Mutation
            type="CREATE"
            resource="PasswordReset"
            payload={payload}
            options={options}
          >
            {(approve: any) => (
              <Button
                onClick={() => {
                  setOpen(false);
                  approve();
                }}
                variant="contained"
                color="primary"
              >
                OK
              </Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default translateDecorator(CreatePasswordResetAction);
