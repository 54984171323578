import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NumberInput, BooleanInput, TextInput, required } from 'react-admin';
import { useForm } from 'react-final-form';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
};

const DeciplusForm = (props: any) => {
  const form = useForm();
  form.change('name', 'DECIPLUS');

  return (
    <div>
      <TextInput
        source="deciplusConfiguration.clientId"
        label="clientId"
        validate={[required()]}
      />
      <TextInput
        source="deciplusConfiguration.clientSecret"
        label="clientSecret"
        validate={[required()]}
      />
      <TextInput
        source="deciplusConfiguration.domain"
        label="domain"
        validate={[required()]}
      />
      <NumberInput
        source="deciplusConfiguration.zoneId"
        label="zoneId"
        validate={[required()]}
      />
      <NumberInput
        source="deciplusConfiguration.uniqueEntryProductID"
        label="unique entry product id"
        validate={[required()]}
      />
      <BooleanInput
        source="deciplusConfiguration.skipValidations"
        label="skip validations"
        initialValue={false}
      />
      <PlanningOffsetComponent />
    </div>
  );
};

export default withStyles(styles)(DeciplusForm);
