import gql from 'graphql-tag';

import { slugify } from '../../../utils';
import { FormatFirm } from './utils';

const CREATE_MUTATION = gql`
  mutation createFirm($input: CreateFirmInput!) {
    data: createFirm(input: $input) {
      id
      name
      slug
      isUnlimitedOfferFirm
      availableSession {
        admissionChargeInCredits
        yield {
          capping
          discoverySession
        }
      }
      promotions {
        startDate
        endDate
        percent
      }
    }
  }
`;

export const Create = (params: any) => {
  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.data) return { data: {} };

    return { data: FormatFirm(resp.data.data) };
  };

  const preparedInput: any = {
    slug: slugify(params.data.slug),
    name: params.data.name,
    promotions: params.data.promotions,
  };

  if (params.data.availableSession) {
    preparedInput.availableSession = {
      admissionChargeInCredits: params.data.admissionChargeInCredits,
      yield: {
        capping: params.data.capping,
        discoverySession: params.data.discoverySession,
      },
    };
  }

  return {
    variables: {
      input: preparedInput,
    },
    parseResponse,
    query: CREATE_MUTATION,
  };
};
