import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';

import { ExternalCouponList } from './ExternalCouponList';
import { IResourceExport } from '../../@types/dataProvider';

import externalCouponProvider from './dataProvider';

export default {
  name: 'UserExternalCouponGeneration',
  resources: {
    list: ExternalCouponList,
  },
  icon: SportsKabaddiIcon,
  dataProvider: {
    getOneName: 'userExternalCouponGeneration',
    shouldForce: true,
    providerWithIntrospection: externalCouponProvider,
  },
} as IResourceExport;
