import React, { useState } from 'react';
import { useRefresh, useTranslate, useDataProvider, useNotify } from 'ra-core';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { isValidEmail } from '../utils';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
  },
};
interface RawProps {
  data: any;
}

interface Props extends RawProps {
  classes: any;
}

const AddManagerAction = ({ data, classes }: Props) => {
  const dataProvider = useDataProvider();

  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const notify = useNotify();
  const refresh = useRefresh();

  const addHrManagerHandler = async () => {
    try {
      await dataProvider
        .create('HrManager', {
          data: {
            email: emailInput,
            companyIDs: [data.id],
          },
        })
        .then(() => {
          setOpen(false);
          refresh();
        });
    } catch (error: any) {
      setOpen(false);
      if (error.message === 'HrManager already exists') {
        return notify('resources.CorporateCompany.manager.error', 'warning');
      }
      return notify('resources.CorporateCompany.generic.error', 'warning');
    }
  };

  return (
    <div style={{ marginLeft: '.5rem' }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.CorporateCompany.manager.add.title')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle>
          {translate('resources.CorporateCompany.manager.add.title')}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={emailInput}
            onChange={(event: any) => setEmailInput(event.target.value.trim())}
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            disabled
            label={translate('resources.HrManager.fields.companyIDs')}
            value={data?.name}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={emailInput.length === 0 || !isValidEmail(emailInput)}
            onClick={addHrManagerHandler}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AddManagerAction);
