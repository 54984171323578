import GymIcon from '@material-ui/icons/FitnessCenter';
import { IResourceExport } from '../../@types/dataProvider';

import { GymList } from './GymList';
import { GymEdit } from './GymEdit';

import gymProvider from './dataProvider';
import gymReviewProvider from './dataProvider/reviews';
import gymCountryProvider from './dataProvider/countries';

export default {
  name: 'Gym',
  resources: {
    list: GymList,
    edit: GymEdit,
  },
  icon: GymIcon,
  dataProvider: {
    getOneName: 'gym',
    useCustomDP: true,
    customDataProvider: gymProvider,
  },
} as IResourceExport;

export const gymReview: IResourceExport = {
  name: 'GymReview',
  dataProvider: {
    getOneName: 'gymReview',
    providerWithIntrospection: gymReviewProvider,
  },
};

export const gymCountry: IResourceExport = {
  name: 'GymCountry',
  dataProvider: {
    getOneName: 'gymCountry',
    providerWithIntrospection: gymCountryProvider,
  },
};
