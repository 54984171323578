import React, { useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { translate, MenuItemLink, Responsive } from 'react-admin';

import ChevronRight from '@material-ui/icons/ChevronRight';

import SubMenu from './SubMenu';

import { Menu as MenuItems } from '../resources';
import { isMenuGroup } from '../@types/common';

interface MenuProps {
  onMenuClick: (a: any) => any;
  logout: Record<string, any>;
  open: boolean;
  translate: any;
}

const Menu = ({ onMenuClick, logout, open, translate }: MenuProps) => {
  let groupIndex = 0;
  const properMenuItems = MenuItems.map((menu) => {
    if (isMenuGroup(menu)) {
      const index = groupIndex++;
      return { ...menu, index };
    }
    return menu;
  });

  const [openedTabs, setOpenedTabs] = useState(
    properMenuItems.filter(isMenuGroup).map((item) => isMenuGroup(item) && !item.closed)
  );

  const handleToggle = (index: number) => {
    openedTabs[index] = !openedTabs[index];
    setOpenedTabs(openedTabs.slice());
  };

  return (
    <div>
      {' '}
      {properMenuItems.map((item) => {
        return isMenuGroup(item) ? (
          <SubMenu
            handleToggle={() => handleToggle(item.index)}
            isOpen={openedTabs[item.index]}
            sidebarIsOpen={open}
            key={`pos.menu.${item.groupName}`}
            name={`pos.menu.${item.groupName}`}
            icon={<ChevronRight />}
          >
            {(sidebarIsOpen: boolean) => {
              return item.resources.map((item) => (
                <MenuItemLink
                  key={item.name}
                  to={`/${item.name}`}
                  primaryText={translate(`resources.${item.name}.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={item.icon && <item.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={sidebarIsOpen}
                />
              ));
            }}
          </SubMenu>
        ) : (
          <MenuItemLink
            key={item.name}
            to={`/${item.name}`}
            primaryText={translate(`resources.${item.name}.name`, {
              smart_count: 2,
            })}
            leftIcon={item.icon && <item.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose<MenuProps, MenuProps>(
  withRouter,
  connect(mapStateToProps, {}),
  translate
);

export default enhance(Menu);
