import gql from 'graphql-tag';

const UPDATE_EXTERNAL_INTEGRATION = gql`
  mutation BO_ExternalIntegrationUpdate($input: ExternalIntegrationInput!) {
    BO_ExternalIntegrationUpdate(input: $input) {
      id
      companyID
      integrationType
      createdAt
      ignoreNextSync
      more {
        s3Path
        receiveByEmail
        blacklistedOrganizationUnits
        keepOrganizationUnitsRegex
        ignoreEmailRegex
      }
      fields {
        email
        refKeyName
        firstName
        lastName
      }
      status
    }
  }
`;

export const Update = (params: any) => {
  const input = {
    email: params?.data?.fields?.email,
    id: params.data.id,
    s3Path: params?.data?.more?.s3Path,
    status: params?.data?.status,
    refKeyName: params?.data?.fields?.refKeyName,
    firstName: params?.data?.fields?.firstName,
    lastName: params?.data?.fields?.lastName,
    receiveByEmail: params?.data?.more?.receiveByEmail,
    blacklistedOrganizationUnits: params?.data?.more?.blacklistedOrganizationUnits?.map(
      (e: any) => e.value
    ),
    keepOrganizationUnitsRegex: params?.data?.more?.keepOrganizationUnitsRegex,
    ignoreEmailRegex: params?.data?.more?.ignoreEmailRegex,
    ignoreNextSync: params?.data?.ignoreNextSync,
  };
  return {
    query: UPDATE_EXTERNAL_INTEGRATION,
    variables: {
      input,
    },

    parseResponse: (resp: any) => {
      return {
        data: {
          ...resp,
          id: params.data.id,
          blacklistedOrganizationUnits: params?.data?.more?.blacklistedOrganizationUnits?.map(
            (e: any) => e.value
          ),
        },
      };
    },
  };
};
