import React from 'react';

import { useTranslate } from 'ra-core';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    // transform: 'rotate(-90deg)',
  },
}));

const UserTitle = ({ record }: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!record) return <span>{translate('User')}</span>;

  const name = `${record.firstname} ${record.lastname} (${record.email})`;

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} alt={name} src={record.picture}>
        {record.firstname[0]}
      </Avatar>
      {name}
    </div>
  );
};

export default UserTitle;
