import AddToPhotos from '@material-ui/icons/AddToPhotos';

import productProvider from './dataProvider';
import { ActivityGroupList } from './ActivityGroupList';
import { IResourceExport } from '../../@types/dataProvider';
import { ActivityGroupEdit } from './ActivityGroupEdit';

export default {
  name: 'ActivityGroup',
  icon: AddToPhotos,
  resources: {
    list: ActivityGroupList,
    edit: ActivityGroupEdit,
    // create: CreditPackCreate,
  },
  dataProvider: {
    getOneName: 'BO_Product',
    providerWithIntrospection: productProvider,
  },
} as IResourceExport;
