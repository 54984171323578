import React from 'react';
import { Datagrid, TextField, List, Filter, TextInput } from 'react-admin';

const ActivityFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Slug" source="slug" alwaysOn />
  </Filter>
);

export const ActivityList = (props: any) => (
  <List
    filters={<ActivityFilter />}
    {...props}
    undoable="false"
    bulkActions={false}
    sort={{ field: 'slug', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="slug" />
      <TextField source="activityID" label="WP ID" />
    </Datagrid>
  </List>
);
