import React from 'react';
import { useForm } from 'react-final-form';
import { TextInput, required, regex } from 'react-admin';
import { useTranslate } from 'ra-core';

const CorporationNameSlug = ({ classes, ...props }: any) => {
  const form = useForm();
  const translate = useTranslate();

  return (
    <tr>
      <td className={classes.halfSize}>
        <TextInput
          source="name"
          label="resources.CorporateCompany.fields.name.label"
          placeholder={translate('resources.CorporateCompany.fields.name.placeholder')}
          validate={[required()]}
          onChange={(e: any) => {
            const companyName = e.target.value;
            form.change('slug', companyName.replace(/\s/g, '-').toLowerCase());
          }}
        />
      </td>
      <td className={classes.halfSize}>
        <TextInput
          source="slug"
          label="resources.CorporateCompany.fields.slug.label"
          placeholder={translate('resources.CorporateCompany.fields.slug.placeholder')}
          validate={[
            required(),
            regex(
              /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/,
              'Le slug ne peut pas contenir de carctères spéciaux'
            ),
          ]}
          autoFocus
        />
      </td>
    </tr>
  );
};

export default CorporationNameSlug;
