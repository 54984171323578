import { GetOne } from './GetOne';
import { GetList, GetMany, GetManyReference } from './Many';
import { Update } from './Update';
import { Delete } from './Delete';
import { DeleteMany } from './DeleteMany';

export default {
  UPDATE: Update,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
  GET_MANY_REFERENCE: GetManyReference,
  DELETE: Delete,
  DELETE_MANY: DeleteMany,
};
