import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

const ActivityTitle = ({ record }: any) => {
  return <span>Activity {record ? `"${record.slug}"` : ''}</span>;
};

export const ActivityEdit = (props: any) => (
  <Edit {...props} undoable={false} title={<ActivityTitle />}>
    <SimpleForm>
      <TextInput disabled source="slug" />
      <TextInput
        label="resources.TranslatedContents.frenchName"
        source="name.fr"
        validate={required('The French Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.englishName"
        source="name.en"
        validate={required('The English Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.dutchName"
        source="name.nl"
        validate={required('The Dutch Translation is required')}
      />
      <TextInput disabled source="activityID" label="WP ID" />
    </SimpleForm>
  </Edit>
);
