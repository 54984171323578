import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'react-admin';
import Menu from './Menu';
import { dark, light } from '../theme/theme';
import { ThemeType } from '../theme/actions';
import AppBar from './AppBar';

const CustomLayout = (props: any) => {
  let theme = useSelector((state: any) =>
    state.theme === ThemeType.DARK ? dark : light
  );

  const localSavedTheme = localStorage.getItem('theme');
  if (localSavedTheme) {
    console.log('Using locally saved theme', localSavedTheme);
    theme = localSavedTheme === ThemeType.DARK ? dark : light;
  }

  return <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />;
};

export default CustomLayout;
