import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation addIntegrationS3($input: BO_AddIntegrationS3Input) {
    data: BO_AddIntegrationS3(input: $input) {
      id
    }
  }
`;

export const Create = (params: any) => {
  console.log('[CREATE][EXTERNAL INTEGRATION]', params);
  const userID = localStorage.getItem('userID') as string;
  return {
    variables: {
      input: {
        companyName: params.data.companyName,
        userID,
      },
    },
    query: CREATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: { ...resp.data.data },
      };
    },
  };
};
