import React from 'react';
import { Datagrid, TextField, List, Filter, TextInput } from 'react-admin';

const FirmFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="q" alwaysOn resettable />
    <TextInput label="Slug" source="slug" allowEmpty />
  </Filter>
);

export const FirmList = (props: any) => (
  <List filters={<FirmFilter />} {...props} undoable="false" bulkActionButtons={<div />}>
    <Datagrid rowClick="edit">
      <TextField source="slug" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
