import React from 'react';
import { useForm } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { TextInput, required } from 'react-admin';
import { BooleanInput } from 'react-admin';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {};

const NeonessForm = () => {
  const form = useForm();

  form.change('name', 'NEONESS');
  return (
    <>
      <BooleanInput
        source="isLive"
        label="resources.Erp.fields.neoness.isLive"
        initialValue={false}
      />
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validation={[required()]}
      />
      <PlanningOffsetComponent />
    </>
  );
};

export default withStyles(styles)(NeonessForm);
