import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { light } from '../../theme/theme';

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    cursor: 'pointer',
  },
  selectedRoot: {
    border: `2px solid ${light.palette.secondary.main}`,
  },
  media: {
    height: 140,
  },
});

const ChallengeCard = (props: {
  id: string;
  image: string;
  name: string;
  onClick: (id: string) => void;
  isSelected: any;
}) => {
  const classes = useStyles();
  const { id, image, name, onClick, isSelected } = props;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Card
      className={isSelected ? classes.selectedRoot : classes.root}
      onClick={handleClick}
    >
      <CardMedia className={classes.media} image={image} title={name} />
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ChallengeCard;
