import gql from 'graphql-tag';
import _ from 'lodash';

import { subscriptionStatus } from './subscriptionStatus';

const UserFragment = gql`
  fragment UserFields on User {
    id
    lastLoginDate
    email
    firstname
    lastname
    gender
    phoneNumber
    address
    birthday
    city
    zipcode
    picture
    hasValidSubscription
    status
    corporateCompany {
      id
      name
      slug
    }
    subscription {
      category
      endDate
    }
    roles
    blockDetails {
      blockedBy {
        id
        email
      }
    }
    activationCodes {
      code
      active
      createdAt
      verifiedAt
    }
    deviceID
    deviceName
    lastDeviceRewindDate
    lastAppVersion
    blockedReason
    credits
  }
`;

const UPDATE_MUTATION = (updateRoles: boolean) => {
  return gql`
  mutation updateUser(
    $id: ID!
    $firstname: String
    $lastname: String
    $gender: UserGender
    $birthday: DateTime
    $phoneNumber: String
    $address: String
    $zipcode: String
    $city: String
    $deviceID: String
    $deviceName: String
    $lastDeviceRewindDate: DateTime
    ${updateRoles ? '$roles: [String!]!' : ''}
    $email: String
    $credits: Int
    $reason: String
  ) {
    ${
      updateRoles
        ? `BO_UpdateUserRole(input: { userID: $id, roles: $roles }) {
            data: user {
              id
            }
          }`
        : ''
    }
    BO_UpdateUser(
      input: {
        userID: $id
        data: {
          firstname: $firstname
          lastname: $lastname
          gender: $gender
          birthday: $birthday
          phoneNumber: $phoneNumber
          address: $address
          zipcode: $zipcode
          city: $city
          deviceID: $deviceID
          deviceName: $deviceName
          lastDeviceRewindDate: $lastDeviceRewindDate
          email: $email
          credits: $credits
          changeCreditsReason: $reason
        }
      }
    ) {
      data: user {
        ...UserFields
      }
    }
  }

  ${UserFragment}
`;
};

const UPDATE_ONLY_ROLES = gql`
  mutation updateUser($id: ID!, $roles: [String!]!) {
    BO_UpdateUserRole(input: { userID: $id, roles: $roles }) {
      data: user {
        ...UserFields
      }
    }
  }
  ${UserFragment}
`;

const ANONYMIZE_USER_MUTATION = gql`
  mutation anonymizeUser($id: ID!) {
    BO_AnonymizeUser(input: { userID: $id }) {
      data: user {
        ...UserFields
      }
    }
  }
  ${UserFragment}
`;

const PAUSE_USER_MUTATION = gql`
  mutation BO_PauseUser($id: ID!, $duration: PauseSubscriptionDuration!) {
    BO_PauseUser(input: { userID: $id, duration: $duration }) {
      user {
        ...UserFields
      }
      success
      lastPause
    }
  }
  ${UserFragment}
`;

const UPDATE_USER_FORMULA_MUTATION = gql`
  mutation BO_UpdateUserOfferInstantly($userID: ID!, $newOfferID: ID!) {
    BO_UpdateUserOfferInstantly(input: { userID: $userID, newOfferID: $newOfferID }) {
      success
    }
  }
`;

export const Update = (params: any) => {
  const UPDATE_NO_ROLES = UPDATE_MUTATION(false);
  const UPDATE_WITH_ROLES = UPDATE_MUTATION(true);

  if (params.data.isPaused) {
    return {
      variables: {
        id: params.data.id,
        duration: params.data.duration,
      },
      query: PAUSE_USER_MUTATION,
      parseResponse(response: { data?: { BO_PauseUser: any } }) {
        if (!response.data || !response.data.BO_PauseUser) return { data: {} };
        return response.data.BO_PauseUser;
      },
    };
  }
  if (params.data.isAnonymized) {
    return {
      variables: {
        id: params.id,
      },
      query: ANONYMIZE_USER_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.anonymizeUser) return { data: {} };
        return resp.data.anonymizeUser;
      },
    };
  }
  if (params.data.updateFormula) {
    return {
      variables: {
        userID: params.userID,
        newOfferID: params.newOfferID,
      },
      query: UPDATE_USER_FORMULA_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.BO_UpdateUserOfferInstantly) return { data: {} };
        return {
          success: !!resp.data.BO_UpdateUserOfferInstantly.success,
          data: params.data,
        };
      },
    };
  }
  const allowedFields = [
    'firstname',
    'lastname',
    'gender',
    'birthday',
    'phoneNumber',
    'address',
    'zipcode',
    'city',
    'roles',
    'deviceID',
    'deviceName',
    'email',
    'lastDeviceRewindDate',
    'credits',
    'reason',
  ];
  const toUpdate: any = {
    id: params.data.id,
  };
  allowedFields.forEach((key) => {
    if (params.previousData && !_.isEqual(params.data[key], params.previousData[key]))
      toUpdate[key] = params.data[key];
    if (!params.previousData) {
      toUpdate[key] = params.data[key];
    }
  });
  if (Object.keys(toUpdate).length === 1) throw new Error('No change to save!');
  const updateRoles = toUpdate.roles;

  let query = updateRoles ? UPDATE_WITH_ROLES : UPDATE_NO_ROLES;
  if (_.isEqual(Object.keys(toUpdate), ['id', 'roles'])) query = UPDATE_ONLY_ROLES;

  const parseResponse = (resp: any) => {
    let searchKey = 'BO_UpdateUser';
    if (query === UPDATE_ONLY_ROLES) searchKey = 'BO_UpdateUserRole';
    if (!resp.data && !resp.data[searchKey]) return { data: {} };

    resp.data[searchKey].data.subscriptionStatus = subscriptionStatus(
      resp.data[searchKey].data
    );

    const blockDetails = resp.data[searchKey].data.blockDetails;

    const blockedByManagerID =
      blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.id;
    const blockedByManagerEmail =
      (blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.email) ||
      'Gymlib';

    resp.data[searchKey].data.blockedBy = {
      id: blockedByManagerID,
      email: blockedByManagerEmail,
    };

    return resp.data[searchKey];
  };

  console.log('[UPDATE][USER]', toUpdate);
  return {
    variables: toUpdate,
    parseResponse,
    query,
  };
};
