import React from 'react';
import {
  Edit,
  Toolbar,
  SaveButton,
  SimpleForm,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Editable } from './form';

const MySaveButton = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (response: any) => {
    if (props.record && props.record.id !== response.data.id) {
      notify(`Remuneration mise à jour!`);
      redirect(`/GymRemuneration/${response.data.id}`);
    }
  };
  return (
    <SaveButton {...props} onSuccess={onSuccess} pristine={false} disabled={false} />
  );
};

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <MySaveButton />
  </Toolbar>
);

export const GymRemunerationEdit = ({ ...props }: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="edit">
      <Editable isCreate={false} />
    </SimpleForm>
  </Edit>
);
