import gql from 'graphql-tag';
import moment from 'moment';

const GET_LIST_QUERY = gql`
  query allUserEvents(
    $page: Int!
    $perPage: Int!
    $sortField: String!
    $sortOrder: String!
    $filter: UserEventFilter!
  ) {
    data: allUserEvents(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      total
      items {
        id
        type
        createdAt
        message
        data {
          user
          userID
          pulses
          message
          event
          balance
          passID
        }
      }
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.data.items) return resp;
  console.log('[EVENTS][RESPONSE]', resp.data.data);
  return {
    data: resp.data.data.items,
    total: resp.data.data.total,
  };
};

export const GetList = (params: any) => {
  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {
      userID: params.id,
      startDate: moment().subtract(4, 'M'),
      endDate: moment().add(1, 'M'),
      actionType: params.filter.actionType,
    },
  };

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: parseResponseMany,
  };
};

export const GetMany = (params: any) => {
  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {
      userID: params.id,
      startDate: moment().subtract(4, 'M'),
      endDate: moment().add(1, 'M'),
      actionType: params.filter.actionType,
    },
  };

  console.log('[EVENTS][MANY]', params);
  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse: parseResponseMany,
  };
};

export const GetManyReference = (params: any) => {
  console.log('[EVENTS][MANY ref]', params);

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {
      userID: params.id,
      startDate: moment().subtract(4, 'M'),
      endDate: moment().add(1, 'M'),
      actionType: params.filter.actionType,
    },
  };

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: parseResponseMany,
  };
};
