import React from 'react';
import {
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
  required,
  useTranslate,
  useNotify,
} from 'react-admin';
import { AmountFormatter } from '../../../utils';
import { priceValidation } from './common';

import Tooltip from '@material-ui/core/Tooltip';

import HelpOutline from '@material-ui/icons/HelpOutline';

const indexFinder = /.*\[([0-9]*)\]/;

export const DegressivityProcess = ({ classes, prefix, disabled, ...props }: any) => {
  const translate = useTranslate();
  const notify = useNotify();

  prefix = prefix || '';

  return (
    <div>
      <div className={classes.line}>
        <SelectInput
          {...props}
          source={`${prefix}degressivityType`}
          choices={[
            { id: 'VOLUME', name: 'resources.GymRemuneration.degressivity.type.volume' },
            {
              id: 'GRADUATED',
              name: 'resources.GymRemuneration.degressivity.type.graduated',
            },
            {
              id: 'USER_LIFETIME_USAGE',
              name: 'resources.GymRemuneration.degressivity.type.user_lifetime_usage',
            },
          ]}
          validate={!disabled ? [required()] : undefined}
          disabled={disabled}
        />
        <FormDataConsumer>
          {({ formData, ...rest }: any) => (
            <Tooltip
              title={translate(
                `resources.GymRemuneration.degressivity.help.${(
                  formData.degressivityType || ''
                ).toLowerCase()}`
              )}
              placement="right"
              classes={{ tooltip: classes.helpBlock }}
            >
              <HelpOutline />
            </Tooltip>
          )}
        </FormDataConsumer>
      </div>
      <div className={classes.line}>
        <ArrayInput
          {...props}
          source={`${prefix}tiers`}
          label="resources.GymRemuneration.fields.tiers.label"
          disabled={disabled}
        >
          <SimpleFormIterator>
            <NumberInput
              source="price"
              {...AmountFormatter(notify)}
              step={0.1}
              className={classes.inlineBlock}
              validate={!disabled ? priceValidation : undefined}
              label="resources.GymRemuneration.fields.tiers.price"
              disabled={disabled}
            />
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...rest }: any) => {
                // We don't have a limit if there is only one tier or we are on the last one
                if (
                  !formData ||
                  !formData.tiers ||
                  formData.tiers.length === 1 ||
                  !rest.id ||
                  parseInt(rest.id.match(indexFinder)[1]) === formData.tiers.length - 1
                )
                  return null;
                return (
                  <div>
                    {formData && formData && (
                      <NumberInput
                        source={getSource(`limit`)}
                        step={1}
                        min={0}
                        className={classes.inlineBlock}
                        validate={!disabled ? [required()] : undefined}
                        label="resources.GymRemuneration.fields.tiers.limit"
                        disabled={disabled}
                      />
                    )}
                  </div>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </div>
    </div>
  );
};
