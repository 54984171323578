import React from 'react';
import Typography from '@material-ui/core/Typography';
import { BooleanInput, NumberInput, CheckboxGroupInput } from 'react-admin';

export const OffPeakComponent = ({ formData, translate }: any) => {
  return (
    formData && (
      <>
        <BooleanInput
          source="availableSession.yield.offPeak.skipFirm"
          label="resources.Gym.fields.offPeak.skipFirm"
        />
        <Typography>{translate('resources.Gym.fields.offPeak.description')}</Typography>
        <NumberInput
          source="availableSession.yield.offPeak.costInCredits"
          label="resources.Gym.fields.offPeak.costInCredits"
          min={1}
          max={99}
          step={1}
        />
        <CheckboxGroupInput
          source="availableSession.yield.offPeak.intervals"
          label="resources.Gym.yield.offPeakHour.intervals"
          choices={[
            { id: 'MORNING', name: 'resources.Gym.yield.offPeakHour.morning' },
            {
              id: 'AFTERNOON',
              name: 'resources.Gym.yield.offPeakHour.afternoon',
            },
          ]}
        />
      </>
    )
  );
};
