import React from 'react';
import { SelectArrayInput } from 'react-admin';
import { Edit, SimpleForm, TextInput, required, ReferenceArrayInput } from 'react-admin';

const Title = ({ record }: any) => <span>{record ? record.name : ''}</span>;

export const ActivityGroupEdit = (props: any) => (
  <Edit {...props} undoable={false} title={<Title />}>
    <SimpleForm>
      <TextInput disabled source="slug" />
      <TextInput
        label="resources.TranslatedContents.frenchName"
        source="translations.fr"
        validate={required('The French Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.englishName"
        source="translations.en"
        validate={required('The English Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.dutchName"
        source="translations.nl"
        validate={required('The Dutch Translation is required')}
      />
      <ReferenceArrayInput
        source="activityIDs"
        reference="Activity"
        perPage={1000}
        sort={{ field: `name.${props.locale || 'fr'}`, order: 'ASC' }}
        allowEmpty
        label="resources.Gym.fields.activitiesIds.label"
      >
        <SelectArrayInput optionText={`name.${props.locale || 'fr'}`} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
