import { transform, isEqual, isObject } from 'lodash';

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export function AmountFormatter(notify?: (message: string, type: any) => void) {
  return {
    parse: (value: number) => {
      return Math.round(value * 100);
    },
    format: (value: number) => {
      return value / 100;
    },
    onChange: notify
      ? ({ target }: any) => {
          if (!target.value) return;
          if (target.value < 1)
            notify('resources.Gym.notifications.outPaymentLow', 'warning');
          if (target.value > 30)
            notify('resources.Gym.notifications.outPaymentHigh', 'warning');
        }
      : undefined,
  };
}

const UUID_REGEX = /^[0-9a-f]{24}$/i;
export const isUUID = (uuid: string) => UUID_REGEX.test(uuid);

// eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const findUniqueEmails = (content: string[]) =>
  Array.from(
    // eslint-disable-next-line no-undef
    new Set(
      content
        .flat()
        .map((email: string) => email.replace(/\s+/g, '').toLowerCase())
        .filter((email: string) => EMAIL_REGEX.test(email))
    )
  );

export const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

/**
 * From: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: any, base: any): object {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export default {
  slugify,
};
