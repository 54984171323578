import React, { useState } from 'react';

import { Mutation, translate as translateDecorator } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useNotify } from 'react-admin';
import { useRefresh } from 'react-admin';

const UserAnonymizeAction = ({ data, translate }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [isWPSubscriptionError, setIsWPSubscriptionError] = useState(false);

  if (!data) return null;

  const payload = { id: data.id, data: { ...data, isAnonymized: true } };

  const options = {
    undoable: false,
    callback: ({ error }: { requestPayload: any; payload: any; error: any }) => {
      if (error) return console.log('Failed to anonymize user', error);
    },
    onSuccess: (data: any) => {
      notify('resources.User.actions.anonymize.success', 'success');
      refresh();
    },
    onFailure: (data: any) => {
      if (
        data.graphQLErrors[0]?.statusCode === 400 &&
        data.graphQLErrors[0]?.name === 'USER_HAS_ACTIVE_OR_FUTURE_WP_MEMBERSHIP'
      ) {
        setIsWPSubscriptionError(true);
      } else {
        notify(translate('resources.User.actions.anonymize.failure'));
      }
    },
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.User.actions.anonymize.name')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.User.actions.anonymize.name')}{' '}
          {data && data.email ? data.email : ''}
        </DialogTitle>
        <DialogContent>
          <span>{translate('resources.User.actions.anonymize.dialogText')}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Cancel
          </Button>

          <Mutation type="UPDATE" resource="User" payload={payload} options={options}>
            {(approve: any) => (
              <Button
                onClick={() => {
                  setOpen(false);
                  approve();
                }}
                variant="contained"
                color="primary"
              >
                OK
              </Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isWPSubscriptionError}
        onClose={() => setIsWPSubscriptionError(false)}
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.User.actions.anonymize.failure')}
        </DialogTitle>
        <DialogContent>
          <span>{translate('resources.User.actions.anonymize.failureWithWP')}</span>
        </DialogContent>
        <DialogActions>
          <Button
            href={`${process.env.REACT_APP_WP_ADMIN_URL}/users/user/${data.egymID}/memberships`}
            target="_blank"
            variant="contained"
            color="primary"
          >
            Access WP Admin
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default translateDecorator(UserAnonymizeAction);
