import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  SaveButton,
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  FormDataConsumer,
  BooleanField,
} from 'react-admin';
import { translate } from 'ra-core';
import { DisplayRemProcess } from '../gyms/RemProcessDisplay';
import Pulses from './Pulses';
import { showPromotions } from './utils';
import { validatePromotionDates } from '../gyms/utils';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const FirmTitle = ({ record, translate }: any) => (
  <span>
    {translate('resources.Firm.editTitle', { name: (record && record.name) || '' })}
  </span>
);
const validateData = (values: any) => {
  const errors: any = {};
  if (showPromotions(values)) {
    const validateDatesResult = validatePromotionDates(values.promotions);
    if (Array.isArray(validateDatesResult) && validateDatesResult.length > 0) {
      errors.promotions = validateDatesResult;
    }
  }
  return errors;
};

const FirmEditwithoutTranslation = (parentProps: any) => (
  <Edit
    {...parentProps}
    undoable={false}
    title={<FirmTitle translate={parentProps.translate} />}
  >
    <TabbedForm toolbar={<CustomToolbar />} redirect="edit" validate={validateData}>
      <FormTab label="resources.Firm.tabs.informations">
        <TextInput disabled source="slug" />
        <TextInput source="name" />
      </FormTab>
      <FormTab label="resources.Firm.tabs.gyms">
        <ReferenceManyField
          pagination={<Pagination />}
          label="resources.Firm.fields.gymListName"
          source="id"
          target="firm"
          reference="Gym"
          sort={{ field: 'slug', order: 'DESC' }}
          perPage={10}
        >
          <Datagrid rowClick="edit">
            <TextField source="slug" />
            <BooleanField source="active" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="resources.Firm.tabs.sessions">
        <Pulses translate={translate} {...parentProps} />
      </FormTab>
      <FormTab label="resources.Gym.tabs.remunerationProcess">
        <FormDataConsumer>
          {({ formData, ...props }: any) => (
            <DisplayRemProcess {...props} type="FIRM" data={formData} />
          )}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const FirmEdit = translate(FirmEditwithoutTranslation);
