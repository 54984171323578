import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query pass($id: ID!) {
    data: pass(id: $id) {
      id
      code
      passType
      expirationDate
      transferredAt
      startDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
      }
      gym {
        id
        name
        slug
        bookingFlow
        remuneration {
          id
        }
      }
      erpPassInfos {
        id
        status
        remainingEntries
        transferedAt
        cancelledAt
      }
      gymOffer {
        name {
          fr_FR
        }
      }
      publicPrice
      admissionChargeInCredits
      isGymRemunerated
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };

      return { data: { ...resp.data.data, currentPrice: 0 } };
    },
  };
};
