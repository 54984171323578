import React, { useState } from 'react';
import { NumberInput } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

export const CappingComponent = ({ formData, translate }: any) => {
  const [showCappingLimit, setShowCappingLimit] = useState(formData.capping != null);
  return (
    formData && (
      <>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={formData.capping != null}
              onChange={(event) => {
                const value = event.target.checked;
                formData.capping = value === true ? 4 : null;
                setShowCappingLimit(value);
              }}
            />
          }
          label={translate('resources.Firm.fields.capping.toggle')}
        ></FormControlLabel>
        <FormHelperText>
          {translate('resources.Firm.fields.capping.description')}
        </FormHelperText>
        {showCappingLimit && (
          <NumberInput
            source="capping"
            label="resources.Firm.fields.capping.input"
            initialValue={4}
            min={1}
            max={99}
            step={1}
            options={{ disabled: true }}
          />
        )}
      </>
    )
  );
};
