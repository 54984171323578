import UserIcon from '@material-ui/icons/Group';
import { IResourceExport } from '../../@types/dataProvider';

import { UserList } from './UserList';
import { UserEdit } from './UserEdit';

import userProvider from './dataProvider';
import userChargeProvider from './dataProvider/charges';
import userEventProvider from './dataProvider/events';
import userDevicesProvider from './dataProvider/devices';

export default {
  name: 'User',
  resources: {
    list: UserList,
    edit: UserEdit,
  },
  icon: UserIcon,
  dataProvider: {
    getOneName: '',
    useCustomDP: true,
    customDataProvider: userProvider,
  },
} as IResourceExport;

export const usersCharge: IResourceExport = {
  name: 'UserCharge',
  dataProvider: {
    getOneName: 'userCharge',
    useCustomDP: true,
    customDataProvider: userChargeProvider,
  },
};

export const usersEvent: IResourceExport = {
  name: 'UserEvent',
  dataProvider: {
    getOneName: 'userEvent',
    useCustomDP: true,
    customDataProvider: userEventProvider,
  },
};

export const userDevices: IResourceExport = {
  name: 'UserDevice',
  dataProvider: {
    getOneName: 'userDevice',
    useCustomDP: true,
    customDataProvider: userDevicesProvider,
  },
};
