import gql from 'graphql-tag';

import { FormatCorporation } from './CorporationFormatter';

const GET_LIST_QUERY = gql`
  query allCorporateCompanies(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CorporateCompanyFilter
  ) {
    items: allCorporateCompanies(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      name
      slug
      id
      country
      stripeAccount
      logo
      createdAt
      chargeCorporate
      plans {
        id
        commitmentInMonth
        priceWithoutDiscount
        price
        category
        categoryDisplayName
        credits
      }
      validEmployeeEmailDomains
      emailBlacklistRegex
      isSponsorshipPlatform
      sponsorshipPlatform
      active
      hasFeed
      hasChallenge
      sendWelcomeEmail
      showIdentifiersOnDashboard
      usersCanRegisterWithIdentifier
      signupInputPlaceholder {
        fr
        en
        nl
      }
      signupInputHelpingText {
        fr
        en
        nl
      }
      companyInvitationLink
      defaultUserLocale
      signupInputName {
        fr
        en
        nl
      }
      productPriceGroup {
        id
        name
      }
      allowListIntegrationScopeId
      delegateEligibilityEventsHandlingToHris
    }
    total: _allCorporateCompaniesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const GET_LITE_LIST_QUERY = gql`
  query allCorporateCompanies(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CorporateCompanyFilter
  ) {
    items: allCorporateCompanies(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      slug
      id
    }
    total: _allCorporateCompaniesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp.data) return { data: {} };
  const formattedResponse = resp.data.items.map((corpo: any) => {
    return FormatCorporation(corpo);
  });
  return { data: formattedResponse, total: resp.data.total.count };
};

export const GetList = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetLiteList = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    // Override the query
    query: GET_LITE_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params.filter, ids: params.ids },
    },
    // Override the query
    query: GET_LIST_QUERY,
    parseResponse,
  };
};
