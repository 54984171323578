import { Box, Typography } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import * as React from 'react';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { AmountFormatter } from '../../utils';

const CustomCreditPackEditToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const CreditPackEditNameRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput resource="CreditPacks" source="name.fr" label="Traduction française" />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput resource="CreditPacks" source="name.en" label="Traduction anglais" />
    </Box>
    <Box flex={2}>
      <TextInput
        resource="CreditPacks"
        source="name.nl"
        label="Traduction néerlandaise"
      />
    </Box>
  </Box>
);

const ProductEditGeneralInfoRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <BooleanInput resource="CreditPacks" source="active" label="Activé" />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput
        {...AmountFormatter()}
        resource="CreditPacks"
        source="price"
        label="Prix €"
        disabled
      />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput resource="CreditPacks" source="credits" label="Crédits" disabled />
    </Box>
  </Box>
);

export const CreditPackEdit: React.FunctionComponent = (props: any) => {
  return (
    <Edit {...props}>
      <FormWithRedirect
        render={(formProps: any) => {
          return (
            <>
              <form>
                <Box marginX="3rem" paddingY="2rem">
                  <section>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Typography variant="h6" gutterBottom>
                          Informations générales
                        </Typography>
                        <ProductEditGeneralInfoRow />
                      </Box>
                    </Box>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Typography variant="h6" gutterBottom>
                          Nom du produit
                        </Typography>
                        <CreditPackEditNameRow />
                      </Box>
                    </Box>
                  </section>
                </Box>
              </form>
              <CustomCreditPackEditToolbar {...formProps} />
            </>
          );
        }}
      />
    </Edit>
  );
};
