import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  FormGroup,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';

import { useTranslate } from 'react-admin';
import { useDataProvider } from 'ra-core';
import CSVFile from '../../utils/csv/CSVFile';

type DataProviderCallback = (
  type: string,
  resource: string,
  payload?: any,
  options?: any
) => Promise<any>;

interface RawProps {
  type: string;
  resource: string;
  payload?: any;
  options?: any;
  classes: any;
}

interface Props extends RawProps {
  ids: string[];
  data: any;
  dataProvider: DataProviderCallback;
  handleSubmitWithRedirect: any;
  redirect: any;
  save: any;
  translate: (key: string, options?: object) => string;
}

export function CouponsAddAction(props: Props) {
  const { ids } = props;
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [app, setApp] = useState<string>(ids[0]);
  const [coupons, setCoupons] = useState([] as string[]);
  const [inserted, setInserted] = useState(null);
  const [expirationDate, handleDateChange] = useState<moment.Moment | null>(moment());

  const dataProvider = useDataProvider();

  const handleSubmit = () => {
    if (!app) setError(translate('resources.ExternalCoupon.actions.add.noAppSelected'));
    else if (!coupons.length)
      setError(translate('resources.ExternalCoupon.actions.add.noCoupons'));
    else {
      setActiveStep(1);
      dataProvider
        .update('CouponStats', {
          id: app,
          previousData: { id: app },
          data: { id: app, coupons, expiresAt: (expirationDate || moment()).toDate() },
        })
        .then((res: any) => {
          setInserted(res.data.data.inserted);
        })
        .catch((err: any) => {
          setError(translate('resources.ExternalCoupon.actions.add.mutationError'));
        });
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.ExternalCoupon.actions.add.button')}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          window.location.reload();
        }}
      >
        <DialogTitle id="form-dialog-title">
          <Stepper activeStep={activeStep} alternativeLabel>
            {['title', 'result'].map(label => (
              <Step key={label}>
                <StepLabel>
                  {translate(`resources.ExternalCoupon.actions.add.${label}`)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {activeStep === 0 ? (
            <FormGroup>
              {translate('resources.ExternalCoupon.actions.add.selectApp')}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={app}
                onChange={event => {
                  setApp(event.target.value as string);
                }}
              >
                {Object.values(ids).map((appID, index) => (
                  <MenuItem key={index} value={appID}>
                    {appID.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <br />
              {translate('resources.ExternalCoupon.actions.add.selectDate')}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker value={expirationDate} onChange={handleDateChange} />
              </MuiPickersUtilsProvider>
              <br />
              <div>{translate('resources.ExternalCoupon.actions.add.template')}</div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowTemplate(!showTemplate)}
              >
                {translate('resources.ExternalCoupon.actions.add.templateButton')}
              </Button>
              {showTemplate ? (
                <pre>
                  <br />
                  code1
                  <br />
                  code2
                  <br />
                  code3
                </pre>
              ) : (
                <div />
              )}
              {error ? <div>{error}</div> : <div />}
              <div>
                {coupons.length
                  ? translate('resources.ExternalCoupon.actions.add.summary', {
                      count: coupons.length,
                      app,
                      expirationDate: (expirationDate || moment()).toDate(),
                    })
                  : ''}
                <br />
              </div>
              <CSVFile
                label={translate('resources.ExternalCoupon.actions.add.upload')}
                handleCSVResult={(coupons: string[][]) => {
                  const uniq = Array.from(
                    new Set(coupons.map(c => c[0]).filter(c => !!c))
                  );
                  setCoupons(uniq);
                }}
              />
              <Button
                disabled={!app || !coupons.length}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {translate('resources.ExternalCoupon.actions.add.submit')}
              </Button>
              <Button onClick={() => setOpen(false)} variant="outlined" color="secondary">
                Cancel
              </Button>
            </FormGroup>
          ) : (
            <div>
              {error ? <div>{error}</div> : <div />}
              {inserted !== null
                ? translate('resources.ExternalCoupon.actions.add.success', {
                    inserted,
                    app,
                  })
                : ''}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
