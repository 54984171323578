import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import { onError } from '@apollo/client/link/error';

import { resolveIntrospection } from './introspection';

import { AllPossibleRequestResolver, IResourceExport } from '../@types/dataProvider';
import { getMainDefinition } from '@apollo/client/utilities';

import omitDeep from 'omit-deep-lodash';
import { createHttpLink, ApolloLink, InMemoryCache, ApolloClient } from '@apollo/client';
import { LegacyDataProvider } from 'ra-core';
import { cloneDeep } from 'lodash';
import { possibleTypes } from './possibleTypes';

interface IProviders {
  [resourceName: string]: AllPossibleRequestResolver;
}
/**
 * A nasty way to catch session expired exception before they are too deep in the RA gql provider
 */
const responseParserWrapper = (originalResponseParser: (_: any) => any) => (data: {
  errors: undefined | any;
  data: any;
}) => {
  const errors = data.errors;
  // This is responsible for login out people which token have expired, unfortunately we still use 401 instead of 403 on the backend for forbiden operation, so people will be logout if they attempt an operation they dont have the right to do
  if (
    errors &&
    (errors[0].name === 'SessionExpiredException' ||
      errors[0].name === 'UnauthorizedException' ||
      errors[0].message === 'Not Logged In')
  )
    throw new Error('USER_NOT_AUTHENTICATED');
  else if (window.hj) {
    window.hj('tagRecording', ['GraphQL Error']);
  }
  return originalResponseParser(data);
};
const handle401 = function (data: any) {
  const errors = data.errors;
  // This is responsible for login out people which token have expired, unfortunately we still use 401 instead of 403 on the backend for forbiden operation, so people will be logout if they attempt an operation they dont have the right to do
  if (
    errors &&
    (errors[0].name === 'SessionExpiredException' ||
      errors[0].name === 'UnauthorizedException' ||
      errors[0].message === 'Not Logged In')
  )
    throw new Error('USER_NOT_AUTHENTICATED');
};

const myBuildQuery = (resources: { [key: string]: IResourceExport }) => (
  introspection: any
) => {
  const providers: IProviders = {};
  Object.values(resources).forEach((resource) => {
    if (
      resource.dataProvider &&
      resource.name &&
      resource.dataProvider.providerWithIntrospection
    ) {
      providers[resource.name] = resource.dataProvider.providerWithIntrospection(
        introspection
      );
    }
  });

  return (fetchType: string, resource: string, params: any) => {
    console.log(fetchType, resource, params);
    if (resource in providers) {
      if (providers[resource][fetchType]) {
        const provider = providers[resource][fetchType](params);

        const keepingIt = provider.parseResponse;
        provider.parseResponse = responseParserWrapper(keepingIt);
        return provider;
      }
    }

    const builtQuery = buildQuery(introspection)(fetchType, resource, params);

    let defaultParseResponse = builtQuery.parseResponse;

    builtQuery.parseResponse = responseParserWrapper(defaultParseResponse);

    return builtQuery;
  };
};

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    if (
      operation.operationName === 'createActivity' &&
      graphQLErrors &&
      graphQLErrors[0].message.startsWith(
        'E11000 duplicate key error collection: gymlib.activities index: slug_1'
      )
    ) {
      graphQLErrors[0].message = 'This slug already exists for activities';
    }
    if (
      operation.operationName === 'createService' &&
      graphQLErrors &&
      graphQLErrors[0].message.startsWith(
        'E11000 duplicate key error collection: gymlib.services index: slug_1'
      )
    ) {
      graphQLErrors[0].message = 'This slug already exists for services';
    }
    if (
      operation.operationName === 'createGymManager' &&
      graphQLErrors &&
      graphQLErrors[0].name === 'CreateExistingGymManagerException'
    ) {
      graphQLErrors[0].message = 'Ce gérant existe déjà';
    }
  }
);

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename']; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);
  if (def && def.kind === 'OperationDefinition' && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    'apollo-require-preflight': true,
    'x-session-token': localStorage['sessionToken'],
  },
  credentials: 'include',
});

export default async (resources: {
  [key: string]: IResourceExport;
}): Promise<LegacyDataProvider> => {
  const forcedTypes: { [key: string]: string } = {};
  const queryRename: { [key: string]: string } = {};
  Object.values(resources)
    .filter((resource) => !resource.dataProvider.useCustomDP)
    .forEach((resource) => {
      // If resource doesn't have any resources, we won't use getOneName and resolveIntrospection won't match name and getOneName.
      // Define empty resources to avoid it
      if (!resource.resources)
        forcedTypes[resource.dataProvider.getOneName || resource.name] = resource.name;
      else {
        if (resource.dataProvider.shouldForce)
          forcedTypes[resource.dataProvider.getOneName || resource.name] = resource.name;
        queryRename[resource.dataProvider.getOneName] = resource.name;
      }
    });

  const appoloClient = new ApolloClient({
    cache: new InMemoryCache({
      resultCaching: false,
      possibleTypes: await possibleTypes(),
    }).restore({}),
    link: ApolloLink.from([cleanTypenameLink, errorLink, httpLink]),
    // link: ApolloLink.from([httpLink, errorLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    assumeImmutableResults: false,
  });

  const oldDataProvider = await buildGraphQLProvider({
    client: appoloClient,
    resolveIntrospection: resolveIntrospection(queryRename, forcedTypes),
    buildQuery: myBuildQuery(resources),
  });

  const dataProvider = (type: string, resourceName: string, params: any) => {
    const definedResource = Object.values(resources)
      .filter((resource) => resource.dataProvider.useCustomDP)
      .find(
        (resource) =>
          resource.name === resourceName &&
          Object.keys(resource.dataProvider.customDataProvider || {}).lastIndexOf(
            type
          ) !== -1
      );
    // We use our own DataProvider, bypassing the GraphqlSimple one
    if (definedResource) {
      let { query, variables, parseResponse } = definedResource.dataProvider
        .customDataProvider![type]!(params);

      if (!parseResponse) parseResponse = (data: any) => data;

      if (/^GET/.test(type)) {
        return appoloClient
          .query({ query, variables, fetchPolicy: 'network-only' })
          .then((res: any) => {
            handle401(res);
            return parseResponse!(cloneDeep(res));
          });
      } else
        return appoloClient
          .mutate({ mutation: query, variables, fetchPolicy: 'no-cache' })
          .then((res: any) => {
            return parseResponse!(cloneDeep(res));
          });
    } else {
      return oldDataProvider(type, resourceName, params);
    }
  };

  return dataProvider;
};
