import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import { IResourceExport } from '../../@types/dataProvider';
import erpProvider from './dataProvider';
import mindbodyProvider from './dataProvider/mindbody';
import mondayProvider from './dataProvider/monday';
import { ErpCreate } from './ErpCreate';
import { ErpEdit } from './ErpEdit';
import { ErpList } from './ErpList';

export default {
  name: 'Erp',
  dataProvider: {
    // FIXME: Remove me when all DP are on the new custom format
    getOneName: '',
    useCustomDP: true,
    customDataProvider: erpProvider,
  },
  icon: SettingsInputComponentIcon,
  resources: {
    list: ErpList,
    edit: ErpEdit,
    create: ErpCreate,
  },
} as IResourceExport;

export const mindbodyMetadata = {
  name: 'MindbodyMetadata',
  dataProvider: {
    getOneName: 'mindbodyMetadata',
    useCustomDP: true,
    customDataProvider: mindbodyProvider,
  },
} as IResourceExport;

export const mondayMetadata = {
  name: 'MondayMetadata',
  dataProvider: {
    getOneName: 'mondayMetadata',
    useCustomDP: true,
    customDataProvider: mondayProvider,
  },
} as IResourceExport;
