import React, { useState } from 'react';
import {
  Button,
  Confirm,
  useNotify,
  useTranslate,
  useDataProvider,
  useUnselectAll,
  useQueryWithStore,
} from 'react-admin';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import WatchLateIcon from '@material-ui/icons/WatchLaterOutlined';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { Modal, LinearProgress, makeStyles } from '@material-ui/core';
import { PassActions } from '../../@types/common';
import { Select, MenuItem } from '@material-ui/core';
import { BookingFlow } from '../gyms/dataProvider/types';

export const styles = makeStyles({
  actionButton: {
    marginLeft: '15px',
  },
  modal: {
    height: '100%',
    width: '100%',
  },
  innerModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    backgroundColor: 'white',
    padding: '30px',
  },
  progress: {
    marginBottom: '20px',
  },
  select: {
    display: 'table',
  },
});

const GenericBulkButton = (props: any) => {
  const classes = styles();
  const [openDialog, setOpenDialog] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll();
  const [passStatus, setPassStatus] = useState('USED');
  const callback = async () => {
    let errors = 0;
    props.blockUser({
      done: 0,
      total: props.data.length,
    });
    for (let i = 0; i < props.data.length; i++) {
      props.blockUser({ done: i, total: props.data.length });
      try {
        await dataProvider.update('Pass', {
          action: props.action,
          data: { ...props.data[i], passStatus },
        });
      } catch (e) {
        errors++;
      }
      props.blockUser({ done: i + 1, total: props.data.length });
    }
    if (errors === props.data.length) {
      notify(`resources.Pass.actions.${props.action}Bulk.error`, 'warning', {
        smart_count: props.data.length,
      });
    } else {
      notify(`resources.Pass.actions.${props.action}Bulk.success`, 'info', {
        successCount: props.data.length - errors,
        failsCount: errors,
        smart_count: props.data.length,
      });
      unselectAll('Pass');
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const confirmDialog = async () => {
    setOpenDialog(false);
    await callback();
  };

  return (
    <>
      <Button
        label={translate(`resources.Pass.actions.${props.action}Bulk.name`, {
          smart_count: props.data.length,
        })}
        onClick={() => setOpenDialog(true)}
        color="primary"
        variant="outlined"
        className={classes.actionButton}
        disabled={props.isUserBlocked}
      >
        {props.icon}
      </Button>
      <Confirm
        isOpen={openDialog}
        title={translate('resources.Pass.actions.modal.title')}
        content={
          <div>
            {translate(`resources.Pass.actions.${props.action}Bulk.modalContent`, {
              smart_count: props.data.length,
            })}
            {[PassActions.CANCEL_LATE_CANCELLED, PassActions.CANCEL_NO_SHOW].includes(
              props.action
            ) && (
              <div>
                {translate(`resources.Pass.actions.${props.action}.choosePassStatus`)}
                <Select
                  value={passStatus}
                  onChange={(event) => setPassStatus(event.target.value as string)}
                  className={classes.select}
                >
                  {['USED', 'CANCELLED', 'NO_SHOW', 'LATE_CANCELLED']
                    .filter((status) => status !== props.data.status)
                    .map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {translate(`resources.Pass.fields.passStatus.${status}`)}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
          </div>
        }
        confirm={translate('resources.Pass.actions.modal.confirm')}
        cancel={translate('resources.Pass.actions.modal.cancel')}
        onConfirm={confirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

export default function BulkActionsButtons(props: any) {
  const [openBlockingModal, setOpenBlockingModal] = useState({
    total: 0,
    done: 0,
  });
  const classes = styles();
  const { data } = useQueryWithStore(
    {
      type: 'getList',
      resource: 'Pass',
      payload: {
        filter: {
          ids: props.selectedIds,
        },
        pagination: { page: 1, perPage: props.selectedIds.length },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      },
    },
    {},
    (state: any) =>
      props.selectedIds.map((id: string) => state.admin.resources.Pass.data[id])
  );
  if (!data || data.length === 0) return null;
  return (
    <>
      {data.every(
        (pass: any) =>
          (['USED', 'PUNCHABLE', 'CREDITED'].includes(pass.status) ||
            (pass.status === 'EXPIRED' && pass.lastValidationDate)) &&
          [
            BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
            BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
            BookingFlow.IN_APP_BOOKING,
          ].includes(pass.gym.bookingFlow)
      ) && (
        <GenericBulkButton
          {...props}
          action={PassActions.NO_SHOW}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<WarningIcon />}
        />
      )}
      {data.every(
        (pass: any) =>
          (['USED', 'PUNCHABLE', 'CREDITED'].includes(pass.status) ||
            (pass.status === 'EXPIRED' && pass.lastValidationDate)) &&
          [
            BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
            BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
            BookingFlow.IN_APP_BOOKING,
          ].includes(pass.gym.bookingFlow)
      ) && (
        <GenericBulkButton
          {...props}
          action={PassActions.LATE_CANCELLED}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<WatchLateIcon />}
        />
      )}
      {data.every((pass: any) => pass.status === 'LATE_CANCELLED') && (
        <GenericBulkButton
          {...props}
          action={PassActions.CANCEL_LATE_CANCELLED}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<CancelIcon />}
        />
      )}
      {data.every((pass: any) => pass.status === 'NO_SHOW') && (
        <GenericBulkButton
          {...props}
          action={PassActions.CANCEL_NO_SHOW}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<CancelIcon />}
        />
      )}
      {data.every(
        (pass: any) =>
          pass.status === 'GENERATED' &&
          pass.gym.bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL
      ) && (
        <GenericBulkButton
          {...props}
          action={PassActions.VALIDATE_BOOKING}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<EventAvailableIcon />}
        />
      )}
      {data.every(
        (pass: any) =>
          pass.status === 'PUNCHABLE' &&
          pass.gym.bookingFlow !== BookingFlow.OPEN_ACCESS_WITH_ERP
      ) && (
        <GenericBulkButton
          {...props}
          action={PassActions.GPS_ANOMALY}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<GpsOffIcon />}
        />
      )}
      {data.every(
        (pass: any) => !['REFUNDED', 'NO_SHOW', 'LATE_CANCELLED'].includes(pass.status)
      ) && (
        <GenericBulkButton
          {...props}
          action={PassActions.REFUND}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<EuroSymbolIcon />}
        />
      )}
      <GenericBulkButton
        {...props}
        action={PassActions.TRIGGER_REMUNERATION}
        data={data}
        blockUser={setOpenBlockingModal}
        isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
        icon={<EuroSymbolIcon />}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openBlockingModal.total - openBlockingModal.done > 0}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
      >
        <div className={classes.innerModal}>
          <h2 id="simple-modal-title">
            {openBlockingModal.done} / {openBlockingModal.total}
          </h2>
          <LinearProgress
            variant="determinate"
            value={(openBlockingModal.done / openBlockingModal.total) * 100}
            className={classes.progress}
          />
        </div>
      </Modal>
    </>
  );
}
