import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query mondayMetadata($mondayBrand: MondayBrand!) {
    data: mondayMetadata(mondayBrand: $mondayBrand) {
      id
      locations {
        id
        name
      }
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: { mondayBrand: params.mondayBrand },
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      return { data: resp?.data?.data || {} };
    },
  };
};
