import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SearchIcon from '@material-ui/icons/Search';
import Fuse from 'fuse.js';
import { unparse as convertToCSV } from 'papaparse';
import { downloadCSV, useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { EMAIL_REGEX, findUniqueEmails } from '../../../utils';
import CSVFile from '../../../utils/csv/CSVFile';
import UpgradeWhitelist from './UpgradeWhitelist';

interface EnhancedTableHeadProps {
  numSelected: number;
  setSelectedEmails: any;
  paginatedEmails: string[];
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { numSelected, setSelectedEmails, paginatedEmails } = props;
  const translate = useTranslate();

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setSelectedEmails(paginatedEmails);
    else setSelectedEmails([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < paginatedEmails.length}
            checked={paginatedEmails.length > 0 && numSelected === paginatedEmails.length}
            onChange={handleSelectAll}
          />
        </TableCell>
        <TableCell>
          {translate('resources.CorporateCompany.fields.validEmployeeEmails.tableHead')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isLastPage = useMemo(() => page >= Math.ceil(count / rowsPerPage) - 1, [
    page,
    count,
    rowsPerPage,
  ]);

  return (
    <Box>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const useTableToolbarStyles = makeStyles({
  search: {
    alignItems: 'center',
    display: 'flex',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  searchIcon: {
    marginRight: '5px',
  },
  searchLabel: {
    padding: '2px 10px',
    margin: 'auto 10px',
    display: 'inline-flex',
    maxWidth: '200px',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid black',
  },
  selectedTitle: {
    alignItems: 'center',
    display: 'flex',
    background: '#f1f1f3',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',

    '& > :first-child': {
      flexGrow: 1,
      fontWeight: 'bold',
    },
  },
});

interface EnhancedTableToolbarProps {
  selectedEmails: string[];
  search: string;
  setEmailTotalCount: any;
  setFilteredEmails: any;
  setSelectedEmails: any;
  setSearch: any;
  validEmployeeEmails: string[];
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useTableToolbarStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const form = useForm();

  const {
    selectedEmails,
    search,
    setEmailTotalCount,
    setFilteredEmails,
    setSelectedEmails,
    setSearch,
    validEmployeeEmails,
  } = props;

  const handleDeleteEmails = () => {
    form.change(
      'validEmployeeEmails',
      validEmployeeEmails.filter((email) => !selectedEmails.includes(email))
    );
    setSearch('');
    setSelectedEmails([]);
    notify(
      'resources.CorporateCompany.fields.validEmployeeEmails.tableToolbar.deleted',
      'info',
      { email_deleted_count: selectedEmails.length }
    );
  };

  useEffect(() => {
    const options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
    };
    if (search === '') return setFilteredEmails(null);

    const fuse = new Fuse(validEmployeeEmails, options);
    const results = fuse.search(search);
    const filteredEmails = results.map((result) => result.item);

    setFilteredEmails(filteredEmails);
    setEmailTotalCount(results.length);
  }, [search, setEmailTotalCount, setFilteredEmails, validEmployeeEmails]);

  return selectedEmails.length > 0 ? (
    <Toolbar className={classes.selectedTitle}>
      <h3>
        {translate(
          'resources.CorporateCompany.fields.validEmployeeEmails.tableToolbar.selected',
          { email_selected_count: selectedEmails.length }
        )}
      </h3>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleDeleteEmails}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classes.search}>
      <h3>{translate('resources.CorporateCompany.fields.validEmployeeEmails.list')}</h3>
      <label className={classes.searchLabel}>
        <SearchIcon className={classes.searchIcon} />
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            type="search"
            placeholder={translate('ra.action.search')}
            disableUnderline
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </label>
    </Toolbar>
  );
};

const useAddEmailStyles = makeStyles({
  addEmail: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: '#f1f1f3',
    },

    '& p': {
      margin: '14px 10px',
    },
  },
  validateNewEmail: {
    display: 'flex',
    padding: '8px',

    '& > :first-child': {
      flexGrow: 1,
      paddingLeft: '60px',
    },
    '& > :not(:first-child)': {
      padding: '0 25px',
    },
  },
});
interface EnhancedAddEmailProps {
  validEmployeeEmails: string[];
  setSearch: any;
}

const EnhancedAddEmail = (props: EnhancedAddEmailProps) => {
  const { validEmployeeEmails, setSearch } = props;
  const [displayAddEmail, setDisplayAddEmail] = useState(true);
  const [newEmail, setNewEmail] = useState('');
  const classes = useAddEmailStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const form = useForm();

  const handleAddEmail = () => {
    if (validEmployeeEmails.includes(newEmail)) {
      return notify(
        'resources.CorporateCompany.fields.validEmployeeEmails.duplicateAddEmail',
        'error'
      );
    }

    form.change('validEmployeeEmails', [newEmail, ...validEmployeeEmails]);
    setNewEmail('');
    setSearch('');
    notify('resources.CorporateCompany.fields.validEmployeeEmails.saveAddEmail', 'info');
  };

  return displayAddEmail ? (
    <TableRow>
      <TableCell padding="none" colSpan={2}>
        <div className={classes.addEmail} onClick={() => setDisplayAddEmail(false)}>
          <AddIcon />
          <p>{translate('ra.action.add')}</p>
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell padding="none" colSpan={2}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddEmail();
          }}
          className={classes.validateNewEmail}
        >
          <Input
            type="email"
            disableUnderline
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            autoFocus
          />
          <MuiButton
            color="primary"
            onClick={() => {
              setNewEmail('');
              setDisplayAddEmail(true);
            }}
            type="button"
          >
            {translate('ra.action.cancel')}
          </MuiButton>
          <MuiButton color="primary" disabled={!EMAIL_REGEX.test(newEmail)} type="submit">
            {translate('ra.action.add')}
          </MuiButton>
        </form>
      </TableCell>
    </TableRow>
  );
};

const useWhitelistInputWithoutTranslateStyles = makeStyles({
  advancedEditHeading: {
    fontSize: 18,
  },
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    'white-space': 'noWrap',
  },
  tablePagination: {
    'white-space': 'noWrap',
  },
  tableFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const EmailWhitelistInput = ({ formData, fromCreate, ...props }: any) => {
  const form = useForm();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useWhitelistInputWithoutTranslateStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredEmails, setFilteredEmails] = useState<string[] | null>(null);
  const [paginatedEmails, setPaginatedEmails] = useState<string[]>([]);
  const [emailTotalCount, setEmailTotalCount] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (filteredEmails) {
      setPaginatedEmails(
        filteredEmails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
    } else if (formData?.validEmployeeEmails) {
      setPaginatedEmails(
        formData.validEmployeeEmails.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      );
      setEmailTotalCount(formData.validEmployeeEmails.length);
    } else {
      setPaginatedEmails([]);
      setEmailTotalCount(0);
    }
  }, [formData, filteredEmails, page, rowsPerPage]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    if (filteredEmails) {
      setPaginatedEmails(
        filteredEmails.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
      );
    } else if (formData?.validEmployeeEmails) {
      setPaginatedEmails(
        formData.validEmployeeEmails.slice(
          newPage * rowsPerPage,
          newPage * rowsPerPage + rowsPerPage
        )
      );
    } else setPaginatedEmails([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = 0;
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    if (filteredEmails) {
      setPaginatedEmails(
        filteredEmails.slice(
          newPage * newRowsPerPage,
          newPage * newRowsPerPage + newRowsPerPage
        )
      );
    } else if (formData?.validEmployeeEmails) {
      setPaginatedEmails(
        formData.validEmployeeEmails.slice(
          newPage * newRowsPerPage,
          newPage * newRowsPerPage + newRowsPerPage
        )
      );
    } else setPaginatedEmails([]);
  };

  const handleSelectClick = (_: React.MouseEvent<unknown>, email: string) => {
    const selectedIndex = selectedEmails.indexOf(email);
    let newSelected: string[] = [];

    if (selectedIndex === -1) newSelected = newSelected.concat(selectedEmails, email);
    else {
      newSelected = newSelected.concat(
        selectedEmails.slice(0, selectedIndex),
        selectedEmails.slice(selectedIndex + 1)
      );
    }

    setSelectedEmails(newSelected);
  };

  const isSelected = (name: string) => selectedEmails.indexOf(name) !== -1;

  const whitelistUploadHandler = async (content: string[]) => {
    if (!content?.length) return;
    console.log(content);

    const emails = findUniqueEmails(content);

    if (emails.length === 0) {
      notify('resources.CorporateCompany.notifications.noEmails', 'warning');
    } else {
      form.change('validEmployeeEmails', emails);
      setSearch('');
      notify('resources.CorporateCompany.notifications.emailChanged', 'info', {
        email_count: emails.length,
      });
    }
  };

  const deleteUploadHandler = async (content: string[]) => {
    if (!content?.length) return;
    console.log(content);

    const toDeleteEmails = findUniqueEmails(content).filter((email) =>
      (formData?.validEmployeeEmails || []).some((e: string) => e === email)
    );

    if (paginatedEmails.length === 0 || toDeleteEmails.length === 0) {
      return notify(
        'resources.CorporateCompany.notifications.noDeleteToProcess',
        'warning'
      );
    }

    form.change(
      'validEmployeeEmails',
      (formData?.validEmployeeEmails || []).filter(
        (email: string) => !toDeleteEmails.includes(email)
      )
    );
    setSearch('');

    notify('resources.CorporateCompany.notifications.deletedEmailChanged', 'info', {
      email_deleted: toDeleteEmails.length,
      email_count: emailTotalCount - toDeleteEmails.length,
    });
  };

  const addUploadHandler = async (content: string[]) => {
    if (!content?.length) return;
    console.log(content);

    const toAddEmails = findUniqueEmails(content).filter(
      (email) => !(formData?.validEmployeeEmails || []).some((e: string) => e === email)
    );

    if (paginatedEmails.length === 0 || toAddEmails.length === 0) {
      return notify(
        'resources.CorporateCompany.notifications.noDeleteToProcess',
        'warning'
      );
    }

    form.change(
      'validEmployeeEmails',
      toAddEmails.concat(formData?.validEmployeeEmails || [])
    );
    setSearch('');

    notify('resources.CorporateCompany.notifications.addEmails', 'info', {
      email_added: toAddEmails.length,
    });
  };

  const exportCSV = () => {
    const csv = convertToCSV({
      data: (formData?.validEmployeeEmails || []).map((email: string) => [email]),
      fields: ['email'],
    });
    downloadCSV(csv, 'whitelist');
  };

  return (
    <div>
      <CSVFile
        handleCSVResult={whitelistUploadHandler}
        label={translate('resources.CorporateCompany.fields.validEmployeeEmails.file')}
      />

      <EnhancedTableToolbar
        selectedEmails={selectedEmails}
        search={search}
        setEmailTotalCount={setEmailTotalCount}
        setFilteredEmails={setFilteredEmails}
        setSelectedEmails={setSelectedEmails}
        setSearch={setSearch}
        validEmployeeEmails={formData?.validEmployeeEmails || []}
      />
      <TableContainer component={Paper}>
        <Table>
          <EnhancedTableHead
            paginatedEmails={paginatedEmails}
            numSelected={selectedEmails.length}
            setSelectedEmails={setSelectedEmails}
          />
          <TableBody>
            {paginatedEmails.map((email) => (
              <TableRow key={email} selected={isSelected(email)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected(email)}
                    color="primary"
                    onClick={(event) => handleSelectClick(event, email)}
                  />
                </TableCell>
                <TableCell align="left">{email}</TableCell>
              </TableRow>
            ))}
            <EnhancedAddEmail
              validEmployeeEmails={formData?.validEmployeeEmails || []}
              setSearch={setSearch}
            />
          </TableBody>
        </Table>
        <div className={classes.tableFooter}>
          <MuiButton
            className={classes.downloadButton}
            color="primary"
            endIcon={<CloudDownloadIcon fontSize="large" />}
            onClick={exportCSV}
          >
            {translate('resources.CorporateCompany.fields.validEmployeeEmails.download')}
          </MuiButton>
          <TablePagination
            component="div"
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={2}
            count={emailTotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </TableContainer>

      {formData?.validEmployeeEmails?.length > 0 && (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.advancedEditHeading}>
              {translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.advancedEditTitle'
              )}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <CSVFile
              handleCSVResult={deleteUploadHandler}
              label={translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.bulkRemoveEmailsFile'
              )}
            />
            <br />
            <CSVFile
              handleCSVResult={addUploadHandler}
              label={translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.bulkAddEmailsFile'
              )}
            />
          </ExpansionPanelDetails>
          <ExpansionPanelActions>
            <UpgradeWhitelist {...props} record={formData} />
          </ExpansionPanelActions>
        </ExpansionPanel>
      )}
    </div>
  );
};
