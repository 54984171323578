import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles({
  boxInLine: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& > div:not(:last-child)': {
      marginRight: '15px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  thirdWidth: {
    width: '33%',
  },
  quarterWidth: {
    width: '25%',
  },
  colorVizualiser: {
    marginTop: '8px',
    width: '40px',
    height: '40px',
  },
});
