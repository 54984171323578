import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import { useMutation, useRefresh, translate, withDataProvider } from 'react-admin';

const useStyles = makeStyles({
  resetButton: { marginBottom: '40px' },
  modal: { margin: '0 auto' },
});

const UserUnlockDeviceButton = ({ record, translate }: any) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const classes = useStyles();
  const [updateDevice, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'User',
      payload: {
        data: {
          ...record,
          lastDeviceRewindDate: new Date().toISOString(),
        },
        previousData: record,
      },
    },
    {
      onSuccess: () => refresh(),
    }
  );
  const onSubmit = async () => {
    await updateDevice();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        className={classes.resetButton}
      >
        {translate('resources.User.fields.resetDevice.button')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        className={classes.modal}
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.User.fields.resetDevice.modalTitle')}
        </DialogTitle>
        <DialogContent>
          {translate('resources.User.fields.resetDevice.modalSubtitle')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Annuler
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default translate(withDataProvider(UserUnlockDeviceButton));
