import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query gymInfrastructure($id: ID!) {
    data: gymInfrastructure(id: $id) {
      id
      slug
      name
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
        },
      };
    },
  };
};
