import React from 'react';

import { Datagrid, Filter, List, translate } from 'react-admin';

import {
  DateField,
  ReferenceField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const ExternalCouponFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      source="userID"
      reference="User"
      allowEmpty
      alwaysOn
      filterToQuery={(filter: any) => {
        if (!filter) return {};
        return { q: filter };
      }}
    >
      <AutocompleteInput optionText="email" resettable />
    </ReferenceInput>
  </Filter>
);

export const ExternalCouponWithoutTranslate = ({ translate, ...props }: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ExternalCouponFilter />}
    sort={{ field: 'claimedAt', order: 'DESC' }}
  >
    <Datagrid>
      <DateField source="claimedAt" />
      <ReferenceField source="usedBy.id" reference="User">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="provider" />
      <TextField source="coupon" />
    </Datagrid>
  </List>
);

export const ExternalCouponList = translate(ExternalCouponWithoutTranslate);
