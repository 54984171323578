import { BookingFlow } from '../gyms/dataProvider/types';

export const showPromotions = (formData: any) => {
  const bookingFlowsForPromotions = [
    BookingFlow.IN_APP_BOOKING,
    BookingFlow.OPEN_ACCESS_WITHOUT_ERP,
    BookingFlow.OPEN_ACCESS_WITH_ERP,
  ];
  return formData.bookingFlows?.some((x: BookingFlow) =>
    bookingFlowsForPromotions.includes(x)
  );
};
