import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query BO_AllProducts {
    items: BO_AllProducts(type: ONE_TIME_PURCHASE) {
      items {
        id
        credits
        name {
          fr
          en
          nl
        }
        price
        active
        type
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.items.map((item: any) => ({
      ...item,
      price: item.price / 100,
    })),
    total: resp.data.items.total,
  };
};

export const GetList = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};

export const GetMany = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};
