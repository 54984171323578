import gql from 'graphql-tag';
import { createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client';

const link = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    'apollo-require-preflight': true,
  },
  credentials: 'include',
});
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION($email: String!, $password: String!) {
    loginUser(credentials: { email: $email, password: $password }) {
      token
      user {
        id
        roles
      }
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    logoutUser
  }
`;

export interface UserLogin {
  token: string;
  user: {
    id: string;
    roles: string[];
  };
}

export const loginUser = async (email: string, password: string): Promise<UserLogin> => {
  const {
    data: { loginUser },
  }: { data: { loginUser: UserLogin } } = (await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      email,
      password,
    },
  })) as any;
  return loginUser;
};

export const logoutUser = async (): Promise<boolean> => {
  const {
    data: { logoutUser },
  }: { data: { logoutUser: boolean } } = (await client.mutate({
    mutation: LOGOUT_MUTATION,
    variables: {},
  })) as any;
  return logoutUser;
};
