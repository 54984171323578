import React from 'react';
import {
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  TextField,
  Datagrid,
  List,
  DateField,
} from 'react-admin';
import { Box } from '@material-ui/core';

const Empty = ({ basePath }: any) => (
  <Box textAlign="center" m={1}>
    <CreateButton basePath={basePath} />
  </Box>
);

const ListActions = ({ basePath, className, ...rest }: any) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const PartnerEventList = ({ ...props }: any) => {
  return (
    <List {...props} actions={<ListActions />} empty={<Empty />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField label="resources.partnerEvent.label.startDate" source="startDate" />
        <DateField label="resources.partnerEvent.label.endDate" source="endDate" />
        <TextField label="resources.partnerEvent.label.title" source="title.fr" />
      </Datagrid>
    </List>
  );
};

export default PartnerEventList;
