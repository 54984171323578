import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query BO_Product($id: ID!) {
    data: BO_Product(id: $id) {
      id
      credits
      name {
        fr
        en
        nl
      }
      price
      active
      type
      prices {
        id
        price
        group {
          id
          name {
            fr
            nl
            en
          }
        }
      }
    }
  }
`;

export const GetOne = () => {
  return (params: any) => {
    return {
      query: GET_ONE_QUERY,
      variables: {
        id: params.id,
      },
      parseResponse: (ret: any) => {
        if (!ret.data) return { data: {} };
        return ret.data;
      },
    };
  };
};
