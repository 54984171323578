import React from 'react';
import { push } from 'connected-react-router';

const Dashboard = () => {
  if (!localStorage.getItem('sessionToken')) {
    push('/login');
  }
  return <div />;
};

export default Dashboard;
