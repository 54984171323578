import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query partnerEvent($id: ID!) {
    data: Bo_partnerEvent(id: $id) {
      id
      startDate
      endDate
      showDates
      location {
        coordinates
      }
      address
      city
      zipCode
      country
      radius
      backgroundColor
      title {
        fr
        en
        nl
      }
      discount
      cta
      subtitle {
        fr
        en
        nl
      }
      picture
      deeplink
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp.data) return { data: {} };

  const data = {
    ...resp.data.data,
    isThereSubtitle: resp.data.data.subtitle === null ? false : true,
    isTherePromotion: resp.data.data.discount === null ? false : true,
    longitude: resp.data.data.location.coordinates[0],
    latitude: resp.data.data.location.coordinates[1],
  };

  return { data };
};

export const GetOne = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: params,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse,
  };
};
