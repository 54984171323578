import gql from 'graphql-tag';
import _ from 'lodash';

const UPDATE_PRODUCT_MUTATION = gql`
  mutation BO_UPDATE_PRODUCT_MUTATION($input: BO_UpdateProductInput!) {
    BO_UpdateProduct(input: $input) {
      data: product {
        id
        credits
        name {
          fr
          en
          nl
        }
        price
        active
        type
        prices {
          id
          price
          group {
            id
            name {
              fr
              nl
              en
            }
          }
        }
      }
    }
  }
`;

const UPDATABLE_FIELDS = ['active', 'name'];
export const Update = () => {
  return (params: { data: any; id: string; previousData: any }) => {
    const input = {
      id: params.id,
    } as { id: string; [field: string]: any };

    for (const field of UPDATABLE_FIELDS) {
      if (!_.isEqual(params.data[field], params.previousData[field])) {
        input[field] = params.data[field];
      }
    }
    return {
      query: UPDATE_PRODUCT_MUTATION,
      variables: { input },
      parseResponse: (response: any) => response.data.BO_UpdateProduct,
    };
  };
};
