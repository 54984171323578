import React from 'react';

import { Datagrid, Filter, List, downloadCSV, translate } from 'react-admin';

import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
} from 'react-admin';

import { unparse as convertToCSV } from 'papaparse';
import { TopToolbar, ExportButton } from 'react-admin';

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="q"
      source="q"
      alwaysOn
      resettable
      parse={(value: string) => value.replace(/^\s+/gi, '')}
    />
    <TextInput source="email" allowEmpty />
    <ReferenceInput
      label="resources.User.fields.corporateCompany.name"
      source="corporateCompany.id"
      reference="CorporateCompany"
      allowEmpty
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const exporter = (users: any) => {
  const usersForExport = users.map((user: any) => {
    const {
      id,
      email,
      firstname,
      lastname,
      createdAt,
      status,
      subscriptionStatus,
      ...rest
    } = user;
    const { endDate, category } = rest.subscription || {
      endDate: '',
      category: '',
    };
    const corporationName = rest.corporateCompany ? rest.corporateCompany.name : '';
    return {
      id,
      email,
      firstname,
      lastname,
      createdAt,
      status,
      subscriptionStatus,
      corporationName,
      subscriptionEndDate: endDate,
      offerType: category.toLowerCase(),
    };
  });
  const csv = convertToCSV({
    data: usersForExport,
    fields: [
      'id',
      'email',
      'firstname',
      'lastname',
      'createdAt',
      'status',
      'subscriptionStatus',
      'corporationName',
      'subscriptionEndDate',
      'offerType',
    ],
  });
  downloadCSV(csv, 'users');
};

const UserActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  ...props
}: any) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </TopToolbar>
);

export const UserListWithoutTranslation = ({ translate, ...props }: any) => (
  <List
    {...props}
    bulkActions={false}
    filters={<UserFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    exporter={exporter}
    actions={<UserActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <FunctionField
        label="resources.User.list.name"
        render={(record: any) => `${record.lastname} ${record.firstname}`}
        sortBy="lastname"
      />
      <DateField source="createdAt" />
      <FunctionField
        label="resources.User.list.status"
        source="subscriptionStatus"
        sortable={false}
        render={(record: any) => {
          if (!record || !record.subscriptionStatus) return 'UNKNOWN';
          return translate(
            `resources.User.fields.statusType.${record.subscriptionStatus.toLowerCase()}`
          );
        }}
      />
      <DateField source="subscription.endDate" />
      <FunctionField
        source="subscription.category"
        render={(record: any) =>
          record &&
          record.subscription &&
          record.subscription.category &&
          translate(
            'resources.User.fields.offerType.' +
              record.subscription.category.toLowerCase()
          )
        }
        sortable={false}
      />
      <ReferenceField
        label="resources.User.fields.corporateCompany.name"
        source="corporateCompany.id"
        reference="CorporateCompany"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      {/* <TextField source="corporateCompany.name" sortable={false} /> */}
    </Datagrid>
  </List>
);

export const UserList = translate(UserListWithoutTranslation);
