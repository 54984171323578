import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query BO_AllGymCountries {
    items: BO_AllGymCountries {
      id
      name
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items,
    total: resp.data.items.length
  };
};

export const GetList = () => {
  return {
    query: GET_LIST_QUERY,
    parseResponse
  };
};
