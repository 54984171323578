import React, { useState } from 'react';
import { useTranslate } from 'ra-core';

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { EMAIL_REGEX } from '../../../utils';
import { useForm } from 'react-final-form';

const commonEmailDomains = [
  /* Default domains included */
  'aol.com',
  'att.net',
  'comcast.net',
  'facebook.com',
  'gmail.com',
  'gmx.com',
  'googlemail.com',
  'google.com',
  'hotmail.com',
  'hotmail.co.uk',
  'mac.com',
  'me.com',
  'mail.com',
  'msn.com',
  'live.com',
  'sbcglobal.net',
  'verizon.net',
  'yahoo.com',
  'yahoo.co.uk',

  /* Other global domains */
  'email.com',
  'fastmail.fm',
  'games.com' /* AOL */,
  'gmx.net',
  'hush.com',
  'hushmail.com',
  'icloud.com',
  'iname.com',
  'inbox.com',
  'lavabit.com',
  'love.com' /* AOL */,
  'outlook.com',
  'pobox.com',
  'protonmail.ch',
  'protonmail.com',
  'tutanota.de',
  'tutanota.com',
  'tutamail.com',
  'tuta.io',
  'keemail.me',
  'rocketmail.com' /* Yahoo */,
  'safe-mail.net',
  'wow.com' /* AOL */,
  'ygm.com' /* AOL */,
  'ymail.com' /* Yahoo */,
  'zoho.com',
  'yandex.com',

  /* United States ISP domains */
  'bellsouth.net',
  'charter.net',
  'cox.net',
  'earthlink.net',
  'juno.com',

  /* British ISP domains */
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'freeserve.co.uk',
  'live.co.uk',
  'ntlworld.com',
  'o2.co.uk',
  'orange.net',
  'sky.com',
  'talktalk.co.uk',
  'tiscali.co.uk',
  'virgin.net',
  'wanadoo.co.uk',
  'bt.com',

  /* Domains used in Asia */
  'sina.com',
  'sina.cn',
  'qq.com',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'nate.com',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.com.sg',
  'yahoo.com.ph',
  '163.com',
  'yeah.net',
  '126.com',
  '21cn.com',
  'aliyun.com',
  'foxmail.com',

  /* French ISP domains */
  'hotmail.fr',
  'live.fr',
  'laposte.net',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'gmx.fr',
  'sfr.fr',
  'neuf.fr',
  'free.fr',

  /* German ISP domains */
  'gmx.de',
  'hotmail.de',
  'live.de',
  'online.de',
  't-online.de' /* T-Mobile */,
  'web.de',
  'yahoo.de',

  /* Italian ISP domains */
  'libero.it',
  'virgilio.it',
  'hotmail.it',
  'aol.it',
  'tiscali.it',
  'alice.it',
  'live.it',
  'yahoo.it',
  'email.it',
  'tin.it',
  'poste.it',
  'teletu.it',

  /* Russian ISP domains */
  'mail.ru',
  'rambler.ru',
  'yandex.ru',
  'ya.ru',
  'list.ru',

  /* Belgian ISP domains */
  'hotmail.be',
  'live.be',
  'skynet.be',
  'voo.be',
  'tvcablenet.be',
  'telenet.be',

  /* Argentinian ISP domains */
  'hotmail.com.ar',
  'live.com.ar',
  'yahoo.com.ar',
  'fibertel.com.ar',
  'speedy.com.ar',
  'arnet.com.ar',

  /* Domains used in Mexico */
  'yahoo.com.mx',
  'live.com.mx',
  'hotmail.es',
  'hotmail.com.mx',
  'prodigy.net.mx',

  /* Domains used in Canada */
  'yahoo.ca',
  'hotmail.ca',
  'bell.net',
  'shaw.ca',
  'sympatico.ca',
  'rogers.com',

  /* Domains used in Brazil */
  'yahoo.com.br',
  'hotmail.com.br',
  'outlook.com.br',
  'uol.com.br',
  'bol.com.br',
  'terra.com.br',
  'ig.com.br',
  'itelefonica.com.br',
  'r7.com',
  'zipmail.com.br',
  'globo.com',
  'globomail.com',
  'oi.com.br',
];

const transformFilterToRegexp = (filter: string) => {
  return new RegExp(`^${filter.replace(/\./g, '\\.').replace(/\*/g, '.*')}$`);
};

const AddBlacklist = () => {
  const form = useForm();
  const translate = useTranslate();

  const [open, setOpen] = useState(false);

  const [filterInput, setFilterInput] = useState('');
  const [globValid, setGlobValid] = useState(true);

  const [match, setMatch] = useState(false);
  const [testInput, setTestInput] = useState('');
  const [testIsEmail, setTestIsEmail] = useState(false);

  const checkMatch = (glob: string, test: string) => {
    const reg = transformFilterToRegexp(glob);

    const res = reg.test(test);

    setMatch(res);
  };

  const handleFilterChange = ({ target: { value } }: any) => {
    setFilterInput(value);
    // if (value.length > 6) debugger;

    const valid =
      value.length > 6 &&
      // No @
      value.split('@').length === 2 &&
      // Not a domain after the @
      value.split('@')[1].split('.').length > 1 &&
      // Forbidden to have * in the domain part
      !/\*/g.test(value.split('@')[1]) &&
      // Disable common email domains
      !commonEmailDomains.includes(value.split('@')[1]);

    setGlobValid(valid);

    if (!valid) return;

    checkMatch(value, testInput);
  };

  const handleTestChange = ({ target: { value } }: any) => {
    setTestInput(value);

    const validEmail = EMAIL_REGEX.test(value);
    setTestIsEmail(validEmail);

    let glob = filterInput;

    if (validEmail) {
      // Here we:
      // Find the first block of alpha numeric (with potential -, ., _), finish with an alpha numeric
      // Find the ext (should be prefixed and/or suffixed with ., -, _)
      // Find the rest of the mail name
      // Find the domain after the @
      const checkExtInName = /^([a-zA-Z0-9_\-.]*[a-zA-Z0-9]+)*([-_.]*ext[-_.]*)([a-zA-Z0-9_\-.]*)@(.*)$/g;

      const matched = checkExtInName.exec(value);

      if (matched && matched.length === 5) {
        const suggestion = `${matched[1] ? '*' : ''}${matched[2]}${
          matched[3] ? '*' : ''
        }@${matched[4]}`;

        handleFilterChange({ target: { value: suggestion } });
        glob = suggestion;
      } else {
        const checkExtInDomain = /^(.*)@(ext|external)\.(.*)$/;

        const matched = checkExtInDomain.exec(value);

        if (matched && matched.length === 4) {
          const suggestion = `*@${matched[2]}.${matched[3]}`;

          handleFilterChange({ target: { value: suggestion } });
          glob = suggestion;
        }
      }
    }

    checkMatch(glob, value);
  };

  const handleSubmit = () => {
    form.change('emailBlacklistRegex', [
      { value: transformFilterToRegexp(filterInput).toString() },
    ]);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.CorporateCompany.actions.addBlacklist.name')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.CorporateCompany.actions.addBlacklist.name')}
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography>
              {translate('resources.CorporateCompany.actions.addBlacklist.test')}
            </Typography>
            <TextField
              onChange={handleTestChange}
              value={testInput}
              error={Boolean(testInput && (!match || !testIsEmail))}
              helperText={
                testInput &&
                (!match
                  ? translate('resources.CorporateCompany.actions.addBlacklist.noMatch')
                  : !testIsEmail
                  ? translate(
                      'resources.CorporateCompany.actions.addBlacklist.isNotEmail'
                    )
                  : '')
              }
            />
          </div>
          {testIsEmail && (
            <div>
              <Typography>
                {translate('resources.CorporateCompany.actions.addBlacklist.filter')}
              </Typography>

              <TextField
                onChange={handleFilterChange}
                value={filterInput}
                error={!globValid}
                helperText={
                  !globValid &&
                  translate('resources.CorporateCompany.actions.addBlacklist.invalidGlob')
                }
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Cancel
          </Button>

          <Button
            onClick={() => {
              setOpen(false);
              handleSubmit();
            }}
            disabled={!filterInput.length || !globValid || !match}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddBlacklist;
