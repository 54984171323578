import React from 'react';
import { FunctionField } from 'ra-ui-materialui';
import { Datagrid, List, TextField, TextInput, Filter } from 'react-admin';

const GrpFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Slug" source="q" alwaysOn />
  </Filter>
);

export const ActivityGroupList = (props: any) => (
  <List
    {...props}
    undoable="false"
    bulkActions={false}
    perPage={25}
    filters={<GrpFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nom" />
      <TextField source="slug" label="Slug" />
      <FunctionField
        source="activityCount"
        label="Nombre d'activités"
        sortable={false}
        render={(record: any) =>
          record && record.activityIDs && record.activityIDs.length
        }
      />
    </Datagrid>
  </List>
);
