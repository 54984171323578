import React from 'react';
import { TextField } from 'react-admin';
import { TextInput } from 'react-admin';
import { Filter } from 'react-admin';
import { FunctionField } from 'react-admin';
import { Datagrid, List } from 'react-admin';

const ErpUserAccountsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Gym Name" source="gymName" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);

export const ErpUserAccountsList = (props: any) => {
  console.log('basePath', props.basePath);

  return (
    <List
      filters={<ErpUserAccountsFilter />}
      undoable="false"
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField label="User Email" source="userEmail" />
        <FunctionField label="Gym Name" render={(record: any) => `${record.gym.name}`} />
        <TextField label="Active" source="active" />
        <TextField label="Anonymized email" source="erpFirmEmail" />
      </Datagrid>
    </List>
  );
};
