import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation BO_DeleteErp($id: ID!) {
    data: BO_DeleteErp(input: { id: $id }) {
      id
    }
  }
`;

export const Delete = (params: any) => {
  const parseResponse = (resp: any) => {
    const data = resp.data?.deleteGym?.data ?? {};
    return { data };
  };

  console.log('[DELETE][ERP]', params.id);

  return {
    variables: {
      id: params.id,
    },
    parseResponse,
    query: DELETE_MUTATION,
  };
};
