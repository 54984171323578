import React, { useState } from 'react';

import { Mutation, translate as translateDecorator } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useNotify } from 'react-admin';
import { useRefresh } from 'react-admin';

const UserSwitchFormulaAction = ({ data, translate }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);

  if (!data) return null;

  const payload = {
    userID: data.userID,
    newOfferID: data.newOfferID,
    data: { ...data, updateFormula: true },
  };

  const options = {
    undoable: false,
    callback: ({ error }: { requestPayload: any; payload: any; error: any }) => {
      if (error) return console.log('Failed to switch user formula', error);
    },
    onSuccess: (data: any) => {
      if (data.success) {
        notify('resources.User.actions.swithFormula.success', 'success');
        refresh();
      } else {
        notify('resources.User.actions.swithFormula.failure', 'error');
      }
    },
    onFailure: () => {
      notify('resources.User.actions.swithFormula.failure', 'error');
    },
  };

  return (
    <div style={{ marginLeft: 20, paddingBottom: 20 }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.User.actions.swithFormula.name')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.User.actions.swithFormula.title')}
        </DialogTitle>
        <DialogContent>
          {translate('resources.User.actions.swithFormula.subtitle')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Annuler
          </Button>

          <Mutation type="UPDATE" resource="User" payload={payload} options={options}>
            {(approve: any) => (
              <Button
                onClick={() => {
                  setOpen(false);
                  approve();
                }}
                variant="contained"
                color="primary"
              >
                OK
              </Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default translateDecorator(UserSwitchFormulaAction);
