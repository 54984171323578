import React, { useState } from 'react';
import { useRefresh, useTranslate, useDataProvider, useNotify } from 'ra-core';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
    display: 'flex',
  },
  formControl: {
    minWidth: 250,
  },
};

interface Props {
  record: any;
}

const BlockUsersAction = ({ record }: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const refresh = useRefresh();

  const triggerBlockUsers = async () => {
    try {
      await dataProvider.update('CorporateCompany', {
        id: record.id,
        data: {
          blockUsers: true,
          id: record.id,
        },
        previousData: {
          id: record.id,
        },
      });
      setOpen(false);
      refresh();
    } catch (e: any) {
      console.log(e);
      if (e.message.indexOf('Unauthorized') >= 0) {
        return notify('resources.CorporateCompany.notifications.missingRole', 'warning');
      } else if (e.message.indexOf('data_provider_error') >= 0) {
        setOpen(false);
        refresh();
        return;
      }
      return notify(e, 'warning');
    }
  };

  return (
    <div style={{ marginRight: '.5rem' }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.CorporateCompany.actions.blockUsers.name')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translate('resources.CorporateCompany.actions.blockUsers.confirm')}
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
            {translate('resources.CorporateCompany.actions.blockUsers.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={triggerBlockUsers}>
            {translate('resources.CorporateCompany.actions.blockUsers.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(BlockUsersAction);
