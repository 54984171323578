import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  required,
  SelectInput,
  TextInput,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { useForm } from 'react-final-form';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
  doc: {
    marginTop: '40px',
  },
  docLink: {
    color: '#5e5eff',
    fontSize: '18px',
  },
};

const ZingfitForm = ({ classes }: any) => {
  const baseUrl = process.env.ZINGFIT_API_URL || 'https://api.zingfit.com';
  const form = useForm();
  const translate = useTranslate();
  const [authError, setAuthError] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [regions, setRegions] = useState<IZingfitRegion[]>([]);
  const [sites, setSites] = useState<IZingfitSite[]>([]);

  form.change('name', 'ZINGFIT');

  const getClientToken = async (login: string, password: string) => {
    try {
      const tokenizedCredentials = btoa(`${login}:${password}`);
      const form = new URLSearchParams({
        grant_type: 'client_credentials',
      });
      const data = await (
        await fetch(baseUrl + '/oauth/token', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${tokenizedCredentials}`,
          },
          method: 'POST',
          body: form.toString(),
        })
      ).json();
      await listRegions(data.access_token);
      setAccessToken(data.access_token);
    } catch (error: any) {
      setAuthError(error.message);
    }
  };

  const listRegions = async (accessToken: string) => {
    try {
      const data = await (
        await fetch(baseUrl + '/regions', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${accessToken}`,
          },
          method: 'GET',
        })
      ).json();
      setRegions(data);
    } catch (error: any) {
      setAuthError(error.message);
    }
  };

  const handleRegionChange = (event: any) => {
    form.change(event.target.name, event.target.value);
    listSites(accessToken, event.target.value);
  };

  const listSites = async (accessToken: string, regionID: string) => {
    try {
      const data = await (
        await fetch(baseUrl + '/sites', {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${accessToken}`,
            'X-ZINGFIT-REGION-ID': regionID,
          },
          method: 'GET',
        })
      ).json();
      setSites(data);
    } catch (error: any) {
      setAuthError(error.message);
    }
  };

  return (
    <>
      <div className={classes.doc}>
        <a
          className={classes.docLink}
          href="https://www.notion.so/gymlib/Setup-Transfert-de-Cr-dit-R-sa-In-App-ZingFit-84ad0e42eaae4b7499deeeeb7ca23cc4"
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate('resources.Erp.openDoc')}
        </a>
      </div>

      <TextInput
        source="login"
        label="resources.Erp.fields.login"
        validation={[required()]}
      />
      <TextInput
        source="password"
        label="resources.Erp.fields.password"
        validation={[required()]}
      />
      {authError && <p className={classes.error}>{authError}</p>}
      <FormDataConsumer>
        {({ formData }: any) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => getClientToken(formData.login, formData.password)}
          >
            {translate('resources.Erp.testConnection')}
          </Button>
        )}
      </FormDataConsumer>
      {accessToken && (
        <>
          {regions.length > 0 && (
            <SelectInput
              source="siteID"
              label="resources.Erp.fields.region"
              choices={regions}
              onChange={handleRegionChange}
              validate={[required()]}
            />
          )}
          <>
            {sites.length > 0 && (
              <SelectInput
                source="businessUnitID"
                label="resources.Erp.fields.site"
                choices={sites}
                validate={[required()]}
              />
            )}
          </>
          <TextInput
            label="resources.Erp.fields.groupID"
            source="groupID"
            validate={[required()]}
          />
          <TextInput
            label="resources.Erp.fields.groupCode"
            source="groupCode"
            validate={[required()]}
          />
          <PlanningOffsetComponent />
          <SelectInput
            source="classFilteringMethod"
            label="resources.Erp.fields.classFilteringMethod"
            placeholder="resources.Erp.fields.classFilteringPlaceholder"
            choices={[
              { id: 'white', name: 'WHITELIST' },
              { id: 'black', name: 'BLACKLIST' },
            ]}
            allowEmpty
          />
          <FormDataConsumer>
            {({ formData }: any) => {
              return (
                <>
                  {formData.classFilteringMethod === 'white' && (
                    <ArrayInput
                      label="resources.Erp.fields.classFilteringMethod"
                      source="whitelistedClasses"
                    >
                      <SimpleFormIterator>
                        <TextInput
                          source="value"
                          label="resources.Erp.fields.filteredClasses.addLabel"
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )}
                  {formData.classFilteringMethod === 'black' && (
                    <ArrayInput
                      label="resources.Erp.fields.classFilteringMethod"
                      source="blacklistedClasses"
                    >
                      <SimpleFormIterator>
                        <TextInput
                          source="value"
                          label="resources.Erp.fields.filteredClasses.addLabel"
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <TextInput
            label="resources.Erp.fields.erpPassTypeID"
            source="erpPassTypeID"
            validate={[required()]}
          />
          <FormDataConsumer>
            {({ formData }: any) => (
              <>
                {formData.discountType === 'PROMOTION' && (
                  <BooleanInput
                    source="enableStartDateSync"
                    label="resources.Erp.fields.enableStartDateSync"
                    initialValue={true}
                  />
                )}
              </>
            )}
          </FormDataConsumer>
          <TextInput
            label="resources.Erp.fields.terms"
            source="terms"
            validate={[required()]}
          />
          <TextInput
            label="resources.Erp.fields.url"
            source="url"
            validate={[required()]}
          />
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ZingfitForm);

interface IZingfitRegion {
  id: string;
  name: string;
}

interface IZingfitSite {
  id: string;
  name: string;
}
