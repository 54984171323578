import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteHrManager($id: ID!) {
    deleteHrManager(input: { id: $id }) {
      data: status
    }
  }
`;

export const Delete = (params: any) => {
  console.log('[DELETE][HR_MANAGER]', params);

  return {
    variables: { id: params.id },
    query: DELETE_MUTATION,
    parseResponse: (resp: any) => {
      return { data: {} };
    },
  };
};
