import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteService($id: ID!) {
    deleteService(input: { id: $id }) {
      data: service {
        id
      }
    }
  }
`;

export const Delete = (params: any) => {
  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.deleteService) return { data: {} };

    return { data: resp.data.deleteService.data };
  };

  console.log('[DELETE][SERVICE]', params);

  return {
    variables: {
      id: params.id,
    },
    parseResponse,
    query: DELETE_MUTATION,
  };
};
