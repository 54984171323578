import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SearchIcon from '@material-ui/icons/Search';
import Fuse from 'fuse.js';
import { unparse as convertToCSV } from 'papaparse';
import { downloadCSV, useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { EMAIL_REGEX } from '../../../utils';
import CSVFile from '../../../utils/csv/CSVFile';
import UpgradeWhitelist from './UpgradeWhitelist';
import { EmployeeIdentifiers } from '../utils';
import { WhitelistUploadSpinnerModal } from './WhitelistUploadSpinnerModal';

type CSVParsedLine = Array<string | undefined | null>;

interface EnhancedTableHeadProps {
  numSelected: number;
  setSelectedEmployeesIdentifiers: any;
  paginatedEmployeesIdentifiers: EmployeeIdentifiers[];
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const {
    numSelected,
    setSelectedEmployeesIdentifiers,
    paginatedEmployeesIdentifiers,
  } = props;
  const translate = useTranslate();

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      setSelectedEmployeesIdentifiers(paginatedEmployeesIdentifiers);
    else setSelectedEmployeesIdentifiers([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={
              numSelected > 0 && numSelected < paginatedEmployeesIdentifiers.length
            }
            checked={
              paginatedEmployeesIdentifiers.length > 0 &&
              numSelected === paginatedEmployeesIdentifiers.length
            }
            onChange={handleSelectAll}
          />
        </TableCell>
        <TableCell>
          {translate(
            'resources.CorporateCompany.fields.validEmployeeEmails.tableHeadRefKey'
          )}
        </TableCell>
        <TableCell>
          {translate('resources.CorporateCompany.fields.validEmployeeEmails.tableHead')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isLastPage = useMemo(() => page >= Math.ceil(count / rowsPerPage) - 1, [
    page,
    count,
    rowsPerPage,
  ]);

  return (
    <Box>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const useTableToolbarStyles = makeStyles({
  search: {
    alignItems: 'center',
    display: 'flex',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  searchIcon: {
    marginRight: '5px',
  },
  searchLabel: {
    padding: '2px 10px',
    margin: 'auto 10px',
    display: 'inline-flex',
    maxWidth: '200px',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid black',
  },
  selectedTitle: {
    alignItems: 'center',
    display: 'flex',
    background: '#f1f1f3',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',

    '& > :first-child': {
      flexGrow: 1,
      fontWeight: 'bold',
    },
  },
});

interface EnhancedTableToolbarProps {
  selectedEmployeesIdentifiers: EmployeeIdentifiers[];
  search: string;
  setEmployeesIdentifiersTotalCount: any;
  setFilteredEmployeesIdentifiers: any;
  setSelectedEmployeesIdentifiers: any;
  setSearch: any;
  validEmployeesIdentifiers: EmployeeIdentifiers[];
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useTableToolbarStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const form = useForm();

  const {
    selectedEmployeesIdentifiers,
    search,
    setEmployeesIdentifiersTotalCount,
    setFilteredEmployeesIdentifiers,
    setSelectedEmployeesIdentifiers,
    setSearch,
    validEmployeesIdentifiers,
  } = props;

  const handleDeleteEmployeesIdentifiers = () => {
    form.change(
      'validEmployees',
      validEmployeesIdentifiers.filter(
        (employeeIdentifiers) =>
          !selectedEmployeesIdentifiers.some(
            (selectedEmployeeIdentifiers) =>
              selectedEmployeeIdentifiers.refKey === employeeIdentifiers.refKey
          )
      )
    );
    setSearch('');
    setSelectedEmployeesIdentifiers([]);
    notify(
      'resources.CorporateCompany.fields.validEmployeeEmails.tableToolbar.deleted',
      'info',
      { email_deleted_count: selectedEmployeesIdentifiers.length }
    );
  };

  useEffect(() => {
    const options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['refKey', 'email'],
    };
    if (search === '') return setFilteredEmployeesIdentifiers(null);

    const fuse = new Fuse(validEmployeesIdentifiers, options);
    const results = fuse.search(search);
    const filteredEmployeesIdentifiers = results.map((result) => result.item);

    setFilteredEmployeesIdentifiers(filteredEmployeesIdentifiers);
    setEmployeesIdentifiersTotalCount(results.length);
  }, [
    search,
    setEmployeesIdentifiersTotalCount,
    setFilteredEmployeesIdentifiers,
    validEmployeesIdentifiers,
  ]);

  return selectedEmployeesIdentifiers.length > 0 ? (
    <Toolbar className={classes.selectedTitle}>
      <h3>
        {translate(
          'resources.CorporateCompany.fields.validEmployees.tableToolbar.selected',
          { count: selectedEmployeesIdentifiers.length }
        )}
      </h3>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" onClick={handleDeleteEmployeesIdentifiers}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  ) : (
    <Toolbar className={classes.search}>
      <h3>
        {translate('resources.CorporateCompany.fields.validEmployeeIdentifiers.list')}
      </h3>
      <label className={classes.searchLabel}>
        <SearchIcon className={classes.searchIcon} />
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            type="search"
            placeholder={translate('ra.action.search')}
            disableUnderline
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </label>
    </Toolbar>
  );
};

const useAddEmailStyles = makeStyles({
  addEmail: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: '#f1f1f3',
    },

    '& p': {
      margin: '14px 10px',
    },
  },
  validateNewEmail: {
    display: 'flex',
    padding: '8px',
    paddingLeft: '52px',
  },
  addRefKeyInput: {
    display: 'flex',
    flexGrow: 1,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: '16px',
  },
  addEmailInput: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: '16px',
  },
  addTableRow: {
    position: 'relative',
  },
  buttonsWrapper: {
    position: 'absolute',
    right: 0,
    paddingLeft: '10px',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    height: '100%',
  },
});
interface EnhancedAddEmailProps {
  validEmployeesIdentifiers: EmployeeIdentifiers[];
  setSearch: any;
}

const EnhancedAddEmail = (props: EnhancedAddEmailProps) => {
  const { validEmployeesIdentifiers, setSearch } = props;
  const [displayAddEmployeeIdentifiers, setDisplayAddEmployeeIdentifiers] = useState(
    true
  );
  const [
    newEmployeeIdentifiers,
    setNewEmployeeIdentifiers,
  ] = useState<EmployeeIdentifiers>({ refKey: '', email: '' });
  const classes = useAddEmailStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const form = useForm();

  const handleAddEmail = () => {
    const clonedNewEmployeeIdentifiers = { ...newEmployeeIdentifiers };
    clonedNewEmployeeIdentifiers.refKey = clonedNewEmployeeIdentifiers.refKey
      .replace(/\s+/g, '')
      .toLowerCase();
    if (
      validEmployeesIdentifiers.some(
        (employeesIdentifiers) =>
          employeesIdentifiers.refKey === clonedNewEmployeeIdentifiers.refKey
      )
    ) {
      return notify(
        'resources.CorporateCompany.fields.validEmployeeEmails.duplicateAddRefKey',
        'error'
      );
    }

    form.change('validEmployees', [newEmployeeIdentifiers, ...validEmployeesIdentifiers]);
    setNewEmployeeIdentifiers({ refKey: '', email: '' });
    setSearch('');
    notify('resources.CorporateCompany.fields.validEmployeeEmails.saveAddEmail', 'info');
  };

  return displayAddEmployeeIdentifiers ? (
    <TableRow>
      <TableCell padding="none" colSpan={3}>
        <div
          className={classes.addEmail}
          onClick={() => setDisplayAddEmployeeIdentifiers(false)}
        >
          <AddIcon />
          <p>{translate('ra.action.add')}</p>
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow className={classes.addTableRow}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell padding="none">
        <Input
          type="text"
          disableUnderline
          value={newEmployeeIdentifiers.refKey}
          onChange={(e) =>
            setNewEmployeeIdentifiers({
              ...newEmployeeIdentifiers,
              refKey: e.target.value,
            })
          }
          className={classes.addRefKeyInput}
          placeholder={translate('resources.CorporateCompany.addRefKeyPlaceholder')}
          autoFocus
        />
      </TableCell>
      <TableCell padding="none">
        <Input
          type="email"
          disableUnderline
          value={newEmployeeIdentifiers.email}
          onChange={(e) =>
            setNewEmployeeIdentifiers({
              ...newEmployeeIdentifiers,
              email: e.target.value,
            })
          }
          className={classes.addEmailInput}
          placeholder={translate('resources.CorporateCompany.addEmailPlaceholder')}
        />
      </TableCell>
      <div className={classes.buttonsWrapper}>
        <MuiButton
          color="primary"
          onClick={() => {
            setNewEmployeeIdentifiers({ refKey: '', email: '' });
            setDisplayAddEmployeeIdentifiers(true);
          }}
          type="button"
        >
          {translate('ra.action.cancel')}
        </MuiButton>
        <MuiButton
          color="primary"
          disabled={
            !newEmployeeIdentifiers.refKey ||
            Boolean(
              newEmployeeIdentifiers?.email?.length &&
                !EMAIL_REGEX.test(newEmployeeIdentifiers?.email)
            )
          }
          type="button"
          onClick={() => {
            handleAddEmail();
          }}
        >
          {translate('ra.action.add')}
        </MuiButton>
      </div>
    </TableRow>
  );
};

const useWhitelistInputWithoutTranslateStyles = makeStyles({
  advancedEditHeading: {
    fontSize: 18,
  },
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    'white-space': 'noWrap',
  },
  tablePagination: {
    'white-space': 'noWrap',
  },
  tableFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const findUniqueEmployeesIdentifiersByRefKey = (
  employeesIdentifiers: Array<CSVParsedLine>
) => {
  const uniqueValidEmployeesIdentifiersMap = new Map<string, string | undefined | null>();

  employeesIdentifiers.forEach((employeeIdentifiers) => {
    if (employeeIdentifiers[0]) {
      const sanitizedRefKey = employeeIdentifiers[0].replace(/\s+/g, '').toLowerCase();
      const sanitizedEmail =
        typeof employeeIdentifiers[1] === 'string'
          ? employeeIdentifiers[1]?.replace(/\s+/g, '').toLowerCase()
          : null;
      uniqueValidEmployeesIdentifiersMap.set(
        sanitizedRefKey,
        sanitizedEmail && EMAIL_REGEX.test(sanitizedEmail) ? sanitizedEmail : null
      );
    }
  });

  return Array.from(uniqueValidEmployeesIdentifiersMap).map(([refKey, email]) => ({
    refKey,
    email,
  }));
};

export const EmployeesIdentifiersWhitelistInput = ({
  formData,
  fromCreate,
  ...props
}: any) => {
  const form = useForm();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useWhitelistInputWithoutTranslateStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredEmployeesIdentifiers, setFilteredEmployeesIdentifiers] = useState<
    EmployeeIdentifiers[] | null
  >(null);
  const [paginatedEmployeesIdentifiers, setPaginatedEmployeesIdentifiers] = useState<
    EmployeeIdentifiers[]
  >([]);
  const [employeesIdentifiersTotalCount, setEmployeesIdentifiersTotalCount] = useState(0);
  const [selectedEmployeesIdentifiers, setSelectedEmployeesIdentifiers] = useState<
    EmployeeIdentifiers[]
  >([]);
  const [search, setSearch] = useState('');
  const [openHugeWhitelistUploadModal, setOpenHugeWhitelistUploadModal] = useState(false);

  useEffect(() => {
    const recordEmployees = JSON.stringify(props.record?.validEmployees);
    const formDataEmployees = JSON.stringify(formData?.validEmployees);
    const validEmployeesChanged = recordEmployees !== formDataEmployees;

    if (props.isSubmitting && validEmployeesChanged) {
      setOpenHugeWhitelistUploadModal(
        props.isSubmitting &&
          formData?.validEmployees?.length > 1 &&
          validEmployeesChanged
      );
    } else if (!props.isSubmitting) {
      setOpenHugeWhitelistUploadModal(false);
    }
  }, [props.isSubmitting, formData, props.record]);

  useEffect(() => {
    if (filteredEmployeesIdentifiers) {
      setPaginatedEmployeesIdentifiers(
        filteredEmployeesIdentifiers.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      );
    } else if (formData?.validEmployees) {
      setPaginatedEmployeesIdentifiers(
        formData.validEmployees.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      );
      setEmployeesIdentifiersTotalCount(formData.validEmployees.length);
    } else {
      setPaginatedEmployeesIdentifiers([]);
      setEmployeesIdentifiersTotalCount(0);
    }
  }, [formData, filteredEmployeesIdentifiers, page, rowsPerPage]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    if (filteredEmployeesIdentifiers) {
      setPaginatedEmployeesIdentifiers(
        filteredEmployeesIdentifiers.slice(
          newPage * rowsPerPage,
          newPage * rowsPerPage + rowsPerPage
        )
      );
    } else if (formData?.validEmployees) {
      setPaginatedEmployeesIdentifiers(
        formData.validEmployees.slice(
          newPage * rowsPerPage,
          newPage * rowsPerPage + rowsPerPage
        )
      );
    } else setPaginatedEmployeesIdentifiers([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = 0;
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    if (filteredEmployeesIdentifiers) {
      setPaginatedEmployeesIdentifiers(
        filteredEmployeesIdentifiers.slice(
          newPage * newRowsPerPage,
          newPage * newRowsPerPage + newRowsPerPage
        )
      );
    } else if (formData?.validEmployees) {
      setPaginatedEmployeesIdentifiers(
        formData.validEmployees.slice(
          newPage * newRowsPerPage,
          newPage * newRowsPerPage + newRowsPerPage
        )
      );
    } else setPaginatedEmployeesIdentifiers([]);
  };

  const handleSelectClick = (
    _: React.MouseEvent<unknown>,
    clickedEmployeeIdentifiers: EmployeeIdentifiers
  ) => {
    const selectedIndex = selectedEmployeesIdentifiers.findIndex(
      (selectedEmployeeIdentifiers) =>
        selectedEmployeeIdentifiers.refKey === clickedEmployeeIdentifiers.refKey
    );
    let newSelected: EmployeeIdentifiers[] = [];

    if (selectedIndex === -1)
      newSelected = newSelected.concat(
        selectedEmployeesIdentifiers,
        clickedEmployeeIdentifiers
      );
    else {
      newSelected = newSelected.concat(
        selectedEmployeesIdentifiers.slice(0, selectedIndex),
        selectedEmployeesIdentifiers.slice(selectedIndex + 1)
      );
    }

    setSelectedEmployeesIdentifiers(newSelected);
  };

  const isSelected = (employeeIdentifiers: EmployeeIdentifiers) =>
    selectedEmployeesIdentifiers.findIndex(
      (selectedEmployeeIdentifiers) =>
        selectedEmployeeIdentifiers.refKey === employeeIdentifiers.refKey
    ) !== -1;

  const whitelistUploadHandler = async (
    //NOTE: the expected type is Array<[string, string | null | undefined]>
    //but current version of the react-scripts and typescript does not allow this Tyyping
    csvContent: Array<CSVParsedLine>
  ) => {
    const employeesIdentifiers: CSVParsedLine[] = [];
    csvContent?.forEach((csvLine) => {
      if (csvLine[0]) {
        const refKey = csvLine[0]?.replace(/\s+/g, '').toLowerCase();
        const email =
          typeof csvLine[1] === 'string'
            ? csvLine[1]?.replace(/\s+/g, '').toLowerCase()
            : null;
        employeesIdentifiers.push([
          refKey,
          email && EMAIL_REGEX.test(email) ? email : null,
        ]);
      }
    });
    if (!employeesIdentifiers?.length) return;
    const uniqueEmployeesIdentifiers = findUniqueEmployeesIdentifiersByRefKey(
      employeesIdentifiers
    );

    if (uniqueEmployeesIdentifiers.length === 0) {
      notify('resources.CorporateCompany.notifications.noRefKey', 'warning');
    } else {
      form.change('validEmployees', uniqueEmployeesIdentifiers);
      setSearch('');
      notify('resources.CorporateCompany.notifications.refKeyChanged', 'info', {
        count: uniqueEmployeesIdentifiers.length,
      });
    }
  };

  const deleteUploadHandler = async (employeesIdentifiers: CSVParsedLine[]) => {
    if (!employeesIdentifiers?.length) return;

    const toDeleteEmployeesIdentifiers = findUniqueEmployeesIdentifiersByRefKey(
      employeesIdentifiers
    ).filter((employeeIdentifiers) =>
      (formData?.validEmployees || []).some(
        (validEmployeeIdentifiers: EmployeeIdentifiers) =>
          validEmployeeIdentifiers.refKey === employeeIdentifiers.refKey
      )
    );

    if (
      paginatedEmployeesIdentifiers.length === 0 ||
      toDeleteEmployeesIdentifiers.length === 0
    ) {
      return notify(
        'resources.CorporateCompany.notifications.noRefKeyDeleteToProcess',
        'warning'
      );
    }

    form.change(
      'validEmployees',
      (formData?.validEmployees || []).filter(
        (validEmployeeIdentifiers: EmployeeIdentifiers) =>
          !toDeleteEmployeesIdentifiers.some(
            (toDeleteEmployeeIdentifiers) =>
              toDeleteEmployeeIdentifiers.refKey === validEmployeeIdentifiers.refKey
          )
      )
    );
    setSearch('');

    notify('resources.CorporateCompany.notifications.deletedRefKeysChanged', 'info', {
      deleted: toDeleteEmployeesIdentifiers.length,
      count: employeesIdentifiersTotalCount - toDeleteEmployeesIdentifiers.length,
    });
  };

  const addUploadHandler = async (employeesIdentifiers: Array<CSVParsedLine>) => {
    if (!employeesIdentifiers?.length) return;
    const toAddEmployeesIdentifiers = findUniqueEmployeesIdentifiersByRefKey(
      employeesIdentifiers
    ).filter(
      (employeeIdentifiers) =>
        !(formData?.validEmployees || []).some(
          (validEmployeeIdentifiers: EmployeeIdentifiers) =>
            validEmployeeIdentifiers.refKey === employeeIdentifiers.refKey
        )
    );

    if (
      paginatedEmployeesIdentifiers.length === 0 ||
      toAddEmployeesIdentifiers.length === 0
    ) {
      return notify(
        'resources.CorporateCompany.notifications.noRefKeyAddToProcess',
        'warning'
      );
    }

    form.change(
      'validEmployees',
      toAddEmployeesIdentifiers.concat(formData?.validEmployees || [])
    );
    setSearch('');

    notify('resources.CorporateCompany.notifications.addRefKey', 'info', {
      count: toAddEmployeesIdentifiers.length,
    });
  };

  const exportCSV = () => {
    const cleanedValidEmployees = formData?.validEmployees?.map(
      (validEmployee: EmployeeIdentifiers) => {
        return [validEmployee.refKey, validEmployee.email];
      }
    );
    if (!cleanedValidEmployees || cleanedValidEmployees.length === 0) {
      return notify('resources.CorporateCompany.notifications.noRefKeys', 'warning');
    }
    const csv = convertToCSV(cleanedValidEmployees);
    downloadCSV(csv, 'whitelist');
  };

  return (
    <div>
      <WhitelistUploadSpinnerModal
        open={openHugeWhitelistUploadModal}
        count={formData?.validEmployees?.length}
      />
      <CSVFile
        handleCSVResult={whitelistUploadHandler}
        label={translate(
          'resources.CorporateCompany.fields.validEmployeeEmails.fileRefKey'
        )}
      />
      <EnhancedTableToolbar
        selectedEmployeesIdentifiers={selectedEmployeesIdentifiers}
        search={search}
        setEmployeesIdentifiersTotalCount={setEmployeesIdentifiersTotalCount}
        setFilteredEmployeesIdentifiers={setFilteredEmployeesIdentifiers}
        setSelectedEmployeesIdentifiers={setSelectedEmployeesIdentifiers}
        setSearch={setSearch}
        validEmployeesIdentifiers={formData?.validEmployees || []}
      />
      <TableContainer component={Paper}>
        <Table>
          <EnhancedTableHead
            paginatedEmployeesIdentifiers={paginatedEmployeesIdentifiers}
            numSelected={selectedEmployeesIdentifiers.length}
            setSelectedEmployeesIdentifiers={setSelectedEmployeesIdentifiers}
          />
          <TableBody>
            {paginatedEmployeesIdentifiers.map((employeeIdentifiers) => (
              <TableRow
                key={employeeIdentifiers.refKey}
                selected={isSelected(employeeIdentifiers)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected(employeeIdentifiers)}
                    color="primary"
                    onClick={(event) => handleSelectClick(event, employeeIdentifiers)}
                  />
                </TableCell>
                <TableCell align="left">{employeeIdentifiers.refKey}</TableCell>
                <TableCell align="left">{employeeIdentifiers.email}</TableCell>
              </TableRow>
            ))}
            <EnhancedAddEmail
              validEmployeesIdentifiers={formData?.validEmployees || []}
              setSearch={setSearch}
            />
          </TableBody>
        </Table>
        <div className={classes.tableFooter}>
          <MuiButton
            className={classes.downloadButton}
            color="primary"
            endIcon={<CloudDownloadIcon fontSize="large" />}
            onClick={exportCSV}
          >
            {translate('resources.CorporateCompany.fields.validEmployeeEmails.download')}
          </MuiButton>
          <TablePagination
            component="div"
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={employeesIdentifiersTotalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </TableContainer>
      {formData?.validEmployees?.length > 0 && (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.advancedEditHeading}>
              {translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.advancedEditTitle'
              )}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <CSVFile
              handleCSVResult={deleteUploadHandler}
              label={translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.bulkRemoveEmailsFile'
              )}
            />
            <br />
            <CSVFile
              handleCSVResult={addUploadHandler}
              label={translate(
                'resources.CorporateCompany.fields.validEmployeeEmails.bulkAddEmailsFile'
              )}
            />
          </ExpansionPanelDetails>
          <ExpansionPanelActions>
            <UpgradeWhitelist {...props} record={formData} />
          </ExpansionPanelActions>
        </ExpansionPanel>
      )}
    </div>
  );
};
