import React, { useState } from 'react';
import { useRefresh, useTranslate, useNotify } from 'ra-core';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { apolloClient } from '../../../utils/apolloClient';
import gql from 'graphql-tag';

const UNBLOCK_USER_MUTATION = gql`
  mutation BO_UnblockUser($input: BO_UnblockUserInput!) {
    BO_UnblockUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const unblockUser = (userID: string) => {
  return apolloClient.mutate({
    mutation: UNBLOCK_USER_MUTATION,
    variables: {
      input: {
        userID,
      },
    },
  });
};

const styles = {
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
interface RawProps {
  data: any;
}

interface Props extends RawProps {
  classes: any;
}

const UnblockUserAction = ({ data, classes }: Props) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const unblockUserHandler = async () => {
    try {
      await unblockUser(data.id);
      setOpen(false);
      notify('resources.User.unblock.success', 'info');
      refresh();
    } catch (error: any) {
      console.error(error);
      setOpen(false);
      return notify(error.message || 'resources.CorporateCompany.generic.error', 'error');
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.User.unblock.button')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle>{translate('resources.User.unblock.title')}</DialogTitle>
        <DialogActions style={styles.dialogButtons}>
          <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
            {translate('resources.User.block.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              unblockUserHandler();
            }}
          >
            {translate('resources.User.unblock.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(UnblockUserAction);
