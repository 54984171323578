import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useMutation, useNotify, useRefresh } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  remContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dialog: {
    width: '500px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const DeleteGymRemuneration = ({ record }: any) => {
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();
  const [modalOpened, setModalOpen] = useState(false);
  const [confirmDelete] = useMutation(
    {
      type: 'DELETE',
      resource: 'GymRemuneration',
      payload: { id: record.id },
    },
    {
      onSuccess: () => {
        notify(translate('resources.Gym.deleteRem.success'), 'info');
        refresh();
      },
      onFailure: (error: any) => {
        const messageKey = error.message.replace('GraphQL error: ', '');
        if (messageKey) {
          return notify(translate(`resources.Gym.deleteRem.${messageKey}`), 'warning');
        }
        return notify(translate('resources.Gym.deleteRem.error'), 'warning');
      },
    }
  );
  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(true);
  };
  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(false);
  };
  const onConfirmDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(false);
    confirmDelete();
  };

  return (
    <>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={modalOpened} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translate('resources.Gym.deleteRem.modalTitle')}
        </DialogTitle>
        <DialogContent>{translate('resources.Gym.deleteRem.modalContent')}</DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} variant="contained" color="primary">
            {translate('resources.Gym.deleteRem.actions.cancel')}
          </Button>

          <Button onClick={onConfirmDelete}>
            {translate('resources.Gym.deleteRem.actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
