import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  minValue,
  maxValue,
  Link as AdminLink,
} from 'react-admin';
import { required, useTranslate } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { AdmissionChargeComponent } from './AdmissionChargeComponent';
import { CappingComponent } from './CappingComponent';
import { OffPeakComponent } from './OffPeakComponent';
import { ArrayInput, DateInput, NumberInput, SimpleFormIterator } from 'ra-ui-materialui';
import { isPromotionEnded, showPromotions } from './utils';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = {
  inlineBlock: { margin: '25px' },
  cappingContainer: { margin: '35px 0' },
  discoverySessionContainer: { margin: '35px 0' },
  alert: {
    backgroundColor: '#fff4e5',
    color: '#6f470e',
    borderRadius: '4px',
    padding: '6px',
  },
};

const addButtonDisabled = (data: any) => data.promotions?.length === 1;

const PulsesWithoutStyle = ({ classes }: any) => {
  const translate = useTranslate();

  return (
    <section className={classes.inlineBlock}>
      <Typography
        className={classes.dividerFullWidth}
        color="textPrimary"
        display="block"
        variant="h6"
      >
        Unlimited:
      </Typography>
      <Divider />

      <FormDataConsumer>
        {({ formData }: any) =>
          formData?.firm?.id ? (
            <div className={classes.line}>
              <BooleanInput
                source="isOverridingUnlimitedOfferFirm"
                label="resources.Gym.fields.overrideIsUnlimitedOfferFirm"
                className={classes.inlineBlock}
              />

              <FormDataConsumer>
                {({ formData }: any) =>
                  formData?.isOverridingUnlimitedOfferFirm ? (
                    <BooleanInput
                      source="isUnlimitedOfferGym"
                      label="resources.Gym.fields.isUnlimitedOfferGym"
                      className={classes.inlineBlock}
                    />
                  ) : null
                }
              </FormDataConsumer>
            </div>
          ) : (
            <div className={classes.line}>
              <BooleanInput
                source="isUnlimitedOfferGym"
                label="resources.Gym.fields.isUnlimitedOfferGym"
                className={classes.inlineBlock}
              />
            </div>
          )
        }
      </FormDataConsumer>

      <Typography
        className={classes.dividerFullWidth}
        color="textPrimary"
        display="block"
        variant="h6"
      >
        Pulses:
      </Typography>
      <Divider />
      <FormDataConsumer>
        {({ formData }: any) => (
          <AdmissionChargeComponent formData={formData} translate={translate} />
        )}
      </FormDataConsumer>

      <Typography
        className={classes.dividerFullWidth}
        color="textPrimary"
        display="block"
        variant="h6"
      >
        Yield:
      </Typography>
      <Divider />
      <div className={classes.cappingContainer}>
        <FormDataConsumer>
          {({ formData }: any) => (
            <CappingComponent formData={formData} translate={translate} />
          )}
        </FormDataConsumer>
      </div>
      <div className={classes.discoverySessionContainer}>
        <BooleanInput
          source="availableSession.yield.discoverySession"
          label="resources.Gym.fields.discoverySession.label"
          helperText="resources.Gym.fields.discoverySession.description"
          initialValue={false}
        />
      </div>
      <div className={classes.cappingContainer}>
        <FormDataConsumer>
          {({ formData }: any) =>
            [
              'IN_APP_BOOKING',
              'OPEN_ACCESS_WITHOUT_ERP',
              'OPEN_ACCESS_WITH_ERP',
            ].includes(formData?.bookingFlow) ? (
              <OffPeakComponent formData={formData} translate={translate} />
            ) : null
          }
        </FormDataConsumer>
      </div>
      <FormDataConsumer>
        {({ formData }: any) =>
          formData.firm?.hasPromotion ? (
            <p>
              L’enseigne&nbsp;
              <AdminLink to={`/Firm/${formData.firm.id}`}>
                {formData.firm.name} <LaunchIcon />
              </AdminLink>
              &nbsp;&nbsp;est en promotion.
            </p>
          ) : (
            showPromotions(formData.bookingFlow) && (
              <>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textPrimary"
                  display="block"
                  variant="h6"
                >
                  Promotions sur les séances
                </Typography>
                <Divider />
                {isPromotionEnded(formData) && (
                  <div className={classes.alert}>la promotion est terminée</div>
                )}
                <ArrayInput source="promotions" label="">
                  <SimpleFormIterator disableAdd={addButtonDisabled(formData)}>
                    <NumberInput
                      source="percent"
                      label="resources.Gym.fields.promotion.percent"
                      min="0"
                      max="100"
                      validate={[minValue(0), maxValue(100), required()]}
                    ></NumberInput>
                    <DateInput
                      source="startDate"
                      label="resources.Gym.fields.promotion.startDate"
                      validate={required()}
                    ></DateInput>
                    <DateInput
                      source="endDate"
                      label="resources.Gym.fields.promotion.endDate"
                      validate={required()}
                    ></DateInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            )
          )
        }
      </FormDataConsumer>
    </section>
  );
};

export default withStyles(styles)(PulsesWithoutStyle);
