import React, { useState } from 'react';
import { NumberInput, CheckboxGroupInput } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

export const OffPeakComponent = ({ formData, translate }: any) => {
  const [showOffPeakLimit, setShowOffPeakLimit] = useState(formData.offPeak != null);

  return (
    formData && (
      <>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={formData.offPeak != null}
              onChange={(event) => {
                formData.offPeak = null;
                setShowOffPeakLimit(event.target.checked);
              }}
            />
          }
          label={translate('resources.Firm.fields.yield.offPeak.toggle')}
        ></FormControlLabel>
        <FormHelperText>
          {translate('resources.Firm.fields.yield.offPeak.description')}
        </FormHelperText>
        {showOffPeakLimit && (
          <>
            <NumberInput
              source="offPeak.costInCredits"
              label="resources.Firm.fields.yield.offPeak.input"
              initialValue={8}
              min={1}
              max={99}
              step={1}
            />
            <CheckboxGroupInput
              source="offPeak.intervals"
              label="resources.Gym.yield.offPeakHour.intervals"
              choices={[
                { id: 'MORNING', name: 'resources.Gym.yield.offPeakHour.morning' },
                {
                  id: 'AFTERNOON',
                  name: 'resources.Gym.yield.offPeakHour.afternoon',
                },
              ]}
            />
          </>
        )}
      </>
    )
  );
};
