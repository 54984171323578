import React from 'react';
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  toolbar: {
    background: 'none',
    margin: 0,
    padding: 0,
  },
});

type FormInput = {
  managerId: string | undefined;
};

const AddGymManagerToolbar = (props: any) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <IconButton onClick={props.handleSubmit}>
        <AddCircleOutlineIcon />
      </IconButton>
    </Toolbar>
  );
};

const AddGymManager = (props: any) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const [addManager] = useMutation();

  const handleAdd = ({ managerId }: FormInput) => {
    if (managerId?.length) {
      return addManager(
        {
          type: 'UPDATE',
          resource: 'GymManager',
          payload: { id: managerId, gymID: props.id, addGymToManager: true },
        },
        {
          onSuccess: () => {
            notify(translate('resources.Gym.manager.add.success'), 'info');
            refresh();
          },
          onFailure: (error: any) => {
            return notify(error.message, 'warning');
          },
        }
      );
    }
    return notify(
      translate('resources.Gym.manager.add.missingManager'),
      'warning',
      undefined,
      false,
      3000
    );
  };

  return (
    <SimpleForm
      className={classes.container}
      save={handleAdd}
      toolbar={<AddGymManagerToolbar />}
      submitOnEnter={false}
    >
      <ReferenceInput
        source="managerId"
        label="resources.Gym.manager.add.oneManager"
        reference="GymManager"
        allowEmpty
      >
        <AutocompleteInput suggestionLimit={5} optionText="email" resettable={true} />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default AddGymManager;
