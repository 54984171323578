import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query ExternalIntegrationList(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: ExternalIntegrationList(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      externalIntegrationList {
        id
        companyID
        integrationType
        createdAt
        company {
          name
        }
        status
      }
    }
    total: _allExternalIntegrationListMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items.externalIntegrationList,
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    query: GET_LIST_QUERY,
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params,
    },
    query: GET_LIST_QUERY,
    parseResponse,
  };
};
