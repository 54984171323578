import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  Pagination,
  TopToolbar,
} from 'react-admin';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { translate } from 'ra-core';

import FileCopy from '@material-ui/icons/FileCopy';
import CreatePasswordResetAction from './CreatePasswordResetAction';

const HrManagerTitle = ({ record, translate }: any) => (
  <span>
    {translate('resources.HrManager.editTitle', { email: record ? record.email : '' })}
  </span>
);

const HrManagerActions = ({ ...props }: any) => (
  <TopToolbar>
    <CreatePasswordResetAction {...props} />
  </TopToolbar>
);

const HrManagerEditWithoutTranslate = ({ showNotification, ...props }: any) => (
  <Edit
    {...props}
    undoable={false}
    title={<HrManagerTitle translate={props.translate} />}
    actions={<HrManagerActions {...props} />}
  >
    <SimpleForm>
      <TextInput disabled source="email" />
      <ReferenceArrayInput
        {...props}
        source="companyIDs"
        reference="CorporateCompany"
        perPage={5}
        sort={{ field: `name`, order: 'ASC' }}
        validate={required('Company selection is mandatory')}
      >
        <AutocompleteArrayInput
          {...props}
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceArrayInput>
      <ReferenceManyField
        pagination={<Pagination />}
        label="resources.HrManager.fields.passwordReset"
        reference="PasswordReset"
        target="HrManager"
        source="id"
        sort={{ field: 'expiredAt', order: 'DESC' }}
        perPage={10}
      >
        <Datagrid
          rowClick={function(id: string, path: string, currentRecord: any) {
            navigator.clipboard.writeText(currentRecord.url).then(
              function() {
                showNotification("Lien d'inscription Copié dans votre presse-papier");
              },
              function() {
                showNotification(
                  'Erreur: Impossible de copier dans votre presse-papier',
                  'warning'
                );
              }
            );
          }}
        >
          <FileCopy />
          <TextField source="url" />
          <TextField source="status" />
          <DateField source="expiredAt" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);

export const HrManagerEdit = connect(
  null,
  { showNotification }
)(translate(HrManagerEditWithoutTranslate));
