import gql from 'graphql-tag';

const UPDATE_MUTATION = gql`
  mutation updateGymManager($id: ID!, $gymIDs: [ID!]!, $displayFrom: DateTime) {
    data: updateGymManager(
      input: { id: $id, gymIDs: $gymIDs, displayFrom: $displayFrom }
    ) {
      id
      email
      gyms {
        id
      }
      status
      displayFrom
    }
  }
`;

const ADD_ONE_GYM = gql`
  mutation addGymToManager($id: ID!, $gymID: ID!) {
    data: addGymToManager(input: { id: $id, gymID: $gymID }) {
      id
      email
      gyms {
        id
      }
      status
      displayFrom
    }
  }
`;

export const Update = (params: any) => {
  if (params.addGymToManager) {
    console.log('[UPDATE][GYM_MANAGER][ADD_GYM]', params);

    return {
      variables: {
        id: params.id,
        gymID: params.gymID,
      },
      // variables: { id: params.id, gymID: params.gymID },
      query: ADD_ONE_GYM,
      parseResponse: (resp: any) => {
        if (!resp.data) return { data: {} };
        return {
          data: {
            ...resp.data.data,
          },
        };
      },
    };
  }
  console.log('[UPDATE][GYM_MANAGER]', params);

  return {
    variables: {
      id: params.data.id,
      gymIDs: params.data.gymIDs,
      displayFrom: params.data.displayFrom,
    },
    query: UPDATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          gymIDs: resp.data.data.gyms.map((gym: any) => gym.id),
        },
      };
    },
  };
};
