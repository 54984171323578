import { cloneDeep } from 'lodash';
import { formatData } from '../../gymRemunerations/dataProvider/utils';

export const FormatFirm = (untouchedFirm: any) => {
  const firm = cloneDeep(untouchedFirm);
  // We need to flatten them for RA
  firm.admissionChargeInCredits = firm.availableSession.admissionChargeInCredits;
  firm.capping = firm.availableSession.yield.capping;
  firm.discoverySession = firm.availableSession.yield.discoverySession;
  firm.offPeak = firm.availableSession.yield.offPeak;

  if (firm.remuneration) firm.remuneration = formatData(firm.remuneration);

  return firm;
};
