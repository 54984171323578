import gql from 'graphql-tag';

import { gymRemFields } from '../../gymRemunerations/dataProvider/GetOne';
import { FormatFirm } from './utils';

const GET_ONE_QUERY = gql`
  query firm($id: ID!) {
    data: firm(id: $id) {
      id
      name
      slug
      isUnlimitedOfferFirm
      availableSession {
        admissionChargeInCredits
        yield {
          capping
          discoverySession
          offPeak {
            costInCredits
            intervals
          }
        }
      }
      remuneration {
        ...GymRemFields
      }
      promotions {
        startDate
        endDate
        percent
      }
      bookingFlows
    }
  }
  ${gymRemFields}
`;

export const GetOne = (params: any) => {
  const preparedVariables = {
    id: params.id,
  };
  return {
    variables: preparedVariables,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return { data: FormatFirm(resp.data.data) };
    },
  };
};
