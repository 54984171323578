import { withStyles } from '@material-ui/core/styles';
import { DAYS } from '../../utils';
import React from 'react';
import { ArrayInput, Labeled, SimpleFormIterator, TextInput } from 'react-admin';

const styles = {
  line: {
    marginLeft: '30px',
    width: '100%',
  },
  hideIteratorLabel: {
    '& p': {
      display: 'none',
    },
    '& li': {
      border: 'none',
      margin: '15px',
    },
  },
  openingTimeContainer: {
    border: '2px solid #ccc',
    borderRadius: '10px',
    padding: '15px',
    display: 'inline-flex',
    width: 'auto',
  },
  inlineBlockHalf: {
    margin: '15px',
    width: 'calc(50% - 30px)',
  },
  openingTimeBigContainer: {
    display: 'inline-flex',
    width: 'auto',
    margin: '15px',
  },
  input: {
    width: 'auto',
  },
};

const OpeningDay = ({ classes, translate, day, ...props }: any) => (
  <Labeled
    label={translate('resources.Gym.fields.openingTimes.days.' + day)}
    className={classes.openingTimeBigContainer}
  >
    <ArrayInput
      {...props}
      className={[classes.hideIteratorLabel, classes.openingTimeContainer]}
      source={'openingTimes.' + day}
      label=""
    >
      <SimpleFormIterator>
        <TextInput
          type="time"
          source="from"
          label="resources.Gym.fields.openingTimes.from"
          className={classes.input}
        />
        <TextInput
          type="time"
          source="to"
          label="resources.Gym.fields.openingTimes.to"
          className={classes.input}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </Labeled>
);

const OpeningTimesWithoutStyle = ({ classes, ...props }: any) => (
  <Labeled label="resources.Gym.fields.openingTimes.label" className={classes.line}>
    <div>
      {DAYS.map(day => (
        <OpeningDay {...props} classes={classes} day={day} key={day} />
      ))}
    </div>
  </Labeled>
);

// @ts-ignore
export default withStyles(styles)(OpeningTimesWithoutStyle);
