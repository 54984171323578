import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';
import { parse, HTMLElement } from 'node-html-parser';
import { DAYS } from '../../../utils';
import { formatData } from '../../gymRemunerations/dataProvider/utils';
import { BookingFlow } from './types';

export const FormatGym = (untouchedGym: any) => {
  const gym = cloneDeep(untouchedGym);
  if (gym.services) {
    gym.servicesIds = gym.services.map((service: any) => service.id);
  }

  if (gym.equipment) {
    gym.equipmentIds = gym.equipment.map((equipment: any) => equipment.id);
  }

  if (gym.infrastructure) {
    gym.infrastructureIds = gym.infrastructure.map(
      (infrastructure: any) => infrastructure.id
    );
  }

  if (gym.pictures && gym.mainPicture) {
    gym.pictures = gym.pictures.map((picture: any) => ({
      url: picture.url,
      isMain: gym.mainPicture === picture.url,
    }));
  }

  if (gym.openingTimes) {
    DAYS.map(
      (day) =>
        (gym.openingTimes[day] = gym.openingTimes[day]
          ? gym.openingTimes[day].map((openingTime: any) => {
              if (openingTime.from === '0:') {
                openingTime.from = '00:00';
              }
              if (openingTime.to === '0:') {
                openingTime.to = '00:00';
              }
              return openingTime;
            })
          : [])
    );
  }

  if (!gym.bookingFlow) {
    gym.bookingFlow = BookingFlow.OPEN_ACCESS_WITHOUT_ERP;
  }

  if (gym.reservationProcess) {
    gym.reservationProcess = gym.reservationProcess
      ? {
          general: gym.reservationProcess.general ? gym.reservationProcess.general : '',
          steps: gym.reservationProcess.steps
            ? gym.reservationProcess.steps.map((step: string) => ({
                data: step,
              }))
            : ['', '', ''],
          email: gym.reservationProcess.email || '',
          phoneNumber: gym.reservationProcess.phoneNumber || '',
          dynamicSchedule: gym.reservationProcess.dynamicSchedule || '',
        }
      : undefined;
  }

  // We need to flatten them for RA
  gym.corporationIDs = gym.corporations.map((corpo: { id: string }) => corpo.id);

  // in order to pass to reference input
  if (gym.mainActivity) gym.mainActivity = [gym.mainActivity];
  else gym.mainActivity = [];

  if (gym.remuneration) gym.remuneration = formatData(gym.remuneration);

  if (gym.firm) {
    gym.firm.hasPromotion = gym.firm?.promotions?.length > 0;
  }

  gym.isOverridingUnlimitedOfferFirm =
    gym.firm?.id && typeof gym.isUnlimitedOfferGym === 'boolean';

  return gym;
};

const formatSpanTags = (spanTags: HTMLElement[]) => {
  spanTags.forEach((tag) => {
    if (
      tag.rawAttributes &&
      tag.rawAttributes.style &&
      tag.rawAttributes.style.split('color: ')[1] &&
      tag.rawAttributes.style.split('color: ')[1].split(';')[0] &&
      !tag.rawAttributes.style.split('color: ')[1].split(';')[0].includes(';')
    ) {
      const color = tag.rawAttributes.style.split('color: ')[1].split(';')[0];
      // We need a font tag to have colors displayed on the app, so we change the <span> into a <font> tag
      tag.rawTagName = 'font';
      // rawAttrs is private, but is accessible with a ts-ignore...
      // @ts-ignore
      tag.rawAttrs = `color="${color}"`;
      const childSpanTags = tag.querySelectorAll('span');
      formatSpanTags(childSpanTags);
    }
  });
};

const formatPTags = (pTags: HTMLElement[]) => {
  pTags.forEach((tag) => {
    if (tag.rawAttributes && (tag.rawAttributes.style || tag.rawAttributes.dir)) {
      // rawAttrs is private, but is accessible with a ts-ignore...
      // @ts-ignore
      tag.rawAttrs = '';
      const childPTags = tag.querySelectorAll('p');
      formatSpanTags(childPTags);
    }
  });
};

export const formatDescription = (description: string) => {
  const root = parse(description, {
    // @ts-ignore
    style: true,
  }) as HTMLElement;
  const spanTags = root.querySelectorAll('span');
  const pTags = root.querySelectorAll('p');
  formatSpanTags(spanTags);
  formatPTags(pTags);
  return root.toString();
};

export const formatOpeningTimes = (openingTimes: any) => {
  const newOpeningTimes: any = {};
  DAYS.map(
    (day) =>
      (newOpeningTimes[day] = openingTimes[day]
        ? openingTimes[day]
            .filter((openingTime: any) => openingTime.from && openingTime.to)
            .map((openingTime: any) => ({
              from: openingTime.from,
              to: openingTime.to,
            }))
        : [])
  );
  return newOpeningTimes;
};

export const GymFragment = gql`
  fragment GymFields on Gym {
    name
    slug
    id
    country
    countryName
    locale
    zipCode
    active
    temporarilyClosed
    latitude
    longitude
    streetName
    streetNumber
    city
    emailPreferences {
      isEnabled
      event
      emails
    }
    biOpsHooks {
      isEnabled
      event
      url
    }
    services {
      id
    }
    equipment {
      id
    }
    infrastructure {
      id
    }
    activitiesIds
    mainActivity
    description
    multilingualDescriptions {
      fr
      en
    }
    multilingualShortDescriptions {
      fr
      en
    }
    availableSession {
      admissionCharge {
        credits
        commission
        isGymLevel
      }
      yield {
        capping {
          capping
          skipFirmCapping
        }
        discoverySession
        offPeak {
          costInCredits
          intervals
          skipFirm
        }
      }
      discoveryPass
    }
    openingTimes {
      monday {
        from
        to
      }
      tuesday {
        from
        to
      }
      wednesday {
        from
        to
      }
      thursday {
        from
        to
      }
      friday {
        from
        to
      }
      saturday {
        from
        to
      }
      sunday {
        from
        to
      }
    }
    active
    pictures(amount: 100, size: LARGE, fallback: true) {
      url
    }
    mainPicture(size: LARGE, fallback: true)
    firm {
      id
      name
      availableSession {
        admissionChargeInCredits
      }
      promotions {
        startDate
        endDate
        percent
      }
    }
    bookingFlow
    cancellationTimeInMinutes
    indexSEO
    erp {
      id
    }
    reservationProcess {
      general
      steps
      email
      phoneNumber
      dynamicSchedule
    }
    isRemunerationAtFirmLevel
    punchRadius
    unlimitedJokers
    showcaseStartDate
    showcaseEndDate
    corporations {
      id
    }
    accessDescription
    area
    promotions {
      startDate
      endDate
      percent
    }
    hasLimitedSession
    externalID
    payWithPassCode
    automaticCheckin
    lastOpeningDate
    usePublicPriceForPayment
    poolLength
    website
    instagramUsername
    isUnlimitedOfferGym
    isOnline
    availableOnGymlib
    egymGymId
    gymUUID
  }
`;
