export const CHANGE_IS_DEV = 'CHANGE_IS_DEV';

export interface Action {
  type: 'CHANGE_IS_DEV';
  payload: boolean;
}

export const changeIsDev: (isDev: boolean) => Action = (isDev: boolean) => ({
  type: CHANGE_IS_DEV,
  payload: isDev,
});
