import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const ActivityCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list" initialValues={{ some: 'value' }}>
      <TextInput source="slug" validate={required('The slug is required')} />
      <TextInput
        label="resources.TranslatedContents.frenchName"
        source="name.fr"
        validate={required('The French Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.englishName"
        source="name.en"
        validate={required('The English Translation is required')}
      />
      <TextInput
        label="resources.TranslatedContents.dutchName"
        source="name.nl"
        validate={required('The Dutch Translation is required')}
      />
      <TextInput
        source="activityID"
        label="WP ID"
        validate={required('The ID is required')}
      />
    </SimpleForm>
  </Create>
);
