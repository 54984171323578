import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allAudits(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AuditFilter
  ) {
    items: allAudits(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      createdAt
      sourceID
      sourceType
      action
      message
    }
    total: _allAuditsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.map((audit: any, index: number) => {
      return {
        id: index,
        ...audit,
      };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    variables: params,
    query: GET_LIST_QUERY,
  };
};

export const GetMany = (params: any) => {
  return {
    variables: params,
    query: GET_LIST_QUERY,
  };
};

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter['targetID'] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
