export const possibleTypes = () =>
  fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'apollo-require-preflight': 'true' },
    body: JSON.stringify({
      variables: {},
      query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
    }),
  })
    .then((result) => result.json())
    .then((result) => {
      const possibleTypes: Record<string, string[]> = {};

      result.data.__schema.types.forEach(
        (supertype: {
          kind: string;
          name: string;
          possibleTypes: { name: string }[];
        }) => {
          if (supertype.possibleTypes) {
            possibleTypes[supertype.name] = supertype.possibleTypes.map(
              (subtype) => subtype.name
            );
          }
        }
      );

      return possibleTypes;
    });
