import infrastructureProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'GymInfrastructure',
  dataProvider: {
    getOneName: 'gymInfrastructure',
    useCustomDP: true,
    customDataProvider: infrastructureProvider,
  },
} as IResourceExport;
