import { GetList, GetMany } from './Many';
import { Delete } from './Delete';
import { Create } from './Create';
import { GetOne } from './GetOne';

export default {
  GET_LIST: GetList,
  GET_MANY: GetMany,
  DELETE: Delete,
  CREATE: Create,
  GET_ONE: GetOne,
};
