import gql from 'graphql-tag';

const CREATE_PRODUCT_MUTATION = gql`
  mutation BO_CREATE_PRODUCT_OPERATION($input: BO_CreateProductInput!) {
    BO_CreateProduct(input: $input) {
      data: product {
        id
        credits
        name {
          fr
          en
          nl
        }
        price
        active
        type
        prices {
          id
          price
          group {
            id
            name {
              fr
              nl
              en
            }
          }
        }
      }
    }
  }
`;

export const Create = () => {
  return (params: { data: any }) => {
    for (const group of params.data.productGroupPrices) {
      group.productGroupID = group.id;
      delete group.id;
    }
    return {
      query: CREATE_PRODUCT_MUTATION,
      variables: {
        input: { ...params.data },
      },
      parseResponse: (response: any) => response.data.BO_CreateProduct,
    };
  };
};
