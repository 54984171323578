import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  Labeled,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { useForm } from 'react-final-form';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
};

const MindbodyForm = ({ classes }: any) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [mindbodyMetadata, setMindbodyMetadata] = useState<IMindbodyMetadata>();
  const [activationLink, setActivationLink] = useState('');
  const [mboError, setMboError] = useState('');

  form.change('name', 'MINDBODY');

  function getMindbodyMetadata(siteID: string) {
    dataProvider
      .getOne('MindbodyMetadata', { id: siteID })
      .then(({ data }: { data: IMindbodyMetadata }) => {
        form.change('mandatoryFields', data.mandatoryFields);
        setMindbodyMetadata(data);
        setMboError('');
      })
      .catch(() => {
        setMboError(translate('resources.Erp.errors.permissionDenied'));
        setMindbodyMetadata(undefined);
      });
  }

  function getActivationLink(siteID: string) {
    dataProvider
      .GET_ACTIVATION_LINK('MindbodyMetadata', { siteID })
      .then(({ data }: { data: { code: string } }) => {
        setActivationLink(data.code);
      })
      .catch((e: any) => {
        console.log('********ERROR', e);
      });
  }

  const checkPrice = (value: string) => {
    const choice = mindbodyMetadata!.services.find(
      (service: any) => service.id === value
    );
    return choice && choice.price ? 'resources.Erp.errors.wrongPrice' : undefined;
  };

  return (
    <>
      <TextInput
        source="siteID"
        label="resources.Erp.fields.siteID"
        validation={[required()]}
      />
      {mboError && <p className={classes.error}>{mboError}</p>}
      <FormDataConsumer>
        {({ formData }: any) => (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              onClick={() => getMindbodyMetadata(formData.siteID)}
            >
              {translate('ra.action.search')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => getActivationLink(formData.siteID)}
            >
              get activation link
            </Button>
            <br />
            {activationLink && (
              <a href={activationLink} target="_blank" rel="noopener noreferrer">
                {activationLink}
              </a>
            )}
          </React.Fragment>
        )}
      </FormDataConsumer>

      {mindbodyMetadata && (
        <>
          <SelectInput
            source="locationID"
            label="resources.Erp.fields.location"
            choices={mindbodyMetadata.locations}
            allowEmpty
          />
          <SelectInput
            source="paymentMethodID"
            label="resources.Erp.fields.paymentMethod"
            choices={mindbodyMetadata.paymentMethods}
            validate={[required()]}
          />
          <Labeled label="resources.Erp.fields.mandatoryFields">
            <div>
              {mindbodyMetadata.mandatoryFields.map((item, i) => (
                <Chip label={item} key={i} />
              ))}
            </div>
          </Labeled>

          <FormDataConsumer>
            {({ formData }: any) => (
              <Button
                variant="contained"
                color="primary"
                onClick={() => getActivationLink(formData.siteID)}
              >
                get activation link
              </Button>
            )}
          </FormDataConsumer>

          {activationLink && (
            <a href={activationLink} target="_blank" rel="noopener noreferrer">
              {activationLink}
            </a>
          )}

          <ArrayInput
            source="erpAvailableSessions"
            label="resources.Erp.fields.erpPassTypes.label"
          >
            <SimpleFormIterator>
              <SelectInput
                source="erpPassTypeID"
                label="resources.Erp.fields.erpPassTypes.name"
                choices={mindbodyMetadata.services}
                validate={[required(), checkPrice]}
              />
              <BooleanInput
                source="isForBooking"
                label="resources.Erp.fields.erpPassTypes.isForBooking"
                initialValue={false}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <div>
            <PlanningOffsetComponent />
            <TextInput
              source="mindbodyConfiguration.referredBy"
              label="Referred By"
              placeholder="Gymlib"
            ></TextInput>
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(MindbodyForm);

interface IMindbodyMetadata {
  id: string;
  mandatoryFields: string[];
  services: {
    id: string;
    name: string;
    price: number;
  }[];
  paymentMethods: {
    id: string;
    name: string;
  }[];
  locations: {
    id: string;
    name: string;
  }[];
}
