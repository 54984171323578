import React, { Fragment } from 'react';
import {
  Datagrid,
  TextField,
  List,
  Filter,
  TextInput,
  BulkDeleteButton,
} from 'react-admin';

const GymManagerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="q" source="q" alwaysOn />
  </Filter>
);

const GymManagerActionButtons = (props: any) => (
  <Fragment>
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
);

export const GymManagerList = (props: any) => (
  <List
    {...props}
    filters={<GymManagerFilter />}
    bulkActionButtons={<GymManagerActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="email" />
    </Datagrid>
  </List>
);
