import gql from 'graphql-tag';
import { slugify } from '../../../utils';

const CREATE_SERVICE_MUTATION = gql`
  mutation createService($input: CreateServiceInput!) {
    data: createService(input: $input) {
      id
      slug
      serviceID
      name {
        en
        fr
        nl
      }
      active
      iconName
    }
  }
`;

export const Create = (params: any) => {
  return {
    variables: {
      input: {
        slug: slugify(params.data.slug),
        serviceID: params.data.serviceID,
        active: true,
        name: {
          en: params.data.name.en,
          fr: params.data.name.fr,
          nl: params.data.name.nl,
        },
        iconName: params.data.iconName,
      },
    },
    query: CREATE_SERVICE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: { ...resp.data.data },
      };
    },
  };
};
