import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteGym($id: ID!) {
    deleteGym(input: { gymID: $id }) {
      data: gym {
        id
      }
    }
  }
`;

export const Delete = (params: any) => {
  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.deleteGym) return { data: {} };

    return { data: resp.data.deleteGym.data };
  };

  console.log('[DELETE][GYM]', params.id);

  return {
    variables: {
      id: params.id,
    },
    parseResponse,
    query: DELETE_MUTATION,
  };
};
