import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteGymManager($id: ID!) {
    deleteGymManager(input: { id: $id }) {
      data: status
    }
  }
`;

export const Delete = (params: any) => {
  const parseResponse = () => {
    return { data: {} };
  };

  return {
    variables: {
      id: params.id,
    },
    parseResponse,
    query: DELETE_MUTATION,
  };
};
