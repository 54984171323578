import { IResourceExport } from '../../@types/dataProvider';
import CategoryIcon from '@material-ui/icons/Category';
import productProvider from './dataProvider';
import { CreditPackList } from './CreditPackList';
import { CreditPackEdit } from './CreditPackEdit';
import { CreditPackCreate } from './CreditPackCreate';

export default {
  name: 'CreditPacks',
  icon: CategoryIcon,
  resources: {
    list: CreditPackList,
    edit: CreditPackEdit,
    create: CreditPackCreate,
  },
  dataProvider: {
    getOneName: 'BO_Product',
    providerWithIntrospection: productProvider,
  },
} as IResourceExport;
