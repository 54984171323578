import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetList, GetMany } from './Many';
import { Update } from './Update';

export default (_: any): AllPossibleRequestResolver => {
  return {
    GET_LIST: GetList(),
    GET_MANY: GetMany(),
    UPDATE: Update(),
  };
};
