import React from 'react';
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  Pagination,
  ReferenceArrayInput,
  ReferenceManyField,
  required,
  showNotification,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { connect } from 'react-redux';
import { translate } from 'ra-core';
import FileCopy from '@material-ui/icons/FileCopy';
import CreatePasswordResetAction from './CreatePasswordResetAction';
import { DisplayFromComponent } from './DisplayFromComponent';

const GymManagerTitle = ({ record, translate }: any) => (
  <span>
    {translate('resources.GymManager.editTitle', { email: record ? record.email : '' })}
  </span>
);

const GymManagerActions = ({ ...props }: any) => (
  <TopToolbar>
    <CreatePasswordResetAction {...props} />
  </TopToolbar>
);

const GymManagerEditWithoutTranslate = ({ showNotification, ...props }: any) => (
  <Edit
    {...props}
    undoable={false}
    title={<GymManagerTitle translate={props.translate} />}
    actions={<GymManagerActions {...props} />}
  >
    <SimpleForm>
      <TextInput disabled source="email" />
      <ReferenceArrayInput
        {...props}
        source="gymIDs"
        reference="Gym"
        perPage={30}
        sort={{ field: `name`, order: 'ASC' }}
        validate={required('Gym selection is mandatory')}
      >
        <AutocompleteArrayInput
          {...props}
          debounce={1000}
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceArrayInput>
      <ReferenceManyField
        pagination={<Pagination />}
        label="resources.GymManager.fields.passwordReset"
        reference="PasswordReset"
        target="GymManager"
        source="id"
        sort={{ field: 'expiredAt', order: 'DESC' }}
        perPage={10}
      >
        <Datagrid
          rowClick={function (id: string, path: string, currentRecord: any) {
            navigator.clipboard.writeText(currentRecord.url).then(
              function () {
                showNotification("Lien d'inscription Copié dans votre presse-papier");
              },
              function () {
                showNotification(
                  'Erreur: Impossible de copier dans votre presse-papier',
                  'warning'
                );
              }
            );
          }}
        >
          <FileCopy />
          <TextField source="url" />
          <TextField source="status" />
          <DateField source="expiredAt" />
        </Datagrid>
      </ReferenceManyField>
      <FormDataConsumer>
        {({ formData }: any) => (
          <DisplayFromComponent formData={formData} translate={props.translate} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const GymManagerEdit = connect(null, { showNotification })(
  translate(GymManagerEditWithoutTranslate)
);
