import gql from "graphql-tag";

const GET_ACTIVATION_LINK_QUERY = gql`
  query getActivationLink($siteID: String!) {
    data: getActivationLink(siteID: $siteID) {
        id
        code
    }
  }
`

export const GetActivationLink = (params: any) => {
    return {
      variables: { siteID: params.siteID },
      query: GET_ACTIVATION_LINK_QUERY,
      parseResponse: (resp: any) => {
        return { data: resp?.data?.data || {} };
      },
    };
  };