import * as _ from 'lodash';
import imageCompression from 'browser-image-compression';

interface IFileField {
  type: string;
  resource: string;
  field: string;
}

const fileFields: IFileField[] = [
  {
    type: 'UPDATE',
    resource: 'CorporateCompany',
    field: 'logo',
  },
  {
    type: 'CREATE',
    resource: 'CorporateCompany',
    field: 'logo',
  },
  {
    type: 'UPDATE',
    resource: 'Gym',
    field: 'addPictures',
  },
  {
    type: 'CREATE',
    resource: 'Gym',
    field: 'addPictures',
  },
  {
    type: 'UPDATE',
    resource: 'PartnerEvent',
    field: 'picture',
  },
  {
    type: 'CREATE',
    resource: 'PartnerEvent',
    field: 'picture',
  },
];

const convertFileToBase64 = (file: any) =>
  new Promise(async (resolve, reject) => {
    const mimeType = file.type.split('image/');
    if (mimeType.length === 2) {
      file = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });
    }
    const reader = new FileReader();
    reader.readAsDataURL(file.hasBlob ? file.blob : file);
    reader.onload = () => {
      resolve({
        type: file.type,
        name: file.name,
        size: file.hasBlob ? file.blob.size : file.size,
        body: reader.result as string | ArrayBuffer,
      });
    };
    reader.onerror = reject;
  });

const checkFileAndConvert = async (data: any, fileToConvert: any) => {
  if (_.get(data, fileToConvert) && _.get(data, fileToConvert).rawFile instanceof File) {
    const file = _.get(data, fileToConvert).rawFile;
    if (_.get(data, fileToConvert).croppedFile instanceof Blob) {
      file.blob = _.get(data, fileToConvert).croppedFile;
      file.hasBlob = true;
    }
    return await convertFileToBase64(file);
  }
  return null;
};

const addUploadFeature = (requestHandler: any) => async (
  type: any,
  resource: any,
  params: any
) => {
  const newParams = { ...params };
  for (let i = 0; i < fileFields.length; i++) {
    if (type === fileFields[i].type && resource === fileFields[i].resource) {
      if (fileFields[i].field.split('.').includes('$')) {
        const arrayDeepth = fileFields[i].field.split('.').indexOf('$');
        const pathToArray = fileFields[i].field
          .split('.')
          .slice(0, arrayDeepth)
          .join('.');
        const arrayLength = (_.get(params.data, pathToArray) || []).length;
        if (arrayLength > 0) {
          for (let j = 0; j < arrayLength; j++) {
            const transformedFile = await checkFileAndConvert(
              params.data,
              fileFields[i].field.replace('$', j.toString())
            );
            if (transformedFile) {
              _.set(
                newParams,
                'data.' + fileFields[i].field.replace('$', j.toString()),
                transformedFile
              );
            }
          }
        }
      } else {
        const file = _.get(params.data, fileFields[i].field);
        if (file instanceof Array) {
          let count = 0;
          for (let toTransform of file) {
            if (toTransform.croppedFile instanceof Blob) {
              toTransform.blob = toTransform.croppedFile;
              toTransform.hasBlob = true;
            }
            _.set(
              newParams,
              `data.${fileFields[i].field}.${count}`,
              await convertFileToBase64(toTransform.rawFile)
            );
            count++;
          }
        } else {
          const transformedFile = await checkFileAndConvert(
            params.data,
            fileFields[i].field
          );
          if (transformedFile)
            _.set(newParams, `data.${fileFields[i].field}`, transformedFile);
        }
      }
    }
  }
  return requestHandler(type, resource, newParams);
};

export default addUploadFeature;
