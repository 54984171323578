import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allCompanyAudits(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CompanyAuditFilter
  ) {
    items: allCompanyAudits(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      action
      message
      createdAt
      fromHrManager
      fromUser
      fromIntegration
      index
      whitelistSize
    }
    total: _allCompanyAuditsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {
      companyID: params.id,
      action: params.filter.action,
      message: params.filter.message,
    },
  };

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: (resp: any) => {
      return { data: resp.data.items, total: resp.data.total.count };
    },
  };
};
