import React from 'react';

import { Datagrid, Filter, List, translate } from 'react-admin';

import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  TextInput,
  Link,
} from 'react-admin';
import { DeletePendingAction } from './DeletePendingAction';

const SponsorshipFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="q" source="q" alwaysOn resettable />
    <TextInput source="sponsoredEmail" allowEmpty />
  </Filter>
);

const DisplaySponsored = ({ record, addLabel = true, ...props }: any) => {
  if (record.sponsoredID)
    return (
      <Link
        to={`/User/${record.sponsoredID}`}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <span>{record.sponsoredEmail}</span>
      </Link>
    );
  return <span>{record.sponsoredEmail}</span>;
};

export const SponsorshipListWithoutTranslation = ({ translate, ...props }: any) => (
  <List
    {...props}
    bulkActionButtons={<DeletePendingAction />}
    filters={<SponsorshipFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <ReferenceField source="sponsorID" reference="User">
        <TextField source="email" />
      </ReferenceField>
      <DisplaySponsored source="sponsored" />
      {/* <FormDataConsumer label="resources.Sponsorship.fields.sponsored">
        {({ formData, ...rest }: any) => {
          debugger;
          return (
            // formData && formData.sponsoredID ? (
            //   <ReferenceField {...rest} source="sponsoredID" reference="User">
            //     <TextField source="email" />
            //   </ReferenceField>
            // ) : (
            //   <TextField {...rest} source="sponsoredEmail" />
            // )
            <TextField {...rest} source="sponsoredEmail" />
          );
        }}
      </FormDataConsumer> */}
      <DateField source="createdAt" />
      <FunctionField
        source="status"
        render={(record: any) =>
          record &&
          record.status &&
          translate(`resources.Sponsorship.fields.statusValue.${record.status}`)
        }
      />
      <FunctionField
        source="sponsorshipType"
        render={(record: any) =>
          record &&
          record.sponsorshipType &&
          translate(`resources.Sponsorship.fields.type.${record.sponsorshipType}`)
        }
      />
    </Datagrid>
  </List>
);

export const SponsorshipList = translate(SponsorshipListWithoutTranslation);
