import gql from 'graphql-tag';

const BO_CREATE_CHALLENGE_BULK_MUTATION = gql`
  mutation BO_CreateChallengeBulk($input: BO_CreateChallengeBulkInput!) {
    data: BO_CreateChallengeBulk(input: $input) {
      status
    }
  }
`;

export const Create = (params: any) => {
  return {
    variables: {
      input: {
        templateID: params.data.input.templateID,
        publishDate: params.data.input.publishDate,
        companySlugs: params.data.input.companySlugs,
      },
    },
    query: BO_CREATE_CHALLENGE_BULK_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: { ...resp.data.data },
      };
    },
  };
};
