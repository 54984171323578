import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const GymActivities = ({ classes, ...props }: any) => {
  const form = useForm();
  let mainActivity: string[] = props.record.mainActivity ? props.record.mainActivity : [];

  //Populate main activity if it's empty and the gym has list of activities. See https://egym.atlassian.net/browse/WPAC-26
  useEffect(() => {
    if (
      !mainActivity.length &&
      props.record &&
      props.record.activitiesIds &&
      !!props.record.activitiesIds.length
    ) {
      form.change('mainActivity', [props.record.activitiesIds[0]]);
    }
  }, [form, mainActivity.length, props.record]);

  const handleMainActivityChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) mainActivity = [];
    if (value.length > 1) {
      mainActivity = [value[value.length - 1]];
      form.change('mainActivity', mainActivity);
    }
  };

  const handleActivitiesChange = (e: any) => {
    if (e.target.value.length && !mainActivity.length) {
      mainActivity = [e.target.value[0]];
      form.change('mainActivity', mainActivity);
    }
  };

  return (
    <>
      <ReferenceArrayInput
        className={classes.gymPropertiesContainer}
        source="mainActivity"
        reference="Activity"
        perPage={1000}
        sort={{ field: `name.${props.locale || 'fr'}`, order: 'ASC' }}
        allowEmpty={false}
        label="resources.Gym.fields.mainActivity.label"
        onChange={(e: any) => handleMainActivityChange(e)}
      >
        <SelectArrayInput optionText={`name.${props.locale || 'fr'}`} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        {...props}
        className={classes.gymPropertiesContainer}
        source="activitiesIds"
        reference="Activity"
        perPage={1000}
        sort={{ field: `name.${props.locale || 'fr'}`, order: 'ASC' }}
        allowEmpty={false}
        label="resources.Gym.fields.activitiesIds.label"
        onChange={(e: any) => handleActivitiesChange(e)}
      >
        <SelectArrayInput optionText={`name.${props.locale || 'fr'}`} />
      </ReferenceArrayInput>
    </>
  );
};

export default GymActivities;
