import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation createGymManager($email: String!, $gymIDs: [ID!]!, $displayFrom: DateTime) {
    data: createGymManager(
      input: { email: $email, gymIDs: $gymIDs, displayFrom: $displayFrom }
    ) {
      id
      email
      gyms {
        id
      }
      status
      displayFrom
    }
  }
`;

export const Create = (params: any) => {
  console.log('[CREATE][GYM_MANAGER]', params);

  return {
    variables: { ...params.data, gymIDs: params.data.gymIDs },
    query: CREATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          gymIDs: resp.data.data.gyms.map((gym: any) => gym.id),
        },
      };
    },
  };
};
