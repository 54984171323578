import React, { useState } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  downloadCSV,
  useTranslate,
} from 'react-admin';

import { unparse as convertToCSV } from 'papaparse';
import { BooleanField } from 'react-admin';

import { DeleteGymRemuneration } from './shared/DeleteGymRemuneration';

const exporter = (gymRemunerations: any[]) => {
  const cleaned = gymRemunerations.map((rem) => ({
    sourceID: rem.sourceID,
    sourceType: rem.sourceType,
    type: rem.type,
    iban: rem.iban || '',
    currentMonthPaidPasses: rem.metrics.currentMonthPaidPasses,
    currentMonthBalance: rem.metrics.currentMonthBalance / 100,
  }));

  const csv = convertToCSV({
    data: cleaned,
    fields: [
      'sourceID',
      'sourceType',
      'type',
      'iban',
      'currentMonthPaidPasses',
      'currentMonthBalance',
    ],
  });
  downloadCSV(csv, 'GymRemuneration');
};

const GymRemunerationFilter = (props: any) => {
  const [sourceType, setSourceType] = useState(
    props && props.filterValues && props.filterValues.sourceType
      ? props.filterValues.sourceType.toLowerCase()
      : 'gym'
  );

  return (
    <Filter {...props}>
      <SelectInput
        source="sourceType"
        alwaysOn
        resettable={false}
        choices={[
          { id: 'FIRM', name: 'resources.GymRemuneration.sourceType.firm' },
          { id: 'GYM', name: 'resources.GymRemuneration.sourceType.gym' },
        ]}
        onChange={function ({ target: { value } }: any) {
          setSourceType(value.toLowerCase());
        }}
      />
      <ReferenceInput
        label="resources.Gym.fields.slug.label"
        source="sourceID"
        reference={sourceType.charAt(0).toUpperCase() + sourceType.slice(1)}
        resettable
        alwaysOn
      >
        <AutocompleteInput optionText="slug" />
      </ReferenceInput>
    </Filter>
  );
};

const DisplayReference = (props: any) => {
  const record = props.record;
  if (!record || !record.sourceType) return null;

  if (record.sourceType === 'GYM')
    return (
      <ReferenceField
        {...props}
        label="resources.Gym.fields.slug.label"
        source="sourceID"
        reference="Gym"
        sortable={false}
      >
        <TextField source="slug" />
      </ReferenceField>
    );
  else
    return (
      <ReferenceField
        {...props}
        label="resources.Gym.fields.slug.label"
        source="sourceID"
        reference="Firm"
        sortable={false}
      >
        <TextField source="slug" />
      </ReferenceField>
    );
};

DisplayReference.defaultProps = { label: 'Slug' };

export const GymRemunerationList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      filters={<GymRemunerationFilter />}
      undoable="false"
      bulkActionButtons={false}
      filterDefaultValues={{ active: true }}
      exporter={exporter}
      sort={{ field: 'effectiveAt', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <DisplayReference />
        <FunctionField
          source="sourceType"
          sortable={false}
          render={(record: any) =>
            record &&
            record.sourceType &&
            translate(
              'resources.GymRemuneration.sourceType.' + record.sourceType.toLowerCase()
            )
          }
        />
        <FunctionField
          source="type"
          render={(record: any) =>
            record &&
            record.type &&
            translate('resources.GymRemuneration.type.' + record.type.toLowerCase())
          }
          sortable={false}
        />
        <DateField source="effectiveAt" />
        <BooleanField source="isEffective" />
        <NumberField source="metrics.currentMonthPaidPasses" sortable={false} />
        <FunctionField
          source="metrics.currentMonthBalance"
          render={(record: any) => {
            return (
              record &&
              record.metrics &&
              record.metrics.currentMonthBalance && (
                <span>{record.metrics.currentMonthBalance / 100} €</span>
              )
            );
          }}
          sortable={false}
        />
        <DeleteGymRemuneration />
      </Datagrid>
    </List>
  );
};
