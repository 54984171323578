import gql from 'graphql-tag';

const UPDATE_MUTATION = gql`
  mutation updateHrManager($id: ID!, $companyIDs: [ID!]!) {
    data: updateHrManager(input: { id: $id, companyIDs: $companyIDs }) {
      id
      email
      companies {
        id
      }
      status
    }
  }
`;

export const Update = (params: any) => {
  console.log('[UPDATE][HR_MANAGER]', params);

  return {
    variables: { id: params.data.id, companyIDs: params.data.companyIDs },
    query: UPDATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          companyIDs: resp.data.data.companies.map((company: any) => company.id),
        },
      };
    },
  };
};
