import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslate, useDataProvider } from 'ra-core';
import React, { useState } from 'react';
import { findUniqueEmails } from '../../../utils';
import MultiBlock, {
  ChildrenParams,
} from '../../../utils/block/MultipleUserBlockStepper';
import CSVFile from '../../../utils/csv/CSVFile';
import { useForm } from 'react-final-form';
import { useNotify } from 'ra-core';
import BlockUsersAction from '../BlockUsersAction';

const styles = {
  explanation: {
    'white-space': 'pre',
  },
};

interface Props {
  classes: any;
  record: any;
}

interface IBlockUser {
  id: string;
  status: string;
  email: string;
  subscriptionStatus: string;
}

const UpgradeWhitelist = ({ record, classes }: Props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  // Modal state
  const [open, setOpen] = useState(false);

  // Old whitelist
  const [oldEmailCount, setOldEmailCount] = useState<number>(
    record && record.validEmployeeEmails ? record.validEmployeeEmails.length : 0
  );

  // Email handling
  // emails: emails from CSV
  const [emails, setEmails] = useState<string[]>([]);

  const [emailFindingLoading, setEmailFindingLoading] = useState(true);

  // emails found in the db
  const [cleanedUsers, setCleanedUsers] = useState<IBlockUser[]>([]);
  const [userTypesCount, setUserTypesCount] = useState({
    currentSubscribed: 0,
    noSub: 0,
  });

  const form = useForm();
  const notify = useNotify();

  const reset = (activeStep: number) => {
    if (activeStep === 3) {
      form.change('validEmployeeEmails', emails);

      notify('resources.CorporateCompany.notifications.whitelistUpgraded', 'info');
    }

    setOpen(false);
    setEmails([]);
    setEmailFindingLoading(true);
    setCleanedUsers([]);
    setUserTypesCount({
      currentSubscribed: 0,
      noSub: 0,
    });
  };

  const handleUpload = (setUsers: ChildrenParams['setUsers']) => (
    content: Array<any>
  ) => {
    if (!content || !content.length) return;

    const newEmails = findUniqueEmails(content);

    if (!newEmails.length) return;

    setEmails(newEmails);
    dataProvider
      .getList('User', {
        pagination: {
          page: 0,
          perPage: 1000,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: {
          countUsers: true,
          corporateCompany: { id: record.id },
        },
      })
      .then(({ data }) => {
        const correctData = data as IBlockUser[];
        setOldEmailCount(data.length);
        const missingEmails = correctData.filter(
          (user: IBlockUser) => newEmails.indexOf(user.email.toLowerCase()) === -1
        );

        console.log(`Missing ${missingEmails.length} emails`);

        dataProvider
          .getList(
            'User',
            {
              pagination: {
                page: 0,
                perPage: 1000,
              },
              sort: {
                field: 'createdAt',
                order: 'DESC',
              },
              filter: {
                countUsers: true,
                emails: missingEmails.map((user) => user.email),
              },
            },
            {
              undoable: false,
            }
          )
          .then(({ data }) => {
            const correctData = data as IBlockUser[];
            const cleanedUser = correctData.filter(
              (user) =>
                user.subscriptionStatus !== 'BLOCKED' &&
                user.subscriptionStatus !== 'TO_BE_BLOCKED'
            );
            setCleanedUsers(cleanedUser);
            setUsers(cleanedUser);
            setUserTypesCount({
              currentSubscribed: correctData.filter(
                (user) =>
                  user.subscriptionStatus === 'PAUSED' ||
                  user.subscriptionStatus === 'ACTIVE_B2B_NORMAL' ||
                  user.subscriptionStatus === 'ACTIVE_B2B_SPONSORSHIP' ||
                  user.subscriptionStatus === 'COVID_BREAK'
              ).length,
              noSub: correctData.filter(
                (user) =>
                  user.subscriptionStatus === 'INACTIVE_B2B_NORMAL' ||
                  user.subscriptionStatus === 'INACTIVE_B2B_SPONSORSHIP'
              ).length,
            });
            setEmailFindingLoading(false);
          });
      });
  };

  const afterBlock = async (emails: string[]) => {
    const res = fetch(
      'https://cpbwk8a4ug.execute-api.eu-west-3.amazonaws.com/Prod/whitelisttellhubspot',
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: JSON.stringify({ mails: emails }),
      }
    );

    console.log(res);
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <BlockUsersAction record={record} />
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          {translate('resources.CorporateCompany.actions.upgradeWhitelist.name')}
        </Button>
      </div>

      <MultiBlock
        open={open}
        onClose={(activeStep: number) => reset(activeStep)}
        onFinished={afterBlock}
      >
        {({ setUsers }) => {
          return (
            <div>
              <CSVFile handleCSVResult={handleUpload(setUsers)} />
              {!emailFindingLoading &&
                (cleanedUsers.length > 0 ? (
                  <div className={classes.explanation}>
                    {translate(
                      'resources.CorporateCompany.actions.upgradeWhitelist.summary',
                      {
                        toBlock: cleanedUsers.length,
                        totalUserCount: oldEmailCount,
                        subscribed: userTypesCount.currentSubscribed,
                        blockUsers: cleanedUsers.map((user) => user.email).join('\n'),
                      }
                    )}
                  </div>
                ) : (
                  <div className={classes.explanation}>
                    {translate(
                      'resources.CorporateCompany.actions.upgradeWhitelist.notFound'
                    )}
                  </div>
                ))}
            </div>
          );
        }}
      </MultiBlock>
    </div>
  );
};

export default withStyles(styles)(UpgradeWhitelist);
