import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import MultiBlock from '../../../utils/block/MultipleUsersMigrateStepper';

const styles = {
  explanation: {
    'white-space': 'pre',
  },
};

interface Props {
  classes: any;
  record: any;
}

const MigrateUsers = ({ record, classes }: Props) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  return (
    <div style={{ marginLeft: '.5rem' }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.CorporateCompany.actions.migrateUsers.name')}
      </Button>

      <MultiBlock
        open={open}
        setOpen={setOpen}
        recordID={record?.id}
        onClose={() => setOpen(false)}
      ></MultiBlock>
    </div>
  );
};

export default withStyles(styles)(MigrateUsers);
