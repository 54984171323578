import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allPassAudits(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PassAuditFilter
  ) {
    items: allPassAudits(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      appType
      userID
      passStatus
      message
      createdAt
    }
    total: _allPassAuditsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;
export const GetManyReference = (params: any) => {
  const preparedVariables = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: { passID: params.id },
  };

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: (resp: any) => {
      return {
        data: resp?.data?.items || [],
        total: resp?.data?.total.count || 0,
      };
    },
  };
};
