export const editAndCreate = {
  inlineBlock: {
    margin: '15px',
    flex: '1',
  },
  line: {
    display: 'flex',
    width: 'calc(100% - 30px)',
    margin: '15px',
  },
  helpBlock: {
    maxWidth: 500,
  },
  hideIteratorLabel: {
    '& p': {
      display: 'none',
    },
  },
  inputsGrid: {
    '& section': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      maxWidth: '100%',
      padding: '1rem 0',
    },
    '& .ra-input': {
      maxWidth: '50%',
      flex: '1 1 50%',
    },
    '& .ra-input:nth-child(1)': { order: 1, boxSizing: 'border-box', paddingRight: '1rem' },
    '& .ra-input:nth-child(2)': { order: 3, boxSizing: 'border-box', paddingRight: '1rem' },
    '& .ra-input:nth-child(3)': { order: 5, boxSizing: 'border-box', paddingRight: '1rem' },
    '& .ra-input:nth-child(4)': { order: 2 },
    '& .ra-input:nth-child(5)': { order: 4 },
    '& .ra-input *': {
      width: '100%',
    }
  }
};
