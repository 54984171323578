import React, { cloneElement, useEffect, useState } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Filter,
  FunctionField,
  List,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
} from 'react-admin';
import GymShowcaseAction from './GymShowcase';
import { SelectInput } from 'ra-ui-materialui';

const GymFilter = (props: any) => {
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('GymCountry').then((res: any) => {
      if (res && res.data) setCountryChoices(res.data);
    });
  }, [dataProvider]);

  const [countryChoices, setCountryChoices] = useState([]);

  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn resettable />
      <BooleanInput
        label="resources.Gym.filters.showcasedGyms"
        source="isShowcased"
        defaultValue={true}
        allowEmpty
      />
      <SelectInput
        label="resources.Gym.filters.country"
        source="country"
        choices={countryChoices}
        allowEmpty
      />
    </Filter>
  );
};

const ListActions = (props: any) => {
  const {
    className,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {React.createElement(GymShowcaseAction, { ...rest })}
    </TopToolbar>
  );
};

export const GymList = ({ ...props }: any) => (
  <List
    {...props}
    filters={<GymFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <FunctionField
        label="resources.Gym.fields.name.label"
        render={(record: any) => record.name}
      />
      <TextField label="resources.Gym.fields.slug.label" source="slug" />
      <TextField
        label="resources.Gym.fields.zipCode.label"
        source="zipCode"
        sortable={false}
      />
      <TextField
        source="countryName"
        label="resources.Gym.fields.country.label"
        sortable={false}
      />
      <FunctionField
        label="resources.Gym.fields.showcaseDate"
        render={(record: any) => {
          if (!record.showcaseStartDate || !record.showcaseEndDate) return null;
          return `${new Date(record.showcaseStartDate).toLocaleDateString()} - ${new Date(
            record.showcaseEndDate
          ).toLocaleDateString()}`;
        }}
        sortable={false}
      />
      <BooleanField source="active" />
      <BooleanField source="temporarilyClosed" />
    </Datagrid>
  </List>
);
