import gql from 'graphql-tag';

import { parseResponseOne } from './utils';

export const gymRemFields = gql`
  fragment GymRemFields on GymRemuneration {
    __typename
    isLatest
    id
    iban
    sourceType
    sourceID
    discoveryPass
    monthlyFirmCapping
    effectiveAt
    ... on GymRemunerationStandard {
      price
      gymOffers {
        price
        costInCredits
        maxPublicPrice
        name {
          fr_FR
          en_EN
          nl_NL
        }
      }
    }
    ... on GymRemunerationMonthlyCapped {
      price
      capping
    }
    ... on GymRemunerationDegressivity {
      type
      tiers {
        price
        limit
      }
    }
    ... on GymRemunerationPeakHours {
      price
      peakHourPrice
      oldIntervals: intervals
    }
    ... on GymRemunerationOffPeakHours {
      price
      peakHourPrice
      intervals
    }
  }
`;

const GET_ONE_QUERY = gql`
  query gymRemuneration($id: ID!) {
    data: gymRemuneration(id: $id) {
      ...GymRemFields
    }
  }
  ${gymRemFields}
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: parseResponseOne,
  };
};
