import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allServices(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ServiceFilter
  ) {
    items: allServices(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      serviceID
      active
      slug
      name {
        fr
        en
        nl
      }
    }
    total: _allServicesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp.data) return { data: {} };
  return {
    data: resp.data.items.map((item: any) => ({
      ...item,
    })),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: params.filter ? params.filter : {},
  };

  if (params.ids && params.ids.length) {
    // We were called from a reference
    if (!(params.ids[0] instanceof String) && params.ids[0].id) {
      preparedVariables.filter.ids = params.ids.map((id: { id: string }) => {
        return id.id;
      });
    } else preparedVariables.filter.id = params.ids[0];
  }

  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  const preparedVariables: any = {
    filter: {},
  };

  if (params.ids && params.ids.length) {
    // We were called from a reference
    if (!(params.ids[0] instanceof String) && params.ids[0].id) {
      preparedVariables.filter.ids = params.ids.map((id: { id: string }) => {
        return id.id;
      });
    } else preparedVariables.filter.id = params.ids[0];
  }

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
