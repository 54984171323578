import { GetOne } from './GetOne';
import { GetList, GetMany } from './Many';
import { Update } from './Update';
import { Create } from './Create';

export default {
  UPDATE: Update,
  CREATE: Create,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
};
