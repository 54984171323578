import { Create } from './Create';
import { Delete } from './Delete';
import { GetOne } from './GetOne';
import { GetList, GetMany } from './Many';
import { Update } from './Update';

export default {
  GET_LIST: GetList,
  GET_MANY: GetMany,
  GET_ONE: GetOne,
  CREATE: Create,
  UPDATE: Update,
  DELETE: Delete,
};
