import React from 'react';
import {
  Datagrid,
  TextField,
  List,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin';
import { CouponsAddAction } from './CouponsAddAction';

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CouponsAddAction {...props} />
    </TopToolbar>
  );
};

export const CouponsList = (props: any) => (
  <List {...props} undoable="false" bulkActions={false} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="stats.used" />
      <TextField source="stats.available" />
    </Datagrid>
  </List>
);
