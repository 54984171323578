import gql from 'graphql-tag';
import { PlatformType } from '../../../@types/common';
import { FormatCorporation } from './CorporationFormatter';
import { EmployeeIdentifiers } from '../utils';

const CREATE_MUTATION = gql`
  mutation createCorporateCompany(
    $name: String!
    $slug: String!
    $logo: FileInput
    $chargeCorporate: Boolean
    $validEmployeeEmailDomains: [String!]
    $emailBlacklistRegex: [String!]
    $validEmployeeEmails: [String!]
    $validEmployees: [ValidEmployeeInput!]
    $validEmployeesAsString: String
    $country: CorporateCompanyCountry!
    $isSponsorshipPlatform: Boolean
    $sponsorshipPlatformID: ID
    $active: Boolean
    $productPriceGroupID: ID
    $hasChallenge: Boolean
    $sendWelcomeEmail: Boolean
    $canBeListedPublicly: Boolean
    $usersCanRegisterWithIdentifier: Boolean
    $signupInputName: TranslatedContentInput
    $signupInputPlaceholder: TranslatedContentInput
    $signupInputHelpingText: TranslatedContentInput
    $defaultUserLocale: CorporateLocale
    $showIdentifiersOnDashboard: Boolean
    $billingLink: String
    $reportingLink: String
    $hideCompanyChannel: Boolean
  ) {
    createCorporateCompany(
      input: {
        name: $name
        slug: $slug
        logo: $logo
        chargeCorporate: $chargeCorporate
        validEmployeeEmailDomains: $validEmployeeEmailDomains
        emailBlacklistRegex: $emailBlacklistRegex
        validEmployeeEmails: $validEmployeeEmails
        validEmployees: $validEmployees
        validEmployeesAsString: $validEmployeesAsString
        country: $country
        isSponsorshipPlatform: $isSponsorshipPlatform
        sponsorshipPlatformID: $sponsorshipPlatformID
        active: $active
        productPriceGroupID: $productPriceGroupID
        hasChallenge: $hasChallenge
        sendWelcomeEmail: $sendWelcomeEmail
        canBeListedPublicly: $canBeListedPublicly
        usersCanRegisterWithIdentifier: $usersCanRegisterWithIdentifier
        signupInputName: $signupInputName
        signupInputPlaceholder: $signupInputPlaceholder
        signupInputHelpingText: $signupInputHelpingText
        defaultUserLocale: $defaultUserLocale
        showIdentifiersOnDashboard: $showIdentifiersOnDashboard
        reportingLink: $reportingLink
        billingLink: $billingLink
        hideCompanyChannel: $hideCompanyChannel
      }
    ) {
      data: corporateCompany {
        name
        slug
        id
        country
        stripeAccount
        logo
        createdAt
        chargeCorporate
        plans {
          id
          commitmentInMonth
          priceWithoutDiscount
          price
          category
          categoryDisplayName
          credits
        }
        validEmployeeEmailDomains
        validEmployeesAsString
        emailBlacklistRegex
        validEmployeeEmails
        isSponsorshipPlatform
        sponsorshipPlatform
        active
        hasChallenge
        sendWelcomeEmail
        canBeListedPublicly
        defaultUserLocale
        showIdentifiersOnDashboard
        billingLink
        reportingLink
        hideCompanyChannel
      }
    }
  }
`;

export const Create = (params: any) => {
  const toCreate = { ...params.data };

  if (!toCreate.chargeCorporate) {
    toCreate.chargeCorporate = false;
  }

  if (toCreate.validEmployeeEmailDomains) {
    toCreate.validEmployeeEmailDomains = params.data.validEmployeeEmailDomains.map(
      (email: any) => email.value
    );
  } else if (toCreate.emailBlacklistRegex) {
    toCreate.emailBlacklistRegex = params.data.emailBlacklistRegex.map(
      (email: any) => email.value
    );
  }

  if (toCreate.validEmployees && toCreate.usersCanRegisterWithIdentifier) {
    toCreate.validEmployeesAsString = JSON.stringify(
      toCreate.validEmployees.map((validEmployee: EmployeeIdentifiers) => {
        return {
          refKey: validEmployee.refKey,
          email: validEmployee.email,
        };
      })
    );
    delete toCreate.validEmployees;
  }

  toCreate.offers = [];

  if (!toCreate.hasChallenge) {
    delete toCreate.hasChallenge;
  }

  if (!toCreate.sendWelcomeEmail) {
    delete toCreate.sendWelcomeEmail;
  }

  if (!toCreate.canBeListedPublicly) {
    delete toCreate.canBeListedPublicly;
  }

  if (!toCreate.showIdentifiersOnDashboard) {
    delete toCreate.showIdentifiersOnDashboard;
  }

  if (toCreate.platformType) {
    switch (toCreate.platformType) {
      case PlatformType.Credit:
        toCreate.chargeCorporate = false;
        break;
      case PlatformType.Sponsorship:
        toCreate.isSponsorshipPlatform = true;
        break;
      case PlatformType.CreditSponsorship:
        toCreate.isSponsorshipPlatform = true;
        toCreate.chargeCorporate = false;
        break;
      default:
        toCreate.isSponsorshipPlatform = false;
        break;
    }
    delete toCreate.platformType;
  }

  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.createCorporateCompany) return { data: {} };
    return FormatCorporation(resp.data.createCorporateCompany);
  };

  console.log('[CREATE][CORPORATE_COMPANY]', toCreate);

  return {
    variables: toCreate,
    parseResponse,
    query: CREATE_MUTATION,
  };
};
