import React from 'react';
import { useForm } from 'react-final-form';

import { useTranslate } from 'ra-core';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core';

const style = {
  deleteButton: {
    backgroundColor: 'rgb(220, 0, 78, 0.8)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgb(220, 0, 78)',
    },
    '&:hover *': {
      backgroundColor: 'transparent',
    },
  },
  rightIcon: {
    marginLeft: '15px',
  },
};

const DeleteUserDevice = ({ classes, ...props }: any) => {
  const form = useForm();
  const translate = useTranslate();

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.deleteButton}
      onClick={() => {
        form.change('deviceName', null);
        form.change('deviceID', null);
      }}
    >
      {translate('resources.User.actions.deleteUserDevice.name')}
      <DeleteIcon className={classes.rightIcon} />
    </Button>
  );
};

export default withStyles(style)(DeleteUserDevice);
