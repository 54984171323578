import React, { useState } from 'react';
import { translate, withDataProvider, useDataProvider } from 'ra-core';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import SetCompanyUsers from '../../resources/corporations/manager/SetCompanyUsers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    color: '#999',
  },
  title: {
    'white-space': 'pre',
  },
  explanation: {
    'white-space': 'pre',
  },
  executeButton: {
    'margin-left': 'auto',
    'margin-right': 'auto',
    display: 'block',
  },
  executeText: {
    'margin-left': 'auto',
    'margin-right': 'auto',
    display: 'block',
    'text-align': 'center',
  },
};

type DataProviderCallback = (
  type: string,
  resource: string,
  payload?: any,
  options?: any
) => Promise<any>;

interface RawProps {
  open: boolean;
  plans?: { id: string; categoryDisplayName: string; credits: number; price: number }[];
  recordID: string;
  onClose?: () => void;
  setOpen: any;
}

interface Props extends RawProps {
  dataProvider: DataProviderCallback;
  translate: (key: string, options?: object) => string;
  classes: any;
}

enum REQ_STATUS_ENUM {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  RESOLVED = 'RESOLVED',
  FAILED = 'FAILED',
}

const OfferPulsesStepper = ({
  open,
  plans,
  recordID,
  onClose,
  translate,
  classes,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const dataProvider = useDataProvider();

  const [reqStatus, setReqStatus] = useState<REQ_STATUS_ENUM>(REQ_STATUS_ENUM.INIT);

  const [users, setUsers] = useState<string[]>([]);

  const [selectedPlan, setSelectedPlan] = useState('Easy');

  const processingMessage = translate(
    'resources.CorporateCompany.actions.migrateUsers.steps.second.processingMessage'
  );
  const resolvedMessage = translate(
    'resources.CorporateCompany.actions.migrateUsers.steps.second.resolvedMessage'
  );
  const failedMessage = translate(
    'resources.CorporateCompany.actions.migrateUsers.steps.second.failedMessage'
  );

  const handleReq = () => {
    setReqStatus(REQ_STATUS_ENUM.PROCESSING);
    if ((!users.length && !users.length) || !recordID) return;
    const plan = plans?.find((plan) => plan.categoryDisplayName === selectedPlan)!;
    dataProvider
      .update('CorporateCompany', {
        id: recordID,
        data: {
          companyID: recordID,
          userEmails: users,
          pulsesAmount: plan.credits,
          moneyAmount: plan.price,
          offerPulses: true,
        },
        previousData: {
          id: recordID,
        },
      })
      .then((data) => {
        setReqStatus(REQ_STATUS_ENUM.RESOLVED);
      })
      .catch((e) => {
        setReqStatus(REQ_STATUS_ENUM.FAILED);
        console.log(e);
      });
  };

  const reset = (force = false) => {
    if (!force && activeStep === 3) return;
    setActiveStep(0);
    setUsers([]);
    setReqStatus(REQ_STATUS_ENUM.INIT);
    if (onClose) onClose();
  };

  const stepLabels = [
    translate('resources.CorporateCompany.actions.migrateUsers.steps.first.title'),
    translate('resources.CorporateCompany.actions.migrateUsers.steps.first.validation'),
    translate('Sélectionnez le nombre de pulses à offrir'),
    translate('resources.CorporateCompany.actions.migrateUsers.steps.second.name'),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const PrintUsers = () => (
    <div>
      <p>{`${users.length} utilisateur(s) trouvé(s):`}</p>
      <ul>
        {users.map((u) => (
          <li>{u}</li>
        ))}
      </ul>
    </div>
  );

  const PulsesChoices = () => (
    <FormControl style={{ marginBottom: '1rem' }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        defaultValue={selectedPlan}
        onChange={(event) => setSelectedPlan((event.target as HTMLInputElement).value)}
      >
        {plans &&
          plans.map((plan) => (
            <FormControlLabel
              value={plan.categoryDisplayName}
              control={<Radio />}
              label={`${plan.categoryDisplayName} (${plan.credits} pulses / ${
                plan.price / 100
              }€)`}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );

  return (
    <div>
      <Dialog open={open} onClose={() => reset()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" classes={classes.title}>
          {translate('resources.CorporateCompany.actions.migrateUsers.name')}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepLabels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === stepLabels.length ? (
              <div>Confirmez-vous?</div>
            ) : (
              <div>
                {activeStep === 0 ? (
                  <div>
                    <SetCompanyUsers
                      setUsers={setUsers}
                      recordID={recordID}
                      users={users}
                    />
                    <Button
                      disabled={!users.length}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                ) : activeStep === 1 ? (
                  <div>
                    <PrintUsers />
                    <Button variant="contained" color="primary" onClick={handlePrevious}>
                      Back
                    </Button>
                    <Button
                      disabled={!users.length}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginLeft: '.5rem' }}
                    >
                      Next
                    </Button>
                  </div>
                ) : activeStep === 2 ? (
                  <div>
                    <PulsesChoices />
                    <Button variant="contained" color="primary" onClick={handlePrevious}>
                      Back
                    </Button>
                    <Button
                      disabled={!users.length}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginLeft: '.5rem' }}
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <div>
                    {reqStatus === REQ_STATUS_ENUM.PROCESSING && (
                      <div>
                        <span>{processingMessage}</span>
                      </div>
                    )}
                    {reqStatus === REQ_STATUS_ENUM.RESOLVED && (
                      <div>
                        <span>{resolvedMessage}</span>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            reset(true);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                    {reqStatus === REQ_STATUS_ENUM.FAILED && (
                      <div>
                        <span>{failedMessage}</span>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            reset(true);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                    {reqStatus === REQ_STATUS_ENUM.INIT && (
                      <div>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleReq();
                          }}
                        >
                          Executer
                        </Button>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            reset(true);
                          }}
                        >
                          Annuler
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

// TODO: Use the new dataprovider
export default compose<Props, RawProps>(
  translate,
  withDataProvider,
  withStyles(styles)
)(OfferPulsesStepper);
