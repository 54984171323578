import challengeProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'ChallengeTemplate',
  dataProvider: {
    getOneName: 'ChallengeTemplate',
    providerWithIntrospection: challengeProvider,
  },
} as IResourceExport;
