import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetList, GetMany } from './Many';

export default (): AllPossibleRequestResolver => {
  return {
    GET_LIST: GetList(),
    GET_MANY: GetMany(),
  };
};
