import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query BO_AllProductPriceGroups {
    items: BO_AllProductPriceGroups(active: true) {
      items {
        id
        name {
          fr
          en
          nl
        }
        active
        prices {
          id
          price
          product {
            id
            name {
              fr
              nl
              en
            }
          }
        }
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items.items,
    total: resp.data.items.total,
  };
};

export const GetList = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};

export const GetMany = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};
