import gql from 'graphql-tag';

import { gymRemFields } from '../../gymRemunerations/dataProvider/GetOne';
import { FormatGym, GymFragment } from './utils';
import { isUUID } from '../../../utils';

const GET_ONE_QUERY = gql`
  query gym($id: String!) {
    data: gym(id: $id) {
      ...GymFields
      remuneration {
        ...GymRemFields
      }
    }
  }
  ${GymFragment}
  ${gymRemFields}
`;

const GET_ONE_BY_SLUG_QUERY = gql`
  query gymBySlug($slug: String!) {
    data: gymBySlug(slug: $slug) {
      ...GymFields
      remuneration {
        ...GymRemFields
      }
    }
  }
  ${GymFragment}
  ${gymRemFields}
`;

export const GetOne = (params: any) => {
  const { query, variables } = isUUID(params.id)
    ? { query: GET_ONE_QUERY, variables: params }
    : { query: GET_ONE_BY_SLUG_QUERY, variables: { slug: params.id } };
  return {
    // Use the default query variables and parseResponse
    variables: variables,
    // Override the query
    query: query,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return { data: FormatGym(resp.data.data) };
    },
  };
};
