import React, { useEffect, useState } from 'react';

import {
  useTranslate,
  useRedirect,
  ReferenceManyField,
  Pagination,
  FunctionField,
  DateField,
  BooleanField,
  Datagrid,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Editable } from '../gymRemunerations/form';
import { DeleteGymRemuneration } from '../gymRemunerations/shared/DeleteGymRemuneration';

const ShowReferencedRemunerations = ({ sourceID, translate, ...props }: any) => {
  return (
    <ReferenceManyField
      {...props}
      pagination={<Pagination />}
      label="Remuneration"
      source="id"
      target="gym"
      reference="GymRemuneration"
      filter={{ active: true, id: sourceID }}
      sort={{ field: 'effectiveAt', order: 'DESC' }}
      perPage={10}
    >
      <Datagrid rowClick="edit">
        <FunctionField
          source="sourceType"
          sortable={false}
          render={(record: any) =>
            record &&
            record.sourceType &&
            translate(
              'resources.GymRemuneration.sourceType.' + record.sourceType.toLowerCase()
            )
          }
        />
        <FunctionField
          source="type"
          render={(record: any) =>
            record &&
            record.type &&
            translate('resources.GymRemuneration.type.' + record.type.toLowerCase())
          }
          sortable={false}
        />

        <DateField source="effectiveAt" />
        <BooleanField source="isEffective" />
        <DeleteGymRemuneration />
      </Datagrid>
    </ReferenceManyField>
  );
};

export const DisplayRemProcess = ({ type, data, ...props }: any) => {
  const translate = useTranslate();

  const redirect = useRedirect();

  const [isRemunerationLoaded, setIsRemunerationLoaded] = useState(
    !!data.remuneration || data.remuneration === null
  );

  useEffect(() => {
    if (data.remuneration) {
      setIsRemunerationLoaded(true);
    }
  }, [data.remuneration]);

  if (!data || !data.id) return null;

  const sourceID = !data.isRemunerationAtFirmLevel
    ? data.id
    : // Fallback to current gym if no firm associated to gym (this should not happen)
      data.firm?.id || data.id;

  if (!isRemunerationLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {!data?.remuneration?.id ? (
        <Button
          onClick={() => {
            redirect(
              `/GymRemuneration/create?source=${JSON.stringify({
                sourceType: type,
                sourceID: data.id,
              })}`
            );
          }}
          color="primary"
          variant="outlined"
        >
          {translate('resources.Gym.actions.createRemProcess')}
        </Button>
      ) : (
        <Button
          onClick={() => {
            redirect(`/GymRemuneration/${data.remuneration.id}`);
          }}
          color="primary"
          variant="outlined"
        >
          {translate('resources.Gym.actions.editRemProcess')}
        </Button>
      )}
      <br />

      {
        // For now we don't display this inlined rem process in the edit tab of gym/firm
        // See (https://gymlib.atlassian.net/browse/SCRUM-1652), we are waiting for PM validation
        //(type === 'FIRM' || !data.isRemunerationAtFirmLevel) && (
        <Editable {...props} prefix="remuneration." isCreate={false} disabled />
        //)
      }

      {
        //for the Gym if applicable
        <ShowReferencedRemunerations
          translate={translate}
          sourceID={sourceID}
          {...props}
        />
      }
      {
        //for the Firm if applicable
        data.firm?.id && (
          <ShowReferencedRemunerations
            translate={translate}
            sourceID={data.firm.id}
            {...props}
          />
        )
      }
    </div>
  );
};
