import React from 'react';
import { BooleanInput, NumberInput, required } from 'react-admin';
import { minValue, useNotify } from 'ra-core';
import { AmountFormatter } from '../../utils';

export const AdmissionChargeComponent = ({ formData, translate }: any) => {
  const notify = useNotify();
  const firmHasCredits =
    formData?.firm &&
    formData?.firm.id &&
    typeof formData.firm.availableSession?.admissionChargeInCredits === 'number';

  const isCreateMode = !formData.id;

  return (
    formData && (
      <>
        {firmHasCredits && (
          <BooleanInput
            source={'availableSession.admissionCharge.isGymLevel'}
            label="resources.Gym.fields.availableSession.admissionCharge.isGymLevel.label"
            helperText={translate(
              'resources.Gym.fields.availableSession.admissionCharge.isGymLevel.description'
            )}
          />
        )}
        <NumberInput
          disabled={
            firmHasCredits && !formData.availableSession?.admissionCharge?.isGymLevel
          }
          validate={required(!firmHasCredits)}
          source={
            !formData.availableSession?.admissionCharge?.isGymLevel
              ? 'firm.availableSession.admissionChargeInCredits'
              : 'availableSession.admissionCharge.credits'
          }
          label="resources.Gym.fields.availableSession.admissionCharge.credits.label"
          initialValue={10}
          min={0}
          step={1}
        />
        {isCreateMode && (
          <>
            <NumberInput
              {...AmountFormatter(notify)}
              inputProps={{
                step: 0.5,
              }}
              step={0.1}
              source="availableSession.admissionCharge.commission"
              label="resources.Gym.fields.availableSession.admissionCharge.commission.label"
              validate={[minValue(0)]}
            />
            <BooleanInput
              source="availableSession.discoveryPass"
              label="resources.Gym.fields.products.refundable.label"
            />
          </>
        )}
      </>
    )
  );
};
