import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allUsersCharges(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: UserChargeFilter
  ) {
    items: allUserCharges(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      type
      amount
      createdAt
      amountRefunded
      status
      receiptUrl
    }
    total: _allUserChargesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.map((charge: any, index: number) => {
      return {
        id: index,
        ...charge,
        amount: charge.amount / 100,
      };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  console.log('List', params);

  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  console.log('MANY', params);
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    variables: preparedVariables,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  console.log('MANY ref', params);
  if (params.source === 'user') {
    params.filter[`userId`] = params.id;
  }

  const preparedVariables: any = {
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
