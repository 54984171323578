import { gql } from '@apollo/client';

const GET_LIST_QUERY = gql`
  query allPartnerEvents(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allPartnerEvents(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      id
      startDate
      endDate
      title {
        fr
      }
    }
    total: _allPartnerEventsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
    }
  }
`;

export const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items,
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  params = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    perPage: params.pagination ? params.pagination.perPage : 25,
    sortField: params.sort ? params.sort.field : null,
    sortOrder: params.sort ? params.sort.order : null,
  };
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse: parseResponseMany,
  };
};

export const GetMany = (params: any) => {
  params = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    perPage: params.pagination ? params.pagination.perPage : 25,
    sortField: params.sort ? params.sort.field : null,
    sortOrder: params.sort ? params.sort.order : null,
  };
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse: parseResponseMany,
  };
};
