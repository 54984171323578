import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query gymManager($id: String!) {
    data: gymManager(id: $id) {
      id
      email
      gyms {
        id
      }
      status
      displayFrom
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: params,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          gymIDs: resp.data.data.gyms.map((gym: any) => gym.id),
        },
      };
    },
  };
};
