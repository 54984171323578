import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';

import React, { useCallback, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CSVFile from '../../../utils/csv/CSVFile';
import { useTranslate } from 'react-admin';
import { EMAIL_REGEX } from '../../../utils';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    minWidth: '550px',
    marginBottom: '24px',
  },
  nextButton: { alignSelf: 'flex-end', marginTop: '24px' },
  tabHead: {
    fontWeight: 'bold',
  },
  info: {
    fontSize: '12px',
    textDecoration: 'italic',
  },
  proceedButton: { alignSelf: 'flex-end' },
});

export type UpdateUserEmailsModalProps = {
  open: boolean;
  companyID: string;
  onClose: () => void;
};

enum UPDATE_USER_EMAIL_STEPS {
  'CSV_UPLOAD',
  'VERIFY',
  'LOADING',
  'RESULT',
}

export const UpdateUserEmailsModal = ({
  open,
  companyID,
  onClose,
}: UpdateUserEmailsModalProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [step, setStep] = useState(UPDATE_USER_EMAIL_STEPS.CSV_UPLOAD);
  const [payload, setPayload] = useState<any>();
  const [conflictedEmails, setConflictedEmails] = useState<
    { from: string; to: string }[]
  >([]);

  const closeModal = useCallback(() => {
    setPayload(undefined);
    setStep(UPDATE_USER_EMAIL_STEPS.CSV_UPLOAD);
    onClose();
  }, [onClose]);

  const uploadCSVHandler = useCallback(
    (csv: Array<any>) => {
      const emails = csv.reduce((acc, csvLine) => {
        const from =
          csvLine[0] && EMAIL_REGEX.test(csvLine[0])
            ? csvLine[0].trim().toLowerCase()
            : undefined;
        const to =
          csvLine[1] && EMAIL_REGEX.test(csvLine[1])
            ? csvLine[1].trim().toLowerCase()
            : undefined;
        if (from && to && from !== to) return [...acc, { from, to }];
        return acc;
      }, []);
      setPayload({ companyID, employeeEmails: emails });
    },
    [setPayload, companyID]
  );

  const onProceedClick = useCallback(async () => {
    setStep(UPDATE_USER_EMAIL_STEPS.LOADING);
    const result = await dataProvider.update('CorporateCompany', {
      id: companyID,
      data: {
        migrateUserEmails: true,
        ...payload,
      },
      previousData: {
        id: companyID,
      },
    });

    const conflictedEmails = (result?.data?.conflictedEmails || []).map(
      (toEmail: string) => {
        return payload.employeeEmails.find((email: any) => email.to === toEmail);
      }
    );
    setConflictedEmails(conflictedEmails);
    setStep(UPDATE_USER_EMAIL_STEPS.RESULT);
  }, [companyID, payload, dataProvider]);

  return (
    <Dialog maxWidth="lg" open={open} onClose={() => closeModal()}>
      <DialogTitle>
        {translate('resources.CorporateCompany.actions.updateUserEmails.name')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {step === UPDATE_USER_EMAIL_STEPS.CSV_UPLOAD && (
          <>
            <CSVFile
              handleCSVResult={uploadCSVHandler}
              label={translate(
                payload
                  ? 'resources.CorporateCompany.actions.updateUserEmails.csvLoaded'
                  : 'resources.CorporateCompany.actions.updateUserEmails.csvInput'
              )}
            />
            <Button
              variant="contained"
              className={classes.nextButton}
              color={payload ? 'primary' : 'default'}
              onClick={() => setStep(UPDATE_USER_EMAIL_STEPS.VERIFY)}
            >
              {translate(
                'resources.CorporateCompany.actions.updateUserEmails.nextButton'
              )}
            </Button>
          </>
        )}

        {step === UPDATE_USER_EMAIL_STEPS.VERIFY && (
          <>
            <TableContainer style={{ maxHeight: 250 }}>
              <Table stickyHeader aria-label="update-user-emails-list" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tabHead}>
                      {translate(
                        'resources.CorporateCompany.actions.updateUserEmails.recapOld'
                      )}
                    </TableCell>
                    <TableCell className={classes.tabHead}>
                      {translate(
                        'resources.CorporateCompany.actions.updateUserEmails.recapNew'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payload.employeeEmails.map((employeeEmail: any) => (
                    <TableRow>
                      <TableCell>{employeeEmail.from}</TableCell>
                      <TableCell>{employeeEmail.to}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className={classes.info}>
              {translate('resources.CorporateCompany.actions.updateUserEmails.warning')}
            </p>
            <Button
              variant="contained"
              className={classes.proceedButton}
              color="primary"
              onClick={onProceedClick}
            >
              {translate(
                'resources.CorporateCompany.actions.updateUserEmails.proceedButton'
              )}
            </Button>
          </>
        )}

        {step === UPDATE_USER_EMAIL_STEPS.LOADING && <CircularProgress size={18} />}

        {step === UPDATE_USER_EMAIL_STEPS.RESULT && (
          <>
            {conflictedEmails.length > 0 ? (
              <>
                <p>
                  <b>
                    Migration started with success but ignored for the following emails
                    because Gymlib accounts exists for both email (from and to) :
                  </b>
                </p>
                <TableContainer style={{ maxHeight: 250 }}>
                  <Table
                    stickyHeader
                    aria-label="update-user-emails-conflicted-list"
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tabHead}>From</TableCell>
                        <TableCell className={classes.tabHead}>To</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {conflictedEmails.map(({ from, to }) => (
                        <TableRow>
                          <TableCell>{from}</TableCell>
                          <TableCell>{to}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <p>
                  To be able to run the email migration for those users, please update or
                  anonymise one of the user link to either the old email or the new email
                  to be able to update the new email for the user remaining.
                </p>
              </>
            ) : (
              <p>Migration started with success.</p>
            )}
            <Button
              variant="contained"
              className={classes.proceedButton}
              color="primary"
              onClick={closeModal}
            >
              Close
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
