import gql from 'graphql-tag';

import { PassActions } from '../../../@types/common';

const TRANSFER_PASS_MUTATION = gql`
  mutation TRANSFER_PASS($input: TransferPassInput!) {
    transferPass(input: $input) {
      data: pass {
        id
        code
        passType
        expirationDate
        activationDate
        lastValidationDate
        createdAt
        status
        penaltyDate
        user {
          id
          email
        }
        gym {
          id
          name
          bookingFlow
        }
      }
    }
  }
`;

const LATE_CANCEL_MUTATION = gql`
  mutation declareLateCancel($passID: ID!) {
    declareLateCancel(passID: $passID) {
      id
      code
      passType
      expirationDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
        email
      }
      gym {
        id
        name
        bookingFlow
      }
    }
  }
`;
const NO_SHOW_MUTAION = gql`
  mutation declareNoShow($passID: ID!) {
    declareNoShow(passID: $passID) {
      id
      code
      passType
      expirationDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
        email
      }
      gym {
        id
        name
        bookingFlow
      }
    }
  }
`;

const REFUND_PASSES = gql`
  mutation refundGymPasses(
    $gymID: ID!
    $filterBy: String!
    $date: DateTime!
    $reason: String!
  ) {
    data: refundGymPasses(
      input: { gymID: $gymID, filterBy: $filterBy, date: $date, reason: $reason }
    ) {
      success
      modifiedCount
    }
  }
`;

const CANCEL_PENALTY_MUTATION = gql`
  mutation disputePassPenalty($input: DisputePassPenaltyInput!) {
    disputePassPenalty(input: $input) {
      id
      code
      passType
      expirationDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
        email
      }
      gym {
        id
        name
        bookingFlow
      }
    }
  }
`;

const UPDATE_PASS_MUTATION = gql`
  mutation updatePass($id: ID!, $expirationDate: DateTime) {
    data: updatePass(input: { passID: $id, data: { expirationDate: $expirationDate } }) {
      id
      code
      passType
      expirationDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
        email
      }
      gym {
        id
        name
        slug
        bookingFlow
      }
    }
  }
`;

const REFUND_PASS_MUTATION = gql`
  mutation refundPass($id: ID!, $reason: String!) {
    data: refundPass(input: { id: $id, reason: $reason }) {
      id
      code
      passType
      expirationDate
      activationDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
        email
      }
      gym {
        id
        name
        slug
        bookingFlow
      }
    }
  }
`;

const TRIGGER_REM_PROCESS = gql`
  mutation triggerRemunerationProcess($passID: ID!) {
    data: triggerRemunerationProcess(input: { passID: $passID }) {
      id
      user {
        id
        email
      }
      gym {
        id
        name
        slug
        bookingFlow
      }
    }
  }
`;

const CANCEL_REM = gql`
  mutation cancelPassRemuneration($passID: ID!, $reason: String!) {
    data: cancelPassRemuneration(input: { passID: $passID, reason: $reason }) {
      id
      user {
        id
        email
      }
      gym {
        id
        name
        slug
        bookingFlow
      }
    }
  }
`;

export const Update = (params: any) => {
  const action = (params.data && params.data.action) || params.action || 'save';

  console.log('[UPDATE][PASS]', params.data);

  const data = params.data.data
    ? params.data.data.find((pass: any) => pass.id === params.data.id)
    : params.data;
  let query = UPDATE_PASS_MUTATION;
  if (action === PassActions.TRIGGER_REMUNERATION) {
    return {
      variables: { passID: data.id },
      query: TRIGGER_REM_PROCESS,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.data) return { data: {} };
        return { data: resp.data.data };
      },
    };
  }
  if (action === PassActions.CANCEL_REMUNERATION) {
    return {
      variables: { passID: data.id, reason: data.reason },
      query: CANCEL_REM,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.data) return { data: {} };
        return { data: resp.data.data };
      },
    };
  }
  if (action === PassActions.REFUND) {
    return {
      variables: { id: data.id, reason: data.reason },
      query: REFUND_PASS_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.data) return { data: {} };
        return { data: resp.data.data };
      },
    };
  } else if (action === PassActions.VALIDATE_BOOKING) {
    return {
      variables: { input: { passCode: data.code } },
      query: TRANSFER_PASS_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.transferPass) return { data: {} };
        return resp.data.transferPass;
      },
    };
  } else if (action === PassActions.LATE_CANCELLED) {
    return {
      variables: { passID: data.id },
      query: LATE_CANCEL_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.declareLateCancel) return { data: {} };
        return { data: resp.data.declareLateCancel };
      },
    };
  } else if (action === PassActions.NO_SHOW) {
    return {
      variables: { passID: data.id },
      query: NO_SHOW_MUTAION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.declareNoShow) return { data: {} };
        return { data: resp.data.declareNoShow };
      },
    };
  } else if (action === PassActions.REFUND_GYM_PASSES) {
    return {
      variables: {
        gymID: data.gymID,
        filterBy: data.filterBy,
        date: data.date,
        reason: data.reason,
      },
      query: REFUND_PASSES,
      parseResponse: (resp: any) => {
        if (!resp.data) return { data: {} };
        return {
          data: {
            id: null,
            number: resp.data.data.modifiedCount,
            result: resp.data.data.success,
          },
        };
      },
    };
  } else if (
    [PassActions.CANCEL_LATE_CANCELLED, PassActions.CANCEL_NO_SHOW].includes(action)
  ) {
    return {
      variables: { input: { passID: data.id, status: data.passStatus } },
      query: CANCEL_PENALTY_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.disputePassPenalty) return { data: {} };
        return { data: resp.data.disputePassPenalty };
      },
    };
  }

  return {
    variables: { id: params.id, expirationDate: data.expirationDate },
    query,
    parseResponse: (resp: any) => {
      if (!resp.data && !resp.data.data) return { data: {} };
      return { data: resp.data.data };
    },
  };
};
