import React from 'react';
import { useForm } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { TextInput, required } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {};

const BsportForm = () => {
  const form = useForm();
  form.change('name', 'BSPORT');

  return (
    <FormDataConsumer>
      {({ formData, ...rest }: any) => (
        <>
          <TextInput
            source="siteID"
            label="resources.Erp.fields.siteID.bsport"
            validation={[required()]}
          />
          <TextInput
            source="bsportConfiguration.establishmentID"
            label="resources.Erp.fields.establishmentID"
          />
          <PlanningOffsetComponent />
        </>
      )}
    </FormDataConsumer>
  );
};

export default withStyles(styles)(BsportForm);
