import { IResourceExport } from '../../@types/dataProvider';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import productProvider from './dataProvider';
import { ProductList } from './ProductList';
import { ProductEdit } from './ProductEdit';
import { ProductCreate } from './ProductCreate';

export default {
  name: 'Products',
  icon: ShopTwoIcon,
  resources: {
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreate,
  },
  dataProvider: {
    getOneName: 'BO_Product',
    providerWithIntrospection: productProvider,
  },
} as IResourceExport;
