import gql from 'graphql-tag';
import { formatGroup } from './shared';

const GET_LIST_QUERY = gql`
  query BO_AllActivityGroup($input: BO_AllActivityGroupInput!) {
    items: BO_AllActivityGroup(input: $input) {
      items {
        id
        name
        slug
        activities {
          id
          slug
        }
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  resp.data.items.items.forEach(formatGroup);
  return {
    data: resp.data.items.items,
    total: resp.data.items.total,
  };
};

const formatParams = (params: {
  pagination: { page: number; perPage: number };
  sort?: { field: string; order: string };
  filter?: { q: string };
}): {
  input: {
    page: number;
    perPage: number;
    sort?: { sortField: string; sortOrder: string };
    filter?: { q: string };
  };
} => {
  return {
    input: {
      page: params.pagination.page,
      perPage: params.pagination.perPage,
      ...(params.sort && {
        sort: { sortField: params.sort.field, sortOrder: params.sort.order },
      }),
      ...(params.filter && params.filter.q && { filter: params.filter }),
    },
  };
};

export const GetList = () => {
  return (params: any) => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
      variables: formatParams(params),
    };
  };
};

export const GetMany = () => {
  return () => {
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
    };
  };
};
