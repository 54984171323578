import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allGymReviews(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: GymReviewFilter!
  ) {
    items: allGymReviews(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      rating
      comment
      postedAt
      author {
        id
      }
    }
    total: _allGymReviewsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp.data) return { data: {} };
  return {
    data: resp.data.items.map((item: any) => ({
      ...item,
    })),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { gymId: params.id },
    },
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };

  if (params.id) {
    // We were called from a reference
    preparedVariables.filter.gymId = params.id;
  }

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
