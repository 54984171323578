import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useRefresh, FormDataConsumer } from 'ra-core';
import * as React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  translate,
  useNotify,
  useRedirect,
  useTranslate,
  Labeled,
} from 'react-admin';
import { AmountFormatter } from '../../utils';
import OfferPulsesAction from './OfferPulsesAction';
import AddManagerAction from './manager/AddManagerAction';
import AddBlacklist from './Whitelist/AddBlacklist';
import { EmailWhitelistInput } from './Whitelist/EmailWhitelistInput';
import { useCallback, useState, useEffect } from 'react';
import { GetManyReference } from '../companyAudits/dataProvider/Many';
import _ from 'lodash';
import MigrateUsers from './Whitelist/MigrateUsers';
import { isHideCompanyChannelToggleDisabled } from './utils';
import { UpdateUserEmailsButton } from './updateUserEmails';
import { EmployeesIdentifiersWhitelistInput } from './Whitelist/EmployeesIdentifiersWhitelistInput';
import Button from '@material-ui/core/Button';
import { apolloClient } from '../../utils/apolloClient';
import gql from 'graphql-tag';
import { WhitelistUploadSpinnerModal } from './Whitelist/WhitelistUploadSpinnerModal';

const styles = {
  logo: {
    '& img': {
      backgroundColor: 'black',
      margin: 0,
      maxHeight: 100,
      maxWidth: 240,
    },
  },
  logoUpdater: {
    width: 200,
  },
  emailList: {
    display: 'flex',
    width: '30%',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  emailListAdditionDeletion: {
    display: 'flex',
    width: '50%',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    '& fieldset': {
      marginLeft: '20px',
      '& div': {
        'flex-direction': 'row',
      },
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  tabs: {
    '& div:only-child': {
      width: '100%',
    },
  },
  inlineBlock: {
    display: 'inline-flex',
    width: 'calc(50% - 6rem)',
    margin: '1rem 3rem',
    '& div': {
      width: '100%',
    },
  },
  inlineBlockFull: {
    display: 'inline-flex',
    width: 'calc(100% - 6rem)',
    margin: '1rem 3rem',
    '& div': {
      width: '100%',
    },
  },
  textInput: {
    display: 'inline-flex',
    width: 'calc(50% - 6rem)',
    '& div': {
      width: '100%',
    },
  },
  tabUser: {
    '& table': {
      width: '100%',
    },
    '& div:only-child': {
      width: '100%',
      'overflow-x': 'auto',
    },
  },
  initialLogoWrapper: {
    margin: 0,
  },
  initialLogo: {
    margin: 0,
    maxHeight: 100,
    maxWidth: 240,
  },
  inlineArrayInput: {
    width: '100%',
    '& section': {
      display: 'flex',
      paddingTop: 15,
      paddingBottom: 10,
      '& div:only-child': {
        display: 'flex',
        marginLeft: '.5rem',
      },
    },
    '& p': {
      display: 'none',
    },
  },
  formControl: {
    minWidth: 250,
  },
  line: {
    display: 'flex',
    gap: '15px',
  },
  inlineSection: {
    width: '50%',
    display: 'flex',
    'flex-direction': 'row',
  },
  link: {
    'margin-top': `20px`,
    'margin-left': `10px`,
  },
};

const CorporationTitle = ({ record, translate }: any) => {
  return (
    <span>
      {translate('resources.CorporateCompany.editTitle', { name: record.name })}
    </span>
  );
};

const CorporationEditActions = (props: any) => {
  return (
    <TopToolbar>
      <AddManagerAction {...props} />
      <OfferPulsesAction {...props} record={props.data} />
      <MigrateUsers {...props} record={props.data} />
    </TopToolbar>
  );
};

const CountryDisplayer = ({ record, source, translate }: any) => {
  const country = record[source] === 'FR' ? 'france' : 'belgium';
  return (
    <span>{translate(`resources.CorporateCompany.fields.${source}.${country}`)}</span>
  );
};
CountryDisplayer.defaultProps = {
  addLabel: true,
};

const CustomToolbar = (props: any) => {
  const isLoading = !props.formData || props.formData.validEmployeeEmails === undefined;
  return (
    <Toolbar {...props} alwaysEnableSaveButton>
      <SaveButton
        disabled={isLoading}
        onClick={() => {
          if (!props.invalid) {
            props.setIsSubmitting(true);
          }
        }}
      />
    </Toolbar>
  );
};

const validateData = async (values: any) => {
  const errors: any = {};

  if (!values.logo?.rawFile) return errors;

  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      var image = new Image();
      image.onerror = () => {
        errors.logo = 'Could not Load Image';
        return resolve(errors);
      };
      image.onload = () => {
        if (image.width !== 240 || image.height !== 100) {
          errors.logo = 'resources.CorporateCompany.create.error.image';
        }
        return resolve(errors);
      };
      image.src = reader.result as string;
    };
    reader.readAsDataURL(values.logo.rawFile);
  });
};

const SYNC_CORPORATION_ALLOWLIST_WITH_HRIS = gql`
  mutation BO_SyncCorporationAllowlistWithHris(
    $input: BO_SyncCorporationAllowlistWithHrisInput!
  ) {
    BO_SyncCorporationAllowlistWithHris(input: $input) {
      corporateCompany {
        id
      }
    }
  }
`;

const CorporationEditWithoutStyle = ({ classes, translate, ...props }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [actionType, setActionType] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canManageHrisIntegration, setCanManageHrisIntegration] = useState(false);
  const [
    isTransferringCompanyAuthorizedUsersToHris,
    setIsTransferringCompanyAuthorizedUsersToHris,
  ] = useState(false);

  const syncCorporationAllowlistWithHris = async (companyID: string) => {
    try {
      setIsTransferringCompanyAuthorizedUsersToHris(true);
      await apolloClient.mutate({
        mutation: SYNC_CORPORATION_ALLOWLIST_WITH_HRIS,
        variables: {
          input: {
            companyID,
          },
        },
      });
      setIsTransferringCompanyAuthorizedUsersToHris(false);
      refresh();
      notify('Allow list has been migrated to HRIS', 'info');
    } catch (error: any) {
      setIsTransferringCompanyAuthorizedUsersToHris(false);
      refresh();
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'error',
        undefined,
        false
      );
    }
  };

  const t = useTranslate();
  const onSuccess = ({ data }: { data: any }) => {
    setIsSubmitting(false);
    notify(
      'ra.notification.updated',
      'info',
      { smart_count: 1 },
      props.mutationMode === 'undoable'
    );
    redirect('edit', props.basePath, data.id, data);
    refresh();
  };

  const onError = (error: any) => {
    setIsSubmitting(false);
    notify(
      typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
      'error',
      undefined,
      false
    );
    refresh();
  };

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('roles')!);
    setCanManageHrisIntegration(roles.includes('manageCompanyHrisIntegration'));
  }, []);

  const handleChange = (selectedOption: string) => {
    if (actionType.includes(selectedOption)) {
      setActionType(actionType.filter((item: string) => item !== selectedOption));
    } else {
      setActionType(actionType.concat(selectedOption));
    }
  };

  const actionTypes = [
    { label: 'Whitelist change', value: 'SYNC' },
    { label: 'block', value: 'BLOCK' },
    { label: 'create', value: 'CREATE' },
    { label: 'update', value: 'UPDATE' },
    { label: 'unblock', value: 'UNBLOCK' },
    { label: 'ignore', value: 'IGNORE' },
    { label: 'unignore', value: 'UNIGNORE' },
    { label: 'inactive', value: 'INACTIVE' },
    { label: 'change email', value: 'CHANGE_EMAIL' },
    { label: 'change refkey', value: 'CHANGE_REFKEY' },
    { label: 'change metadata', value: 'CHANGE_METADATA' },
    { label: 'delete', value: 'DELETE' },
    { label: 'send email', value: 'SEND_EMAIL' },
  ];

  const debouncedOnChange = useCallback(
    _.debounce((value: string) => {
      setInputValue(value);
    }, 500),
    []
  );

  const CustomColumn = ({ label, customRender, record }: any) => {
    return (
      <div>
        {customRender ? (
          customRender(record)
        ) : (
          <div>{record.fromUser ? record.fromUser : record.fromHrManager}</div>
        )}
      </div>
    );
  };

  return (
    <Edit
      {...props}
      undoable={false}
      actions={<CorporationEditActions />}
      title={<CorporationTitle translate={translate} />}
      submitOnEnter={false}
      onSuccess={onSuccess}
      onFailure={onError}
    >
      <TabbedForm
        redirect="edit"
        validate={validateData}
        toolbar={
          <FormDataConsumer>
            {(props: any) => (
              <CustomToolbar setIsSubmitting={setIsSubmitting} {...props} />
            )}
          </FormDataConsumer>
        }
      >
        <FormTab label="resources.CorporateCompany.tabs.informations">
          <BooleanInput
            source="active"
            label="resources.CorporateCompany.fields.active.label"
            formClassName={classes.inlineBlock}
          />
          <FormDataConsumer formClassName={classes.inlineBlock}>
            {({ formData }) => {
              return (
                formData.creditCounterpart && (
                  <div>
                    <BooleanInput
                      label="Bascule crédits"
                      source="creditCounterpart.creditCompany.active"
                      disabled={formData.creditCounterpart.creditCompany.active}
                    />{' '}
                    <ReferenceInput
                      label="Version crédit"
                      source="creditCounterpart.creditCompany.id"
                      reference="CorporateCompany"
                      disabled={true}
                      allowEmpty
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </div>
                )
              );
            }}
          </FormDataConsumer>
          <br />
          <TextInput
            source="name"
            label="resources.CorporateCompany.fields.name.label"
            formClassName={classes.inlineBlock}
            validate={[required()]}
          />
          <TextInput
            disabled
            source="slug"
            label="resources.CorporateCompany.fields.slug.label"
            formClassName={classes.inlineBlock}
            validate={[required()]}
          />
          <br />
          <CountryDisplayer
            {...props}
            translate={translate}
            source="country"
            label="resources.CorporateCompany.fields.country.label"
            formClassName={classes.inlineBlock}
          />
          <CountryDisplayer
            {...props}
            translate={translate}
            source="stripeAccount"
            label="resources.CorporateCompany.fields.stripeAccount.label"
            formClassName={classes.inlineBlock}
          />
          <br />
          <FormDataConsumer>
            {({ formData, record }: any) => (
              <>
                <ImageInput
                  source="logo"
                  accept="image/png"
                  label={`resources.CorporateCompany.fields.logo.${
                    formData.logo ? 'modify' : 'add'
                  }Label`}
                  className={[classes.fileUploader, classes.logo]}
                >
                  <ImageField source="src" />
                </ImageInput>
                {record.logo && (formData.logo === record.logo || !formData.logo) && (
                  <div className={classes.initialLogoWrapper}>
                    <img
                      src={record.logo}
                      className={classes.initialLogo}
                      alt="initialLogo"
                    />
                  </div>
                )}
                {!record.logo && !formData.logo && (
                  <div className={classes.initialLogoWrapper}>
                    <img
                      src="//place-hold.it/240x100?text=No Logo Yet!"
                      className={classes.initialLogo}
                      alt="No Logo Yet!"
                    />
                  </div>
                )}
              </>
            )}
          </FormDataConsumer>
          <br />
          <FormDataConsumer className={classes.inlineBlockFull}>
            {({ formData, ...rest }: any) => (
              <>
                <BooleanInput
                  source="isSponsorshipPlatform"
                  label="resources.CorporateCompany.fields.isSponsorshipPlatform"
                  formClassName={classes.inlineBlock}
                />
                {formData && !formData.isSponsorshipPlatform && (
                  <>
                    {/* This field is only here to display the sponsorship platform */}
                    {formData.sponsorshipPlatform && (
                      <TextInput
                        {...rest}
                        className={classes.inlineBlockFull}
                        disabled
                        label="resources.CorporateCompany.fields.sponsorshipPlatform.label"
                        source="sponsorshipPlatform"
                      />
                    )}
                    {/* Edit sponsorship platform */}
                    <ReferenceInput
                      className={classes.inlineBlockFull}
                      label="resources.CorporateCompany.fields.sponsorshipPlatform.update"
                      source="sponsorshipPlatformID"
                      reference="CorporateCompany"
                      filter={{
                        isActive: formData.active,
                      }}
                      filterToQuery={(searchText: string) => ({ name: searchText })}
                      allowEmpty
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  </>
                )}
              </>
            )}
          </FormDataConsumer>
          <br />
          <FormDataConsumer>
            {({ formData }: any) => (
              <BooleanInput
                source="hideCompanyChannel"
                label="resources.CorporateCompany.fields.hideCompanyChannel.label"
                initialValue={false}
                format={(value: boolean) => !value}
                parse={(value: boolean) => !value}
                disabled={isHideCompanyChannelToggleDisabled(formData)}
              ></BooleanInput>
            )}
          </FormDataConsumer>
          <BooleanInput
            source="hasChallenge"
            label="resources.CorporateCompany.fields.hasChallenge.label"
            formClassName={classes.inlineBlock}
          />
          <BooleanInput
            source="sendWelcomeEmail"
            label="resources.CorporateCompany.fields.sendWelcomeEmail.label"
            formClassName={classes.inlineBlock}
          />
          <br></br>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <SelectInput
            source="defaultUserLocale"
            label="Language"
            choices={[
              { id: 'fr', name: 'FR' },
              { id: 'en', name: 'EN' },
              { id: 'nl', name: 'NL' },
            ]}
          ></SelectInput>
          <br />
          <TextInput
            source="billingLink"
            label="Billing link"
            formClassName={classes.inlineBlock}
            placeholder="Billing link"
          />
          <TextInput
            source="reportingLink"
            label="reportingLink"
            formClassName={classes.inlineBlock}
            placeholder="Reporting link"
          />
        </FormTab>
        <FormTab
          label="resources.CorporateCompany.tabs.offers"
          contentClassName={classes.tabs}
        >
          <section className={classes.inlineSection}>
            <TextInput
              source="sfAccountId"
              label="Salesforce Account ID"
              formClassName={classes.textInput}
              placeholder="ACC-XXXXXX"
            />
            <FormDataConsumer>
              {({ formData }: any) => (
                <a
                  className={classes.link}
                  href={`${process.env.REACT_APP_WP_ADMIN_URL}/companies/search?sfAccountId=${formData.sfAccountId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon />
                </a>
              )}
            </FormDataConsumer>
          </section>
          <FormDataConsumer>
            {({ formData, ...props }: any) => {
              return (
                <>
                  <ReferenceInput
                    {...props}
                    label="resources.CorporateCompany.fields.productPrice.label"
                    source="productPriceGroup.id"
                    reference="ProductPriceGroups"
                    filterToQuery={(searchText: string) => ({ name: searchText })}
                    allowEmpty={false}
                  >
                    <AutocompleteInput optionText="name.fr" />
                  </ReferenceInput>

                  <ArrayInput
                    {...props}
                    disabled
                    source="plans"
                    label="Prix des offres pour le niveau de financement actuel"
                    style={{ width: '100%' }}
                  >
                    <SimpleFormIterator>
                      <TextInput
                        label="resources.CorporateCompany.plans.categoryDisplayName"
                        source="categoryDisplayName"
                        disabled
                      />
                      <NumberInput
                        label="resources.CorporateCompany.plans.price"
                        source="price"
                        {...AmountFormatter()}
                        disabled
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.CorporateCompany.tabs.limitations">
          <FormDataConsumer>
            {(props: any) => {
              return props.formData?.allowListIntegrationScopeId ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: '55px',
                  }}
                >
                  <TextInput
                    source="allowListIntegrationScopeId"
                    label="resources.CorporateCompany.fields.allowListIntegrationScopeId.label"
                    disabled
                    style={{ width: '50%' }}
                  />
                  {canManageHrisIntegration && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          syncCorporationAllowlistWithHris(props.formData.id);
                        }}
                        style={{ marginLeft: '40px', height: '48px', marginTop: '8px' }}
                      >
                        Sync Users to HRIS
                      </Button>
                      {props.formData?.validEmployees?.length > 1 && (
                        <WhitelistUploadSpinnerModal
                          open={isTransferringCompanyAuthorizedUsersToHris}
                          count={props.formData?.validEmployees?.length}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : canManageHrisIntegration ? (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      syncCorporationAllowlistWithHris(props.formData.id);
                    }}
                  >
                    Setup Hris Integration
                  </Button>
                  {props.formData?.validEmployees?.length > 1 && (
                    <WhitelistUploadSpinnerModal
                      open={isTransferringCompanyAuthorizedUsersToHris}
                      count={props.formData?.validEmployees?.length}
                    />
                  )}
                </div>
              ) : (
                <></>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {(props: any) => {
              return props.formData?.allowListIntegrationScopeId ? (
                <div>
                  <BooleanInput
                    id="delegateEligibilityEventsHandlingToHris"
                    source="delegateEligibilityEventsHandlingToHris"
                    label="resources.CorporateCompany.fields.delegateEligibilityEventsHandlingToHris.label"
                    initialValue={false}
                  />
                </div>
              ) : (
                <></>
              );
            }}
          </FormDataConsumer>
          <ArrayInput
            label="resources.CorporateCompany.fields.validEmployeeEmailDomains.label"
            source="validEmployeeEmailDomains"
            className={classes.emailList}
          >
            <SimpleFormIterator>
              <TextInput
                source="value"
                placeholder={translate(
                  'resources.CorporateCompany.fields.validEmployeeEmailDomains.addPlaceholder'
                )}
                label="resources.CorporateCompany.fields.validEmployeeEmailDomains.addLabel"
              />
            </SimpleFormIterator>
          </ArrayInput>
          <AddBlacklist />
          <ArrayInput
            label="resources.CorporateCompany.fields.emailBlacklistRegex.label"
            source="emailBlacklistRegex"
            className={classes.emailList}
          >
            <SimpleFormIterator disableAdd disableRemove>
              <TextInput
                disabled
                source="value"
                label="resources.CorporateCompany.fields.emailBlacklistRegex.addLabel"
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {(props: any) => {
              return props.formData?.usersCanRegisterWithIdentifier ? (
                <EmployeesIdentifiersWhitelistInput
                  isSubmitting={isSubmitting}
                  {...props}
                />
              ) : (
                <EmailWhitelistInput {...props} />
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label="resources.CorporateCompany.tabs.users"
          contentClassName={classes.tabUser}
        >
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.CorporateCompany.fields.userList"
            reference="User"
            target="corporateCompany.id"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid rowClick="edit">
              <DateField source="createdAt" />
              <TextField source="email" />
              <DateField source="subscription.endDate" />
              <TextField source="subscription.category" />
            </Datagrid>
          </ReferenceManyField>

          <UpdateUserEmailsButton companyID={props.id} />
        </FormTab>
        <FormTab label="resources.CorporateCompany.tabs.events">
          <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {actionTypes.map((option) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                control={
                  <Checkbox
                    color="primary"
                    checked={actionType.includes(option.value)}
                    onChange={() => {
                      handleChange(option.value);
                    }}
                  />
                }
              />
            ))}
          </div>
          <TextInput
            source="message"
            alwaysOn
            onChange={(event: any) => debouncedOnChange(event.target.value)}
          />
          <ReferenceManyField
            filter={{ action: actionType, message: inputValue }}
            pagination={<Pagination />}
            label="resources.CorporateCompany.fields.events"
            reference="CompanyAudit"
            target="corporateCompany.id"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
            getManyReference={(params: any) =>
              GetManyReference({
                ...params,
                filter: { action: actionType, message: inputValue },
              })
            }
          >
            <Datagrid>
              <TextField source="action" />
              <TextField source="message" />
              <DateField source="createdAt" showTime />
              <CustomColumn
                label="Created By"
                customRender={(record: any) => (
                  <div>{record.fromUser ? record.fromUser : record.fromHrManager}</div>
                )}
              />
              <TextField source="fromIntegration" />
              <TextField source="whitelistSize" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.CorporateCompany.tabs.managers">
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.CorporateCompany.manager.registered"
            source="id"
            target="corporateCompany"
            reference="HrManager"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid rowClick="edit">
              <TextField source="email" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.CorporateCompany.tabs.parameter">
          <BooleanInput
            source="canBeListedPublicly"
            label="resources.CorporateCompany.fields.canBeListedPublicly.label"
            initialValue={false}
          />
          <BooleanInput
            source="showIdentifiersOnDashboard"
            label="resources.CorporateCompany.fields.displayLogin.label"
            initialValue={false}
          />
          <BooleanInput
            source="usersCanRegisterWithIdentifier"
            label="resources.CorporateCompany.fields.usersCanRegisterWithIdentifier.label"
            initialValue={false}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.usersCanRegisterWithIdentifier && (
                <>
                  <Labeled
                    label={translate(
                      'resources.CorporateCompany.fields.signupInputName.label'
                    )}
                  />
                  <div className={classes.line}>
                    <TextInput
                      multiline
                      source="signupInputName.fr"
                      label="fr"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputName.en"
                      label="en"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputName.nl"
                      label="nl"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                  </div>
                  <Labeled
                    label={t(
                      'resources.CorporateCompany.fields.signupInputPlaceholder.label'
                    )}
                  />
                  <div className={classes.line}>
                    <TextInput
                      multiline
                      source="signupInputPlaceholder.fr"
                      label="fr"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputPlaceholder.en"
                      label="en"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputPlaceholder.nl"
                      label="nl"
                      inputProps={{ maxLength: 240 }}
                      validate={[required()]}
                    />
                  </div>
                  <Labeled
                    label={t(
                      'resources.CorporateCompany.fields.signupInputHelpingText.label'
                    )}
                  />
                  <div className={classes.line}>
                    <TextInput
                      multiline
                      source="signupInputHelpingText.fr"
                      label="fr"
                      inputProps={{ maxLength: 140 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputHelpingText.en"
                      label="en"
                      inputProps={{ maxLength: 140 }}
                      validate={[required()]}
                    />
                    <TextInput
                      multiline
                      source="signupInputHelpingText.nl"
                      label="nl"
                      inputProps={{ maxLength: 140 }}
                      validate={[required()]}
                    />
                  </div>

                  <TextInput
                    multiline
                    source="companyInvitationLink"
                    label="resources.CorporateCompany.fields.companyInvitationLink.label"
                    inputProps={{ maxLength: 500 }}
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CorporationEdit = translate(withStyles(styles)(CorporationEditWithoutStyle));
