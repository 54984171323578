import { Box, Typography } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import * as React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  required,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { AmountFormatter } from '../../utils';

const CustomProductCreateToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const ProdutCreateNameRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput
        resource="Products"
        source="name.fr"
        label="Traduction française"
        validate={[required()]}
      />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <TextInput
        resource="Products"
        source="name.en"
        label="Traduction anglais"
        validate={[required()]}
      />
    </Box>
    <Box flex={2}>
      <TextInput
        resource="Products"
        source="name.nl"
        label="Traduction néerlandaise"
        validate={[required()]}
      />
    </Box>
  </Box>
);

const ProductPricesDisplayTable: React.FunctionComponent<any> = ({ groups }) => {
  const form = useForm();
  if (!form.getFieldState('productGroupPrices')?.value?.length) {
    const groupDefaultValues = Object.entries(groups).map(([id]) => ({
      id,
      price: 0,
    }));
    form.change('productGroupPrices', groupDefaultValues);
  }
  return (
    <ArrayInput source="productGroupPrices" label="">
      <SimpleFormIterator disableRemove disableAdd>
        <SelectInput
          source="id"
          label="Nom du niveau de financement"
          choices={Object.entries<any>(groups).map(([id, g]) => ({
            id,
            name: g.name.fr,
          }))}
        />

        <FormDataConsumer>
          {({ formData, getSource, scopedFormData, ...rest }: any) =>
            scopedFormData && scopedFormData.id ? (
              <NumberInput
                {...rest}
                {...AmountFormatter()}
                source={getSource('price')}
                label="Prix de l'offre pour ce niveau"
                min={0}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const ProductCreateGeneralInfoRow: React.FunctionComponent = () => (
  <Box display={{ xs: 'block', sm: 'flex' }}>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <BooleanInput resource="Products" source="active" label="Activé" />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <NumberInput
        {...AmountFormatter()}
        min={0}
        resource="Products"
        source="price"
        label="Prix € sans financement"
      />
    </Box>
    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
      <NumberInput resource="Products" source="credits" label="Crédits" min={0} />
    </Box>
  </Box>
);

export const ProductCreate: React.FunctionComponent = (props: any) => {
  const { data } = useGetList('ProductPriceGroups');
  return (
    <Create {...props}>
      <FormWithRedirect
        resource="Products"
        validateOnBlur={true}
        initialValues={{
          name: { fr: '', en: '', nl: '' },
          credits: 0,
          active: true,
          price: 0,
          productGroupPrices: [],
          type: 'CREDITS_SUBSCRIPTION',
        }}
        render={(formProps: any) => (
          <>
            <form>
              <Box marginX="3rem" paddingY="2rem">
                <section>
                  <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h6" gutterBottom>
                        Informations générales
                      </Typography>
                      <ProductCreateGeneralInfoRow />
                    </Box>
                  </Box>
                  <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h6" gutterBottom>
                        Nom du produit
                      </Typography>
                      <ProdutCreateNameRow />
                    </Box>
                  </Box>
                </section>
                {Object.keys(data || {}).length ? (
                  <section>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h5" gutterBottom>
                        Niveaux de financements appliqués
                      </Typography>
                      <ProductPricesDisplayTable groups={data} />
                    </Box>
                  </section>
                ) : null}
              </Box>
            </form>
            <CustomProductCreateToolbar {...formProps} />
          </>
        )}
      />
    </Create>
  );
};
