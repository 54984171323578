import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation deleteManyGyms($ids: [ID!]!) {
    deleteManyGyms(input: { gymIDs: $ids }) {
      data: gym {
        id
      }
    }
  }
`;

export const DeleteMany = (params: any) => {
  console.log('[DELETE_MANY][GYM]', params);

  return {
    variables: {
      ids: params.ids,
    },
    parseResponse: (_: any) => {
      return { data: [] };
    },
    query: DELETE_MUTATION,
  };
};
