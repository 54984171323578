export const subscriptionStatus = (user: any) => {
  // Let's create a simple object holding informations about the subscription
  let result = 'CREDIT';
  if (
    user.status === 'BLOCKED' ||
    user.status === 'TO_BE_BLOCKED' ||
    user.status === 'COVID_BREAK' ||
    user.status === 'PAUSED' ||
    user.status === 'TO_BE_CHANGING_OFFER'
  )
    result = user.status;
  else {
    const active = user.hasValidSubscription;
    const sponsorship = user.corporateCompany
      ? !!user.corporateCompany.isSponsorshipPlatform
      : false;
    const credit = result === 'CREDIT';

    if (active) result = 'ACTIVE_';
    else result = 'INACTIVE_';
    if (credit) result += 'CREDIT_';
    else result += 'B2B_';
    if (sponsorship) result += 'SPONSORSHIP';
    else result += 'NORMAL';
  }
  return result;
};
