import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 180,
      paddingLeft: theme.spacing(2),
    },
    wrapper: {
      // width: 100 + theme.spacing(2),
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      margin: theme.spacing(1),
    },
    code: {
      background: 'grey',
      color: 'white',
    },
    svg: {
      width: 100,
      height: 100,
    },
    polygon: {
      fill: theme.palette.common.white,
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
but:{
padding:10,margin:10,
        cursor:"pointer"
    },
it:{
padding:20}
  })
);

export interface Props {
  record?: any;
  fields: {
    label: string;
    content: (record: NonNullable<Props['record']>) => string;
    isCode: boolean;
  }[];
}

const DebugInfos = ({ record, fields }: Props) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(true);

  const isDev: boolean = useSelector((state: any) => state.isDev);

  const copyEmail = function(){
      navigator.clipboard.writeText(record.email);
  };

  if (!record || !isDev) return null;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <FormControlLabel
          control={
            <Switch checked={checked} onChange={() => setChecked(prev => !prev)} />
          }
          label=""
        />
        <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
          <Paper elevation={4} className={classes.paper}>
            <div><button onClick={copyEmail} className={classes.but} >Copy email</button></div>
            {fields.map(field =>
              field.isCode ? (
                <div>
                  {field.label}
                  <br />
                  <pre>
                    <code className={classes.code}>{field.content(record)}</code>
                  </pre>
                </div>
              ) : (
                <div className={classes.it} >
                  <Link className={classes.it} href={field.content(record)} target="blank_">{field.label}</Link>
                </div>
              )
            )}
          </Paper>
        </Slide>
      </div>
    </div>
  );
};

export default DebugInfos;
