import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation BO_DeleteManyErps($ids: [ID!]!) {
    data: BO_DeleteManyErps(input: { ids: $ids }) {
      id
    }
  }
`;

export const DeleteMany = (params: any) => {
  console.log('[DELETE_MANY][ERP]', params);

  return {
    variables: {
      ids: params.ids,
    },
    parseResponse: (_: any) => {
      return { data: [] };
    },
    query: DELETE_MUTATION,
  };
};
