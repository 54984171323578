import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from 'react-admin';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '550px',
    marginBottom: '24px',
    alignItems: 'center',
    flexWrap: 'nowrap',
    minHeight: '60px',
  },
  linearProgress: {
    width: '100%',
  },
  explanation: {
    flexGrow: 1,
  },
  loader: {
    display: 'inline-flex',
    width: '30px',
  },
});

export type WhiteUploadSpinnerModalProps = {
  open: boolean;
  count?: number;
};

export const WhitelistUploadSpinnerModal = ({
  open,
  count,
}: WhiteUploadSpinnerModalProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const max = count || 10000;
  const refreshIntervalInMs = 1000;
  const endPercentage = 100;

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(5);
  const increment = (endPercentage * refreshIntervalInMs) / max;

  const progressRef = React.useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > endPercentage) {
        setProgress(0);
        setBuffer(increment);
      } else {
        const diff = Math.random() * increment;
        const diff2 = Math.random() * increment;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, refreshIntervalInMs);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.title}>
        {translate(
          'resources.CorporateCompany.actions.whitelistUploadSpinnerModal.title'
        )}
        <LinearProgress
          className={classes.linearProgress}
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.explanation}>
          {translate(
            'resources.CorporateCompany.actions.whitelistUploadSpinnerModal.content'
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
