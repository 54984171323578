import { gql } from '@apollo/client';

const CREATE_MUTATION = gql`
  mutation createPartnerEvent(
    $startDate: DateTime!
    $endDate: DateTime!
    $showDates: Boolean!
    $longitude: Float!
    $latitude: Float!
    $address: String!
    $city: String!
    $zipCode: String!
    $country: GymCountry!
    $radius: Int!
    $backgroundColor: PartnerEventColor!
    $title: TranslatedTitleInput!
    $discount: Int
    $cta: PartnerEventCTA!
    $subtitle: TranslatedTitleInput
    $picture: FileInput!
    $deeplink: String!
  ) {
    createPartnerEvent(
      input: {
        startDate: $startDate
        endDate: $endDate
        showDates: $showDates
        longitude: $longitude
        latitude: $latitude
        address: $address
        city: $city
        zipCode: $zipCode
        country: $country
        radius: $radius
        backgroundColor: $backgroundColor
        title: $title
        discount: $discount
        cta: $cta
        subtitle: $subtitle
        picture: $picture
        deeplink: $deeplink
      }
    ) {
      data: partnerEvent {
        id
        startDate
        endDate
        showDates
        location {
          coordinates
        }
        address
        city
        zipCode
        country
        radius
        backgroundColor
        title {
          fr
          en
          nl
        }
        discount
        cta
        subtitle {
          fr
          en
          nl
        }
        deeplink
      }
    }
  }
`;

const allowedFields = [
  'startDate',
  'endDate',
  'showDates',
  'longitude',
  'latitude',
  'address',
  'city',
  'zipCode',
  'country',
  'radius',
  'backgroundColor',
  'title',
  'discount',
  'cta',
  'subtitle',
  'picture',
  'deeplink',
  'isThereSubtitle',
  'isTherePromotion',
];

const parseResponse = (resp: any) => {
  if (!resp.data && !resp.data.createPartnerEvent) return { data: {} };
  return { data: resp.data.createPartnerEvent.data };
};

export const Create = (params: any) => {
  let payload: any = {};

  console.log(params);

  allowedFields.forEach((key) => {
    if (key === 'showDates') {
      payload.showDates = params.showDates ?? false;
    }
    if (key === 'latitude') {
      payload.latitude = parseFloat(params.data.latitude);
    }
    if (key === 'longitude') {
      payload.longitude = parseFloat(params.data.longitude);
    }
    if (key === 'isThereSubtitle') {
      payload.subtitle = params.data.isThereSubtitle ? params.data.subtitle : null;
    }
    if (key === 'isTherePromotion') {
      payload.discount = params.data.isTherePromotion ? params.data.discount : null;
    } else if (!!params.data?.[key]) {
      payload[key] = params.data[key];
    }
  });

  console.log('[CREATE][HOMEHEADER] = ', payload);
  return {
    variables: payload,
    parseResponse,
    query: CREATE_MUTATION,
  };
};
