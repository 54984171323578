import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
  translate,
} from 'react-admin';
import { PlatformType } from '../../@types/common';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const CorporationFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn resettable />
  </Filter>
);

const CountryDisplayer = ({ record, source, translate }: any) => {
  const country = record[source] === 'FR' ? 'france' : 'belgium';
  return (
    <span>{translate(`resources.CorporateCompany.fields.${source}.${country}`)}</span>
  );
};

const PlateformTypeDisplayer = ({ record, translate }: any) => {
  let platformType: string;
  if (record['isSponsorshipPlatform']) {
    platformType = PlatformType.Sponsorship;
  } else {
    platformType = PlatformType.Employees;
  }

  return (
    <span>
      {translate(`resources.CorporateCompany.fields.platformType.${platformType}`)}
    </span>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({ colorGreen: { color: 'green' }, colorRed: { color: 'red' } })
);

const ActiveDisplayer = ({ record, translate }: any) => {
  const classes = useStyles();

  if (record.active) {
    return <div className={classes.colorGreen}>Active</div>;
  } else {
    return <div className={classes.colorRed}>Inactive</div>;
  }
};

const CorporationListWithoutTranslate = ({ translate, ...props }: any) => (
  <List
    {...props}
    filters={<CorporationFilter />}
    bulkActions={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField label="resources.CorporateCompany.fields.name.label" source="name" />
      <TextField label="resources.CorporateCompany.fields.slug.label" source="slug" />
      <ActiveDisplayer
        {...props}
        source="active"
        label="resources.CorporateCompany.fields.active.label"
      />
      <DateField label="resources.CorporateCompany.fields.createdAt" source="createdAt" />
      <PlateformTypeDisplayer
        {...props}
        translate={translate}
        label="resources.CorporateCompany.fields.platformType.label"
      />
      <CountryDisplayer
        {...props}
        translate={translate}
        source="country"
        label="resources.CorporateCompany.fields.country.label"
      />
    </Datagrid>
  </List>
);

export const CorporationList = translate(CorporationListWithoutTranslate);
