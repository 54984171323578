import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query BO_ErpFirmUserAccountGetOne($id: ID!) {
    data: BO_ErpFirmUserAccountGetOne(id: $id) {
      id
    }
  }
`;

export const GetOne = (params: any) => {
  const preparedVariables = {
    id: params.id.split('__')[1],
  };
  return {
    variables: preparedVariables,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };

      return {
        data: {
          ...resp.data.data,
        },
      };
    },
  };
};
