import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query mindbodyMetadata($id: ID!) {
    data: mindbodyMetadata(id: $id) {
      id
      mandatoryFields
      services {
        id
        name
        price
      }
      paymentMethods {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: { id: params.id },
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      return { data: resp?.data?.data || {} };
    },
  };
};
