import { loginUser, logoutUser } from './login';

const authProvider = {
  login: (params: any) => {
    const { username, password } = params;
    return loginUser(username, password).then((userInfo) => {
      console.log('LOGIN', userInfo);
      localStorage.setItem('sessionToken', userInfo.token);
      localStorage.setItem('roles', JSON.stringify(userInfo.user.roles));
      localStorage.setItem('userID', userInfo.user.id);
      if (window.hj) window.hj('identify', localStorage.getItem('userID'), {});
    });
  },
  logout: () => {
    console.log('LOGIN OUT');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('roles');
    localStorage.removeItem('userID');

    logoutUser()
      .then(() => console.log('Logged Out'))
      .catch((e) => console.log('Logout error', e));

    return Promise.resolve();
  },
  checkError: (error: any) => {
    console.log('CHECK ERROR');
    console.log('LOG ERROR', JSON.stringify(error));

    if (error instanceof Error) {
      if (error.message === 'USER_NOT_AUTHENTICATED') {
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('roles');
        localStorage.removeItem('userID');

        return Promise.reject(`Could no authenticate with username`);
      }
    }

    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('roles');
      localStorage.removeItem('userID');

      return Promise.reject(`Could no authenticate with username: ${status}`);
    }

    return Promise.resolve();
  },

  checkAuth: () => {
    console.log('CHECK AUTH');
    if (!localStorage.getItem('sessionToken') || !localStorage.getItem('userID')) {
      console.log('NOT CONNECTED');
      return Promise.reject({ message: 'Not logged in', redirectTo: '/login' });
    } else {
      const roles = JSON.parse(localStorage.getItem('roles') || '[]') as string[];
      let hasAccess = 0;
      for (const role in roles) {
        if (roles[role] === 'admin') ++hasAccess;
      }
      if (hasAccess === 1) return Promise.resolve();
      return Promise.reject('Not authorized to access BackOffice (ask your manager)');
    }
  },

  getPermissions: () => {
    console.log('GET PEMISSIONS');
    return Promise.resolve();
  },
};

export default authProvider;
