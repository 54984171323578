import React from 'react';
import { Datagrid, TextField, List, Filter, TextInput } from 'react-admin';

const HrManagerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="q" source="q" alwaysOn />
  </Filter>
);

export const HrManagerList = (props: any) => (
  <List filters={<HrManagerFilter />} {...props} undoable="false" bulkActions={false}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
    </Datagrid>
  </List>
);
