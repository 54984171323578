import { IResourceExport } from '../../@types/dataProvider';
import { ExternalIntegrationList } from './ExternalIntegrationList';
import { ExternalIntegrationCreate } from './ExternalIntegrationCreate';

import externalIntegrationProvider from './dataProvider';
import { ExternalIntegrationEdit } from './ExternalIntegrationEdit';

export default {
  name: 'ExternalIntegration',
  resources: {
    list: ExternalIntegrationList,
    create: ExternalIntegrationCreate,
    edit: ExternalIntegrationEdit,
  },
  dataProvider: {
    getOneName: 'externalIntegration',
    useCustomDP: true,
    customDataProvider: externalIntegrationProvider,
  },
} as IResourceExport;
