import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allPasses(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PassFilter
  ) {
    items: allPasses(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      code
      passType
      expirationDate
      activationDate
      transferredAt
      startDate
      lastValidationDate
      createdAt
      status
      penaltyDate
      user {
        id
      }
      gym {
        id
        name
        bookingFlow
        erp {
          id
          url
        }
      }
      erpPassInfos {
        cancelledAt
      }
    }
    total: _allPassesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  return {
    data: resp.data.items.map((pass: any) => {
      return { ...pass, isERP: !!pass.gym.erp };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  if (params.filter && params.filter.code) {
    params.filter.code = params.filter.code.replace(/\s/g, '');
  }
  return {
    variables: {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
