import gql from 'graphql-tag';
import { GetOneParams } from 'ra-core';

const GET_ONE_QUERY = gql`
  query erp($id: ID!) {
    data: erp(id: $id) {
      id
      siteID
      name
      locationID
      mandatoryFields
      paymentMethodID
      erpAvailableSessions {
        erpPassTypeID
        isForBooking
      }
      groupID
      businessUnitID
      planningHoursOffset
      groupCode
      erpPassTypeID
      promoCode
      blacklistedClasses
      whitelistedClasses
      terms
      discountType
      enableStartDateSync
      login
      password
      url
      data
      isLive
    }
  }
`;

export const GetOne = (params: GetOneParams) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      return { data: resp?.data?.data || {} };
    },
  };
};
