import { GetOne } from './GetOne';
import { GetList, GetMany, GetManyReference } from './Many';
import { Update } from './Update';
import { Create } from './Create';
import { Delete } from './Delete';

export default {
  UPDATE: Update,
  CREATE: Create,
  GET_ONE: GetOne,
  GET_LIST: GetList,
  GET_MANY: GetMany,
  DELETE: Delete,
  GET_MANY_REFERENCE: GetManyReference,
};
