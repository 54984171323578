import React from 'react';
import { NumberInput, useNotify } from 'react-admin';

import { AmountFormatter } from '../../../utils';
import { priceValidation } from './common';

export const StandardProcess = ({ classes, prefix, disabled, ...props }: any) => {
  const notify = useNotify();
  prefix = prefix || '';
  return (
    <div className={classes.line}>
      <NumberInput
        {...props}
        source={`${prefix}price`}
        {...AmountFormatter(notify)}
        step={0.1}
        min={0}
        className={classes.inlineBlock}
        validate={!disabled ? priceValidation : undefined}
        disabled={disabled}
      />
    </div>
  );
};
