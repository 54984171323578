import * as React from 'react';
import {
  useRefresh,
  useRedirect,
  useNotify,
  Edit,
  FormTab,
  TabbedForm,
  translate,
  FunctionField
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  organizationUnitsList: {
    display: 'flex',
    width: '30%',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
};

const ErpUserAccountsEditWithoutStyle = ({ classes, translate, ...props }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }: { data: any }) => {
    notify(
      'ra.notification.updated',
      'info',
      { smart_count: 1 },
      props.mutationMode === 'undoable'
    );
    redirect('edit', props.basePath, data.erpFirmUserAccountId, data);
    refresh();
  };
  return (
    <Edit {...props} undoable={false} submitOnEnter={false} onSuccess={onSuccess}>
      <TabbedForm redirect="edit">
        <FormTab>
        <FunctionField
            label="ID"
            render={(record: any) => `${record.id.split('__')[1]}`}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ErpUserAccountsEdit = translate(withStyles(styles)(ErpUserAccountsEditWithoutStyle));
