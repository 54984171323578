import React from 'react';
import { BooleanField, Datagrid, List, NumberField, TextField } from 'react-admin';

export const CreditPackList = (props: any) => (
  <List {...props} undoable="false" bulkActions={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="name.fr" label="Nom" />
      <BooleanField source="active" label="Activé" />
      <NumberField source="credits" label="Nombre de crédits" />
      <NumberField source="price" label="Prix €" />
    </Datagrid>
  </List>
);
