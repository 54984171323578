import React, { useState } from 'react';
import {
  Button,
  useTranslate,
  useRedirect,
  useMutation,
  useNotify,
  Confirm,
  TopToolbar,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';

import GpsOffIcon from '@material-ui/icons/GpsOff';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchLateIcon from '@material-ui/icons/WatchLaterOutlined';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { ISelectItem, PassActions } from '../../@types/common';
import { BookingFlow } from '../gyms/dataProvider/types';
import Input from '@material-ui/core/Input';

type ToolbarButton = JSX.Element | null;
const TriggerRemunerationProcessExceptionMapping: Record<string, string> = {
  ALREADY_USED: 'alreadyUsed',
  NO_REMUNERATION_FOUND: 'noRemunerationFound',
  GENERAL_ERROR: 'GENERAL_ERROR',
  GYM_NOT_FOUND: 'GYM_NOT_FOUND',
  PASS_NOT_FOUND: 'PASS_NOT_FOUND',
};

export const styles = makeStyles({
  buttonsSet: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingBottom: '1rem',
  },
  actionButtonContainer: {
    paddingLeft: '15px',
    '&:first-child': {
      paddingLeft: 0,
    },
    '& > button': {
      height: '100%',
    },
  },
  select: {
    display: 'table',
  },
  errorSelect: {
    color: '#f44336',
  },
  errorLabel: {
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
});

const NO_VALUE = 'NO_VALUE';

const GenericActionButton = (props: any) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const actionReasons = (props.actionReasons as ISelectItem[]) || [];

  const [open, setOpen] = useState(false);
  const [passStatus, setPassStatus] = useState('USED');
  const [actionReason, setActionReason] = useState<string>(NO_VALUE);
  const [manualReason, setManualReason] = useState('');
  const [manualReasonValidationError, setManualReasonValidationError] = useState(false);
  const [validationError, setValidationError] = useState(
    Array.isArray(actionReasons) && actionReasons.length > 0 ? true : false
  );

  const classes = styles();
  const payload = { data: { ...props.data, passStatus }, action: props.action };

  const mutationSuccessCallback = () => {
    notify(`resources.Pass.actions.${props.action}.success`);
    redirectTo('/Pass');
  };

  const mutationFailureCallback = (error: Error) => {
    if (error.message.endsWith('NoPassValidationAttempt')) {
      return notify(`resources.Pass.actions.${props.action}.noAttemptError`, 'warning');
    }

    if (error.message in TriggerRemunerationProcessExceptionMapping) {
      return notify(
        `resources.Pass.actions.${props.action}.${
          TriggerRemunerationProcessExceptionMapping[error.message]
        }.error`,
        'warning'
      );
    }

    return notify(`resources.Pass.actions.${props.action}.error`, 'warning');
  };

  const mutationData = {
    type: 'update',
    resource: 'Pass',
    payload,
  };

  const mutationCallbacks = {
    onSuccess: mutationSuccessCallback,
    onFailure: mutationFailureCallback,
  };

  const [callback, { loading }] = useMutation(mutationData, mutationCallbacks);

  const closeDialog = () => {
    setOpen(false);
  };

  const confirmDialog = () => {
    if (validationError || manualReasonValidationError) {
      return;
    }

    if (actionReason || manualReason) {
      mutationData.payload.data.reason = actionReason || manualReason;
    }
    callback(mutationData);
    setOpen(false);
  };

  const confirmContent: JSX.Element[] = [
    translate(`resources.Pass.actions.${props.action}.modalContent`),
  ];

  if (Array.isArray(actionReasons) && actionReasons.length > 0) {
    const menuItems: any[] = [];
    // add default empty not valid menu item
    menuItems.push(
      <MenuItem key="-99" value={NO_VALUE}>
        Choisissez une raison...
      </MenuItem>
    );

    // add other menu items
    actionReasons.forEach((x, index) =>
      menuItems.push(
        <MenuItem key={index} value={x.id}>
          {x.name}
        </MenuItem>
      )
    );

    const selectContent = (
      <div>
        <Select
          value={actionReason}
          className={[classes.select, validationError ? classes.errorSelect : ''].join(
            ' '
          )}
          onChange={(e) => {
            const value = e.target.value as string;
            setActionReason(value);
            setValidationError(value === NO_VALUE);
            setManualReason('');
            if (value === 'OTHER') {
              setManualReasonValidationError(true);
            }
          }}
        >
          {menuItems}
        </Select>
        {validationError && <p className={classes.errorLabel}>Ce champ est requis</p>}
        {actionReason === 'OTHER' && (
          <div>
            <Input
              value={manualReason}
              onChange={(e: any) => {
                const value = e.target.value;
                setManualReason(value);
                setManualReasonValidationError(!value);
              }}
            />
            {manualReasonValidationError && (
              <p className={classes.errorLabel}>Ce champ est requis</p>
            )}
          </div>
        )}
      </div>
    );

    confirmContent.push(selectContent);
  }

  const isPassLateCancelledOrNoShow = [
    PassActions.CANCEL_LATE_CANCELLED,
    PassActions.CANCEL_NO_SHOW,
  ].includes(props.action);

  if (isPassLateCancelledOrNoShow) {
    const availablePassStatuses = ['USED', 'CANCELLED', 'NO_SHOW', 'LATE_CANCELLED'];

    const selectDescription = translate(
      `resources.Pass.actions.${props.action}.choosePassStatus`
    );

    const selectItems = availablePassStatuses
      .filter((status) => status !== props.data.status)
      .map((status, index) => {
        return (
          <MenuItem key={index} value={status}>
            {translate(`resources.Pass.fields.passStatus.${status}`)}
          </MenuItem>
        );
      });

    const selectElement = (
      <Select
        value={passStatus}
        onChange={(event) => setPassStatus(event.target.value as string)}
        className={classes.select}
      >
        {selectItems}
      </Select>
    );

    confirmContent.push(
      <div>
        {selectDescription}
        {selectElement}
      </div>
    );
  }

  return (
    <div className={classes.actionButtonContainer}>
      <Button
        label={`resources.Pass.actions.${props.action}.name`}
        onClick={() => setOpen(true)}
        color="primary"
        variant="contained"
        disabled={loading}
      >
        {props.icon}
      </Button>
      <Confirm
        isOpen={open}
        title={translate('resources.Pass.actions.modal.title')}
        content={<div>{confirmContent}</div>}
        confirm={translate('resources.Pass.actions.modal.confirm')}
        cancel={translate('resources.Pass.actions.modal.cancel')}
        onConfirm={confirmDialog}
        onClose={closeDialog}
      />
    </div>
  );
};

const getVolatileButton = (pass: any, props: any): ToolbarButton => {
  const bookingFlow = pass.gym.bookingFlow;

  const isGpsAnomalyButton =
    bookingFlow === BookingFlow.OPEN_ACCESS_WITH_ERP ||
    bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL ||
    pass.status === 'PUNCHABLE';

  if (isGpsAnomalyButton) {
    return (
      <GenericActionButton
        className={'VolatileButton'}
        action={PassActions.GPS_ANOMALY}
        icon={<GpsOffIcon />}
        {...props}
      />
    );
  }

  if (
    bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL &&
    pass.status === 'GENERATED'
  ) {
    return (
      <GenericActionButton
        className={'VolatileButton'}
        action={PassActions.VALIDATE_BOOKING}
        icon={<EventAvailableIcon />}
        {...props}
      />
    );
  }

  return (
    <GenericActionButton
      className={'VolatileButton'}
      action={PassActions.PUNCH_PASS}
      icon={<CheckCircleIcon />}
      {...props}
    />
  );
};

export default function ActionButtons(props: any) {
  const classes = styles();
  const { data: pass } = props;

  if (!pass) {
    return null;
  }

  const bookingFlow = pass.gym.bookingFlow;

  const triggerRemunerationButton = (
    <GenericActionButton
      className={'TriggerRemunerationButton'}
      action={PassActions.TRIGGER_REMUNERATION}
      icon={<EuroSymbolIcon />}
      {...props}
    />
  );

  const cancelRemunerationReasons = [
    {
      id: 'Annulation cours par la salle',
      name: 'Annulation cours par la salle',
    },
    {
      id: 'Décalage de cours par la salle',
      name: 'Décalage de cours par la salle',
    },
    {
      id: 'Annulation par le user en accord avec la salle',
      name: 'Annulation par le user en accord avec la salle',
    },
    {
      id: 'OTHER',
      name: 'Autre',
    },
  ];

  const cancelRemunerationButton = (
    <GenericActionButton
      className={'CancelRemunerationButton'}
      action={PassActions.CANCEL_REMUNERATION}
      icon={<EuroSymbolIcon />}
      actionReasons={cancelRemunerationReasons}
      {...props}
    />
  );

  const buttonsSet: { [key: string]: ToolbarButton } = {
    volatileButton: getVolatileButton(pass, props),
    refundButton: null,
    lateCancelButton: null,
    noShowButton: null,
    cancelLateCancelButton: null,
    cancelNoShowButton: null,
  };

  if (!['REFUNDED', 'NO_SHOW', 'LATE_CANCELLED'].includes(pass.status)) {
    const actionReasons = [
      {
        id: 'Annulation cours par la salle',
        name: 'Annulation cours par la salle',
      },
      {
        id: 'Demande décalage cours',
        name: 'Demande décalage cours',
      },
      {
        id: 'Annulation tardive justifiée (maladie, accident, etc)',
        name: 'Annulation tardive justifiée (maladie, accident, etc)',
      },
      {
        id: 'Annulation tardive geste co (une fois / an)',
        name: 'Annulation tardive geste co (une fois / an)',
      },
      {
        id: 'Bug application',
        name: 'Bug application',
      },
    ];

    buttonsSet.refundButton = (
      <GenericActionButton
        className={'RefundButton'}
        action={PassActions.REFUND}
        icon={<EuroSymbolIcon />}
        actionReasons={actionReasons}
        {...props}
      />
    );
  }

  const isLateCancelledButtonSuitable =
    [
      BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL,
      BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
      BookingFlow.IN_APP_BOOKING,
    ].includes(bookingFlow) &&
    (['USED', 'PUNCHABLE', 'CREDITED'].includes(pass.status) ||
      (pass.status === 'EXPIRED' && pass.lastValidationDate));

  if (isLateCancelledButtonSuitable) {
    buttonsSet.lateCancelButton = (
      <GenericActionButton
        className={'LateCancelButton'}
        action={PassActions.LATE_CANCELLED}
        icon={<WatchLateIcon />}
        {...props}
      />
    );

    buttonsSet.noShowButton = (
      <GenericActionButton
        className={'NoShowButton'}
        action={PassActions.NO_SHOW}
        icon={<WarningIcon />}
        {...props}
      />
    );
  }

  if (pass.status === 'LATE_CANCELLED') {
    buttonsSet.cancelLateCancelButton = (
      <GenericActionButton
        className={'CancelLateCancelButton'}
        action={PassActions.CANCEL_LATE_CANCELLED}
        icon={<CancelIcon />}
        {...props}
      />
    );
  }

  if (pass.status === 'NO_SHOW') {
    buttonsSet.cancelNoShowButton = (
      <GenericActionButton
        className={'CancelNoShowButton'}
        action={PassActions.CANCEL_NO_SHOW}
        icon={<CancelIcon />}
        {...props}
      />
    );
  }

  return (
    <TopToolbar className={classes.buttonsSet}>
      {buttonsSet.noShowButton}
      {buttonsSet.lateCancelButton}
      {buttonsSet.cancelNoShowButton}
      {buttonsSet.cancelLateCancelButton}
      {buttonsSet.refundButton}
      {buttonsSet.volatileButton}
      {triggerRemunerationButton}
      {cancelRemunerationButton}
    </TopToolbar>
  );
}
