import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allPassValidations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PassValidationFilter
  ) {
    items: allPassValidations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      createdAt
      type
    }
    total: _allPassValidationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;

  return {
    data: resp.data.items.map((validation: any, index: number) => {
      return {
        id: index,
        ...validation,
      };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    variables: params,
    query: GET_LIST_QUERY,
    parseResponse,
  };
};

export const GetManyReference = (params: any) => {
  if (params.source === 'pass') {
    params.filter[`passId`] = params.id;
  }

  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter[`${params.target}Id`] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse,
  };
};
