import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query BO_allExternalCouponStats(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
  ) {
    items: BO_allExternalCouponStats(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      items {
        id
        stats {
          used
          available
        }
      }
      total
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items.items,
    total: resp.data.items.total,
  };
};

export const GetList = () => {
  return (params: any) => {
    return {
      query: GET_LIST_QUERY,
      variables: {
        page: params.page || 0,
        perPage: params.perPage || 10,
      },
      parseResponse: parseResponseMany,
    };
  };
};

export const GetMany = () => {
  return (params: any) => {
    return {
      query: GET_LIST_QUERY,
      variables: {
        page: params.page || 0,
        perPage: params.perPage || 10,
      },
      parseResponse: parseResponseMany,
    };
  };
};
