import React from 'react';
import { useForm } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { required, TextInput } from 'react-admin';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
};

const ResamaniaV2Form = ({ classes }: any) => {
  const form = useForm();

  form.change('name', 'RESAMANIAV2');

  return (
    <div>
      <TextInput
        source="resamaniav2Configuration.clientID"
        label="clientID"
        validate={[required()]}
      />
      <TextInput
        source="resamaniav2Configuration.clientSecret"
        label="clientSecret"
        validate={[required()]}
      />
      <TextInput
        source="resamaniav2Configuration.clientToken"
        label="clientToken"
        validate={[required()]}
      />
      <TextInput
        source="resamaniav2Configuration.clubID"
        label="clubID"
        validate={[required()]}
      />
      <TextInput source="siteID" label="siteID" validate={[required()]} />
      <PlanningOffsetComponent />
    </div>
  );
};

export default withStyles(styles)(ResamaniaV2Form);
