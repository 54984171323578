import { IResourceExport } from '../../@types/dataProvider';
import GymManagerIcon from '@material-ui/icons/Face';

import { GymManagerList } from './GymManagerList';
import { GymManagerCreate } from './GymManagerCreate';
import { GymManagerEdit } from './GymManagerEdit';

import gymManagerProvider from './dataProvider';

export default {
  name: 'GymManager',
  resources: {
    list: GymManagerList,
    edit: GymManagerEdit,
    create: GymManagerCreate,
  },
  icon: GymManagerIcon,
  dataProvider: {
    getOneName: 'gymManager',
    useCustomDP: true,
    customDataProvider: gymManagerProvider,
  },
} as IResourceExport;
