import PassIcon from '@material-ui/icons/LocalActivity';
import { IResourceExport } from '../../@types/dataProvider';

import { PassEdit } from './PassEdit';
import { PassList } from './PassList';

import passProvider from './dataProvider';
import passValidationProvider from './dataProvider/validations';

export default {
  name: 'Pass',
  resources: {
    list: PassList,
    edit: PassEdit,
  },
  icon: PassIcon,
  dataProvider: {
    getOneName: 'pass',
    useCustomDP: true,
    customDataProvider: passProvider,
  },
} as IResourceExport;

export const passesValidation: IResourceExport = {
  name: 'PassValidation',
  dataProvider: {
    getOneName: 'passValidation',
    useCustomDP: true,
    customDataProvider: passValidationProvider,
  },
};
