import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
} from 'react-admin';

export const HrManagerCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list" initialValues={{ some: 'value' }}>
      <TextInput source="email" validate={required('Email required')} />
      <ReferenceArrayInput
        {...props}
        source="companyIDs"
        reference="CorporateCompany"
        perPage={5}
        sort={{ field: `name`, order: 'ASC' }}
        validate={required('Company selection is mandatory')}
      >
        <AutocompleteArrayInput
          {...props}
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
