export const light = {
  palette: {
    type: 'light',
    secondary: {
      main: '#5e5eff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
};

export const dark = {
  palette: {
    type: 'dark',
    primary: {
      main: '#a9a9ff',
    },
    secondary: {
      main: '#5e5eff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
};
