import ActivityIcon from '@material-ui/icons/Assignment';

import { CouponsList } from './CouponsList';
import { IResourceExport } from '../../@types/dataProvider';

import activityProvider from './dataProvider';

export default {
  name: 'CouponStats',
  resources: {
    list: CouponsList,
  },
  icon: ActivityIcon,
  dataProvider: {
    getOneName: 'externalCouponStats',
    shouldForce: true,
    providerWithIntrospection: activityProvider,
  },
} as IResourceExport;
