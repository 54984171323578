import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { EMAIL_REGEX } from '../../../utils';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
  },
};

const SetCompanyUsers = ({ setUsers, users }: any) => {
  const textAreaPlaceholder = 'email1@example.com\nemail2@example.com';

  const validateEmails = (emails: string[]) => {
    const emailsToSet: string[] = [];
    for (const email of emails) {
      if (EMAIL_REGEX.test(email)) emailsToSet.push(email);
    }
    setUsers(emailsToSet);
  };

  const sanitizeEmails = (input: string) => {
    const trimmedInput = input.trim();
    const userEmails = trimmedInput.split(/\s+/);
    if (userEmails && userEmails.length) {
      const uniqueEmails = userEmails.filter((element, index) => {
        return userEmails.indexOf(element) === index;
      });
      validateEmails(uniqueEmails);
    } else setUsers([]);
  };
  return (
    users && (
      <textarea
        cols={65}
        placeholder={textAreaPlaceholder}
        defaultValue={users.registered}
        onChange={(e) => sanitizeEmails(e.target.value)}
      />
    )
  );
};

export default withStyles(styles)(SetCompanyUsers);
