import PartnerEventIcon from '@material-ui/icons/Event';
import { IResourceExport } from '../../@types/dataProvider';
import partnerEventProvider from './dataProvider';
import PartnerEventList from './PartnerEventList';
import PartnerEventCreate from './PartnerEventCreate';
import PartnerEventEdit from './PartnerEventEdit';

export default {
  name: 'PartnerEvent',
  resources: {
    list: PartnerEventList,
    create: PartnerEventCreate,
    edit: PartnerEventEdit,
  },
  icon: PartnerEventIcon,
  dataProvider: {
    getOneName: 'partnerEvent',
    useCustomDP: true,
    customDataProvider: partnerEventProvider,
  },
} as IResourceExport;
