import gql from 'graphql-tag';

import { gymRemFields } from '../../gymRemunerations/dataProvider/GetOne';
import { FormatFirm } from './utils';

const GET_LIST_QUERY = gql`
  query allFirms(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: FirmFilter
  ) {
    items: allFirms(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      slug
      isUnlimitedOfferFirm
      availableSession {
        admissionChargeInCredits
        yield {
          capping
          discoverySession
        }
      }
      remuneration {
        ...GymRemFields
      }
    }
    total: _allFirmsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
  ${gymRemFields}
`;

const parseResponse = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items.map(FormatFirm),
    total: resp.data.total.count,
  };
};

export const GetList = (params: any) => {
  return {
    query: GET_LIST_QUERY,
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params.filter,
    },
    parseResponse,
  };
};

export const GetMany = (params: any) => {
  return {
    variables: {
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: params,
    },
    query: GET_LIST_QUERY,
    parseResponse,
  };
};
