import gql from 'graphql-tag';

import { FormatFirm } from './utils';

const UPDATE_MUTATION = gql`
  mutation updateFirm($input: UpdateFirmInput!) {
    data: updateFirm(input: $input) {
      id
      name
      slug
      isUnlimitedOfferFirm
      availableSession {
        admissionChargeInCredits
        yield {
          capping
          discoverySession
        }
      }
      promotions {
        startDate
        endDate
        percent
      }
    }
  }
`;

export const Update = (params: any) => {
  const preparedVariables = {
    input: {
      id: params.data.id,
      data: {
        name: params.data.name,
        isUnlimitedOfferFirm: params.data.isUnlimitedOfferFirm,
        availableSession: {
          admissionChargeInCredits: params.data?.admissionChargeInCredits,
          yield: {
            capping: params.data?.capping,
            discoverySession: params.data?.discoverySession,
            offPeak: params.data?.offPeak,
          },
        },
        promotions: params.data.promotions,
      },
    },
  };

  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.data) return { data: {} };

    return { data: FormatFirm(resp.data.data) };
  };

  return {
    variables: preparedVariables,
    parseResponse,
    query: UPDATE_MUTATION,
  };
};
