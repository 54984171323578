import gql from 'graphql-tag';
import * as _ from 'lodash';

import { FormatCorporation } from './CorporationFormatter';
import { EmployeeIdentifiers } from '../utils';

const UPDATE_MUTATION = gql`
  mutation BO_UpdateCorporateCompany(
    $id: ID!
    $name: String
    $logo: FileInput
    $chargeCorporate: Boolean
    $validEmployeeEmailDomains: [String!]
    $emailBlacklistRegex: [String!]
    $validEmployeeEmails: [String!]
    $validEmployees: [ValidEmployeeInput!]
    $validEmployeesAsString: String
    $isSponsorshipPlatform: Boolean
    $sponsorshipPlatformID: ID
    $active: Boolean
    $hasChallenge: Boolean
    $productPriceGroupID: ID
    $sendWelcomeEmail: Boolean
    $showIdentifiersOnDashboard: Boolean
    $usersCanRegisterWithIdentifier: Boolean
    $signupInputPlaceholder: TranslatedContentInput
    $signupInputHelpingText: TranslatedContentInput
    $companyInvitationLink: String
    $canBeListedPublicly: Boolean
    $defaultUserLocale: CorporateLocale
    $signupInputName: TranslatedContentInput
    $billingLink: String
    $reportingLink: String
    $hideCompanyChannel: Boolean
    $sfAccountId: String
    $delegateEligibilityEventsHandlingToHris: Boolean
  ) {
    BO_UpdateCorporateCompany(
      input: {
        corporateCompanyID: $id
        data: {
          name: $name
          logo: $logo
          chargeCorporate: $chargeCorporate
          validEmployeeEmailDomains: $validEmployeeEmailDomains
          emailBlacklistRegex: $emailBlacklistRegex
          validEmployeeEmails: $validEmployeeEmails
          validEmployees: $validEmployees
          validEmployeesAsString: $validEmployeesAsString
          isSponsorshipPlatform: $isSponsorshipPlatform
          sponsorshipPlatformID: $sponsorshipPlatformID
          active: $active
          hasChallenge: $hasChallenge
          productPriceGroupID: $productPriceGroupID
          sendWelcomeEmail: $sendWelcomeEmail
          usersCanRegisterWithIdentifier: $usersCanRegisterWithIdentifier
          signupInputPlaceholder: $signupInputPlaceholder
          signupInputHelpingText: $signupInputHelpingText
          companyInvitationLink: $companyInvitationLink
          canBeListedPublicly: $canBeListedPublicly
          defaultUserLocale: $defaultUserLocale
          signupInputName: $signupInputName
          showIdentifiersOnDashboard: $showIdentifiersOnDashboard
          billingLink: $billingLink
          reportingLink: $reportingLink
          hideCompanyChannel: $hideCompanyChannel
          sfAccountId: $sfAccountId
          delegateEligibilityEventsHandlingToHris: $delegateEligibilityEventsHandlingToHris
        }
      }
    ) {
      data: corporateCompany {
        name
        slug
        id
        country
        stripeAccount
        logo
        createdAt
        chargeCorporate
        plans {
          id
          commitmentInMonth
          priceWithoutDiscount
          price
          category
          categoryDisplayName
          credits
        }
        validEmployeeEmailDomains
        validEmployeeEmails
        # validEmployees {
        #   email
        #   refKey
        # }
        validEmployeesAsString
        emailBlacklistRegex
        isSponsorshipPlatform
        sponsorshipPlatform
        active
        creditCounterpart {
          creditCompany {
            id
            active
          }
        }
        hasChallenge
        sendWelcomeEmail
        showIdentifiersOnDashboard
        usersCanRegisterWithIdentifier
        signupInputHelpingText {
          fr
          en
          nl
        }
        signupInputPlaceholder {
          fr
          en
          nl
        }
        companyInvitationLink
        canBeListedPublicly
        defaultUserLocale
        signupInputName {
          fr
          en
          nl
        }
        billingLink
        reportingLink
        hideCompanyChannel
        sfAccountId
        allowListIntegrationScopeId
        delegateEligibilityEventsHandlingToHris
      }
    }
  }
`;

const CREATE_DISCOUNT_MUTATION = gql`
  mutation createDiscount(
    $amountOff: Float
    $percentOff: Float
    $startDate: DateTime!
    $endDate: DateTime!
    $type: BO_CompanyDiscountType!
    $companyID: ID!
  ) {
    BO_CreateCompanyDiscount(
      input: {
        amountOff: $amountOff
        percentOff: $percentOff
        startDate: $startDate
        endDate: $endDate
        type: $type
        companyID: $companyID
      }
    ) {
      companyDiscount {
        type
      }
    }
  }
`;

const DELETE_DISCOUNT_MUTATION = gql`
  mutation deleteDiscount($companyDiscountID: ID!) {
    BO_DeleteCompanyDiscount(input: { companyDiscountID: $companyDiscountID }) {
      companyDiscount {
        id
      }
    }
  }
`;

const ANONYMIZE_USERS_MUTATION = gql`
  mutation anonymizeCompanyUsers($companyID: ID!) {
    anonymizeCompanyUsers(input: { companyID: $companyID }) {
      users {
        id
      }
    }
  }
`;

const BLOCK_USERS_MUTATION = gql`
  mutation blockCompanyUsers($companyID: ID!) {
    blockCompanyUsers(input: { companyID: $companyID }) {
      corporateCompany {
        id
      }
    }
  }
`;

const MIGRATE_USERS_MUTATION = gql`
  mutation BO_MigrateCorporateUsers($companyToID: ID!, $userEmails: [String!]!) {
    BO_MigrateCorporateUsers(
      input: { corporateToID: $companyToID, employeeEmails: $userEmails }
    ) {
      status
    }
  }
`;

const MIGRATE_USER_EMAILS_MUTATION = gql`
  mutation BO_MigrateCorporateUserEmails(
    $companyID: ID!
    $employeeEmails: [BO_MigrateCorporateUserEmailsFromTo!]!
  ) {
    BO_MigrateCorporateUserEmails(
      input: { companyID: $companyID, employeeEmails: $employeeEmails }
    ) {
      status
      conflictedUserEmails
    }
  }
`;

const OFFER_PULSES_MUTATION = gql`
  mutation BO_OfferPulsesMutation(
    $companyID: ID!
    $pulsesAmount: Int!
    $moneyAmount: Int!
    $userEmails: [String!]!
  ) {
    BO_OfferPulsesMutation(
      input: {
        companyID: $companyID
        pulsesAmount: $pulsesAmount
        moneyAmount: $moneyAmount
        employeeEmails: $userEmails
      }
    ) {
      status
    }
  }
`;

const validEmployeesSortFn = (
  validEmployeeA: EmployeeIdentifiers,
  validEmployeeB: EmployeeIdentifiers
) => {
  return validEmployeeA.refKey
    .toLowerCase()
    .localeCompare(validEmployeeB.refKey.toLowerCase());
};

export const Update = (params: any) => {
  if (params.data.createDiscount) {
    // convert potential amount in cents
    if (params.data.discount.amountOff) {
      params.data.discount.amountOff = params.data.discount.amountOff * 100;
    }
    return {
      variables: params.data.discount,
      parseResponse: (resp: any) => {
        return { data: {} };
      },
      query: CREATE_DISCOUNT_MUTATION,
    };
  } else if (params.data.anonymizeUsers) {
    return {
      variables: { companyID: params.data.id },
      parseResponse: (resp: any) => {
        return { data: {} };
      },
      query: ANONYMIZE_USERS_MUTATION,
    };
  } else if (params.data.deleteDiscount) {
    return {
      variables: { companyDiscountID: params.data.id },
      parseResponse: (resp: any) => {
        return { data: {} };
      },
      query: DELETE_DISCOUNT_MUTATION,
    };
  } else if (params.data.blockUsers) {
    return {
      variables: { companyID: params.data.id },
      parseResponse: (resp: any) => {
        return { data: {} };
      },
      query: BLOCK_USERS_MUTATION,
    };
  } else if (params.data.migrateUsers) {
    return {
      variables: {
        companyToID: params.data.companyToID,
        userEmails: params.data.userEmails,
      },
      parseResponse: (resp: any) => {
        if (!resp.data || !resp.data.BO_MigrateCorporateUsers.status) return { data: {} };
        return { data: { status: resp.data.BO_MigrateCorporateUsers.status, id: 1 } };
      },
      query: MIGRATE_USERS_MUTATION,
    };
  } else if (params.data.offerPulses) {
    return {
      variables: {
        companyID: params.data.companyID,
        pulsesAmount: params.data.pulsesAmount,
        moneyAmount: params.data.moneyAmount,
        userEmails: params.data.userEmails,
      },
      // change once req will be ready
      parseResponse: (resp: any) => {
        if (!resp.data || !resp.data.BO_OfferPulsesMutation.status) return { data: {} };
        return { data: { status: resp.data.BO_OfferPulsesMutation.status, id: 1 } };
      },
      query: OFFER_PULSES_MUTATION,
    };
  } else if (params.data.migrateUserEmails) {
    return {
      variables: {
        companyID: params.data.companyID,
        employeeEmails: params.data.employeeEmails,
      },
      parseResponse: (resp: any) => {
        if (!resp.data || !resp.data.BO_MigrateCorporateUserEmails.status)
          return { data: {} };
        return {
          data: {
            status: resp.data.BO_MigrateCorporateUserEmails.status,
            id: 1,
            conflictedEmails:
              resp.data.BO_MigrateCorporateUserEmails.conflictedUserEmails,
          },
        };
      },
      query: MIGRATE_USER_EMAILS_MUTATION,
    };
  }

  const allowedFields = [
    'name',
    'logo',
    'chargeCorporate',
    'validEmployeeEmailDomains',
    'emailBlacklistRegex',
    'validEmployeeEmails',
    'validEmployees',
    'sponsorshipPlatformID',
    'isSponsorshipPlatform',
    'active',
    'creditCounterpart',
    'hasChallenge',
    'productPriceGroup',
    'sendWelcomeEmail',
    'showIdentifiersOnDashboard',
    'usersCanRegisterWithIdentifier',
    'signupInputPlaceholder',
    'signupInputHelpingText',
    'companyInvitationLink',
    'canBeListedPublicly',
    'defaultUserLocale',
    'signupInputName',
    'billingLink',
    'reportingLink',
    'hideCompanyChannel',
    'sfAccountId',
    'delegateEligibilityEventsHandlingToHris',
  ];
  const toUpdate: any = {
    id: params.data.id,
  };

  allowedFields.forEach((key) => {
    if (!_.isEqual(params.data[key], params.previousData[key])) {
      if (key === 'validEmployeeEmailDomains') {
        toUpdate.validEmployeeEmailDomains = params.data.validEmployeeEmailDomains.map(
          (email: any) => email.value
        );
      } else if (key === 'emailBlacklistRegex') {
        toUpdate.emailBlacklistRegex = params.data.emailBlacklistRegex.map(
          (email: any) => email.value
        );
      } else if (key === 'validEmployeeEmails') {
        if (
          params.data.validEmployeeEmails &&
          !params.data.usersCanRegisterWithIdentifier &&
          !_.isEqual(
            params.data.validEmployeeEmails.sort(),
            (params.previousData.validEmployeeEmails || []).sort() // previous WL may be null
          )
        ) {
          toUpdate.validEmployeeEmails = params.data.validEmployeeEmails.sort();
        }
      } else if (key === 'validEmployees') {
        if (
          params.data.validEmployees &&
          params.data.usersCanRegisterWithIdentifier &&
          !_.isEqual(
            params.data.validEmployees.sort(validEmployeesSortFn),
            (params.previousData.validEmployees || []).sort(validEmployeesSortFn) // previous WL may be null
          )
        ) {
          toUpdate.validEmployees = params.data.validEmployees
            .map((validEmployee: EmployeeIdentifiers) => {
              return {
                refKey: validEmployee.refKey,
                email: validEmployee.email,
              };
            })
            .sort(validEmployeesSortFn);
          toUpdate.validEmployeesAsString = JSON.stringify(toUpdate.validEmployees);
          delete toUpdate.validEmployees;
        }
      } else if (key === 'productPriceGroup') {
        toUpdate.productPriceGroupID = params.data.productPriceGroup.id;
      } else {
        toUpdate[key] = params.data[key];
      }
    }
  });
  if (Object.keys(toUpdate).length === 1) throw new Error('No change to save!');

  console.log('[UPDATE][CORPORATE_COMPANY]', toUpdate);

  return {
    variables: toUpdate,
    parseResponse: (resp: any) => {
      if (!resp.data || !resp.data.BO_UpdateCorporateCompany.data) return { data: {} };
      return { data: FormatCorporation(resp.data.BO_UpdateCorporateCompany.data) };
    },
    query: UPDATE_MUTATION,
  };
};
