import React, { useState, ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { Editor } from '@tinymce/tinymce-react';
import * as _ from 'lodash';
import { TextInput } from 'react-admin';

function extractContent(s: string) {
  var span = document.createElement('span');
  span.innerHTML = s;
  const result = span.innerText || span.textContent;
  span.remove();
  return result;
}

export const RichInput = ({
  source,
  settings,
  charCounterDisplay,
}: {
  source: string;
  settings: any['init'];
  charCounterDisplay?: (counter: number) => ReactNode;
}) => {
  const form = useForm();
  const initalState = _.get(form.getState().values, source, '');
  const [init] = useState(initalState);
  const [desc, setDesc] = useState(extractContent(initalState));
  return (
    <div>
      <Editor
        plugins="lists emoticons preview link"
        toolbar="undo redo bold italic underline link emoticons preview removeformat"
        init={settings}
        initialValue={init}
        onEditorChange={_.debounce((text) => {
          setDesc(extractContent(text));
          return form.change(source, text);
        }, 500)}
      />
      {charCounterDisplay && desc && charCounterDisplay(desc.length)}
      {/* Persist form data even when switching tab (`FormTab`) */}
      <TextInput source={source} style={{ display: 'none' }} />
    </div>
  );
};
