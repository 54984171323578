export enum PartnerEventCta {
  Booking = 'BOOKING',
  Participate = 'PARTICIPATE',
}

export enum PartnerEventColor {
  Lemon = 'LEMON',
  Peach = 'PEACH',
  Pearl = 'PEARL',
}
