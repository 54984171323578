import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { NumberInput } from 'react-admin';

const styles = {};
const useClasses = makeStyles({
  line: {
    display: 'flex',
    width: 'calc(100% - 30px)',
    margin: '15px',
    '& >div:first-child': {
      marginRight: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    '& >div:nth-child(2)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

const PlanningOffsetComponent = () => {
  const classes = useClasses();

  return (
    <>
      <div className={classes.line}>
        <NumberInput
          source="planningHoursOffset"
          defaultValue={30 * 24}
          format={(value: number) => (value > 0 ? Number(value) : 30 * 24)}
          label="resources.Erp.fields.planningHoursOffset"
          parse={(value: number) => (value > 0 ? Number(value) : 30 * 24)}
        />
        <NumberInput
          disabled
          source="planningHoursOffset"
          format={(value: number) => (value > 0 ? Math.ceil(Number(value) / 24) : 30)}
          label=""
          helperText="resources.Erp.fields.planningHoursOffsetHelper"
          parse={(value: number) => Math.ceil(Number(value) / 24)}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(PlanningOffsetComponent);
