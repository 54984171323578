import React, { useState, useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale, Loading } from 'react-admin';

import { merge } from 'lodash';

import themeReducer from './theme/reducer';
import themeSaga from './theme/saga';
import isDevReducer from './debug/reducer';
import isDevSaga from './debug/saga';
import customRoutes from './routes';
import authProvider from './authProvider/authProvider';
import reloadedDataProvider from './dataProvider/dataProvider';

import Dashboard from './dashboard';

import resources from './resources';

import addUploadFeature from './UploadFeature';

import { Layout } from './layout';

import frenchMessages from 'ra-language-french';
import gymlibMessages from './i18n';
import polyglotI18nProvider from 'ra-i18n-polyglot';

if (window.hj) window.hj('identify', localStorage.getItem('userID'), {});

const messages: any = {
  fr: merge(frenchMessages, gymlibMessages.fr),
};

const i18nProvider = polyglotI18nProvider(() => messages['fr'], 'fr');

const App = () => {
  const [dataProvider, setDataProvider] = useState<
    ((type: any, resource: any, params: any) => any) | null
  >(null);

  useEffect(() => {
    reloadedDataProvider(resources).then((dataProvider: any) => {
      setDataProvider(() => addUploadFeature(dataProvider));
    });
  }, []);

  if (!dataProvider)
    return (
      <Loading
        // Weird but it's because the i18nProvider is not yet loaded
        loadingPrimary={messages['fr']['ra']['page']['loading']}
        loadingSecondary={messages['fr']['ra']['message']['loading']}
      />
    );

  return (
    <Admin
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      customReducers={{ theme: themeReducer, isDev: isDevReducer }}
      customSagas={[themeSaga, isDevSaga]}
      customRoutes={customRoutes}
      locale={resolveBrowserLocale()}
      i18nProvider={i18nProvider}
      layout={Layout}
      initialState={{
        theme: localStorage.getItem('theme') || 'light',
        isDev: localStorage.getItem('isDev') === 'true',
      }}
      dashboard={Dashboard}
      loading={<Loading />}
    >
      <style>{'.tox-notifications-container {display: none !important;}'}</style>
      {Object.values(resources).map((resource) => {
        if (resource.resources) {
          return (
            <Resource key={resource.name} name={resource.name} {...resource.resources} />
          );
        } else {
          // fake resources
          return <Resource key={resource.name} name={resource.name} />;
        }
      })}
    </Admin>
  );
};

export default App;
