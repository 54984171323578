export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
}

export const CHANGE_THEME = 'CHANGE_THEME';

export interface Action {
  type: 'CHANGE_THEME';
  payload: ThemeType;
}

export const changeTheme: (theme: ThemeType) => Action = (theme: ThemeType) => ({
  type: CHANGE_THEME,
  payload: theme,
});
