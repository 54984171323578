import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { BookingFlow, OpenAccessBookingFlows } from './dataProvider/types';
import { BooleanInput } from 'react-admin';

export const AutomaticCheckinComponent = (props: any) => {
  const form = useForm();
  const [automaticCheckinReadonly, setAutomaticCheckinReadonly] = useState(false);
  const { formData, classes, initialValue } = props;

  const { payWithPassCode, bookingFlow } = formData || {};

  useEffect(() => {
    if (payWithPassCode && bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL) {
      form.change('automaticCheckin', true);
      setAutomaticCheckinReadonly(true);
    } else if (OpenAccessBookingFlows.has(bookingFlow)) {
      form.change('automaticCheckin', false);
      setAutomaticCheckinReadonly(true);
    } else {
      form.change('automaticCheckin', true);
      setAutomaticCheckinReadonly(false);
    }
  }, [payWithPassCode, bookingFlow, form]);

  return (
    <div className={classes.line}>
      <BooleanInput
        className={classes.inlineBlock}
        source="automaticCheckin"
        label="resources.Gym.fields.automaticCheckin.label"
        disabled={automaticCheckinReadonly}
        initialValue={initialValue || formData.automaticCheckin}
      />
    </div>
  );
};
