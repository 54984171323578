import gql from 'graphql-tag';

const CREATE_MUTATION = gql`
  mutation createHrManager($email: String!, $companyIDs: [ID!]!) {
    data: createHrManager(input: { email: $email, companyIDs: $companyIDs }) {
      id
      email
      companies {
        id
      }
      status
    }
  }
`;

export const Create = (params: any) => {
  console.log('[CREATE][HR_MANAGER]', params);

  return {
    variables: { email: params.data.email, companyIDs: params.data.companyIDs },
    query: CREATE_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
          companyIDs: resp.data.data.companies.map((company: any) => company.id),
        },
      };
    },
  };
};
