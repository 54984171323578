import gql from 'graphql-tag';
import * as _ from 'lodash';
import { EMAIL_REGEX } from '../../../utils';
import { extractContent } from '../utils';
import { formatDescription, formatOpeningTimes, FormatGym, GymFragment } from './utils';

const UPDATE_MUTATION = gql`
  mutation updateGym(
    $id: ID!
    $active: Boolean
    $temporarilyClosed: Boolean
    $name: String
    $slug: String
    $latitude: Float
    $longitude: Float
    $streetNumber: String
    $streetName: String
    $zipCode: String
    $city: String
    $country: String
    $locale: String
    $openingTimes: OpeningTimesInput
    $emailPreferences: [GymEmailPreferenceInput!]
    $biOpsHooks: [BiOpsHookInput!]
    $multilingualDescriptions: GymDescriptionsInput
    $multilingualShortDescriptions: GymDescriptionsInput
    $activitiesIds: [ID!]
    $servicesIds: [ID!]
    $equipmentIds: [ID!]
    $infrastructureIds: [ID!]
    $firmID: ID
    $bookingFlow: BookingFlow
    $cancellationTimeInMinutes: Int
    $pictures: GymPicturesInput
    $indexSEO: Boolean
    $reservationProcess: GymReservationProcessInput
    $erpID: ID
    $punchRadius: Float
    $unlimitedJokers: Boolean
    $availableSession: BO_AvailableSessionInput
    $corporationIDs: [ID!]
    $accessDescription: String
    $area: Float
    $promotions: [BO_GymFirmPromotionInput!]
    $hasLimitedSession: Boolean
    $externalID: String
    $payWithPassCode: Boolean
    $automaticCheckin: Boolean
    $lastOpeningDate: DateTime
    $usePublicPriceForPayment: Boolean
    $mainActivity: ID
    $poolLength: Float
    $website: String
    $instagramUsername: String
    $isUnlimitedOfferGym: Boolean
    $isOnline: Boolean
  ) {
    updateGym(
      input: {
        gymID: $id
        data: {
          active: $active
          temporarilyClosed: $temporarilyClosed
          name: $name
          slug: $slug
          latitude: $latitude
          longitude: $longitude
          address: $streetName
          streetNumber: $streetNumber
          zipCode: $zipCode
          city: $city
          country: $country
          locale: $locale
          openingTimes: $openingTimes
          emailPreferences: $emailPreferences
          biOpsHooks: $biOpsHooks
          multilingualDescriptions: $multilingualDescriptions
          multilingualShortDescriptions: $multilingualShortDescriptions
          activitiesIds: $activitiesIds
          servicesIds: $servicesIds
          equipmentIds: $equipmentIds
          infrastructureIds: $infrastructureIds
          firmID: $firmID
          bookingFlow: $bookingFlow
          cancellationTimeInMinutes: $cancellationTimeInMinutes
          pictures: $pictures
          indexSEO: $indexSEO
          reservationProcess: $reservationProcess
          erpID: $erpID
          punchRadius: $punchRadius
          unlimitedJokers: $unlimitedJokers
          availableSession: $availableSession
          corporationIDs: $corporationIDs
          accessDescription: $accessDescription
          area: $area
          promotions: $promotions
          hasLimitedSession: $hasLimitedSession
          externalID: $externalID
          payWithPassCode: $payWithPassCode
          automaticCheckin: $automaticCheckin
          lastOpeningDate: $lastOpeningDate
          usePublicPriceForPayment: $usePublicPriceForPayment
          mainActivity: $mainActivity
          poolLength: $poolLength
          website: $website
          instagramUsername: $instagramUsername
          isUnlimitedOfferGym: $isUnlimitedOfferGym
          isOnline: $isOnline
        }
      }
    ) {
      data: gym {
        ...GymFields
      }
    }
  }

  ${GymFragment}
`;

const SET_GYM_SHOWCASES_MUTATION = gql`
  mutation setGymShowcases($input: SetGymShowcasesInput!) {
    setGymShowcases(input: $input) {
      gymIDs
    }
  }
`;

export const Update = (params: any) => {
  if (params.action === 'GymShowcase') {
    return {
      variables: { input: { gymShowcases: params.data.gymShowcases } },
      query: SET_GYM_SHOWCASES_MUTATION,
      parseResponse: (resp: any) => {
        if (!resp.data && !resp.data.setGymShowcases) return { data: {} };
        // RA absolutly need to return id on update mutation. As we use a trick, we return null
        return { data: { ...resp.data.setGymShowcases, id: null } };
      },
    };
  }

  const allowedFields = [
    'active',
    'temporarilyClosed',
    'name',
    'slug',
    'latitude',
    'longitude',
    'streetName',
    'streetNumber',
    'zipCode',
    'city',
    'country',
    'locale',
    'gymType',
    'openingTimes',
    'emailPreferences',
    'biOpsHooks',
    'multilingualDescriptions',
    'multilingualShortDescriptions',
    'activitiesIds',
    'servicesIds',
    'products',
    'firm',
    'bookingFlow',
    'cancellationTimeInMinutes',
    'pictures',
    'addPictures',
    'indexSEO',
    'reservationProcess',
    'erpID',
    'punchRadius',
    'unlimitedJokers',
    'corporationIDs',
    'commission',
    'availableSession',
    'accessDescription',
    'area',
    'promotions',
    'hasLimitedSession',
    'externalID',
    'payWithPassCode',
    'automaticCheckin',
    'lastOpeningDate',
    'usePublicPriceForPayment',
    'mainActivity',
    'equipmentIds',
    'infrastructureIds',
    'poolLength',
    'website',
    'instagramUsername',
    'isUnlimitedOfferGym',
    `isOverridingUnlimitedOfferFirm`,
    'isOnline',
  ];
  const toUpdate: any = {
    id: params.data.id,
    firmID: params.data.firm ? params.data.firm.id : null,
  };

  allowedFields.forEach((key) => {
    if (_.isEqual(params.data[key], params.previousData[key])) {
      return;
    }

    if (key === 'multilingualDescriptions' || key === 'multilingualShortDescriptions') {
      // TODO: handle multi-lingual input
      if (params.data[key].fr.length === 0)
        throw new Error('Vous ne pouvez pas avoir une description vide');
      const processed = formatDescription(params.data[key].fr);
      toUpdate[key] = { fr: processed, en: processed };
    } else if (key === 'reservationProcess') {
      const general = formatDescription(params.data.reservationProcess.general);

      toUpdate.reservationProcess = {};
      toUpdate.reservationProcess.general = general;

      if (params.data.reservationProcess.email) {
        toUpdate.reservationProcess.email = params.data.reservationProcess.email;
      }

      if (params.data.reservationProcess.phoneNumber) {
        toUpdate.reservationProcess.phoneNumber =
          params.data.reservationProcess.phoneNumber;
      }

      if (params.data.reservationProcess.dynamicSchedule) {
        toUpdate.reservationProcess.dynamicSchedule =
          params.data.reservationProcess.dynamicSchedule;
      }
    } else if (key === 'accessDescription') {
      if (extractContent(params.data[key]).length > 200)
        throw new Error("La description d'accès ne doit pas dépasser 200 caractères");
      else toUpdate.accessDescription = formatDescription(params.data[key]);
    } else if (key === 'openingTimes') {
      const newOpeningTimes = formatOpeningTimes(params.data.openingTimes);
      const oldOpeningTimes = formatOpeningTimes(params.previousData.openingTimes);
      if (!_.isEqual(newOpeningTimes, oldOpeningTimes)) {
        toUpdate.openingTimes = newOpeningTimes;
      }
    } else if (key === 'emailPreferences') {
      toUpdate.emailPreferences = params.data.emailPreferences.map(
        (emailPreference: any) => ({
          event: emailPreference.event,
          emails: emailPreference.emails.filter((email: string) =>
            EMAIL_REGEX.test(email)
          ),
          isEnabled: !!emailPreference.isEnabled,
        })
      );
    } else if (key === 'biOpsHooks') {
      toUpdate.biOpsHooks = [];
      const handledEvents: string[] = [];
      params.data.biOpsHooks.forEach((biOpsHook: any) => {
        if (handledEvents.includes(biOpsHook.event)) {
          throw new Error(`BizOps Event ${biOpsHook.event} is duplicated`);
        }
        handledEvents.push(biOpsHook.event);
        toUpdate.biOpsHooks.push({
          event: biOpsHook.event,
          url: biOpsHook.url,
          isEnabled: !!biOpsHook.isEnabled,
        });
      });
      if (toUpdate.biOpsHooks.length === 0) delete toUpdate.biOpsHooks;
    } else if (key === 'pictures') {
      toUpdate.pictures = {
        ...toUpdate.pictures,
        update: params.data.pictures.map((picture: any) => ({
          url: picture.url,
          isMain: !!picture.isMain,
        })),
      };
    } else if (key === 'addPictures') {
      let count = 0;
      toUpdate.pictures = {
        ...toUpdate.pictures,
        add: params.data.addPictures.map((picture: any) => ({
          file: picture,
          isMain: params.previousData.pictures.length ? false : !count++,
        })),
      };
    } else if (key === 'punchRadius') {
      if (params.data.punchRadius >= 0) toUpdate.punchRadius = params.data.punchRadius;
    } else if (key === 'availableSession') {
      // Craft mandatory fields
      toUpdate['availableSession'] = {
        admissionCharge: {
          credits:
            params.data?.availableSession?.admissionCharge?.credits ??
            params.previousData?.availableSession?.admissionCharge?.credits,
          isGymLevel:
            params.data?.availableSession?.admissionCharge?.isGymLevel ??
            params.previousData?.availableSession?.admissionCharge?.isGymLevel,
          commission:
            params.data?.availableSession?.admissionCharge?.commission ??
            params.previousData?.availableSession?.admissionCharge?.commission,
        },
        discoveryPass:
          params.data?.availableSession?.discoveryPass ??
          params.previousData?.availableSession?.discoveryPass,
      };

      if (
        !_.isEqual(
          _.get(params.data, 'availableSession.yield'),
          _.get(params.previousData, 'availableSession.yield')
        )
      ) {
        const subCappingObj = {
          capping:
            params.data?.availableSession?.yield?.capping?.capping ??
            params.previousData?.availableSession?.yield?.capping?.capping,
          skipFirmCapping:
            params.data?.availableSession?.yield?.capping?.skipFirmCapping ??
            params.previousData?.availableSession?.yield?.capping?.skipFirmCapping,
        };
        const subOffPeakObj = {
          costInCredits:
            params.data?.availableSession?.yield?.offPeak?.costInCredits ??
            params.previousData?.availableSession?.yield?.offPeak?.costInCredits,
          intervals:
            params.data?.availableSession?.yield?.offPeak?.intervals ??
            params.previousData?.availableSession?.yield?.offPeak?.intervals,
          skipFirm:
            params.data?.availableSession?.yield?.offPeak?.skipFirm ??
            params.previousData?.availableSession?.yield?.offPeak?.skipFirm,
        };
        toUpdate['availableSession']['yield'] = {
          capping:
            subCappingObj.capping != null && subCappingObj.skipFirmCapping != null
              ? subCappingObj
              : undefined,
          offPeak:
            subOffPeakObj.costInCredits != null &&
            subOffPeakObj.intervals != null &&
            subOffPeakObj.skipFirm != null
              ? subOffPeakObj
              : undefined,
          discoverySession:
            params.data?.availableSession?.yield?.discoverySession ??
            params.previousData?.availableSession?.yield?.discoverySession,
        };
      }
    } else if (
      key === 'payWithPassCode' &&
      params.previousData.payWithPassCode &&
      !params.data.payWithPassCode
    ) {
      toUpdate.payWithPassCode = false;
    } else if (
      key === 'automaticCheckin' &&
      params.previousData.automaticCheckin &&
      !params.data.automaticCheckin
    ) {
      toUpdate.automaticCheckin = false;
    } else if (
      key === 'usePublicPriceForPayment' &&
      params.previousData.usePublicPriceForPayment &&
      !params.data.usePublicPriceForPayment
    ) {
      toUpdate.usePublicPriceForPayment = false;
    } else if (key === 'mainActivity') {
      if (params.data.mainActivity.length) {
        toUpdate.mainActivity = params.data.mainActivity[0];
      }
    } else if (key === 'latitude' || key === 'longitude') {
      toUpdate.mainActivity = params.data.mainActivity[0];
      toUpdate[key] = parseFloat(params.data[key]);
    } else if (
      key === 'isUnlimitedOfferGym' ||
      key === `isOverridingUnlimitedOfferFirm`
    ) {
      toUpdate.isUnlimitedOfferGym =
        !params.data.firm || params.data.isOverridingUnlimitedOfferFirm
          ? !!params.data.isUnlimitedOfferGym
          : undefined;
    } else {
      toUpdate[key] = params.data[key];
    }
  });

  if (Array.isArray(params.data.promotions) && !toUpdate.promotions) {
    toUpdate.promotions = [...params.data.promotions];
  }

  if (Object.keys(toUpdate).length === 1) throw new Error('No change to save!');

  const parseResponse = (resp: any) => {
    if (!resp.data && !resp.data.updateGym) return { data: {} };

    return { data: FormatGym(resp.data.updateGym.data) };
  };

  console.log('[UPDATE][GYM]', toUpdate);

  return {
    variables: toUpdate,
    parseResponse,
    query: UPDATE_MUTATION,
  };
};
