import ActivityIcon from '@material-ui/icons/Assignment';

import { ActivityList } from './ActivityList';
import { ActivityEdit } from './ActivityEdit';
import { ActivityCreate } from './ActivityCreate';
import { IResourceExport } from '../../@types/dataProvider';

import activityProvider from './dataProvider';

export default {
  name: 'Activity',
  resources: {
    list: ActivityList,
    edit: ActivityEdit,
    create: ActivityCreate,
  },
  icon: ActivityIcon,
  dataProvider: {
    getOneName: 'activity',
    useCustomDP: true,
    customDataProvider: activityProvider,
  },
} as IResourceExport;
