import React, { useState, useCallback, useEffect } from 'react';
import {
  GoogleMap,
  Circle,
  Marker,
  useLoadScript,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { withStyles } from '@material-ui/core/styles';
import { TextInput, required, NumberInput, SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { useNotify } from 'ra-core';

const styles = {
  mapContainerElement: {
    height: '500px',
    display: 'flex',
    margin: '15px',
    width: 'calc(100%-30px)',
    '& >div:nth-child(2)': {
      width: 'calc(50% - 30px)',
      margin: '15px',
      height: '370px',
      display: 'flex',
    },
  },
  searchElement: {
    display: 'flex',
  },
  line: {
    display: 'inline-flex',
    width: '100%',
    '& >div:nth-child(2)': {
      marginLeft: '15px',
    },
  },
  halfLine: {
    display: 'inline-flex',
    width: '50%',
  },
};

interface MapMarkerProps {
  position: google.maps.LatLngLiteral;
  onPositionChanged: (newPosition: google.maps.LatLngLiteral) => void;
}

const MapMarker = ({ position, onPositionChanged }: MapMarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const onLoad = useCallback((marker) => {
    setMarker(marker);
  }, []);

  return (
    <Marker
      onLoad={onLoad}
      position={position}
      onDragEnd={() => {
        if (!marker) return;
        const newPosition = marker.getPosition();
        if (!newPosition) return;
        return onPositionChanged(newPosition.toJSON());
      }}
      draggable
    />
  );
};

interface MapProps {
  formData: any;
  classes: any;
}

const plugins = ['places'];

const MapWithoutStyle = ({ classes, formData, ...props }: MapProps) => {
  const form = useForm();

  const notify = useNotify();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    // @ts-ignore
    libraries: plugins,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: formData.latitude ?? 48.858282,
    lng: formData.longitude ?? 2.3833525999999665,
  });

  useEffect(() => {
    if (formData.latitude && formData.longitude) {
      setCenter({
        lat: formData.latitude,
        lng: formData.longitude,
      });
    }
  }, [formData.latitude, formData.longitude]);

  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>(center);

  useEffect(() => {
    setMarkerPosition(center);
  }, [center]);

  const changeMarkerPosition = (pos: google.maps.LatLngLiteral) => {
    if (!map) return;

    map.setCenter(pos);
    setMarkerPosition(pos);

    form.change('latitude', pos.lat);
    form.change('longitude', pos.lng);
  };

  const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
    if (!place.geometry || !place.address_components) return;

    const streetNumber = place.address_components.find((addressType: any) =>
      addressType.types.includes('street_number')
    );
    const routeName = place.address_components.find((addressType: any) =>
      addressType.types.includes('route')
    );

    const zipCode = place.address_components.find((addressType: any) =>
      addressType.types.includes('postal_code')
    );
    const city = place.address_components.find((addressType: any) =>
      addressType.types.includes('locality')
    );
    const country = place.address_components.find((addressType: any) =>
      addressType.types.includes('country')
    );

    if (country && !['BE', 'FR'].includes(country.short_name)) {
      return notify('Code pays non supporté', 'error'); // TODO replace when phrase will no longer be broken
    }

    const lat = place.geometry.location.lat();

    const lng = place.geometry.location.lng();

    const fields = {
      address:
        streetNumber && routeName
          ? `${streetNumber.long_name} ${routeName.long_name}`
          : routeName
          ? routeName.long_name
          : undefined,
      zipCode: zipCode ? zipCode.long_name : undefined,
      city: city ? city.long_name : undefined,
      country: country?.short_name ?? 'FR',
      latitude: lat,
      longitude: lng,
    };

    for (let [key, value] of Object.entries(fields)) {
      if (value) form.change(key, value);
    }

    changeMarkerPosition(place.geometry.location.toJSON());
  };

  if (!formData) return null;

  const renderMap = () => {
    return (
      <div>
        <GoogleMap
          options={{
            streetViewControl: false,
            mapTypeControl: true,
            fullscreenControl: false,
            draggable: true,
          }}
          onLoad={(mapInstance) => {
            setMap(mapInstance);
          }}
          mapContainerClassName={classes.mapContainerElement}
          zoom={16}
          center={center}
        >
          <StandaloneSearchBox
            onLoad={(searchBoxInstance) => {
              setSearchBox(searchBoxInstance);
            }}
            onPlacesChanged={() => {
              if (!map || !searchBox) return;

              const places = searchBox.getPlaces();
              if (places.length) {
                const place = places[0];
                onPlaceChanged(place);
              }
            }}
          >
            <input
              type="text"
              placeholder="Customized your placeholder"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '50%',
                marginLeft: '-120px',
              }}
            />
          </StandaloneSearchBox>
          <MapMarker
            position={markerPosition}
            onPositionChanged={(newPosition) => {
              changeMarkerPosition(newPosition);
            }}
          />
          <Circle
            center={markerPosition}
            radius={formData.radius || 500}
            options={{ fillColor: 'rgb(43,152,240)', strokeColor: 'rgb(43,152,240)' }}
          />
        </GoogleMap>

        <div className={classes.line}>
          <NumberInput
            {...props}
            source="longitude"
            validate={required()}
            label="resources.partnerEvent.label.longitude"
            className={classes.addressFields}
          />
          <NumberInput
            {...props}
            source="latitude"
            validate={required()}
            label="resources.partnerEvent.label.latitude"
            className={classes.addressFields}
          />
        </div>
        <div className={classes.line}>
          <TextInput
            {...props}
            source="address"
            validate={required()}
            label="resources.partnerEvent.label.adress"
            className={classes.addressFields}
          />
          <TextInput
            {...props}
            source="zipCode"
            validate={required()}
            label="resources.partnerEvent.label.zipCode"
            className={classes.addressFields}
          />
        </div>
        <div className={classes.line}>
          <TextInput
            {...props}
            source="city"
            validate={required()}
            label="resources.partnerEvent.label.city"
            className={classes.addressFields}
          />
          <SelectInput
            label="resources.partnerEvent.label.country.label"
            source="country"
            choices={[
              { id: 'FR', name: 'resources.partnerEvent.label.country.france' },
              { id: 'BE', name: 'resources.partnerEvent.label.country.belgium' },
            ]}
            validate={[required()]}
            className={classes.addressFields}
          />
        </div>
        <div className={classes.halfLine}>
          <NumberInput
            step={1}
            label="resources.partnerEvent.label.radius"
            source="radius"
            validate={[required()]}
          />
        </div>
      </div>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Map loading</div>;
};

export default withStyles(styles)(MapWithoutStyle);
