import moment from 'moment';
import { BookingFlow } from './dataProvider/types';
import { Translate } from 'ra-core';

export const validateCode = (code?: string) => {
  if (!code) return 'Code required';
  if (!/^[a-zA-Z0-9%_]+(?:-[a-zA-Z0-9%]+)*$/.test(code))
    return 'Le code club ne doit peut contenir que des lettres/chiffres/-/_/%';
};

export const showCancellationTime = (
  bookingFlow: BookingFlow | undefined,
  isUnlimitedOfferGym: boolean
) => {
  if (!bookingFlow || isUnlimitedOfferGym) {
    return false;
  }

  return [
    BookingFlow.IN_APP_BOOKING,
    BookingFlow.BOOKING_WITHOUT_ERP_REGULAR,
    BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL,
  ].includes(bookingFlow);
};

export const showPromotions = (bookingFlow?: BookingFlow) => {
  if (!bookingFlow) {
    return false;
  }

  return [
    BookingFlow.IN_APP_BOOKING,
    BookingFlow.OPEN_ACCESS_WITHOUT_ERP,
    BookingFlow.OPEN_ACCESS_WITH_ERP,
  ].includes(bookingFlow);
};

export const validatePromotionDates = (promotions: any[]) => {
  const errors: any[] = [];
  if (Array.isArray(promotions) && promotions.length > 0) {
    const promotion = promotions[0];
    if (promotion) {
      if (promotion.startDate && promotion.endDate) {
        if (moment(promotion.endDate).isBefore(promotion.startDate)) {
          errors.push({
            endDate:
              'resources.GymFirm.fields.promotion.validation.endDateBeforeStartDate',
          });
        }
      } else {
        if (
          promotion.startDate &&
          moment(promotion.startDate).isBefore(moment().startOf('day'))
        ) {
          errors.push({
            startDate:
              'resources.GymFirm.fields.promotion.validation.startDateBeforeCurrent',
          });
        }
      }
    }
  }

  return errors;
};

export const isPromotionEnded = (formData: any) => {
  if (!Array.isArray(formData.promotions) || formData.promotions.length === 0) {
    return false;
  }

  const promotion = formData.promotions[0];

  if (!promotion) {
    return false;
  }

  const { endDate } = promotion;

  return moment(endDate).isBefore(moment().startOf('day'));
};

export const isOpenAccess = (formData?: any) => {
  if (!formData?.bookingFlow) {
    return false;
  }

  return [BookingFlow.OPEN_ACCESS_WITH_ERP, BookingFlow.OPEN_ACCESS_WITHOUT_ERP].includes(
    formData.bookingFlow
  );
};

export const showGeneralReservationProcess = (bookingFlow: BookingFlow) =>
  bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL;

export const isUsePublicPriceForPaymentDisabled = ({ formData }: any) => {
  const isDisabled = !(
    formData.bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL &&
    formData.payWithPassCode === true
  );

  if (isDisabled) {
    formData.usePublicPriceForPayment = false;
  }

  return isDisabled;
};

export const isAPIV2 = ({ formData }: any) => {
  return (
    formData.bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL &&
    formData.payWithPassCode === true
  );
};

export function extractContent(s: string) {
  var span = document.createElement('span');
  span.innerHTML = s;
  const result = span.innerText;
  span.remove();
  return result;
}

export const ALLOWED_WRITE_GYM_COUNTRIES = ['FR', 'BE', 'RE'];
export const ALLOWED_WRITE_LOCALES = ['fr_FR', 'en_BE', 'fr_BE', 'nl_BE', 'fr_RE'];

export const getCountriesChoices = (translate: Translate) => {
  return ALLOWED_WRITE_GYM_COUNTRIES.map((countryCode) => ({
    id: countryCode,
    name: translate(`resources.Gym.fields.country.${countryCode}`),
  }));
};

const getGymLocalesChoices = (translate: Translate) => {
  return ALLOWED_WRITE_LOCALES.map((locale) => ({
    id: locale,
    name: translate(`resources.Gym.fields.locale.${locale}`),
  }));
};

export const getGymLocalesForCountry = (country: string, translate: Translate) =>
  getGymLocalesChoices(translate).filter(({ id }) => id.endsWith(`_${country}`));

export const extractDataFromPlace = (place: google.maps.places.PlaceResult) => {
  if (!place.geometry || !place.address_components) return;

  const streetNumber = place.address_components.find((addressType: any) =>
    addressType.types.includes('street_number')
  );
  const routeName = place.address_components.find((addressType: any) =>
    addressType.types.includes('route')
  );
  const zipCode = place.address_components.find((addressType: any) =>
    addressType.types.includes('postal_code')
  );
  const city = place.address_components.find((addressType: any) =>
    addressType.types.includes('locality')
  );
  const region = place.address_components.find((addressType: any) =>
    addressType.types.includes('administrative_area_level_1')
  );
  const country = place.address_components.find((addressType: any) =>
    addressType.types.includes('country')
  );
  const countryCode = country ? country.short_name : undefined;
  if (countryCode && !ALLOWED_WRITE_GYM_COUNTRIES.includes(countryCode)) {
    throw new Error('Unsupported country');
  }

  let locale: string | undefined;
  if (countryCode === 'FR' || countryCode === 'RE') {
    locale = `fr_${countryCode}`;
  } else if (countryCode === 'BE') {
    if (city?.short_name === 'Bruxelles') {
      locale = `en_${countryCode}`;
    } else if (region?.short_name === 'Région Wallonne') {
      locale = `fr_${countryCode}`;
    } else {
      locale = `nl_${countryCode}`;
    }
  }

  return {
    streetNumber: streetNumber ? streetNumber.long_name : undefined,
    streetName: routeName ? routeName.long_name : undefined,
    zipCode: zipCode ? zipCode.long_name : undefined,
    city: city ? city.long_name : undefined,
    country: countryCode,
    locale,
  };
};
