import React from 'react';
import { NumberInput, CheckboxGroupInput, useNotify } from 'react-admin';
import { AmountFormatter } from '../../../utils';
import { priceValidation } from './common';

export const PeakHourProcess = ({ isOld, prefix, classes, disabled, ...props }: any) => {
  const notify = useNotify();

  prefix = prefix || '';

  let intervals = [
    { id: 'MORNING', name: 'resources.GymRemuneration.peakHour.morning' },
    { id: 'NOON', name: 'resources.GymRemuneration.peakHour.noon' },
    { id: 'EVENING', name: 'resources.GymRemuneration.peakHour.evening' },
    { id: 'WEEK_END', name: 'resources.GymRemuneration.peakHour.weekEnd' },
  ];
  if (!isOld) {
    intervals = [
      { id: 'MORNING', name: 'resources.GymRemuneration.offPeakHour.morning' },
      { id: 'AFTERNOON', name: 'resources.GymRemuneration.offPeakHour.afternoon' },
    ];
  }

  return (
    <div>
      <div className={classes.line}>
        <NumberInput
          {...props}
          label={
            isOld
              ? 'resources.GymRemuneration.fields.offPeakHourPrice'
              : 'resources.GymRemuneration.fields.price'
          }
          source={`${prefix}peakHourPrice`}
          {...AmountFormatter(notify)}
          step={0.1}
          min={0}
          className={classes.inlineBlock}
          validate={!disabled ? priceValidation : undefined}
          disabled={disabled}
        />
        <NumberInput
          {...props}
          label={
            isOld
              ? 'resources.GymRemuneration.fields.price'
              : 'resources.GymRemuneration.fields.offPeakHourPrice'
          }
          source={`${prefix}price`}
          {...AmountFormatter(notify)}
          step={0.1}
          min={0}
          className={classes.inlineBlock}
          validate={!disabled ? priceValidation : undefined}
          disabled={disabled}
        />
      </div>
      <div className={classes.line}>
        <CheckboxGroupInput
          {...props}
          source={`${prefix}intervals`}
          choices={intervals}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
