import React, { useState } from 'react';
import {
  DateInput,
  ImageField,
  NumberInput,
  TranslatableInputs,
  SelectInput,
  ImageInput,
  required,
  TextInput,
  SimpleForm,
  Create,
  maxLength,
  BooleanInput,
} from 'react-admin';
import { styles } from './styles';
import { PartnerEventColor } from './types';
import { FormDataConsumer } from 'react-admin';
import PartnerEventMapAddress from './PartnerEventMapAddress';

const PartnerEventCreate = (props: any) => {
  const classes = styles();

  const [isThereSubtitle, setIsThereSubtitle] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const handleColorChange = (event: any) => {
    setSelectedColor(event.target.value);
  };

  const [isTherePromotion, setIsTherePromotion] = useState(false);

  const titleMaxLength = (value: any) => {
    const max = isThereSubtitle ? 21 : 40;
    if (value.length > max) {
      return 'resources.partnerEvent.error.titleTooLong';
    }
    return undefined;
  };
  const titleValidation = [required(), titleMaxLength];
  const subtitleValidation = [isThereSubtitle && required(), maxLength(70)];

  const backgroundColorChoice = [
    { id: PartnerEventColor.Lemon, name: 'Lemon' },
    { id: PartnerEventColor.Peach, name: 'Peach' },
    { id: PartnerEventColor.Pearl, name: 'Pearl' },
  ];
  const getBackgroundColorPreview = () => {
    switch (selectedColor) {
      case PartnerEventColor.Lemon:
        return '#F7F7DA';
      case PartnerEventColor.Peach:
        return '#F9DBDA';
      case PartnerEventColor.Pearl:
        return '#D3E8EA';
      default:
        return 'white';
    }
  };

  return (
    <Create {...props}>
      <SimpleForm>
        <div className={classes.boxInLine}>
          <DateInput
            label="resources.partnerEvent.label.startDate"
            source="startDate"
            validate={[required()]}
          />
          <DateInput
            label="resources.partnerEvent.label.endDate"
            source="endDate"
            validate={[required()]}
          />
        </div>
        <BooleanInput
          label="resources.partnerEvent.label.showDates"
          source="showDates"
          defaultValue={false}
        />

        <FormDataConsumer>
          {(props: any) => <PartnerEventMapAddress {...props} />}
        </FormDataConsumer>

        <TranslatableInputs
          groupKey="title"
          locales={['fr', 'en', 'nl']}
          validate={titleValidation}
        >
          <TextInput
            label="resources.partnerEvent.label.title"
            source="title"
            validate={titleValidation}
            className={classes.fullWidth}
          />
        </TranslatableInputs>

        <BooleanInput
          label="resources.partnerEvent.label.showSubtitle"
          onChange={(v: boolean) => setIsThereSubtitle(v)}
          source="isThereSubtitle"
        />

        {isThereSubtitle && (
          <TranslatableInputs groupKey="subtitle" locales={['fr', 'en', 'nl']}>
            <TextInput
              label="resources.partnerEvent.label.subtitle"
              source="subtitle"
              validate={subtitleValidation}
              className={classes.fullWidth}
            />
          </TranslatableInputs>
        )}

        <ImageInput
          source="picture"
          label="resources.partnerEvent.label.image"
          accept="image/png"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <div className={classes.boxInLine}>
          <SelectInput
            label="resources.partnerEvent.label.backgroundColor"
            source="backgroundColor"
            choices={backgroundColorChoice}
            validate={[required()]}
            onChange={handleColorChange}
            className={classes.quarterWidth}
          />
          <div
            className={classes.colorVizualiser}
            style={{
              backgroundColor: getBackgroundColorPreview(),
            }}
          />
        </div>

        <BooleanInput
          label="resources.partnerEvent.label.showDiscount"
          onChange={(v: boolean) => setIsTherePromotion(v)}
          source="isTherePromotion"
        />

        {isTherePromotion && (
          <NumberInput
            step={1}
            label="resources.partnerEvent.label.discount"
            source="discount"
            validate={[isTherePromotion && required()]}
          />
        )}

        <SelectInput
          label="resources.partnerEvent.label.cta"
          source="cta"
          choices={[
            { id: 'BOOKING', name: 'resources.partnerEvent.label.booking' },
            {
              id: 'PARTICIPATE',
              name: 'resources.partnerEvent.label.participate',
            },
          ]}
          validate={[required()]}
        />

        <TextInput
          label="resources.partnerEvent.label.deeplink"
          source="deeplink"
          placeholder="gymlib://gym/:gymId"
          validate={[required()]}
          style={{
            width: '50%',
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default PartnerEventCreate;
