import React, { Fragment } from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BooleanInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';

const styles = {
  line: {
    display: 'inline-flex',
    width: '100%',
  },
  hideIteratorLabel: {
    '& li>p': {
      display: 'none',
    },
    '& li': {
      border: 'none',
      margin: '15px',
      width: 'calc(25% - 30px)',
      display: 'inline-block',
    },
    '& ul': {
      width: '100%',
    },
  },
  imageContainer: {
    width: '100%',
    borderRadius: '10px',
    '& img': {
      maxWidth: '100%',
      border: 'rgb(43,152,240) 4px solid',
      'box-sizing': 'border-box',
      borderRadius: '5px',
      padding: '2px',
    },
  },
  title: {
    marginLeft: '15px',
    '& >label': {
      fontSize: '1.5rem',
    },
  },
  inlineBlock: {
    margin: '15px',
    flex: '1',
  },
  croppedImageContainer: {
    '& span>div>div': {
      width: '100%',
    },
    '& span>div:first-child': {
      marginBottom: '15px',
    },
    '& span>div>div>button': {
      left: '0.5rem',
    },
    '& span>div>div>div': {
      display: 'inline-flex',
    },
    '& span>div>div>div>div:first-child': {
      flex: '4',
    },
    '& span>div>div>div>div:nth-child(2)': {
      paddingLeft: '15px',
      paddingTop: '0',
      flex: '1',
    },
    '& span>div>div>div>div:nth-child(2) img': {
      border: 'green 3px solid',
      'box-sizing': 'border-box',
      borderRadius: '4px',
      padding: '1px',
    },
  },
};

const handleChange = (change: any, formData: any, currentUrl: string, value: boolean) => {
  if (value) {
    if (formData.pictures) {
      for (let i = 0; i < formData.pictures.length; i++) {
        if (formData.pictures[i].isMain && formData.pictures[i].url !== currentUrl) {
          change('pictures[' + i + '].isMain', false);
        }
      }
    }

    if (formData.addPictures) {
      for (let i = 0; i < formData.addPictures.length; i++) {
        if (
          formData.addPictures[i].isMain &&
          formData.addPictures[i].file &&
          formData.addPictures[i].file.image !== currentUrl
        ) {
          change('addPictures[' + i + '].isMain', false);
        }
      }
    }
  }
};

const PicturesWithoutStyle = ({ classes }: any) => {
  const form = useForm();
  return (
    <Fragment>
      <ArrayInput
        className={classes.hideIteratorLabel}
        source="pictures"
        label="resources.Gym.fields.pictures.currentList"
      >
        <SimpleFormIterator disableAdd>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...props }: any) => {
              if (scopedFormData && formData && scopedFormData.url) {
                return (
                  <div className={classes.imageContainer}>
                    <img alt="salle" src={scopedFormData.url} />
                    <BooleanInput
                      {...props}
                      source={getSource('isMain')}
                      onChange={(value: boolean) =>
                        handleChange(form.change, formData, scopedFormData.url, value)
                      }
                      label="resources.Gym.fields.pictures.mainPicture"
                      initialValue={false}
                      options={{ disabled: scopedFormData.isMain }}
                    />
                  </div>
                );
              }
              return null;
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <ImageInput
        label=""
        source={'addPictures'}
        accept="image/*"
        // aspectRatio={1.5}
        className={classes.croppedImageContainer}
        multiple
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </Fragment>
  );
};

export default withStyles(styles)(PicturesWithoutStyle);
