import gql from 'graphql-tag';

const GET_LIST_QUERY = gql`
  query allUserExternalCouponGenerations(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
    $filter: AllUserExternalCouponGenerationFilter
  ) {
    items: allUserExternalCouponGenerations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      provider
      coupon
      claimedAt
      usedBy {
        id
        email
      }
    }
    total: _allUserExternalCouponGenerationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponseMany = (resp: any) => {
  if (!resp || !resp.data || !resp.data.items) return resp;
  return {
    data: resp.data.items,
    total: resp.data.total.count,
  };
};

export const GetList = () => {
  return (params: any) => {
    const variables = {
      ...params,
      ...params.pagination,
    };
    variables.page = variables.page - 1;
    return {
      query: GET_LIST_QUERY,
      parseResponse: parseResponseMany,
      variables,
    };
  };
};

export const GetMany = () => {
  return (params: any) => {
    const preparedVariables: any = {
      page: 1,
      perPage: 25,
      filter: {},
    };

    if (params.ids && params.ids.length) {
      // We were called from a reference
      if (!(params.ids[0] instanceof String) && params.ids[0].id) {
        preparedVariables.filter.ids = params.ids.map((id: { id: string }) => {
          return id.id;
        });
      } else preparedVariables.filter.id = params.ids[0];
    }
    return {
      query: GET_LIST_QUERY,
      variables: preparedVariables,
      parseResponse: parseResponseMany,
    };
  };
};
