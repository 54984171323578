import gql from 'graphql-tag';

const UPDATE_ACTIVITY_MUTATION = gql`
  mutation updateActivity($input: UpdateActivityInput!) {
    data: updateActivity(input: $input) {
      id
      slug
      name {
        en
        fr
        nl
      }
      active
      activityID
    }
  }
`;

export const Update = (params: any) => {
  console.log('[UPDATE][ACTIVITY]', params);

  return {
    variables: {
      input: {
        id: params.data.id,
        data: {
          name: {
            en: params.data.name.en,
            fr: params.data.name.fr,
            nl: params.data.name.nl,
          },
        },
      },
    },
    query: UPDATE_ACTIVITY_MUTATION,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
        },
      };
    },
  };
};
