import React, { Fragment, useState } from 'react';
import { get } from 'lodash';
import {
  FormDataConsumer,
  useNotify,
  required as originalRequired,
  ReferenceField,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  SelectInput,
  NumberInput,
  useTranslate,
  minValue,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { StandardProcess } from './shared/Standard';
import { DegressivityProcess } from './shared/Degressivity';
import { PeakHourProcess } from './shared/PeakHour';

import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { editAndCreate } from './style';
import { AmountFormatter } from '../../utils';
import { DateInput } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';

const useStyles = makeStyles(editAndCreate);

export const Editable = ({ isCreate, prefix, disabled, ...props }: any) => {
  const form = useForm();
  if (!prefix) prefix = '';

  const required = (): any => (!disabled ? originalRequired() : true);

  const record = get(props, `record${prefix ? '.' : ''}${prefix.replace('.', '')}`);

  const [sourceType, setSourceType] = useState(
    record && record.sourceType ? record.sourceType.toLowerCase() : 'gym'
  );

  const [hasDiscoveryPass, setHasDiscoveryPass] = useState(
    record && (record.discoveryPass === 0 || !!record.discoveryPass)
  );

  const initialMonthlyFirmCapping = form.getFieldState('monthlyFirmCapping')?.initial;
  const [hasmonthlyFirmCapping, setHasmonthlyFirmCapping] = useState(
    record && (record.monthlyFirmCapping === 0 || !!record.monthlyFirmCapping)
  );

  const [hasMultiOffers, setHasMultiOffers] = useState(
    record && (record?.gymOffers?.length > 0 || false)
  );

  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const remTypes = [
    { id: 'STANDARD', name: 'resources.GymRemuneration.type.standard' },
    { id: 'DEGRESSIVITY', name: 'resources.GymRemuneration.type.degressivity' },
    {
      id: 'OFF_PEAK_HOURS',
      name: 'resources.GymRemuneration.type.off_peak_hours',
    },
  ];

  const [selectedRemType, setSelectedRemType] = useState(
    record && record.type ? record.type.toLowerCase() : 'standard'
  );

  if (!isCreate)
    remTypes.push({
      id: 'PEAK_HOURS',
      name: 'resources.GymRemuneration.type.peak_hours',
    });

  return (
    <Fragment>
      {isCreate ? (
        <SelectInput
          {...props}
          source={`${prefix}sourceType`}
          choices={[
            { id: 'GYM', name: 'resources.GymRemuneration.sourceType.gym' },
            { id: 'FIRM', name: 'resources.GymRemuneration.sourceType.firm' },
          ]}
          initialValue="GYM"
          validate={[required()]}
          className={classes.inlineBlock}
          onChange={function ({ target: { value } }: any) {
            setSourceType(value.toLowerCase());
          }}
        />
      ) : (
        <FunctionField
          source={`${prefix}sourceType`}
          sortable={false}
          render={(record: any) =>
            record &&
            record.sourceType &&
            translate(
              'resources.GymRemuneration.sourceType.' + record.sourceType.toLowerCase()
            )
          }
        />
      )}
      {isCreate ? (
        <ReferenceInput
          {...props}
          label="resources.Gym.fields.slug.label"
          source={`${prefix}sourceID`}
          reference={sourceType.charAt(0).toUpperCase() + sourceType.slice(1)}
          validate={[required()]}
        >
          <AutocompleteInput optionText="slug" />
        </ReferenceInput>
      ) : (
        <ReferenceField
          label="resources.Gym.fields.slug.label"
          source={`${prefix}sourceID`}
          reference={sourceType.charAt(0).toUpperCase() + sourceType.slice(1)}
        >
          <TextField source={`${prefix}slug`} disabled={disabled} />
        </ReferenceField>
      )}

      <DateInput {...props} source={`${prefix}effectiveAt`} disabled={disabled} />

      <TextInput {...props} source={`${prefix}iban`} disabled={disabled} />

      <div className={classes.line}>
        <SelectInput
          {...props}
          source={`${prefix}type`}
          choices={remTypes}
          validate={[required()]}
          className={classes.inlineBlock}
          disabled={disabled}
          onChange={(event: any) => {
            const value = event.target.value as string;

            if (!value) {
              return;
            }

            setSelectedRemType(value.toLowerCase());

            form.change('gymOffers', []);
            setHasMultiOffers(false);
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasDiscoveryPass}
              onChange={(event) => {
                setHasDiscoveryPass(event.target.checked);
                form.change('discoveryPass', event.target.checked ? 0 : null);
              }}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label={translate(
            `resources.GymRemuneration.fields.${sourceType}HasDiscoveryPass`
          )}
        />
        {hasDiscoveryPass && (
          <NumberInput
            {...props}
            source={`${prefix}discoveryPass`}
            initialValue={0}
            className={classes.inlineBlock}
            {...AmountFormatter(notify)}
            disabled={disabled}
          />
        )}
      </div>
      <div className={classes.line}>
        <FormControlLabel
          control={
            <Switch
              checked={hasmonthlyFirmCapping}
              onChange={(event) => {
                setHasmonthlyFirmCapping(event.target.checked);
                if (!event.target.checked) {
                  form.change('monthlyFirmCapping', null);
                } else {
                  form.change('monthlyFirmCapping', initialMonthlyFirmCapping);
                }
              }}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label={translate('resources.GymRemuneration.fields.hasmonthlyFirmCapping')}
          disabled={disabled}
        />
        <Tooltip
          title={translate(`resources.GymRemuneration.monthlyCapping.help`)}
          placement="bottom"
          classes={{ tooltip: classes.helpBlock }}
        >
          <HelpOutline />
        </Tooltip>
        {hasmonthlyFirmCapping && (
          <NumberInput
            {...props}
            source={`${prefix}monthlyFirmCapping`}
            initialValue={null}
            className={classes.inlineBlock}
            validate={[required(), minValue(0)]}
            disabled={disabled}
          />
        )}
      </div>
      <FormDataConsumer {...props}>
        {({ formData, ...rest }: any) => {
          if (!formData) return;
          switch (get(formData, `${prefix}type`)) {
            case 'STANDARD':
              return (
                <StandardProcess
                  prefix={prefix}
                  classes={classes}
                  disabled={disabled}
                  {...(prefix ? props : rest)}
                />
              );
            case 'DEGRESSIVITY':
              return (
                <DegressivityProcess
                  prefix={prefix}
                  classes={classes}
                  disabled={disabled}
                  {...(prefix ? props : rest)}
                />
              );
            case 'PEAK_HOURS':
              return (
                <PeakHourProcess
                  prefix={prefix}
                  isOld={true}
                  classes={classes}
                  disabled={disabled}
                  {...(prefix ? props : rest)}
                />
              );
            case 'OFF_PEAK_HOURS':
              return (
                <PeakHourProcess
                  prefix={prefix}
                  isOld={false}
                  classes={classes}
                  disabled={disabled}
                  {...(prefix ? props : rest)}
                />
              );
            default:
              break;
          }
        }}
      </FormDataConsumer>
      {selectedRemType === 'standard' && (
        <div className={classes.line}>
          <FormControlLabel
            control={
              <Switch
                checked={hasMultiOffers}
                onChange={(event) => {
                  setHasMultiOffers(event.target.checked);
                  form.change('gymOffers', []);
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
            label={translate('resources.GymRemuneration.fields.gymOffer.label.activate')}
            disabled={disabled}
          />
        </div>
      )}
      {hasMultiOffers && (
        <div className={classes.line}>
          <ArrayInput
            {...props}
            className={[classes.hideIteratorLabel]}
            source={`${prefix}gymOffers`}
            label="Multi Offers"
            disabled={disabled}
          >
            <SimpleFormIterator className={classes.inputsGrid}>
              <TextInput
                source="name.fr_FR"
                className={[classes.hideIteratorLabel]}
                label={translate('resources.GymRemuneration.fields.gymOffer.name.fr')}
                validate={[required()]}
                format={(value: any) => (typeof value === 'object' ? null : value)}
                disabled={disabled}
              />
              <TextInput
                source="name.en_EN"
                className={[classes.hideIteratorLabel]}
                label={translate('resources.GymRemuneration.fields.gymOffer.name.en')}
                validate={[required()]}
                format={(value: any) => (typeof value === 'object' ? null : value)}
                disabled={disabled}
              />
              <TextInput
                source="name.nl_NL"
                className={[classes.hideIteratorLabel]}
                label={translate('resources.GymRemuneration.fields.gymOffer.name.nl')}
                validate={[required()]}
                format={(value: any) => (typeof value === 'object' ? null : value)}
                disabled={disabled}
              />
              <NumberInput
                source="price"
                label={translate('resources.GymRemuneration.fields.gymOffer.label.price')}
                step={1}
                min={1}
                initialValue={null}
                {...AmountFormatter()}
                validate={[required()]}
                disabled={disabled}
              />
              <NumberInput
                source="costInCredits"
                label={translate(
                  'resources.GymRemuneration.fields.gymOffer.label.costInCredits'
                )}
                step={1}
                min={1}
                initialValue={null}
                validate={[required()]}
                disabled={disabled}
              />
              <NumberInput
                source="maxPublicPrice"
                label={translate(
                  'resources.GymRemuneration.fields.gymOffer.label.maximumPublicPrice'
                )}
                step={1}
                min={1}
                initialValue={null}
                validate={[required()]}
                {...AmountFormatter()}
                disabled={disabled}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      )}
    </Fragment>
  );
};
