import React from 'react';
import { TextField } from 'react-admin';
import { CreateButton } from 'react-admin';
import { DateField } from 'react-admin';
import { Datagrid, List } from 'react-admin';
import { TopToolbar } from 'react-admin';

const ExternalIntegrationActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  ...props
}: any) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

export const ExternalIntegrationList = (props: any) => {
  console.log('basePath', props.basePath);

  return (
    <List actions={<ExternalIntegrationActions />} {...props}>
      <Datagrid rowClick="edit">
        <TextField label="Company Name" source="company.name" />
        <TextField label="Integration Type" source="integrationType" />
        <DateField source="createdAt" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};
