import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  required,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';
import { AmountFormatter } from '../../utils';
import { Product, ProductType } from '../products/dataProvider/Many';

import { makeStyles } from '@material-ui/core';
import { Loading } from 'react-admin';

export const styles = makeStyles({
  productType: {
    marginTop: '30px',
  },
  productForm: {
    gap: '20px',
    display: 'grid',
    gridAutoColumns: 'auto',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(auto, 15%) 1fr 1fr',
  },
  productName: {
    overflowWrap: 'break-word',
  },
  activateToggle: {
    whiteSpace: 'nowrap',
  },
});

const CustomProductPriceGroupCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

type ProductTypeGridProps = {
  label: string;
  products: Product[];
};

const ProductTypeGrid: React.FunctionComponent<ProductTypeGridProps> = ({
  label,
  products,
}) => {
  const classes = styles();
  return (
    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
      <Typography variant="h5" gutterBottom className={classes.productType}>
        {label}
      </Typography>
      {products.map((product) => (
        <Box key={product.id} className={classes.productForm}>
          <Typography variant="subtitle1" className={classes.productName}>
            {product.name.fr}
          </Typography>

          <NumberInput
            {...AmountFormatter()}
            source={`productPrices[${product.id}].price`}
            label="Prix de l'offre pour ce niveau"
            min={0}
          />

          <BooleanInput
            label="Activer le produit"
            source={`productPrices[${product.id}].active`}
            initialValue={false}
            className={classes.activateToggle}
          />
        </Box>
      ))}
    </Box>
  );
};

const ProductPriceGroupCreateGeneralInfoRow: React.FunctionComponent = () => (
  <>
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <BooleanInput resource="ProductPriceGroups" source="active" label="Activé" />
      </Box>
    </Box>
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.fr"
          label="Traduction française"
          validate={[required()]}
        />
      </Box>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.en"
          label="Traduction anglais"
          validate={[required()]}
        />
      </Box>
      <Box flex={2}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.nl"
          label="Traduction néerlandaise"
          validate={[required()]}
        />
      </Box>
    </Box>
  </>
);

export const ProductPriceGroupCreate: React.FunctionComponent = (props: any) => {
  const { data, loading } = useGetList('Products');

  const initialValues = useMemo(() => {
    const products = (Object.values(data) || []) as Product[];
    return {
      active: true,
      name: { fr: '', en: '', nl: '' },
      productPrices: products.reduce(
        (acc, product) => ({
          ...acc,
          [product.id]: {
            id: product.id,
            type: product.type,
            price: 0,
            // default products with unlimited to false until EPIC is ready for prod
            // @todo: UNLIMITED : adapt this default value as business will decide
            active: product.type === ProductType.CREDITS_SUBSCRIPTION ? true : false,
          },
        }),
        {} as Record<string, { id: string; price: number; active: boolean }>
      ),
    };
  }, [data]);

  const productsByType = useMemo(() => {
    if (data) {
      const ordererProducts = (Object.values(data as Product[]) || []).sort((a, b) =>
        a.credits < b.credits ? -1 : 1
      );
      return {
        credits: ordererProducts.filter(
          (product: any) => product.type === ProductType.CREDITS_SUBSCRIPTION
        ),
        bundles: ordererProducts.filter(
          (product: any) =>
            product.type === ProductType.CREDITS_AND_UNLIMITED_SUBSCRIPTION
        ),
      };
    }
  }, [data]);

  return (
    <Create {...props}>
      {!loading && initialValues && productsByType ? (
        <FormWithRedirect
          resource="ProductPriceGroups"
          validateOnBlur={true}
          initialValues={initialValues}
          render={(formProps: any) => (
            <>
              <Box marginX="3rem" paddingY="2rem">
                <section>
                  <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                      <Typography variant="h6" gutterBottom>
                        Informations générales
                      </Typography>
                      <ProductPriceGroupCreateGeneralInfoRow />
                    </Box>
                  </Box>
                </section>
                <section>
                  <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <ProductTypeGrid
                      label={'Credits'}
                      products={productsByType.credits}
                    />
                    <ProductTypeGrid
                      label={'Bundles'}
                      products={productsByType.bundles}
                    />
                  </Box>
                </section>
              </Box>
              <CustomProductPriceGroupCreateToolbar
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </>
          )}
        />
      ) : (
        <Loading />
      )}
    </Create>
  );
};
