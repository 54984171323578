import React, { useState } from 'react';
import { translate as translateDecorator } from 'react-admin';
import { Button } from '@material-ui/core';
import { useNotify } from 'react-admin';

const TestErpIntegration = ({ data, translate }: any) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleCreateErpTestUser = async (gymID: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          'apollo-require-preflight': 'true',
        },
        credentials: 'include',
        body: JSON.stringify({
          variables: { gymID },
          query: `
        mutation testErpIntegration($gymID: ID!){
          testErpIntegration(input:{gymID:$gymID}){
            httpResponse{
              status
              body
              statusText
            }
          }
        }
      `,
        }),
      })
      const data = await response.json();

      setLoading(false);
      const httpResponse = data.data.testErpIntegration?.httpResponse;
      try {
        httpResponse.body = { data: JSON.parse(httpResponse.body) }
      } catch (err) {}
      
      if (httpResponse.status >= 300) {
        notify(
          translate('resources.Gym.actions.TestERPIntegration.errorERP', { statusText: httpResponse.statusText, status: httpResponse.status }),
          'warning');
        console.log(httpResponse);
      } else {
        notify(translate('resources.Gym.actions.TestERPIntegration.success'), 'info')
        console.log(httpResponse);
      }
    } catch (error) {
      setLoading(false);
      notify(translate('resources.Gym.actions.TestERPIntegration.error'), 'warning')
      console.log(error);
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleCreateErpTestUser(data.id)} disabled={loading}>
        {loading ? 'loading...' : translate('resources.Gym.actions.TestERPIntegration.name')}
      </Button>
    </div>
  );
}

export default translateDecorator(TestErpIntegration);