import gql from 'graphql-tag';
import * as _ from 'lodash';
import { UpdateParams } from '../../../@types/common';

const UPDATE_MUTATION = gql`
  mutation updateErp($input: BO_UpdateErpInput!) {
    data: BO_UpdateErp(input: $input) {
      id
      siteID
      name
      locationID
      mandatoryFields
      paymentMethodID
      erpAvailableSessions {
        erpPassTypeID
        isForBooking
      }
      groupID
      businessUnitID
      planningHoursOffset
      groupCode
      erpPassTypeID
      promoCode
      blacklistedClasses
      whitelistedClasses
      terms
      discountType
      enableStartDateSync
      login
      password
      url
      isLive
      data
    }
  }
`;

export const Update = (params: UpdateParams<UpdateErpDataInput>) => {
  // if (!['MINDBODY', 'ZINGFIT'].includes(params.data.name))
  //   throw new Error('Can only update Mindbody and Zingfit erp');

  // Cf https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
  const allowedFields = [
    'erpAvailableSessions',
    'locationID',
    'groupID',
    'businessUnitID',
    'planningHoursOffset',
    'groupCode',
    'erpPassTypeID',
    'promoCode',
    'blacklistedClasses',
    'whitelistedClasses',
    'discountType',
    'enableStartDateSync',
    'terms',
    'url',
    'login',
    'password',
    'isLive',
    'siteID',
    'paymentMethodID',
    'data',
  ] as const;

  const data = allowedFields.reduce(
    (toUpdate, key): any =>
      !_.isEqual(params.data[key], params.previousData[key])
        ? Object.assign({}, toUpdate, { [key]: params.data[key] })
        : toUpdate,
    {} as { [key in typeof allowedFields[number]]?: any }
  );

  if (Object.keys(data).length === 0) throw new Error('No change to save!');

  return {
    variables: { input: { id: params.id, data } },
    query: UPDATE_MUTATION,
    parseResponse: (resp: any) => {
      return { data: resp?.data?.data || {} };
    },
  };
};

interface UpdateErpDataInput {
  erpAvailableSessions?: {
    erpPassTypeID: string;
    isForBooking: boolean;
  };
  locationID?: string;
  name: string;
  groupID: string;
  businessUnitID: string;
  planningHoursOffset: number;
  groupCode: string;
  erpPassTypeID: string;
  promoCode: string;
  blacklistedClasses: string[];
  whitelistedClasses: string[];
  discountType: string;
  enableStartDateSync: boolean;
  terms: string;
  url: string;
  login: string;
  password: string;
  isLive: boolean;
  siteID: string;
  data: object;
  paymentMethodID?: string;
}
