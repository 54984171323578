import React, { useState, useEffect, ReactNode } from 'react';

import { translate, withDataProvider } from 'ra-core';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import compose from 'recompose/compose';

import TypeSelect from '../../utils/block/TypeSelect';
import { BlockReason } from '../../@types/common';

const styles = {
  dropZone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    color: '#999',
  },
  title: {
    'white-space': 'pre',
  },
  explanation: {
    'white-space': 'pre',
  },
  executeButton: {
    'margin-left': 'auto',
    'margin-right': 'auto',
    display: 'block',
  },
  executeText: {
    'margin-left': 'auto',
    'margin-right': 'auto',
    display: 'block',
    'text-align': 'center',
  },
};

const noPromiseReject = (p: Promise<any>) =>
  p.then(v => ({ v, status: 'fulfilled' }), e => ({ e, status: 'rejected' }));

type DataProviderCallback = (
  type: string,
  resource: string,
  payload?: any,
  options?: any
) => Promise<any>;

export interface ChildrenParams {
  setUsers: (emails: IBlockUser[]) => void;
}

interface RawProps {
  children: (params: ChildrenParams) => ReactNode;
  open: boolean;
  onClose?: (activeStep: number) => void;
  onFinished?: (emails: string[]) => void;
}

interface Props extends RawProps {
  dataProvider: DataProviderCallback;
  translate: (key: string, options?: object) => string;
  classes: any;
}

interface IBlockUser {
  id: string;
  status: string;
  email: string;
  subscriptionStatus: string;
}

const MultipleUserBlockAction = ({
  children,
  open,
  onClose,
  onFinished,
  dataProvider,
  translate,
  classes,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);

  const [users, setUsers] = useState<IBlockUser[]>([]);

  const [atPeriodEnd, setAtPeriodEnd] = useState(false);
  const [reason, setReason] = useState(BlockReason.LeftCompany);

  const [isBlocking, setIsBlocking] = useState(false);
  const [blockingCount, setBlockingCount] = useState(0);
  // blocking failed
  const [blockingFailed, setBlockingFailed] = useState([] as string[]);

  const reset = (force = false) => {
    if (!force && activeStep === 2) return;

    setActiveStep(0);

    setUsers([]);

    setAtPeriodEnd(false);

    setIsBlocking(false);
    setBlockingCount(0);

    setBlockingFailed([]);
    if (onClose) onClose(activeStep);
  };

  const stepLabels = [
    translate('resources.User.actions.block.steps.first.name'),
    translate('resources.User.actions.block.steps.second'),
    translate('resources.User.actions.block.steps.third'),
    translate('resources.User.actions.block.steps.fourth'),
  ];

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBlocking = async () => {
    console.log('Current users', users);
    if (!isBlocking || !users.length) return;

    let currentSplit = [...users];
    const effectivelyBlocked: string[] = [];

    while (currentSplit.length > 0) {
      const blockGroup = currentSplit.slice(0, 5);
      console.log('Blocking group', blockGroup);
      await Promise.all(
        blockGroup.map(user =>
          noPromiseReject(
            dataProvider(
              'UPDATE',
              'User',
              {
                data: {
                  isBlocked: true,
                  id: user.id,
                  atPeriodEnd,
                  reason,
                },
              },
              {
                undoable: false,
              }
            )
              .catch(error => {
                console.log('error blocking', error);
                setBlockingFailed(failed => [...failed, user.email]);
              })
              .then(() => {
                effectivelyBlocked.push(user.email);
              })
          )
        )
      );

      console.log('Blocking finished');

      setBlockingCount(newBlocking => newBlocking + blockGroup.length);

      currentSplit.splice(0, 5);
    }

    setIsBlocking(false);
    setActiveStep(3);

    if (onFinished) onFinished(effectivelyBlocked);
  };

  useEffect(() => {
    handleBlocking();
    // eslint-disable-next-line
  }, [users, isBlocking]);

  return (
    <div>
      <Dialog open={open} onClose={() => reset()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" classes={classes.title}>
          {translate('resources.User.actions.block.name')}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepLabels.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === stepLabels.length ? (
              <div>Confirmez-vous?</div>
            ) : (
              <div>
                {activeStep === 0 ? (
                  <div>
                    {children({ setUsers })}
                    <Button
                      disabled={!users.length}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                ) : activeStep === 1 ? (
                  <div>
                    <TypeSelect
                      onChange={(atPeriodEnd, reason) => {
                        setAtPeriodEnd(atPeriodEnd);
                        setReason(reason);
                      }}
                    />
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Next
                    </Button>
                  </div>
                ) : activeStep === 2 ? (
                  <div>
                    <div className={classes.executeText}>
                      <b>{translate('resources.User.actions.block.executeWarning')}</b>
                      {isBlocking && (
                        <div>
                          {blockingCount} / {users.length}
                        </div>
                      )}
                    </div>
                    <br />
                    {!isBlocking && (
                      <div>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setIsBlocking(true);
                          }}
                        >
                          Executer
                        </Button>
                        <Button
                          className={classes.executeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            reset(true);
                          }}
                        >
                          Annuler
                        </Button>
                      </div>
                    )}
                    {isBlocking && (
                      <LinearProgress
                        variant="determinate"
                        value={(blockingCount / users.length) * 100}
                      />
                    )}
                  </div>
                ) : (
                  <div className={classes.explanation}>
                    {blockingFailed.length
                      ? translate('resources.User.actions.block.summary.bad', {
                          count: blockingCount,
                        })
                      : translate('resources.User.actions.block.summary.ok', {
                          count: users.length,
                        })}
                    <br />
                    {blockingFailed.join('\n')}
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

// TODO: Use the new dataprovider
export default compose<Props, RawProps>(
  translate,
  withDataProvider,
  withStyles(styles)
)(MultipleUserBlockAction);
