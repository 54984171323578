import React, { useState } from 'react';
import { useRefresh, useTranslate, useNotify } from 'ra-core';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { apolloClient } from '../../../utils/apolloClient';
import gql from 'graphql-tag';
import { required } from 'react-admin';
import { Form } from 'react-final-form';
import { SelectInput } from 'react-admin';

const BLOCK_USER_MUTATION = gql`
  mutation BO_BlockUser($input: BO_BlockUserInput!) {
    BO_BlockUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const blockUser = (userID: string, reason: BlockReason) => {
  return apolloClient.mutate({
    mutation: BLOCK_USER_MUTATION,
    variables: {
      input: {
        userID,
        reason,
      },
    },
  });
};

const styles = {
  modal: {
    'min-width': '300px',
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
interface RawProps {
  data: any;
}

interface Props extends RawProps {
  classes: any;
}

enum BlockReason {
  BlockAllEmployees = 'BLOCK_ALL_EMPLOYEES',
  CompanyOffline = 'COMPANY_OFFLINE',
  EndedProspect = 'ENDED_PROSPECT',
  Fraudster = 'FRAUDSTER',
  GatewayPeriodEnd = 'GATEWAY_PERIOD_END',
  IgnoredUser = 'IGNORED_USER',
  LeftCompany = 'LEFT_COMPANY',
  NoReason = 'NO_REASON',
  SponsorLeft = 'SPONSOR_LEFT',
  SyncEmailsList = 'SYNC_EMAILS_LIST',
}

const BlockReasonChoices = [
  { id: BlockReason.BlockAllEmployees, name: 'BLOCK_ALL_EMPLOYEES' },
  { id: BlockReason.CompanyOffline, name: 'COMPANY_OFFLINE' },
  { id: BlockReason.EndedProspect, name: 'ENDED_PROSPECT' },
  { id: BlockReason.Fraudster, name: 'FRAUDSTER' },
  { id: BlockReason.GatewayPeriodEnd, name: 'GATEWAY_PERIOD_END' },
  { id: BlockReason.IgnoredUser, name: 'IGNORED_USER' },
  { id: BlockReason.LeftCompany, name: 'LEFT_COMPANY' },
  { id: BlockReason.NoReason, name: 'NO_REASON' },
  { id: BlockReason.SponsorLeft, name: 'SPONSOR_LEFT' },
  { id: BlockReason.SyncEmailsList, name: 'SYNC_EMAILS_LIST' },
];

const BlockUserAction = ({ data, classes }: Props) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [selectedBlockReason, setSelectedBlockReason] = useState(BlockReason.LeftCompany);
  const notify = useNotify();
  const refresh = useRefresh();

  const blockUserHandler = async () => {
    try {
      await blockUser(data.id, selectedBlockReason);
      setOpen(false);
      notify('resources.User.block.success', 'info');
      refresh();
    } catch (error: any) {
      console.error(error);
      setOpen(false);
      return notify(error.message || 'resources.CorporateCompany.generic.error', 'error');
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.User.block.button')}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.modal }}
      >
        <DialogTitle>{translate('resources.User.block.title')}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectInput
                  source="selectedBlockReason"
                  initialValue={BlockReason.LeftCompany}
                  label="resources.User.block.reason.label"
                  choices={BlockReasonChoices}
                  onChange={(event: any) => setSelectedBlockReason(event.target.value)}
                  validate={[required()]}
                />
              </form>
            )}
          />
        </DialogContent>
        <DialogActions style={styles.dialogButtons}>
          <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
            {translate('resources.User.block.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedBlockReason}
            onClick={() => {
              blockUserHandler();
            }}
          >
            {translate('resources.User.block.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(BlockUserAction);
