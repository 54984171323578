import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { translate } from 'ra-core';
import compose from 'recompose/compose';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { BlockReason } from '../../@types/common';

const styles = {
  explanation: {
    'white-space': 'pre',
  },
};

interface RawProps {
  onChange?: (atPeriodEnd: boolean, reason: BlockReason) => undefined | void;
  hasActiveSubscription?: boolean;
}

interface TypeSelectProps extends RawProps {
  locale: string;
  translate: (key: string, vars?: object) => string;
  classes: any;
}

const TypeSelect = ({
  translate,
  classes,
  onChange,
  hasActiveSubscription = true,
}: TypeSelectProps) => {
  const [atPeriodEnd, setAtPeriodEnd] = useState(false);
  const [reason, setReason] = useState(BlockReason.LeftCompany);

  const handleCheck = (_: any, checked: boolean) => {
    if (onChange) onChange(checked, reason);
    setAtPeriodEnd(checked);
  };

  const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const localReason = event.target.value as BlockReason;
    if (onChange) onChange(atPeriodEnd, localReason);
    setReason(localReason);
  };

  return (
    <FormGroup>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={reason}
        onChange={handleReasonChange}
      >
        {Object.values(BlockReason).map((type, index) => (
          <MenuItem key={index} value={type}>
            {translate(`resources.User.actions.block.type.${type.toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
      <br />
      {hasActiveSubscription && (
        <FormControlLabel
          control={<Switch checked={atPeriodEnd} onChange={handleCheck} />}
          label={translate('resources.User.actions.block.choice')}
        />
      )}
      <div className={classes.explanation}>
        {!hasActiveSubscription || !atPeriodEnd
          ? translate('resources.User.actions.block.immediatly')
          : translate('resources.User.actions.block.atPeriodEnd')}
      </div>
    </FormGroup>
  );
};

export default compose<TypeSelectProps, RawProps>(
  translate,
  withStyles(styles)
)(TypeSelect);
