import equipmentProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'GymEquipment',
  dataProvider: {
    getOneName: 'equipment',
    useCustomDP: true,
    customDataProvider: equipmentProvider,
  },
} as IResourceExport;
