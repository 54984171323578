import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import * as React from 'react';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin';
import { AmountFormatter } from '../../utils';

const CustomProductPriceGroupEditToolbar = (props: any) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton disabled />
  </Toolbar>
);

const ProductPriceGroupCreateGeneralInfoRow: React.FunctionComponent = () => (
  <>
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <BooleanInput resource="ProductPriceGroups" source="active" label="Activé" />
      </Box>
    </Box>
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.fr"
          label="Traduction française"
        />
      </Box>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.en"
          label="Traduction anglais"
        />
      </Box>
      <Box flex={2}>
        <TextInput
          resource="ProductPriceGroups"
          source="name.nl"
          label="Traduction néerlandaise"
        />
      </Box>
    </Box>
  </>
);

const ProductPriceGroupPriceDisplayTable: React.FunctionComponent<{
  prices: {
    price: number;
    id: string;
    product: { name: { fr: string; nl: string; en: string } };
  }[];
}> = ({ prices }) => (
  <Table size="medium">
    <TableHead>
      <TableRow>
        <TableCell>Produit</TableCell>
        <TableCell>Prix en €</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {prices.map((price) => (
        <TableRow key={price.id}>
          <TableCell>{price.product.name.fr}</TableCell>
          <TableCell>{AmountFormatter().format(price.price)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const ProductPriceGroupEdit: React.FunctionComponent = (props: any) => {
  return (
    <Edit {...props}>
      <FormWithRedirect
        render={(formProps: any) => {
          return (
            <>
              <form>
                <Box marginX="3rem" paddingY="2rem">
                  <section>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Typography variant="h6" gutterBottom>
                          Informations générales
                        </Typography>
                        <ProductPriceGroupCreateGeneralInfoRow />
                      </Box>
                    </Box>
                  </section>
                  {formProps.record.prices?.length ? (
                    <section>
                      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Typography variant="h5" gutterBottom>
                          Produits associés
                        </Typography>
                        <ProductPriceGroupPriceDisplayTable
                          prices={formProps.record.prices}
                        />
                      </Box>
                    </section>
                  ) : null}
                </Box>
              </form>
              <CustomProductPriceGroupEditToolbar {...formProps} />
            </>
          );
        }}
      />
    </Edit>
  );
};
