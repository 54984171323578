import React from 'react';
import {
  TabbedForm,
  FormTab,
  Edit,
  DateInput,
  Toolbar,
  SaveButton,
  Link as AdminLink,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  Pagination,
  NullableBooleanInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LaunchIcon from '@material-ui/icons/Launch';
import { translate, FormDataConsumer } from 'ra-core';
import ActionButtons from './ActionButtons';
import { ReferenceField } from 'react-admin';
import { DateTimeInput } from 'react-admin';

export const styles = {
  editToolbar: {
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  editLink: {
    launchIcon: {
      width: '0.8em',
      paddingLeft: 2,
    },
  },
  editForm: {
    infoTable: {
      width: '100%',
    },
    infoRows: {
      '& td': {
        width: '50%',
      },
      '& td:nth-child(2n)': {
        width: '50%',
        paddingLeft: 30,
      },
    },
    infoData: {
      display: 'flex',
    },
    roles: {
      '& div': {
        'flex-direction': 'column',
      },
    },
  },
};

export const formstyles = {
  infoTable: {
    width: '100%',
  },
  infoRows: {
    '& td': {
      width: '50%',
    },
    '& td:nth-child(2n)': {
      width: '50%',
      paddingLeft: 30,
    },
  },
  infoData: {
    display: 'flex',
  },
  roles: {
    '& div': {
      'flex-direction': 'column',
    },
  },
};

const CustomToolbar = withStyles(styles.editToolbar)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const PassTitle = ({ record }: any) => {
  return <span>Pass {record ? `"${record.code}"` : ''}</span>;
};

const PassEditWithoutStyle = (props: any) => {
  return (
    <Edit
      {...props}
      undoable={false}
      title={<PassTitle />}
      actions={<ActionButtons {...props} />}
    >
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab {...props} label="Informations">
          <Table {...props}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextInput disabled label="Code" source="code" />
                </TableCell>
                <FormDataConsumer>
                  {({ formData, ...props }) =>
                    formData &&
                    formData.publicPrice && (
                      <TableCell>
                        <TextInput disabled label="Public price" source="publicPrice" />
                      </TableCell>
                    )
                  }
                </FormDataConsumer>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextInput
                    disabled
                    label="Status"
                    source="status"
                    format={(value: string) =>
                      props.translate(
                        'resources.Pass.fields.passStatus.' + (value || 'unknown')
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextInput disabled label="Gym" source="gym.name" />
                </TableCell>
                <TableCell>
                  <FormDataConsumer>
                    {({ formData, ...props }) =>
                      formData && (
                        <AdminLink to={`/Gym/${formData.gym.id}`}>
                          <LaunchIcon />
                        </AdminLink>
                      )
                    }
                  </FormDataConsumer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ReferenceInput
                    label="Email"
                    source="user.id"
                    reference="User"
                    allowEmpty
                    resource="User"
                  >
                    <SelectInput disabled optionText="email" />
                  </ReferenceInput>
                </TableCell>
                <TableCell>
                  <FormDataConsumer>
                    {({ formData, ...props }) =>
                      formData && (
                        <AdminLink to={`/User/${formData.user.id}`}>
                          <LaunchIcon />
                        </AdminLink>
                      )
                    }
                  </FormDataConsumer>
                </TableCell>
              </TableRow>
              <FormDataConsumer>
                {({ formData, ...props }) =>
                  formData.gymOffer && (
                    <>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <TextInput
                            disabled
                            label="Offre de la Gym"
                            source="gymOffer.name.fr_FR"
                          />
                        </TableCell>
                        <TableCell colSpan={1}>
                          <AdminLink
                            to={
                              formData.gym.remuneration?.id
                                ? `/GymRemuneration/${formData.gym.remuneration.id}`
                                : `/Gym/${formData.gym.id}/3`
                            }
                          >
                            <LaunchIcon />
                          </AdminLink>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                }
              </FormDataConsumer>
              <TableRow>
                <TableCell>
                  <DateInput disabled label="createdAt" source="createdAt" />
                </TableCell>
                <TableCell>
                  <DateInput
                    disabled
                    label="resources.Pass.fields.startDate"
                    source="startDate"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <DateInput
                    disabled
                    label="resources.Pass.fields.lastValidationDate"
                    source="lastValidationDate"
                  />
                </TableCell>
                <TableCell>
                  <DateTimeInput
                    disabled
                    label="resources.Pass.fields.cancelledAt"
                    source="erpPassInfos.cancelledAt"
                    showTime
                  />
                </TableCell>
                <TableCell>
                  <DateInput
                    label="resources.Pass.fields.expirationDate"
                    source="expirationDate"
                  />
                </TableCell>
              </TableRow>
              <FormDataConsumer>
                {({ formData, ...props }) =>
                  formData &&
                  formData.penaltyDate &&
                  !formData.lastValidationDate && (
                    <TableRow>
                      <TableCell>
                        <DateInput
                          disabled
                          label="resources.Pass.fields.penaltyDate"
                          source="penaltyDate"
                        />
                      </TableCell>
                    </TableRow>
                  )
                }
              </FormDataConsumer>
            </TableBody>
          </Table>
          <TextInput
            disabled
            source="admissionChargeInCredits"
            label="resources.Pass.fields.currentPrice"
          />
          <FormDataConsumer>
            {({ formData, ...rest }: any) =>
              formData.erpPassInfos && (
                <>
                  <h5>{props.translate('resources.Pass.edit.erpInfosTitle')}</h5>
                  <TextInput
                    {...rest}
                    disabled
                    source="erpPassInfos.id"
                    label="resources.Pass.fields.erpId"
                  />
                  <TextInput
                    {...rest}
                    disabled
                    source="erpPassInfos.status"
                    label="resources.Pass.fields.erpStatus"
                  />
                  <TextInput
                    {...rest}
                    disabled
                    source="erpPassInfos.remainingEntries"
                    label="resources.Pass.fields.erpEntries"
                  />
                  <DateInput
                    {...rest}
                    disabled
                    source="erpPassInfos.transferedAt"
                    label="resources.Pass.fields.expirationDate"
                  />
                </>
              )
            }
          </FormDataConsumer>
          <NullableBooleanInput
            disabled
            label="resources.Pass.fields.isGymRemunerated"
            source="isGymRemunerated"
          />
        </FormTab>
        <FormTab label="resources.Pass.tabs.punchHistory">
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.Pass.tabs.punchHistory"
            reference="PassValidation"
            target="pass"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid>
              <DateField label="createdAt" showTime source="createdAt" />
              <TextField label="resources.Pass.fields.punchType" source="type" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Audit">
          <ReferenceManyField
            pagination={<Pagination />}
            label="resources.CorporateCompany.fields.events"
            reference="PassAudit"
            target="passID"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid>
              <ReferenceField source="userID" reference="User" label="Email">
                <TextField source="email" />
              </ReferenceField>
              <DateField source="createdAt" showTime label="createdAt" />
              <TextField source="appType" />
              <TextField source="passStatus" />
              <TextField source="message" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const PassEdit = translate(PassEditWithoutStyle);
