import gql from 'graphql-tag';

const ADD_COUPONS_MUTATION = gql`
  mutation BO_ExternalCouponsAdd($input: BO_ExternalCouponsAddInput!) {
    data: BO_ExternalCouponsAdd(input: $input) {
      inserted
    }
  }
`;

export const Update = () => {
  return (params: any) => {
    return {
      query: ADD_COUPONS_MUTATION,
      variables: {
        input: params.data,
      },
      parseResponse: (resp: any) => {
        return resp;
      },
    };
  };
};
