import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { FormDataConsumer, useTranslate, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const OnlineTemporarilyClosedComponent = (parentProps: any) => {
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState('active');
  const translate = useTranslate();
  const form = useForm();
  const notify = useNotify();

  return (
    <FormDataConsumer>
      {({ formData, ...props }: any) => (
        <>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{translate('resources.Gym.actions.active.title')}</DialogTitle>
            <DialogContent>
              {translate('resources.Gym.actions.active.details')}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (source === 'active') {
                    form.change('active', false);
                    form.change('temporarilyClosed', true);
                    // Only from temp we set temp
                  } else {
                    form.change('temporarilyClosed', true);
                  }
                  setOpen(false);
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <FormControlLabel
            control={
              <Switch
                checked={formData.active}
                onChange={(event) => {
                  if (!event.target.checked) {
                    setSource('active');
                    setOpen(true);
                  } else {
                    if (!formData.activitiesIds.length || !formData.mainActivity.length) {
                      notify(`resources.Gym.fields.activities.warning`, 'warning');
                      form.change('active', false);
                      return;
                    }
                    if (!formData.activitiesIds.includes(formData.mainActivity[0])) {
                      notify(`resources.Gym.fields.activities.warning`, 'warning');
                      form.change('active', false);
                      return;
                    }
                    form.change('active', event.target.checked);
                  }
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={formData.availableOnGymlib === false}
              />
            }
            label={translate('resources.Gym.fields.active.label')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.temporarilyClosed}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSource('temporarilyClosed');
                    setOpen(true);
                  } else {
                    form.change('temporarilyClosed', event.target.checked);
                  }
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={!formData.active}
              />
            }
            label={translate('resources.Gym.fields.temporarilyClosed.label')}
          />
        </>
      )}
    </FormDataConsumer>
  );
};
