import { withStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { required, SelectInput, useDataProvider, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import PlanningOffsetComponent from './PlanningOffsetComponent';

const styles = {
  error: {
    color: 'red',
    fontSize: '0.75rem',
  },
};

enum MondayBrand {
  DYNAMO = 'DYNAMO',
  PUNCH = 'PUNCH',
  RIISE = 'RIISE',
}

const MondayForm = ({ classes }: any) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const mondayBrandItems = [
    { id: MondayBrand.DYNAMO, name: MondayBrand.DYNAMO },
    { id: MondayBrand.PUNCH, name: MondayBrand.PUNCH },
    { id: MondayBrand.RIISE, name: MondayBrand.RIISE },
  ];
  const [mondayBrandMetadata, setMondayBrandMetadata] = useState<IMondayMetadata>();
  const [error, setError] = useState('');

  form.change('name', 'MONDAY');

  // Search Monday metadata
  const handleBrandChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const mondayBrand = event.target.value;
      setError('');
      setMondayBrandMetadata(undefined);

      dataProvider
        .getOne('MondayMetadata', { mondayBrand })
        .then(({ data }: { data: IMondayMetadata }) => {
          setMondayBrandMetadata(data);
          setError('');
        })
        .catch(() => {
          setError(translate('resources.Erp.errors.permissionDenied'));
          setMondayBrandMetadata(undefined);
        });
    },
    [dataProvider, translate]
  );

  return (
    <>
      <SelectInput
        source="mondayBrand"
        label="resources.Erp.fields.mondayBrand"
        choices={mondayBrandItems}
        validate={[required()]}
        onChange={handleBrandChange}
      />

      {error && <p className={classes.error}>{error}</p>}

      {mondayBrandMetadata && (
        <>
          <SelectInput
            source="locationID"
            label="resources.Erp.fields.location"
            choices={mondayBrandMetadata.locations}
            validate={[required()]}
          />
          <PlanningOffsetComponent />
        </>
      )}
    </>
  );
};

export default withStyles(styles)(MondayForm);

interface IMondayMetadata {
  locations: {
    id: string;
    name: string;
  }[];
}
