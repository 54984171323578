import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin';

const ErpFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="q" source="q" alwaysOn resettable />
    <ReferenceInput
      source="gym.id"
      label="resources.Erp.filter.gymSlug"
      reference="Gym"
      allowEmpty
    >
      <AutocompleteInput optionText="slug" />
    </ReferenceInput>
  </Filter>
);

export const ErpList = (props: any) => (
  <List {...props} undoable="false" filters={<ErpFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="siteID" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
