import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-admin';
import React, { useState, Fragment } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  ArrayInput,
  BooleanField,
  CheckboxGroupInput,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  Link as AdminLink,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Pagination,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  translate,
  UrlField,
  NumberInput,
} from 'react-admin';
import { PulseChangeEvents } from '../../@types/common';
import DebugInfos, { Props as DebugInfosProps } from '../../utils/DebugInfos';
import DeleteUserDevice from './DeleteUserDevice';
import UserAnonymizeAction from './UserAnonymizeAction';
import UserTitle from './UserTitle';
import UserUnlockDevice from './UserUnlockDevice';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-final-form';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { EditCredits } from './components/EditCredits';
import BlockUserAction from './actions/BlockUserAction';
import UnblockUserAction from './actions/UnblockUserAction';
import UserSwitchFormulaAction from './UserSwitchFormulaAction';

export const styles: Styles<any, any> = {
  infoTable: {
    width: '100%',
  },
  infoRows: {
    '& td': {
      width: '50%',
    },
    '& td:nth-child(2n)': {
      width: '50%',
      paddingLeft: 30,
    },
  },
  infoData: {
    display: 'flex',
  },
  infoLink: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '10px',
  },
  roles: {
    '& div': {
      'flex-direction': 'column',
    },
  },
  avatar: {
    'max-width': '100px',
    'max-height': '100px',
  },
  missingAppVersion: {
    color: 'red',
  },
  actions: {
    alignItems: 'center',
    padding: '1rem',
    '& button': {
      margin: '0 0.5rem',
    },
  },
  rows: {
    display: 'flex',
    'flex-direction': 'row',
    alignItems: 'center',
  },
};

const UserEditActions = withStyles(styles)((props: any) => {
  return (
    <TopToolbar className={props.classes.actions}>
      {/* TODO: Delete if it is decided to remove pause subscription */}
      {/* <UserPauseAction {...props} /> */}
      <BlockUserAction {...props} />
      <UnblockUserAction {...props} />
      <UserAnonymizeAction {...props} />
    </TopToolbar>
  );
});

const UserEditToolbar = (props: any) => {
  const [open, setOpen] = useState(false);
  const form = useForm();
  const emailField = form.getFieldState('email');
  const onSubmit = async () => {
    setOpen(false);
    props.handleSubmitWithRedirect();
  };
  const { translate } = props;

  return (
    <Toolbar {...props} submitOnEnter={false}>
      <SaveButton
        onClick={() => {
          if (emailField?.initial !== emailField?.value) {
            setOpen(true);
          } else {
            props.handleSubmitWithRedirect();
          }
        }}
        redirect="edit"
        submitOnEnter={false}
        handleSubmitWithRedirect={() => {
          return false;
        }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {translate('resources.User.fields.editEmail.modalTitle')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Annuler
          </Button>
          <Button onClick={() => onSubmit()} variant="contained" color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const debugs: DebugInfosProps['fields'] = [
  {
    label: 'Stripe',
    content: (record) =>
      record.customerID
        ? `https://dashboard.stripe.com/customers/${record.customerID}`
        : 'Missing customerID',
    isCode: false,
  },
  {
    label: 'Mixpanel',
    content: (record) =>
      record?.id
        ? `https://mixpanel.com/project/2125785/view/135811/app/profile#distinct_id=${record.id}`
        : 'Missing record.id',
    isCode: false,
  },
  {
    label: 'Hubspot',
    content: (record) => {
      return record?.corporateCompany?.name
        ? `https://app.hubspot.com/contacts/2529003/objects/0-2/views/all/list?query=${record.corporateCompany.name}`
        : 'Missing corporateCompany name';
    },
    isCode: false,
  },
  {
    label: 'Intercom',
    content: (record) => {
      return record?.email
        ? `https://app.intercom.com/a/apps/mgs6ejbv/inbox/inbox/search/conversations/63772?q=${record.email}`
        : 'Missing record email';
    },
    isCode: false,
  },

  {
    label: 'Sendinblue',
    content: (record) => {
      return `https://app-smtp.sendinblue.com/log`;
    },
    isCode: false,
  },

  {
    label: 'Instabug',
    content: (record) => {
      return record?.id
        ? 'https://dashboard.instabug.com/applications/gymlib/live/crashes?filters=%7B"date_ms":%7B"shortcutLabel":"Last 28 days"%7D,"user_attributes":%7B"3":%7B"operator":"equal","text":"' +
            record.id +
            '"%7D%7D%7D'
        : 'Missing record.id';
    },
    isCode: false,
  },
  {
    label: 'Google logs',
    content: (record) => {
      const cursorTimestamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      const stripeQuery = record?.customerID
        ? `%20OR%20jsonPayload.data.customerID%3D%22${record?.customerID}%22`
        : '';
      return record?.email
        ? `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.cluster_name%3D%22cluster-2%22%0Aresource.labels.namespace_name%3D%22gymlib-backend-prod%22%0Aseverity%3E%3DWARNING%0AjsonPayload.data.email%3D%22${record.email}%22${stripeQuery};cursorTimestamp=${cursorTimestamp};duration=P15D?project=prod-co`
        : 'Missing record email';
    },
    isCode: false,
  },
  {
    label: 'Find in db',
    content: (record) =>
      record?.id ? `db.users.find({_id: ObjectId("${record.id}")})` : 'Missing record id',
    isCode: true,
  },
];

const renderCreditsBalance = (record: any) => {
  const eventsToShowBalance = [
    PulseChangeEvents.CANCELATION,
    PulseChangeEvents.RESERVATION,
    PulseChangeEvents.REFUND,
    PulseChangeEvents.UNSUBSCRIBE,
    PulseChangeEvents.PURCHASE_OF_PULSES,
    PulseChangeEvents.UPGRADE,
    PulseChangeEvents.DOWNGRADE,
    PulseChangeEvents.MOVEMENT_VIA_BO,
    PulseChangeEvents.SUBSCRIBE_RENEWAL,
    PulseChangeEvents.REQUEST_REJECTED,
    PulseChangeEvents.TRIGGER_REM,
    PulseChangeEvents.UNLIMITED_ROLLOUT,
  ];

  if (!record.data.balance || !eventsToShowBalance.includes(record.data.event)) {
    return '';
  }

  return record.data.balance;
};

const renderPassLink = (record: any) => {
  if (!record?.data?.passID) {
    return '';
  }

  return (
    <Link to={`/Pass/${record.data.passID}`} target="_blank">
      <LaunchIcon />
    </Link>
  );
};

const UserEditWithoutStyle = ({ classes, translate, ...props }: any) => {
  const [showEditCreditsDialog, setShowEditCreditsDialog] = useState(false);
  const handleChangeCreditsClick = () => {
    setShowEditCreditsDialog((prev) => !prev);
  };

  const handleCloseDialog = () => {
    setShowEditCreditsDialog(false);
  };

  return (
    <Edit
      {...props}
      mutationMode="undoable"
      title={<UserTitle />}
      actions={<UserEditActions />}
      aside={<DebugInfos fields={debugs} />}
    >
      <TabbedForm redirect="edit" toolbar={<UserEditToolbar translate={translate} />}>
        <FormTab label="resources.User.tabs.infos">
          <table className={classes.infoTable}>
            <FormDataConsumer>
              {({ formData }: { formData: Record<string, any> }) =>
                formData && (
                  <tbody>
                    <tr className={classes.infoRows}>
                      <td>
                        <div style={{ display: 'flex', gap: '5px' }}>
                          {formData.subscriptionStatus && (
                            <TextInput
                              disabled
                              source="subscriptionStatus"
                              format={(value: string) =>
                                translate(
                                  `resources.User.fields.statusType.${value.toLowerCase()}`
                                )
                              }
                            />
                          )}
                          {formData.blockedAt &&
                          moment(new Date()).isSameOrAfter(formData.blockedAt) ? (
                            <DateInput
                              disabled
                              label="resources.User.actions.block.at"
                              source="blockedAt"
                            />
                          ) : (
                            formData.blockedAt && (
                              <DateInput
                                disabled
                                label="resources.User.actions.block.atFutur"
                                source="blockedAt"
                              />
                            )
                          )}
                        </div>
                        {formData.blockedReason && (
                          <TextInput
                            disabled
                            source="blockedReason"
                            format={(value: string) =>
                              translate(
                                'resources.User.actions.block.type.' + value.toLowerCase()
                              )
                            }
                          />
                        )}
                      </td>
                      {['BLOCKED', 'TO_BE_BLOCKED'].includes(formData.status) && (
                        <td>
                          <TextInput disabled source="blockedBy.email" />
                        </td>
                      )}
                      {formData.subscription &&
                        formData.subscription.category &&
                        moment(new Date()).isSameOrBefore(formData.blockedAt) && (
                          <td>
                            <TextInput
                              disabled
                              source="subscription.category"
                              format={(value: string) =>
                                translate(
                                  'resources.User.fields.offerType.' + value.toLowerCase()
                                )
                              }
                            />
                          </td>
                        )}
                    </tr>
                    <tr className={classes.infoRows}>
                      <td>
                        <TextInput
                          label="resources.User.fields.firstname"
                          source="firstname"
                          className={classes.infoData}
                          validate={required()}
                        />
                      </td>
                      <td>
                        <TextInput
                          label="resources.User.fields.lastname"
                          source="lastname"
                          className={classes.infoData}
                          validate={required()}
                        />
                      </td>
                    </tr>
                    <tr className={classes.infoRows}>
                      <td>
                        <DateInput
                          label="resources.User.fields.birthday"
                          source="birthday"
                        />
                      </td>
                      <td>
                        <SelectInput
                          label="resources.User.fields.gender"
                          source="gender"
                          choices={[
                            { id: 'MAN', name: 'Homme' },
                            { id: 'WOMAN', name: 'Femme' },
                            { id: 'OTHER', name: 'Non renseigné' },
                          ]}
                          className={classes.infoData}
                        />
                      </td>
                    </tr>
                    <tr className={classes.infoRows}>
                      <td>
                        <TextInput source="email" className={classes.infoData} />
                      </td>
                      <td>
                        <TextInput
                          source="phoneNumber"
                          className={classes.infoData}
                          label="resources.User.fields.phoneNumber"
                        />
                      </td>
                    </tr>
                    <tr className={classes.infoRows}>
                      <td>
                        {formData.lastAppVersion ? (
                          <TextInput
                            disabled
                            source="lastAppVersion"
                            label="resources.User.fields.lastAppVersion"
                            className={classes.infoData}
                          />
                        ) : (
                          <tr>
                            <td className={classes.missingAppVersion}>
                              {translate('resources.User.fields.missingAppVersion')}
                            </td>
                          </tr>
                        )}
                        {formData.deviceID && (
                          <TextInput
                            disabled
                            source="deviceID"
                            label="resources.User.fields.deviceID"
                            className={classes.infoData}
                          />
                        )}
                      </td>
                      <td>
                        {formData.deviceName && (
                          <TextInput
                            disabled
                            source="deviceName"
                            label="resources.User.fields.deviceName"
                            className={classes.infoData}
                          />
                        )}
                        {formData.deviceName && <DeleteUserDevice {...props} />}
                      </td>
                    </tr>
                    <tr className={classes.infoRows}>
                      <td>
                        <TextInput source="address" />
                      </td>
                      <td className={classes.infoData}>
                        <TextInput source="city" />
                        <TextInput source="zipcode" />
                      </td>
                    </tr>
                    <tr className={classes.infoRows}>
                      <td className={classes.infoData}>
                        <DateInput
                          label="resources.User.fields.subscription.resumeDetails.effectiveAt"
                          source="subscription.resumeDetails.effectiveAt"
                          disabled
                        />
                      </td>
                      {formData.egymID && (
                        <>
                          <td>
                            <TextInput
                              source="egymID"
                              label="resources.User.fields.egymID"
                              disabled
                            />
                          </td>
                          <td className={classes.infoData}>
                            <a
                              href={`${process.env.REACT_APP_EGYM_USER_MANAGER_URL}/manage/user/q?accountId=${formData.egymID}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <OpenInNewIcon />
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                )
              }
            </FormDataConsumer>
          </table>
        </FormTab>
        <FormTab label="resources.User.tabs.subscription">
          <FormDataConsumer>
            {({ formData, ...rest }: any) =>
              formData && (
                <Fragment>
                  <div className={classes.infoLink}>
                    <TextInput disabled source="corporateCompany.name" {...rest} />
                    <AdminLink to={`/CorporateCompany/${formData.corporateCompany.id}`}>
                      <LaunchIcon />
                    </AdminLink>
                  </div>

                  <br />
                  <TextInput disabled source="corporateCompany.slug" {...rest} />
                  <br />
                  {formData.subscription && formData.subscription.category && (
                    <div className={classes.infoLink}>
                      <TextInput
                        disabled
                        source="subscription.category"
                        format={(value: string) =>
                          translate(
                            'resources.User.fields.offerType.' + value.toLowerCase()
                          )
                        }
                        {...rest}
                      />
                      {formData.subscription.category?.toLowerCase() === 'unlimited' && (
                        <a
                          href={`${process.env.REACT_APP_WP_ADMIN_URL}/users/user/${formData.egymID}/memberships`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LaunchIcon />
                        </a>
                      )}
                    </div>
                  )}
                  <br />
                  {formData.subscription && formData.subscription.endDate && (
                    <TextInput
                      disabled
                      source="subscription.endDate"
                      format={(isoDate: string) => new Date(isoDate).toLocaleDateString()}
                      parse={(isoDate: Date) => new Date(isoDate).toISOString()}
                      {...rest}
                    />
                  )}
                  <br />
                  {formData.subscription?.category?.toLowerCase() !== 'unlimited' && (
                    <>
                      <NumberInput
                        source="credits"
                        variant="contained"
                        disabled
                        validate={required()}
                        {...rest}
                      />
                      <Button color="secondary" onClick={handleChangeCreditsClick}>
                        <EditIcon />
                        Edit value
                      </Button>
                      {showEditCreditsDialog && (
                        <EditCredits
                          open={showEditCreditsDialog}
                          data={formData}
                          closeDialog={handleCloseDialog}
                          {...props}
                        />
                      )}
                    </>
                  )}
                  <br />
                  {formData.subscription &&
                    formData.subscription.nextOffer &&
                    formData.subscription.nextOfferID &&
                    formData.subscription.nextOfferDisplayName && (
                      <div className={classes.rows}>
                        <TextInput
                          disabled
                          source="subscription.nextOfferDisplayName"
                          {...rest}
                        />
                        {formData.subscriptionStatus !== 'PAST_DUE' && (
                          <UserSwitchFormulaAction
                            data={{
                              userID: formData.id,
                              newOfferID: formData.subscription.nextOfferID,
                              data: formData,
                            }}
                            {...props}
                          />
                        )}
                      </div>
                    )}
                </Fragment>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.User.tabs.invoice">
          <ReferenceManyField
            pagination={<Pagination />}
            label="Paiements"
            reference="UserCharge"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid>
              <DateField source="createdAt" />
              <UrlField source="receiptUrl" />
              <TextField source="amount" />
              <TextField source="status" />
              <TextField source="amountRefunded" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.User.tabs.passes">
          <ReferenceManyField
            pagination={<Pagination perPage={25} />}
            label="Passes"
            reference="Pass"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
          >
            {/* HACK: This is nasty (the rowClick redirect) but a simple 'edit' value does not seem to work correctly for this */}
            <Datagrid rowClick={(id: any) => `/pass/${id}`}>
              <TextField source="code" sortable={false} />
              <TextField source="gym.name" />
              <DateField source="createdAt" showTime />
              <DateField
                source="startDate"
                label="resources.Pass.fields.startDate"
                showTime
              />
              <DateField source="lastValidationDate" showTime />
              <DateField
                source="erpPassInfos.cancelledAt"
                label="resources.Pass.fields.cancelledAt"
                showTime
              />
              <DateField source="expirationDate" showTime />
              <FunctionField
                label="Status"
                render={(record: any) =>
                  translate(
                    'resources.Pass.fields.passStatus.' +
                      ((record && record.status) || 'unknown')
                  )
                }
              />
              <BooleanField source="isERP" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.User.tabs.activationCodes">
          <ArrayInput source="activationCodes">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }: any) => {
                  if (!scopedFormData) return null;
                  let status = translate('resources.User.fields.activationCodes.active');
                  if (scopedFormData.verifiedAt)
                    status = translate('resources.User.fields.activationCodes.used');
                  return (
                    <Fragment {...props}>
                      {status}
                      <br />
                      <DateTimeInput
                        label="createdAt"
                        source={getSource('createdAt')}
                        disabled
                      />
                      <TextInput
                        label="URL"
                        source={getSource('url') || getSource('code')}
                        disabled
                      />
                      {scopedFormData.verifiedAt && (
                        <DateTimeInput
                          label="verifiedAt"
                          source={getSource('verifiedAt')}
                          disabled
                        />
                      )}
                    </Fragment>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.User.tabs.roles">
          <CheckboxGroupInput
            source="roles"
            choices={[
              { id: 'admin', name: 'Admin (accès backoffice)' },
              { id: 'anonymizeUser', name: 'Anonymiser les utilisateurs' },
              {
                id: 'anonymizeCompany',
                name: `Anonymiser tous les utilisateurs d'une plateforme`,
              },
              { id: 'changeRoles', name: 'Changement de rôle' },
              { id: 'platformHandling', name: 'Gestion des plateformes' },
              { id: 'updateEmail', name: 'Modification des emails' },
              { id: 'accessGymRemuneration', name: 'Modification de la rem' },
              {
                id: 'createGymTags',
                name: 'Création de tag (activités, services) pour les gyms',
              },
              { id: 'productManagement', name: 'Gestion des produits et des prix' },
              { id: 'changeCreditBalance', name: 'Crédit de pulses' },
              { id: 'companyDiscountManagement', name: 'Gestion des promotions' },
              {
                id: 'manageExternalIntegration',
                name: 'Gestion des external intégrations',
              },
            ]}
            className={classes.roles}
          />
        </FormTab>
        <FormTab label="resources.User.tabs.events">
          <ReferenceManyField
            pagination={<Pagination />}
            filter={{ actionType: 'BO_USER_EVENTS' }}
            reference="UserEvent"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
          >
            <Datagrid style={{ width: '100%' }}>
              <DateField source="createdAt" showTime />
              <TextField source="message" />
              <TextField source="type" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            pagination={<Pagination />}
            filter={{ actionType: 'PULSE_CHANGE' }}
            reference="UserEvent"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={25}
            label="Pulse changes history"
          >
            <Datagrid style={{ width: '100%' }}>
              <DateField source="createdAt" showTime />
              <TextField source="data.pulses" label="Pulses" />
              <TextField source="data.event" label="Event" />
              <FunctionField
                source="data.balance"
                label="Solde de pulses"
                render={(record: any) => renderCreditsBalance(record)}
              />
              <FunctionField
                source="data.passID"
                label="Pass"
                render={(record: any) => renderPassLink(record)}
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.User.tabs.devices" className={classes.devices}>
          <DateField
            source="lastDeviceRewindDate"
            label="resources.User.fields.resetDevice.label"
            showTime
          />
          <UserUnlockDevice {...props} />
          <ReferenceManyField
            pagination={<Pagination />}
            label="Appareils"
            reference="UserDevice"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
          >
            <Datagrid style={{ width: '100%' }}>
              <DateField source="createdAt" />
              <TextField source="deviceID" />
              <TextField source="deviceName" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const UserEdit = translate(withStyles(styles)(UserEditWithoutStyle));
