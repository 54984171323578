import gql from 'graphql-tag';

const GET_ONE_QUERY = gql`
  query service($id: ID!) {
    data: service(id: $id) {
      id
      active
      slug
      serviceID
      name {
        en
        fr
        nl
      }
      iconName
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return {
        data: {
          ...resp.data.data,
        },
      };
    },
  };
};
