import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import { subscriptionStatus } from './subscriptionStatus';

const GET_ONE_QUERY = gql`
  query user($id: ID!) {
    data: user(id: $id) {
      id
      lastLoginDate
      email
      firstname
      lastname
      gender
      phoneNumber
      address
      birthday
      city
      zipcode
      picture
      hasValidSubscription
      status
      customerID
      blockDetails {
        blockedBy {
          id
          email
        }
        blockedAt
      }
      lastDeviceRewindDate
      corporateCompany {
        id
        name
        slug
        isSponsorshipPlatform
      }
      subscription {
        category
        endDate
        nextOfferID
        nextOffer
        nextOfferDisplayName
        resumeDetails {
          effectiveAt
        }
      }
      roles
      activationCodes {
        code
        url
        active
        createdAt
        verifiedAt
      }
      deviceID
      deviceName
      lastAppVersion
      blockedReason
      credits
      egymID
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    // Use the default query variables and parseResponse
    variables: params,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (untouched: any) => {
      const ret = cloneDeep(untouched);
      if (!ret.data) return { data: {} };

      // Let's create a simple object holding informations about the subscription
      ret.data.data.subscriptionStatus = subscriptionStatus(ret.data.data);

      const blockDetails = ret.data.data.blockDetails;
      const blockedByManagerID =
        blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.id;
      const blockedByManagerEmail =
        (blockDetails && blockDetails.blockedBy && blockDetails.blockedBy.email) ||
        'Gymlib';
      const blockedAt = blockDetails?.blockedAt;

      ret.data.data.blockedBy = {
        id: blockedByManagerID,
        email: blockedByManagerEmail,
      };

      ret.data.data.blockedAt = blockedAt;

      return ret.data;
    },
  };
};
