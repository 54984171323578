import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslate, FormDataConsumer } from 'react-admin';
import { RichInput } from './RichInput';

const TinyMCEInput = () => {
  const translate = useTranslate();

  return (
    <div>
      <FormDataConsumer>
        {({ formData, ...rest }: any) => (
          <div>
            <Typography component="div">
              <Box fontWeight="fontWeightBold">
                {translate('resources.Gym.multilingualShortDescriptions.general')}
              </Box>
              <Box m={1}>
                {translate('resources.Gym.multilingualShortDescriptions.details')}
              </Box>
            </Typography>
            <RichInput
              source="multilingualShortDescriptions.fr"
              settings={{
                plugins: ['autoresize', 'lists link '],
                menubar: false,
                statusbar: false,
                extended_valid_elements: 'reservationprocess',
                custom_elements: 'reservationprocess',
                content_css: 'editor.css',
                toolbar: 'undo redo | bold underline | removeformat',
              }}
              charCounterDisplay={(desc) => (
                <Box
                  fontWeight={desc > 250 ? 'fontWeightBold' : undefined}
                  m={1}
                  color={desc > 250 ? 'red' : undefined}
                >
                  {translate('resources.Gym.multilingualShortDescriptions.counter', {
                    currentCount: desc,
                  })}
                </Box>
              )}
            />
            <Typography component="div">
              <Box fontWeight="fontWeightBold">
                {translate('resources.Gym.description.descriptionTitle')}
              </Box>
              <Box m={1}>
                {translate('resources.Gym.description.descriptionReminder')}
              </Box>
            </Typography>
            <RichInput
              source="multilingualDescriptions.fr"
              settings={{
                plugins: ['autoresize', 'lists link '],
                menubar: false,
                statusbar: false,
                extended_valid_elements: 'reservationprocess',
                custom_elements: 'reservationprocess',
                content_css: 'editor.css',
                toolbar: 'undo redo | bold underline | link | removeformat | reservation',
              }}
              charCounterDisplay={(desc) => (
                <Box
                  fontWeight={desc > 650 ? 'fontWeightBold' : undefined}
                  m={1}
                  color={desc > 650 ? 'red' : undefined}
                >
                  {translate('resources.Gym.description.counter', {
                    currentCount: desc,
                  })}
                </Box>
              )}
            />
            <Typography component="div">
              <Box fontWeight="fontWeightBold">
                {translate('resources.Gym.accessDescription.general')}
              </Box>
              <Box m={1}>{translate('resources.Gym.accessDescription.details')}</Box>
            </Typography>
            <RichInput
              source="accessDescription"
              settings={{
                menubar: false,
                statusbar: false,
                toolbar: 'undo redo | bold underline | removeformat',
              }}
              charCounterDisplay={(desc) => (
                <Box
                  fontWeight={desc > 200 ? 'fontWeightBold' : undefined}
                  m={1}
                  color={desc > 200 ? 'red' : undefined}
                >
                  {translate('resources.Gym.accessDescription.counter', {
                    currentCount: desc,
                  })}
                </Box>
              )}
            />
          </div>
        )}
      </FormDataConsumer>
    </div>
  );
};

export default TinyMCEInput;
