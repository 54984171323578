import React from 'react';
import { useDataProvider } from 'ra-core';
import { TextInput, regex } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  modal: {
    'min-height': '500px',
    'min-width': '500px',
  },
};
interface Props {
  setHrEmail: React.Dispatch<React.SetStateAction<any>>;
}

const CreateHrContactWithoutStyles = ({ setHrEmail }: Props) => {
  const dataProvider = useDataProvider();
  const isHrEmailFree = (message = 'Not unique email') => (email: string) => {
    if (email) {
      console.log('checking is hr email free');
      return dataProvider
        .IS_HR_EMAIL_FREE('HrManager', {
          email,
        })
        .then((res: any) => {
          if (!res || !res.data) return;
          if (res.data.data) return;
          return message;
        });
    }
  };

  return (
    <TextInput
      source="hrContactemail"
      label="resources.CorporateCompany.fields.HrEmail.label"
      validate={[
        regex(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'not en email'
        ),
        isHrEmailFree(),
      ]}
      onChange={(e: any) => {
        setHrEmail(e.target.value);
      }}
    />
  );
};

export const CreateHrContact = withStyles(styles)(CreateHrContactWithoutStyles);
