import React, { useState } from 'react';
import { BooleanInput, NumberInput } from 'react-admin';

import Typography from '@material-ui/core/Typography';

export const CappingComponent = ({ formData, translate }: any) => {
  const [skipFirmCapping, setSkipFirmCapping] = useState<boolean>(
    formData?.availableSession?.yield?.capping?.skipFirmCapping || false
  );

  const handleChange = () => {
    setSkipFirmCapping((prev) => {
      const curValue = !prev;

      const cappingValue = formData.availableSession.yield.capping;
      cappingValue.skipFirmCapping = curValue;
      cappingValue.capping = curValue ? 4 : null;

      return curValue;
    });
  };

  return (
    formData && (
      <>
        <BooleanInput
          source="availableSession.yield.capping.skipFirmCapping"
          label="resources.Gym.fields.skipFirmCapping"
          onChange={handleChange}
        />
        {skipFirmCapping && (
          <div>
            <Typography>
              {translate('resources.Gym.fields.capping.description')}
            </Typography>
            <NumberInput
              source="availableSession.yield.capping.capping"
              label="resources.Gym.fields.capping.input"
              initialValue={4}
              min={1}
              max={99}
              step={1}
              options={{ disabled: true }}
            />
          </div>
        )}
      </>
    )
  );
};
