import { useTranslate } from 'ra-core';
import React from 'react';
import { SelectInput } from 'react-admin';
import { BookingFlow as BookingTypes } from './dataProvider/types';

export const BookingFlow = ({
  formData,
  classes,
  ...props
}: {
  formData?: null | { bookingFlow: string; id: string; firm: { id: string } };
  classes: any;
}) => {
  const translate = useTranslate();

  return (
    <div className={classes.inlineBlock}>
      <SelectInput
        fullWidth
        label="resources.Gym.fields.bookingFlow.label"
        source="bookingFlow"
        choices={Object.values(BookingTypes).map((status) => {
          return {
            id: status,
            name: translate(`resources.Gym.fields.bookingFlow.${status.toLowerCase()}`),
          };
        })}
      />
      {/* <p className={localClasses.explanation}>{warning}</p> */}
    </div>
  );
};
