import React, { useState } from 'react';
import { useNotify, useTranslate, useMutation, Confirm } from 'react-admin';
import { Input, Select, MenuItem, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';

const NO_VALUE = 'NO_VALUE';

const styles = makeStyles({
  select: {
    display: 'table',
  },
  errorSelect: {
    color: '#f44336',
  },
  errorLabel: {
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
});

export function EditCredits({ open, closeDialog, data, ...props }: any) {
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = styles();
  const [reason, setReason] = useState(NO_VALUE);
  const notify = useNotify();
  const [manualReason, setManualReason] = useState('');
  const [credits, setCredits] = useState<number>(data.credits);
  const [manualReasonValidationError, setManualReasonValidationError] = useState(false);
  const [validationError, setValidationError] = useState(true);

  const [mutation] = useMutation();

  const menuItems: any[] = [];
  // add default empty not valid menu item
  menuItems.push(
    <MenuItem key="-99" value={NO_VALUE}>
      Choisissez une raison...
    </MenuItem>
  );

  // add other menu items
  ['Geste co Care', 'Offre Pulse Team Gymlib', 'Bug', 'Test N2', 'Autre'].forEach(
    (x, index) =>
      menuItems.push(
        <MenuItem key={index} value={x}>
          {x}
        </MenuItem>
      )
  );

  const content = (
    <>
      <Select
        value={reason}
        className={[classes.select, validationError ? classes.errorSelect : ''].join(' ')}
        onChange={(e) => {
          const value = e.target.value as string;
          setReason(value);
          setValidationError(value === NO_VALUE);
          setManualReason('');
          if (value === 'Autre') {
            setManualReasonValidationError(true);
          }
        }}
      >
        {menuItems}
      </Select>
      {validationError && <p className={classes.errorLabel}>Ce champ est requis</p>}
      {reason === 'Autre' && (
        <div>
          <Input
            value={manualReason}
            onChange={(e: any) => {
              const value = e.target.value;
              setManualReason(value);
              setManualReasonValidationError(!value);
            }}
          />
          {manualReasonValidationError && (
            <p className={classes.errorLabel}>Ce champ est requis</p>
          )}
        </div>
      )}
      <Input
        type="number"
        value={credits}
        onChange={(e) => {
          setCredits(Number(e.target.value));
        }}
      />
      {credits < 0 && <p className={classes.errorLabel}>Ce champ est requis</p>}
    </>
  );

  const handleConfirm = () => {
    if (reason === NO_VALUE || (reason === 'Autre' && !manualReason)) {
      return notify('Saisir un motif valable', 'warning');
    }
    if (credits < 0) {
      return notify('Saisir un montant de crédit valide', 'warning');
    }

    mutation({
      type: 'update',
      resource: 'User',
      payload: {
        data: {
          id: data.id,
          credits,
          reason: reason === 'Autre' ? manualReason : reason,
        },
      },
    });
    closeDialog();
    refresh();
    notify('Crédits mis à jour avec succès', 'success');
  };

  return (
    <Confirm
      isOpen={open}
      title="Modification du solde de pulses"
      content={content}
      confirm={translate('resources.Pass.actions.modal.confirm')}
      cancel={translate('resources.Pass.actions.modal.cancel')}
      onConfirm={handleConfirm}
      onClose={closeDialog}
    ></Confirm>
  );
}
