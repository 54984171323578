import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

const GET_LIST_QUERY = gql`
  query allPasswordResets(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PasswordResetFilter
  ) {
    items: allPasswordResets(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      which
      status
      expiredAt
      code
    }
    total: _allPasswordResetsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetManyReference = (params: any) => {
  const preparedVariables: any = {
    // We need to set the page to -1 because the Pagination component will start at 1 and not 0
    page: params.pagination ? params.pagination.page - 1 : undefined,
    perPage: params.pagination ? params.pagination.perPage : undefined,
    sortField: params.sort ? params.sort.field : undefined,
    sortOrder: params.sort ? params.sort.order : undefined,
    filter: {},
  };
  preparedVariables.filter['sourceID'] = params.id;

  return {
    query: GET_LIST_QUERY,
    variables: preparedVariables,
    parseResponse: (untouched: any) => {
      const resp = cloneDeep(untouched);
      if (resp && resp.data)
        resp.data.items.forEach((item: any) => {
          switch (item.which) {
            case 'GymManager':
              item.url = `https://partners.gymlib.com/#/change-password/${item.code}`;
              break;
            case 'HrManager':
              item.url = `https://dashboard.gymlib.com/#/change-password/${item.code}`;
              break;
          }
        });
      return { data: resp.data.items, total: resp.data.total.count };
    },
  };
};
