import FirmIcon from '@material-ui/icons/LocationCity';
import { IResourceExport } from '../../@types/dataProvider';

import { FirmList } from './FirmList';
import { FirmEdit } from './FirmEdit';
import { FirmCreate } from './FirmCreate';

import firmProvider from './dataProvider';

export default {
  name: 'Firm',
  resources: {
    list: FirmList,
    edit: FirmEdit,
    create: FirmCreate,
  },
  icon: FirmIcon,
  dataProvider: {
    getOneName: 'firm',
    useCustomDP: true,
    customDataProvider: firmProvider,
  },
} as IResourceExport;
