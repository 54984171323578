import gql from 'graphql-tag';

const DELETE_MUTATION = gql`
  mutation BO_DeactivateErpFirmUserAccount($id: ID!) {
    BO_DeactivateErpFirmUserAccount(input: { id: $id }) {
      data: id
    }
  }
`;

export const Delete = (params: any) => {
  console.log('[DELETE][ERM_FIRM_USER_ACCOUNT]', params);
  console.log('params id', params.id.split('__')[1]);

  return {
    variables: { id: params.id.split('__')[1] },
    query: DELETE_MUTATION,
    parseResponse: (resp: any) => {
      return { data: {} };
    },
  };
};
