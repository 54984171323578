import auditProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'Audit',
  dataProvider: {
    getOneName: 'audit',
    providerWithIntrospection: auditProvider,
  },
} as IResourceExport;
