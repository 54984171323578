import gql from 'graphql-tag';
import { CreateParams, ErpFirmName } from '../../../@types/common';

const CREATE_MUTATION = gql`
  mutation createErp($input: BO_CreateErpInput!) {
    data: BO_CreateErp(input: $input) {
      id
      siteID
      name
      locationID
      mandatoryFields
      paymentMethodID
      erpAvailableSessions {
        erpPassTypeID
        isForBooking
      }
      isLive
    }
  }
`;

export const Create = ({
  data: input,
}: CreateParams<CreateErpInput & { classFilteringMethod?: string }>) => {
  delete input.classFilteringMethod;

  return {
    variables: { input },
    query: CREATE_MUTATION,
    parseResponse: (resp: any) => {
      return { data: resp?.data?.data || {} };
    },
  };
};

interface CreateErpInput {
  siteID: string;
  name: ErpFirmName;
  mandatoryFields?: string[];
  erpAvailableSessions?: {
    erpPassTypeID: string;
    isForBooking: boolean;
  };
  locationID?: string;
  paymentMethodID?: string;
  login?: string;
  password?: string;
  planningHoursOffset?: number;
  whitelistedClasses?: string[];
  blacklistedClasses?: string[];
  promoCode?: string;
  groupID?: string;
  groupCode?: string;
  businessUnitID?: string;
  terms?: string;
  url?: string;
  discountType?: string;
  erpPassTypeID?: string;
  isLive?: boolean;
  mindbodyConfiguration?: {
    referredBy?: string;
  };
  deciplusConfiguration?: {
    clientId: string;
    clientSecret: string;
    domain: string;
    zoneId: number;
    uniqueEntryProductID: number;
    skipValidations?: boolean;
  };
  resamaniav2Configuration?: {
    clientID: string;
    clientSecret: string;
    clientToken: string;
    clubID: string;
  };
  bsportConfiguration: {
    establishmentID?: string;
  };
}
