import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslate, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { changeTheme, ThemeType } from '../theme/actions';
import { changeIsDev } from '../debug/actions';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
});

const Configuration = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const theme: ThemeType = useSelector((state: any) => state.theme);

  const isDev: boolean = useSelector((state: any) => state.isDev);

  const dispatch = useDispatch();
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <div className={classes.label}>{translate('pos.theme.name')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === ThemeType.LIGHT ? 'primary' : 'default'}
          onClick={() => dispatch(changeTheme(ThemeType.LIGHT))}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === ThemeType.DARK ? 'primary' : 'default'}
          onClick={() => dispatch(changeTheme(ThemeType.DARK))}
        >
          {translate('pos.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.isDev.name')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={isDev ? 'primary' : 'default'}
          onClick={() => dispatch(changeIsDev(true))}
        >
          {translate('pos.isDev.true')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={!isDev ? 'primary' : 'default'}
          onClick={() => dispatch(changeIsDev(false))}
        >
          {translate('pos.isDev.false')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;
