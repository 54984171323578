import gymAuditProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'GymAudit',
  dataProvider: {
    getOneName: 'gymAudit',
    useCustomDP: true,
    customDataProvider: gymAuditProvider,
  },
} as IResourceExport;