import * as React from 'react';
import {
  useRefresh,
  ArrayInput,
  BooleanInput,
  useRedirect,
  useNotify,
  TextInput,
  DateField,
  Edit,
  FormTab,
  TabbedForm,
  TextField,
  SimpleFormIterator,
  translate,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  organizationUnitsList: {
    display: 'flex',
    width: '30%',
    '& li': {
      border: 'none',
    },
    '& section': {
      paddingTop: 5,
      paddingBottom: 5,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    '& p': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
};

const ExternalIntegrationEditWithoutStyle = ({ classes, translate, ...props }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }: { data: any }) => {
    notify(
      'ra.notification.updated',
      'info',
      { smart_count: 1 },
      props.mutationMode === 'undoable'
    );
    redirect('edit', props.basePath, data.id, data);
    refresh();
  };
  return (
    <Edit {...props} undoable={false} submitOnEnter={false} onSuccess={onSuccess}>
      <TabbedForm redirect="edit">
        <FormTab>
          <TextField label="Company ID" source="companyID"></TextField>
          <TextField source="integrationType"></TextField>
          <BooleanInput
            source="ignoreNextSync"
            label="resources.ExternalIntegration.fields.ignoreNextSync.label"
            initialValue
          />
          <FormDataConsumer>
            {({ formData }: any) =>
              formData.integrationType === 'AMAZON_S3' && (
                <>
                  <DateField source="createdAt"></DateField>
                  <TextInput label="Email column name" source="fields.email" />
                  <TextInput label="S3 Path" source="more.s3Path" />
                  <TextInput label="Ref Key Name" source="fields.refKeyName" />
                  <TextInput label="First Name column" source="fields.firstName" />
                  <TextInput label="Last Name column" source="fields.lastName" />
                  <BooleanInput
                    source="more.receiveByEmail"
                    label="resources.ExternalIntegration.more.receiveByEmail.label"
                    initialValue={false}
                  />
                  <SelectInput
                    source="status"
                    label="resources.ExternalIntegration.fields.status.label"
                    choices={[
                      { id: 'INACTIVE', name: 'INACTIVE' },
                      { id: 'ACTIVE', name: 'ACTIVE' },
                    ]}
                  />
                </>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }: any) =>
              formData.integrationType === 'GOOGLE_ADMIN_API' && (
                <>
                  <TextInput disabled label="Status" source="status" />
                  <ArrayInput
                    label="resources.CorporateCompany.fields.blacklistedOrganizationUnits.label"
                    source="more.blacklistedOrganizationUnits"
                    className={classes.organizationUnitsList}
                  >
                    <SimpleFormIterator>
                      <TextInput
                        source="value"
                        placeholder={translate(
                          'resources.CorporateCompany.fields.blacklistedOrganizationUnits.addPlaceholder'
                        )}
                        label="resources.CorporateCompany.fields.blacklistedOrganizationUnits.addLabel"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <TextInput
                    label="Keep Organization Unit Regex"
                    source="more.keepOrganizationUnitsRegex"
                  />
                  <TextInput label="Ignore Email regex" source="more.ignoreEmailRegex" />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ExternalIntegrationEdit = translate(
  withStyles(styles)(ExternalIntegrationEditWithoutStyle)
);
