import gql from 'graphql-tag';

import { formatGroup } from './shared';

const UPDATE_ACTIVITY_MUTATION = gql`
  mutation BO_UpdateActivityGroup($input: BO_UpdateActivityGroupInput!) {
    data: BO_UpdateActivityGroup(input: $input) {
      activityGroup {
        id
        slug
        translations {
          fr
          en
          nl
        }
        activities {
          id
        }
      }
    }
  }
`;

export const Update = () => {
  return (params: any) => {
    return {
      query: UPDATE_ACTIVITY_MUTATION,
      variables: {
        input: {
          id: params.data.id,
          translations: params.data.translations,
          activityIDs: params.data.activityIDs,
        },
      },
      parseResponse: (ret: any) => {
        if (!ret.data) return { data: {} };
        return { data: formatGroup(ret.data.data.activityGroup) };
      },
    };
  };
};
