import gql from 'graphql-tag';

import { FormatCorporation } from './CorporationFormatter';

const GET_ONE_QUERY = gql`
  query companyById($id: ID!) {
    data: companyById(id: $id) {
      name
      slug
      id
      country
      stripeAccount
      logo
      createdAt
      chargeCorporate
      plans {
        price
        category
        categoryDisplayName
      }
      validEmployeeEmailDomains
      validEmployeeEmails
      validEmployeesAsString
      emailBlacklistRegex
      isSponsorshipPlatform
      sponsorshipPlatform
      active
      plans {
        id
        commitmentInMonth
        priceWithoutDiscount
        price
        category
        categoryDisplayName
        credits
      }
      creditCounterpart {
        creditCompany {
          id
          active
        }
      }
      productPriceGroup {
        id
        name
      }
      discounts {
        id
        startDate
        endDate
        percentOff
        amountOff
        type
        stripeCouponID
        usageCount
      }
      hasFeed
      hasChallenge
      sendWelcomeEmail
      canBeListedPublicly
      defaultUserLocale
      showIdentifiersOnDashboard
      usersCanRegisterWithIdentifier
      signupInputPlaceholder {
        fr
        en
        nl
      }
      signupInputHelpingText {
        fr
        en
        nl
      }
      companyInvitationLink
      signupInputName {
        fr
        en
        nl
      }
      billingLink
      reportingLink
      hideCompanyChannel
      sfAccountId
      allowListIntegrationScopeId
      delegateEligibilityEventsHandlingToHris
    }
  }
`;

export const GetOne = (params: any) => {
  return {
    variables: params,
    // Override the query
    query: GET_ONE_QUERY,
    parseResponse: (resp: any) => {
      if (!resp.data) return { data: {} };
      return { data: FormatCorporation(resp.data.data) };
    },
  };
};
