import CorporationIcon from '@material-ui/icons/Domain';

import { CorporationList } from './CorporationList';
import { CorporationEdit } from './CorporationEdit';
import { CorporationCreate } from './CorporationCreate';
import corporationProvider from './dataProvider';
import { IResourceExport } from '../../@types/dataProvider';

export default {
  name: 'CorporateCompany',
  resources: {
    list: CorporationList,
    edit: CorporationEdit,
    create: CorporationCreate,
  },
  icon: CorporationIcon,
  dataProvider: {
    getOneName: 'company',
    useCustomDP: true,
    customDataProvider: corporationProvider,
  },
} as IResourceExport;
