import { AllPossibleRequestResolver } from '../../../@types/dataProvider';

import { GetList, GetMany } from './Many';
import { GetOne } from './GetOne';
import { Create } from './Create';

export default (_: any): AllPossibleRequestResolver => {
  return {
    GET_LIST: GetList(),
    GET_MANY: GetMany(),
    GET_ONE: GetOne(),
    CREATE: Create(),
  };
};
