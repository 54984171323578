import { IResourceExport } from '../../@types/dataProvider';
import HrManagerIcon from '@material-ui/icons/SupervisorAccount';

import { HrManagerList } from './HrManagerList';
import { HrManagerCreate } from './HrManagerCreate';
import { HrManagerEdit } from './HrManagerEdit';

import hrManagerProvider from './dataProvider';

export default {
  name: 'HrManager',
  resources: {
    list: HrManagerList,
    edit: HrManagerEdit,
    create: HrManagerCreate,
  },
  icon: HrManagerIcon,
  dataProvider: {
    getOneName: 'hrManager',
    useCustomDP: true,
    customDataProvider: hrManagerProvider,
  },
} as IResourceExport;
