import React from 'react';
import { NumberInput, useTranslate } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useForm } from 'react-final-form';

export const CustomPunchControl = ({ classes, formData, ...props }: any) => {
  const translate = useTranslate();
  const form = useForm();
  const [customPunch, setCustomPunch] = React.useState(!!formData.punchRadius);

  return (
    <div className={classes.halfLine}>
      <FormControlLabel
        control={
          <Switch
            defaultChecked={!!formData.punchRadius}
            onChange={(event) => {
              setCustomPunch(event.target.checked);
              if (!event.target.checked) {
                form.change('punchRadius', 0);
              } else {
                if (formData.punchRadius)
                  form.change('punchRadius', formData.punchRadius);
              }
            }}
            name="checkedB"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        label={translate('resources.Gym.fields.customPunchRadius.label')}
      />
      {customPunch && (
        <NumberInput
          {...props}
          source="punchRadius"
          label="resources.Gym.fields.punchRadius.label"
          placeholder={translate('resources.Gym.fields.punchRadius.placeholder')}
          className={classes.addressFields}
        />
      )}
    </div>
  );
};

export default CustomPunchControl;
