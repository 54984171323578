import { Button } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import {
  UpdateUserEmailsModal,
  UpdateUserEmailsModalProps,
} from './UpdateUserEmailsModal';

type UpdateUserEmailsButtonProps = Pick<UpdateUserEmailsModalProps, 'companyID'>;

export const UpdateUserEmailsButton = ({ companyID }: UpdateUserEmailsButtonProps) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('resources.CorporateCompany.actions.updateUserEmails.button')}
      </Button>

      <UpdateUserEmailsModal
        companyID={companyID}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
