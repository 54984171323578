import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import { DisplayFromComponent } from './DisplayFromComponent';
import { useTranslate } from 'ra-core';

export const GymManagerCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
      <SimpleForm redirect="list" initialValues={{ some: 'value' }}>
        <TextInput source="email" validate={required('Email required')} />
        <ReferenceArrayInput
          {...props}
          source="gymIDs"
          reference="Gym"
          perPage={30}
          sort={{ field: `name`, order: 'ASC' }}
          validate={required('Gym selection is mandatory')}
        >
          <AutocompleteArrayInput
            {...props}
            shouldRenderSuggestions={(val: string) => {
              return val.trim().length > 2;
            }}
          />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData }: any) => (
            <DisplayFromComponent formData={formData} translate={translate} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
