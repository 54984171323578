import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  SelectField,
  TextField,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
  FunctionField,
} from 'react-admin';
import { translate } from 'ra-core';

import BulkActionButtons from './BulkActionButtons';

const PassFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="code" alwaysOn resettable />
    <ReferenceInput source="user" reference="User" allowEmpty>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput source="gym" reference="Gym" allowEmpty>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <DateInput source="startDate" allowEmpty />
    <DateInput source="endDate" allowEmpty />
  </Filter>
);

const PassListWithoutTranslate = (props: any) => (
  <List
    {...props}
    bulkActionButtons={<BulkActionButtons />}
    filters={<PassFilter />}
    undoable="false"
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="code" sortable={false} />
      <SelectField
        source="passType"
        choices={[
          { id: 'MONTH_1', name: '1 mois' },
          { id: 'MONTHS_3', name: '3 mois' },
          { id: 'VISIT_1', name: '1 entrée' },
          { id: 'VISITS_5', name: '5 entrées' },
          { id: 'VISITS_10', name: '10 entrées' },
        ]}
      />
      <ReferenceField
        label="resources.Pass.fields.userEmail"
        source="user.id"
        reference="User"
        sortable={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="resources.Pass.fields.gymName"
        source="gym.id"
        reference="Gym"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="startDate" label="resources.Pass.fields.startDate" showTime />
      <DateField source="transferredAt" showTime />
      <DateField source="lastValidationDate" showTime />
      <DateField
        source="erpPassInfos.cancelledAt"
        label="resources.Pass.fields.cancelledAt"
        showTime
      />
      <DateField source="expirationDate" showTime />
      <FunctionField
        label="Status"
        render={(record: any) =>
          props.translate(
            'resources.Pass.fields.passStatus.' + ((record && record.status) || 'unknown')
          )
        }
      />
      <BooleanField source="isERP" sortable={false} />
    </Datagrid>
  </List>
);

export const PassList = translate(PassListWithoutTranslate);
