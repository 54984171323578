import React, { useState } from 'react';
import { DateInput } from 'react-admin';

import Switch from '@material-ui/core/Switch';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from 'react-final-form';

export const DisplayFromComponent = ({ formData, translate }: any) => {
  const form = useForm();
  const [showDisplayFrom, setShowDisplayFrom] = useState(!!formData.displayFrom);
  return (
    formData &&
    form && (
      <>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={!!formData.displayFrom}
              onChange={event => {
                setShowDisplayFrom(event.target.checked);

                if (!event.target.checked) {
                  form.change('displayFrom', null);
                } else {
                  if (formData.displayFrom)
                    form.change('displayFrom', formData.displayFrom);
                }
              }}
              name="checkedB"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label={translate('resources.Gym.fields.displayFrom.switch.label')}
        ></FormControlLabel>
        {showDisplayFrom && (
          <DateInput
            source="displayFrom"
            label="resources.Gym.fields.displayFrom.label"
          />
        )}
      </>
    )
  );
};
