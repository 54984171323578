import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

const ServiceTitle = ({ record }: any) => {
  return <span>Service {record ? `"${record.slug}"` : ''}</span>;
};

export const ServiceEdit = (props: any) => {
  return (
    <Edit {...props} undoable={false} title={<ServiceTitle />}>
      <SimpleForm>
        <TextInput disabled source="slug" />
        <TextInput
          label="resources.TranslatedContents.frenchName"
          source="name.fr"
          validate={required('The French Translation is required')}
        />
        <TextInput
          label="resources.TranslatedContents.englishName"
          source="name.en"
          validate={required('The English Translation is required')}
        />
        <TextInput
          label="resources.TranslatedContents.dutchName"
          source="name.nl"
          validate={required('The Dutch Translation is required')}
        />
        <TextInput
          label="resources.services.icon"
          source="iconName"
          validate={required('Icon is required')}
        />
        <TextInput disabled source="serviceID" label="WP ID" />
      </SimpleForm>
    </Edit>
  );
};
