import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import { useGetList, useMutation } from 'react-admin';
import ChallengeCard from './ChallengeCard';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialogContent: { width: 600 },
  inputLabel: { marginBottom: 8 },
  challengeCard: { display: 'flex', flexDirection: 'row', gap: '16px' },
  datePicker: { marginTop: 16 },
  inputLabelCSV: { marginTop: 16 },
  inputCSV: { display: 'block', marginTop: 8 },
});

const Home = () => {
  const t = useTranslate();
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [open, setOpen] = useState(false);
  const [companySlugs, setCompanySlugs] = useState<Array<String>>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const classes = useStyles();

  const { data: challengeTemplatesData } = useGetList('ChallengeTemplate', {
    page: 1,
    perPage: 20,
  });

  const challengeTemplatesArray = Object.values(challengeTemplatesData || {});

  const handleCardClick = (id: any) => {
    setSelectedTemplateId(id);
  };

  const sanitizeSlugs = (input: string) => {
    const trimmedInput = input.trim();
    const companySlug = trimmedInput.split(/\s+/);
    if (companySlug && companySlug.length) {
      const uniqueSlugs = companySlug.filter((element, index) => {
        return companySlug.indexOf(element) === index;
      });
      setCompanySlugs(uniqueSlugs);
    } else setCompanySlugs([]);
  };

  const handleSubmit = () => {
    setCompanySlugs([]);
    setSelectedTemplateId(null);
    setSelectedDate(null);
    setOpen(false);
    publish();
  };
  const [publish] = useMutation({
    type: 'create',
    resource: 'Challenge',
    payload: {
      data: {
        input: {
          templateID: selectedTemplateId,
          publishDate: selectedDate ? selectedDate.toISOString() : null,
          companySlugs: companySlugs,
        },
      },
    },
  });

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {t('challenge.title.1')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'xl'}>
        <DialogTitle>{t('resources.Challenge.createChallenge')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <InputLabel id="challenge-templates" className={classes.inputLabel}>
            {t('challenge.choiceTemplate.label')}
          </InputLabel>
          <div className={classes.challengeCard}>
            {challengeTemplatesArray.map((template: any) => (
              <ChallengeCard
                isSelected={template.id === selectedTemplateId}
                id={template.id}
                image={template.picture}
                name={template.name}
                onClick={handleCardClick}
              />
            ))}
          </div>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              placeholder={t('challenge.publishDialog.datePickerPlaceholder')}
              disablePast
              format="DD/MM/YY"
              shouldDisableDate={(day) => Boolean(day && moment(day).isoWeekday() !== 1)}
              value={selectedDate}
              onChange={setSelectedDate}
              className={classes.datePicker}
            />
          </MuiPickersUtilsProvider>

          <InputLabel id="company-text-area-slugs" className={classes.inputLabelCSV}>
            {t('challenge.input.company.slugs.label')}
          </InputLabel>

          <textarea
            cols={65}
            placeholder={'companySlug1\ncompanySlug2'}
            onChange={(e) => {
              sanitizeSlugs(e.target.value);
            }}
          />

          <DialogActions>
            <Button
              disabled={!companySlugs.length || !selectedDate || !selectedTemplateId}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t('challenge.button')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;
